import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import { AxiosInstance } from "../../configration/AxiosConfiguration";

const LabOrder = () => {
  const [testNames, setTestNames] = useState([""]);
  const [rows, setRows] = useState({
    hsnSac: "",
    quantity: 0,
    priceUnit: 0,
    taxableAmount: 0,
    gst: 0,
    totalAmount: 0,
  });
  const [formState, setFormState] = useState({
    clientType: "",
    clientName: "",
    clientEmail: "",
    labName: "",
    labContactNo: "",
    clientContactNo: "",
    testCollectionDateTime: "",
    clientAddress: "",
    testCollectionDate: "",
    testCollectionTime: "",
    medicalCondition: "",
    typeOfTest: "",
    labId: 0,
  });
  const [todayDate, setTodayDate] = useState("");
  const [LabDetails, setLabDetails] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      console.log("Selected file:", file);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = `${String(currentDate.getDate()).padStart(
      2,
      "0"
    )}-${String(currentDate.getMonth() + 1).padStart(
      2,
      "0"
    )}-${currentDate.getFullYear()}`;
    setTodayDate(formattedDate);
    AxiosInstance.get("corporate-order/getMedicalLabs")
      .then((response) => {
        if (response != null) {
          setLabDetails(response);
        } else {
          setLabDetails([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "labName") {
      const selectedLab = LabDetails.find((lab) => lab.labName === value);
      setFormState({
        ...formState,
        labName: value,
        labContactNo: selectedLab ? selectedLab.labContact : "",
        labId: selectedLab ? selectedLab.labId : null,
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleDateTimeChange = (e) => {
    const { name, value } = e.target;
    const newFormState = { ...formState, [name]: value };
    const { testCollectionDate, testCollectionTime } = newFormState;

    if (testCollectionDate && testCollectionTime) {
      const dateTime = new Date(
        `${testCollectionDate}T${testCollectionTime}:00`
      ).toISOString();
      newFormState.testCollectionDateTime = dateTime;
    }

    setFormState(newFormState);
  };

  const handleAddTestName = () => {
    setTestNames([...testNames, ""]);
    // setRows([
    //   ...rows,
    //   {
    //     hsnSac: "",
    //     quantity: 0,
    //     priceUnit: 0,
    //     taxableAmount: 0,
    //     gst: 0,
    //     totalAmount: 0,
    //   },
    // ]);
  };

  const handleTestNameChange = (index, value) => {
    const updatedTestNames = [...testNames];
    updatedTestNames[index] = value;
    setTestNames(updatedTestNames);
  };

  const handleRowChange = (field, value) => {
    const updatedRow = {
      ...rows,
      [field]:
        field === "quantity" || field === "priceUnit" ? parseInt(value) : value,
    };

    if (field === "quantity" || field === "priceUnit") {
      updatedRow.taxableAmount = updatedRow.quantity * updatedRow.priceUnit;
      updatedRow.gst = parseInt(updatedRow.taxableAmount * 0.18);
      updatedRow.totalAmount = updatedRow.taxableAmount + updatedRow.gst;
    }

    setRows(updatedRow);
  };

  const getCommaSeparatedTestNames = () => {
    return testNames.join(",");
  };

  const createMultipartFormData = (formData, employeeFile) => {
    const multipartFormData = new FormData();

    if (formData.testCollectionDate) {
      const date = new Date(formData.testCollectionDate);
      formData.testCollectionDate = date; // Pass as Date object
    }

    // Add keys and values from the formData object
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        multipartFormData.append(key, value);
      }
    });

    // Add the file (if any)
    if (employeeFile) {
      multipartFormData.append("employeeFile", employeeFile);
    }

    return multipartFormData;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Example usage:
    const formData = {
      clientName: formState.clientName,
      typeOfClient: formState.clientType,
      clientEmail: formState.clientEmail,
      clientContact: formState.clientContactNo,
      testCollectionDate: formState.testCollectionDateTime,
      testCollectionTime: formState.testCollectionTime,
      labId: formState.labId,
      labName: formState.labName,
      medicalCondition: formState.medicalCondition,
      typeOfTest: formState.typeOfTest,
      labContact: formState.labContactNo,
      clientAddress: formState.clientAddress,
      testNames: testNames.length > 0 ? getCommaSeparatedTestNames() : "",
      hsnSac: rows.hsnSac,
      quantity: rows.quantity,
      priceUnit: rows.priceUnit,
      taxableAmount: rows.taxableAmount,
      gst: rows.gst,
      totalAmount: rows.totalAmount,
    };

    const employeeFile = selectedFile; // Assuming this is the file object from input[type="file"]
    const multipartFormData = createMultipartFormData(formData, employeeFile);

    // Log the multipart form data for inspection
    for (let pair of multipartFormData.entries()) {
      console.log(pair[0], pair[1]);
    }

    AxiosInstance.post("corporate-order/createOrder", multipartFormData)
      .then((response) => {
        toast.success("Order Created successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Phone</b>
              </Label>
              <Input
                disabled
                id=""
                name="name"
                value={7506753773}
                type="number"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Email</b>
              </Label>
              <Input
                disabled
                value="anjan@livingscopehealth.com"
                type="email"
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>State</b>
              </Label>
              <Input disabled value="Maharastra" type="text" />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>GSTIN</b>
              </Label>
              <Input
                disabled
                id=""
                name="name"
                value="27AAFCL3118G1ZP"
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleEmail">
                <b>Address</b>
              </Label>
              <Input
                disabled
                id=""
                name="name"
                value="HD 090, Chromium, Milind Nagar, Powai-400076"
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <hr style={{ border: "1px solid #000000" }}></hr>

        <Card className="LabOrderDetailCard">
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="todayDate">
                  <b>Today's Date:</b>
                </Label>
                <Input
                  disabled
                  id="todayDate"
                  name="todayDate"
                  type="text"
                  value={todayDate}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="clientType">
                  <b>Type of Client:</b>
                </Label>
                <Input
                  id="clientType"
                  name="clientType"
                  type="select"
                  value={formState.clientType}
                  onChange={handleChange}
                >
                  <option value="">Select Client Type</option>
                  <option value="Corporate">Corporate</option>
                  <option value="Consumer">Consumer</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientName">
                  <b>Client Name:</b>
                </Label>
                <Input
                  id="clientName"
                  name="clientName"
                  type="text"
                  value={formState.clientName}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="clientEmail">
                  <b>Client Email:</b>
                </Label>
                <Input
                  id="clientEmail"
                  name="clientEmail"
                  type="email"
                  value={formState.clientEmail}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="labName">
                  <b>Lab Name:</b>
                </Label>
                {/* <Input
                  id="labName"
                  name="labName"
                  type="text"
                  value={formState.labName}
                  onChange={handleChange}
                /> */}
                <Input
                  id="labName"
                  name="labName"
                  type="select"
                  value={formState.labName}
                  onChange={handleChange}
                >
                  <option value="">Select Lab</option>
                  {LabDetails?.map((lab) => (
                    <option key={lab.labId} value={lab.labName}>
                      {lab.labName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="labContactNo">
                  <b>Lab Contact No:</b>
                </Label>
                <Input
                  id="labContactNo"
                  name="labContactNo"
                  type="number"
                  maxLength={10}
                  value={formState.labContactNo}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="clientContactNo">
                  <b>Client Contact No:</b>
                </Label>
                <Input
                  id="clientContactNo"
                  name="clientContactNo"
                  type="number"
                  maxLength={10}
                  value={formState.clientContactNo}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="testCollectionDate">
                  <b>Test Collection Date:</b>
                </Label>
                <Input
                  id="testCollectionDate"
                  name="testCollectionDate"
                  type="date"
                  value={formState.testCollectionDate}
                  onChange={handleDateTimeChange}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="testCollectionTime">
                  <b>Test Collection Time:</b>
                </Label>
                <Input
                  id="testCollectionTime"
                  name="testCollectionTime"
                  type="time"
                  value={formState.testCollectionTime}
                  onChange={handleDateTimeChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="typeOfTest">
                  <b>Type Of Test:</b>
                </Label>
                <Input
                  id="typeOfTest"
                  name="typeOfTest"
                  type="select"
                  value={formState.typeOfTest}
                  onChange={handleChange}
                >
                  <option value="">Select Test</option>
                  <option value="Client Required">Client Required</option>
                  <option value="Preventive">Preventive</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="medicalCondition">
                  <b>Medical Condition:</b>
                </Label>
                <Input
                  id="medicalCondition"
                  name="medicalCondition"
                  type="text"
                  value={formState.medicalCondition}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="clientAddress">
                  <b>Client Address (Test collection site):</b>
                </Label>
                <Input
                  id="clientAddress"
                  name="clientAddress"
                  type="text"
                  value={formState.clientAddress}
                  onChange={handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Card>

        <TableContainer
          component={Paper}
          sx={{ border: "1px solid black", borderRadius: "5px" }}
          elevation={5}
          className="mt-3 mb-3"
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  Test Name
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  HSN/SAC
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  Price Unit
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  Taxable Amount
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  GST (18%)
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: "1px solid black",
                    backgroundColor: "#c60176",
                    color: "white",
                  }}
                >
                  Total Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {testNames.map((testName, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ borderRight: "1px solid black" }}>
                    <TextField
                      value={testName}
                      onChange={(e) =>
                        handleTestNameChange(index, e.target.value)
                      }
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  {index === 0 && (
                    <>
                      <TableCell
                        rowSpan={testNames.length}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        <TextField
                          value={rows.hsnSac}
                          onChange={(e) =>
                            handleRowChange("hsnSac", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        rowSpan={testNames.length}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        <TextField
                          type="number"
                          value={rows.quantity}
                          onChange={(e) =>
                            handleRowChange("quantity", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        rowSpan={testNames.length}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        <TextField
                          type="number"
                          value={rows.priceUnit}
                          onChange={(e) =>
                            handleRowChange("priceUnit", e.target.value)
                          }
                          variant="outlined"
                          size="small"
                        />
                      </TableCell>
                      <TableCell
                        rowSpan={testNames.length}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {rows.taxableAmount.toFixed(2)}
                      </TableCell>
                      <TableCell
                        rowSpan={testNames.length}
                        sx={{ borderRight: "1px solid black" }}
                      >
                        {rows.gst.toFixed(2)}
                      </TableCell>
                      <TableCell rowSpan={testNames.length}>
                        {rows.totalAmount.toFixed(2)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTestName}
                  >
                    Add Test
                  </Button>
                </TableCell>
                {testNames.length > 1 && (
                  <>
                    <TableCell colSpan={6} />
                  </>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ marginBottom: "10px" }}>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <label htmlFor="fileInput">
            <Button
              component="span"
              sx={{
                background:
                  "linear-gradient(45deg, royalblue, mediumvioletred)", // Define your gradient here
                color: "white",
                padding: "10px 20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, mediumvioletred, royalblue)", // Optional hover effect
                },
              }}
            >
              Attach Employees Excel File
            </Button>
          </label>
          {selectedFile && (
            <Typography
              variant="body2"
              style={{ margin: "5px 0px 0px 0px", color: "gray" }}
            >
              {selectedFile.name}
            </Typography>
          )}
        </div>

        <Row>
          <b style={{ textDecoration: "underline" }}>Notes to Lab:</b>
          <ul className="adminnotes">
            <li>
              Please call our client promptly at the contact number provided
              above to coordinate the tests collection as per date and time
              mentioned above
            </li>
            <li>
              After tests are done and reports are available, please upload them
              on Uncurl: health app platform.
            </li>
            <li>
              If there are any questions regarding this order or the PO amount
              shown above does not match as per your quote, then please contact
              Living Scope Health at the number provided and resolve.
              <b>
                You are not supposed to discuss anything with our client
                regarding this purchase order or the amount stated in it
              </b>
            </li>
            <li>
              Invoice also to be attached on Uncurl: health app platform.
              Payment will be done though our payment portal within seven days
              after submission of invoice and all test reports on our Uncurl:
              health platform.
            </li>
          </ul>

          <div>
            <b>
              Note: This is an electronically generated order and so does not
              require any signature for approval
            </b>
          </div>
        </Row>
        <center>
          <Button
            onClick={handleSubmit}
            style={{
              marginTop: "4%",
              width: "15%",
              backgroundColor: "rgb(83, 83, 233)",
              color: "white",
            }}
          >
            Save
          </Button>
        </center>
      </Form>
    </>
  );
};

export default LabOrder;
