import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { ListChannelMembershipsForAppInstanceUserRequestFilterSensitiveLog, ListChannelMembershipsForAppInstanceUserResponseFilterSensitiveLog, } from "../models/models_0";
import { de_ListChannelMembershipsForAppInstanceUserCommand, se_ListChannelMembershipsForAppInstanceUserCommand, } from "../protocols/Aws_restJson1";
export { $Command };
export class ListChannelMembershipsForAppInstanceUserCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "ListChannelMembershipsForAppInstanceUser", {})
    .n("ChimeSDKMessagingClient", "ListChannelMembershipsForAppInstanceUserCommand")
    .f(ListChannelMembershipsForAppInstanceUserRequestFilterSensitiveLog, ListChannelMembershipsForAppInstanceUserResponseFilterSensitiveLog)
    .ser(se_ListChannelMembershipsForAppInstanceUserCommand)
    .de(de_ListChannelMembershipsForAppInstanceUserCommand)
    .build() {
}
