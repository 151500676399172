import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  CircularProgress,
  Autocomplete,
  TextField,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const stateList = [
  { id: 1, name: "Andhra Pradesh" },
  { id: 2, name: "Arunachal Pradesh" },
  { id: 3, name: "Assam" },
  { id: 4, name: "Bihar" },
  { id: 5, name: "Chattisgarh" },
  { id: 6, name: "Goa" },
  { id: 7, name: "Gujarat" },
  { id: 8, name: "Haryana" },
  { id: 9, name: "Himachal Pradesh" },
  { id: 10, name: "Jharkhand" },
  { id: 11, name: "Karnataka" },
  { id: 12, name: "Kerala" },
  { id: 13, name: "Madhya Pradesh" },
  { id: 14, name: "Maharashtra" },
  { id: 15, name: "Manipur" },
  { id: 16, name: "Meghalaya" },
  { id: 17, name: "Mizoram" },
  { id: 18, name: "Nagaland" },
  { id: 19, name: "Odisha" },
  { id: 20, name: "Punjab" },
  { id: 21, name: "Rajasthan" },
  { id: 22, name: "Sikkim" },
  { id: 23, name: "Tamil Nadu" },
  { id: 24, name: "Telangana" },
  { id: 25, name: "Tripura" },
  { id: 26, name: "Uttarakhand" },
  { id: 27, name: "Uttar Pradesh" },
  { id: 28, name: "West Bengal" },
  { id: 29, name: "Andaman and Nicobar Islands  (UT)" },
  { id: 30, name: "Chandigarh  (UT)" },
  { id: 31, name: "Dadra and Nagar Haveli and Daman & Diu  (UT)" },
  { id: 32, name: "Delhi  (UT)" },
  { id: 33, name: "Jammu & Kashmir  (UT)" },
  { id: 34, name: "Ladakh  (UT)" },
  { id: 35, name: "Lakshadweep  (UT)" },
  { id: 36, name: "Puducherry  (UT)" },
  { id: 37, name: "Other Territory  (UT)" },
];

const LabDocumentModal = ({ open, onClose, data, type = null }) => {
  const [fileList, setFileList] = useState(data);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoadingView, setIsLoadingView] = useState(false);

  const [selectedState, setSelectedState] = useState(null);

  // Filter rows based on the selected state
  const filteredRows = selectedState
    ? fileList.filter((row) => row.state === selectedState.name)
    : fileList;

  // Handle opening the View Modal
  const handleView = (file) => {
    setIsLoadingView(true);
    setSelectedFile(file);
    setViewModalOpen(true);
    const authToken = window.sessionStorage.getItem("token");
    const viewApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(file.documentLink);
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${viewApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch document: ${response.status}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        setSelectedFile({ ...file, blobUrl });
      })
      .catch((error) => {
        toast.error(`Error fetching the file: ${error}`);
        setViewModalOpen(false); // Close modal if fetch fails
      })
      .finally(() => {
        setIsLoadingView(false); // Stop loader
      });
  };

  // Handle closing the View Modal
  const closeViewModal = () => {
    if (selectedFile?.blobUrl) {
      window.URL.revokeObjectURL(selectedFile.blobUrl); // Clean up the blob URL
    }
    setSelectedFile(null);
    setViewModalOpen(false);
  };

  const handleDownload = (filePath) => {
    setIsDownloading(true);
    const downloadApi = "lab-partner/download-lab-file/LAB_DOCUMENT";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      })
      .finally(() => {
        setIsDownloading(false); // Stop loader
      });
  };

  const handleDelete = (filePath, file) => {
    const id = file.id;
    const deleteApi = "lab-partner/delete-lab-file";
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${deleteApi}/${id}/LICENSE_FILE`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              container: "swal-container-above-modal", // Custom class for Swal
            },
          });
          setFileList((prev) => prev.filter((item) => item.id !== file.id));
        } else {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
      })
      .catch((error) => {
        toast.error(`Error deleting the file: ${error}`);
      });
  };

  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };

  console.log("file", fileList);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              borderBottom: "1px solid #ccc",
              pb: 1,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              File List
            </Typography>
            <IconButton onClick={onClose} sx={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              mb: 4,
              display: "flex",
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Filter by State
            </Typography>
            <Autocomplete
              options={stateList}
              getOptionLabel={(option) => option.name}
              value={selectedState}
              onChange={(event, newValue) => setSelectedState(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select State"
                  variant="outlined"
                />
              )}
              sx={{
                minWidth: 300,
                backgroundColor: "white",
                boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                borderRadius: 1,
              }}
            />
          </Box>

          {filteredRows.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {type && (
                      <TableCell>
                        <strong>Iso Name</strong>
                      </TableCell>
                    )}
                    <TableCell>
                      <strong>Branch Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>City</strong>
                    </TableCell>
                    <TableCell>
                      <strong>State</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Expiry Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Document Path</strong>
                    </TableCell>
                    <TableCell>
                      <strong>View</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Download</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Delete</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.map((file, index) => (
                    <TableRow key={index}>
                      {type && (
                        <TableCell>
                          {file.license ? file.license : "N/A"}
                        </TableCell>
                      )}
                      <TableCell>
                        {file.branchName ? file.branchName : "N/A"}
                      </TableCell>
                      <TableCell>{file.city ? file.city : "N/A"}</TableCell>
                      <TableCell>{file.state ? file.state : "N/A"}</TableCell>
                      <TableCell>
                        {file.expiryDate
                          ? formatDateToDDMMYYYY(file.expiryDate)
                          : "Not Available"}
                      </TableCell>
                      <TableCell>{file.documentLink}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleView(file)}>
                          <VisibilityIcon color="action" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDownload(file.documentLink)}
                        >
                          <DownloadForOfflineIcon color="primary" />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleDelete(file.documentLink, file)}
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            // Empty Message
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
                No Files found.
              </Typography>
              <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
            </Box>
          )}

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>

      {isDownloading && (
        <Box
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {viewModalOpen && (
        <Modal open={viewModalOpen} onClose={closeViewModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              maxHeight: "90vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "1px solid #ccc",
                pb: 1,
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                Viewing: {selectedFile.license}
              </Typography>
              <IconButton onClick={closeViewModal} sx={{ color: "#000" }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {isLoadingView ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60vh",
                }}
              >
                <CircularProgress />
              </Box>
            ) : selectedFile?.blobUrl ? (
              <iframe
                src={selectedFile.blobUrl}
                title={selectedFile?.license}
                style={{ width: "100%", height: "60vh", border: "none" }}
              />
            ) : (
              <Typography color="error">Failed to load document.</Typography>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={closeViewModal}>
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default LabDocumentModal;
