import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Modal,
  Box,
  Typography,
  Grid,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
} from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import AccountManagerCommentModel from "./AccountManagerCommentModel";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import EditIcon from "@mui/icons-material/Edit";
import Spinner from "../../shared/Spinner";
const ApprovalsInvoice = () => {
  const [LabDetails, setLabDetails] = useState();
  const [labName, setLabName] = useState("");
  const [labPhoneNumber, setLabPhoneNumber] = useState("");
  const [invoiceData, setInvoiceData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openCommentModal, setOpenCommentModal] = useState(false); // Modal open state
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [formState, setFormState] = useState({
    labName: "",
    labId: "",
  });
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [updatedOrder, setUpdatedOrder] = useState({});
  const [comment, setComment] = useState("");
  const [approvalStatus, setApprovalStatus] = useState("");
  const [UnApprovedInvoiceLabsLoading, setUnApprovedInvoiceLabsLoading] =
    useState(false);
  const [UnApprovedInvoiceLoading, setUnApprovedInvoiceLoading] =
    useState(false);
  const [errors, setErrors] = useState({
    approvalStatus: false,
    comment: false,
  });
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    setApprovalStatus("");
    setComment("");
  };

  const handleEdit = (order) => {
    setUpdatedOrder({ ...order }); // Create a copy of the order to edit
    toggleModal();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const InvoiceAmount =
      parseInt(
        name === "invoiceAmount" ? value : updatedOrder?.invoiceAmount
      ) || 0;

    const quantity =
      parseInt(
        name === "totalQuantity" ? value : updatedOrder?.totalQuantity
      ) || 0;
    const price =
      parseInt(name === "priceUnit" ? value : updatedOrder?.priceUnit) || 0;
    const gstRate = 0.18;

    // Calculate derived values
    const taxableAmount = quantity * price;
    const gst =
      name === "gst" ? parseInt(value) || 0 : parseInt(taxableAmount * gstRate);
    const totalAmount = taxableAmount + gst;

    // Update state with the new values
    setUpdatedOrder((prevOrder) => ({
      ...prevOrder,
      [name]: value, // Update the specific field being changed
      priceUnit: price,
      totalQuantity: quantity,
      taxableAmount: taxableAmount, // Update taxable amount
      gst: gst, // Update GST
      totalAmount: totalAmount, // Update total amount
      invoiceAmount: InvoiceAmount,
    }));
  };

  const handleOpenCommentModal = (invoice) => {
    setSelectedInvoice(invoice); // Set the selected invoice data
    setOpenCommentModal(true); // Open the modal
  };

  // Close the comment modal
  const handleCloseCommentModal = () => {
    setOpenCommentModal(false); // Close the modal
    setSelectedInvoice(null); // Clear selected invoice data
  };

  useEffect(() => {
    setUnApprovedInvoiceLabsLoading(true);
    AxiosInstance.get("admin/get-unApproved-invoice-labs")
      .then((response) => {
        setLabPhoneNumber(response?.labContact);
        if (response != null) {
          setLabDetails(response);
        } else {
          setLabDetails([]);
        }
        setUnApprovedInvoiceLabsLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setUnApprovedInvoiceLabsLoading(false);
      });
  }, []);
  const handleCloseModal = () => setOpenModal(false);
  useEffect(() => {
    reloadInvoiceData();
  }, [formState.labId]);

  useEffect(() => {
    if (LabDetails?.length > 0 && !formState.labId) {
      const firstLab = LabDetails[0];
      setFormState({
        labName: firstLab.labName,
        labId: firstLab.labId,
      });
      setLabPhoneNumber(firstLab?.labContact);
      setLabName(firstLab?.labOwnerName);
    }
  }, [LabDetails]);

  const handleSubmit = (invoiceDetails) => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "labName") {
      const selectedLab = LabDetails.find((lab) => lab.labName === value);
      setFormState({
        ...formState,
        labName: value,
        labContactNo: selectedLab ? selectedLab.labContact : "",
        labId: selectedLab ? selectedLab.labId : null,
      });
      setLabPhoneNumber(selectedLab?.labContact);
      setLabName(selectedLab?.labOwnerName);
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  let token = window.sessionStorage.getItem("token");
  let myHeaders = new Headers();
  myHeaders.append("accept", "multipart/mixed");
  myHeaders.append("Authorization", "Bearer " + token);

  let requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const handleInvoiceDownload = async (docLink) => {
    const downloadApi = "admin/download-invoice-file";
    let token = window.sessionStorage.getItem("token");
    let myHeaders = new Headers();
    myHeaders.append("accept", "multipart/mixed");
    myHeaders.append("Authorization", "Bearer " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const encodedPath = encodeURIComponent(docLink);
      const responseDoc = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedPath}`,
        requestOptions
      );

      if (!responseDoc.ok) {
        throw new Error(`Network response was not ok: ${responseDoc.status}`);
      }

      const contentDispositionHeader = responseDoc.headers.get(
        "Content-Disposition"
      );

      if (contentDispositionHeader) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDispositionHeader
        );
        if (matches != null && matches[1]) {
          const filename = matches[1].replace(/['"]/g, "");
          responseDoc.blob().then((blobData) => {
            const blobUrl = window.URL.createObjectURL(blobData);

            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = filename;
            a.click();

            window.URL.revokeObjectURL(blobUrl);
          });
        } else {
          console.error("Filename not found in Content-Disposition header");
        }
      } else {
        console.error("Content-Disposition header not found in the response");
      }
    } catch (error) {
      console.error("Error fetching or downloading the file:", error);
    }
  };

  const reloadInvoiceData = () => {
    if (formState?.labId) {
      setUnApprovedInvoiceLoading(true);
      AxiosInstance.get(`admin/get-unApprove-invoice/${formState?.labId}`)
        .then((response) => {
          if (response) {
            setInvoiceData(response);
          } else {
            setInvoiceData([]);
          }
          setUnApprovedInvoiceLoading(false);
        })
        .catch((err) => {
          console.log("err: ", err);
          setUnApprovedInvoiceLoading(false);
        });
    }
  };
  const handleUpdate = async () => {
    if (updatedOrder) {
      console.log("updated", updatedOrder);
      let newErrors = {
        approvalStatus: !approvalStatus,
        comment: !comment,
      };
      setErrors(newErrors);
      if (!approvalStatus || !comment) {
        return;
      } else {
        const formData = {
          totalAmount: updatedOrder.totalAmount,
          invoiceAmount: updatedOrder.invoiceAmount,
          priceUnit: updatedOrder.priceUnit,
          taxableAmount: updatedOrder.taxableAmount,
          quantity: updatedOrder.totalQuantity,
          gst: updatedOrder.gst,
          comment: comment,
          status: approvalStatus,
        };
        try {
          await AxiosInstance.post(
            `/admin/update-invoice-order/${updatedOrder.orderId}/${updatedOrder.invoiceType}`,
            formData
          )
            .then((response) => {
              toast.success(
                approvalStatus === "Approved"
                  ? "Invoice Approved Successfully"
                  : "Invoice Declined Successfully"
              );
              toggleModal();
              reloadInvoiceData();
            })
            .catch((err) => {
              console.log("Upload error:", err);
            });
        } catch (error) {
          console.error("Error uploading invoice:", error);
        }
      }
      // Call the API to update the order
    }
  };
  const radioButtonStyle = (status) => {
    return approvalStatus === status
      ? {
          backgroundColor: status === "Approved" ? "#c8e6c9" : "#EC9CA8FF",
          borderRadius: "4px",
        }
      : {};
  };

  return (
    <>
      {UnApprovedInvoiceLabsLoading ? (
        <Spinner />
      ) : (
        <Form
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            marginTop: "25px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            fontSize: "18px",
          }}
        >
          <h5>Select Lab Name : </h5>
          <hr style={{ border: "1px solid #000000", width: "12%" }}></hr>
          <Card className="LabOrderDetailCard">
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="labName">
                    <b>Lab Name:</b>
                  </Label>
                  <Input
                    id="labName"
                    name="labName"
                    type="select"
                    value={formState.labName}
                    onChange={handleChange}
                  >
                    {LabDetails?.map((lab) => (
                      <option key={lab.labId} value={lab.labName}>
                        {lab.labName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="labName">
                    <b>Lab Owner Name:</b>
                  </Label>
                  <Input
                    id="labName"
                    name="labName"
                    type="text"
                    value={labName}
                    disabled
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="labName">
                    <b>Lab Phone Number:</b>
                  </Label>
                  <Input
                    id="labPhoneNumber"
                    name="labPhoneNumber"
                    type="text"
                    value={labPhoneNumber}
                    disabled
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Card>
          <br></br>
          {UnApprovedInvoiceLoading ? (
            <Spinner></Spinner>
          ) : (
            formState?.labId && (
              <Card className="LabOrderDetailCard">
                <h3>Invoice</h3>
                <p style={{ marginTop: "1%" }}>
                  <b>
                    System will automatically generate the invoice information,
                    and you will be able to attach your invoice for payment.
                  </b>
                </p>
                <TableContainer>
                  <Table
                    style={{
                      border: "1px solid lightgrey",
                      marginTop: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="tableCell">
                          LSH Order ID
                        </TableCell>
                        <TableCell className="tableCell">
                          Invoice Month/Year
                        </TableCell>
                        <TableCell className="tableCell">
                          Total Orders
                        </TableCell>
                        <TableCell className="tableCell">
                          Total Amount
                        </TableCell>
                        <TableCell className="tableCell">
                          Invoice File
                        </TableCell>
                        <TableCell className="tableCell">
                          Invoice Date
                        </TableCell>
                        <TableCell className="tableCell">
                          Invoice Amount
                        </TableCell>
                        <TableCell className="tableCell">
                          Total Confirmed?
                        </TableCell>
                        <TableCell className="tableCell">
                          Invoice Approval
                        </TableCell>
                        <TableCell className="tableCell">
                          Account Mgr. Comments
                        </TableCell>
                        <TableCell className="tableCell">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoiceData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.orderId}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.orderDate}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.totalQuantity}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.totalAmount}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            <span
                              style={{
                                color: "royalblue",
                                fontWeight: 600,
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleInvoiceDownload(row.invoiceFile);
                              }}
                            >
                              {row.invoiceFile}
                            </span>
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.invoiceDate}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.invoiceAmount}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.totalConfirmed}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {row.approval}
                          </TableCell>
                          <TableCell style={{ border: "1px solid black" }}>
                            {
                              row.managerComment || "-"
                              // ? (
                              //   row.managerComment
                              // ) : (
                              //   <Button
                              //     variant="contained"
                              //     onClick={() => handleOpenCommentModal(row)}
                              //   >
                              //     Add Comment
                              //   </Button>
                            }
                          </TableCell>
                          <TableCell
                            style={{
                              border: "1px solid black",
                            }}
                          >
                            <IconButton onClick={() => handleEdit(row)}>
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            )
          )}
        </Form>
      )}

      <Modal
        open={modal}
        onClose={toggleModal}
        aria-labelledby="edit-order-modal"
        aria-describedby="edit-order-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#f5fafd",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Card
            sx={{
              mt: 2,
              p: 3,
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#007acc",
                marginBottom: 3,
                textAlign: "center",
              }}
            >
              Edit Order Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Total Quantity"
                  name="totalQuantity"
                  value={updatedOrder?.totalQuantity}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Price"
                  name="priceUnit"
                  value={updatedOrder?.priceUnit || 0}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Taxable Amount"
                  name="taxableAmount"
                  value={updatedOrder?.taxableAmount || 0}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Gst(18%)"
                  name="gst"
                  value={updatedOrder?.gst || 0}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                  inputProps={{ min: 0 }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Invoice Amount"
                  name="invoiceAmount"
                  value={updatedOrder?.invoiceAmount || 0}
                  onChange={handleInputChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#007acc" }}
                >
                  Total Amount : {updatedOrder?.totalAmount}
                </Typography>
              </Grid>
            </Grid>
          </Card>

          <Card
            sx={{
              mt: 2,
              p: 3,
              borderRadius: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: "#007acc",
                mb: 2,
                textAlign: "center",
              }}
            >
              Approval Section
            </Typography>

            <FormControl component="fieldset" required>
              <FormLabel component="legend">Approval Status</FormLabel>
              <RadioGroup
                value={approvalStatus}
                onChange={(e) => {
                  setApprovalStatus(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    approvalStatus: false,
                  }));
                }}
                row
              >
                <div
                  style={{
                    ...radioButtonStyle("Approved"),
                    position: "relative",
                    border: "1px solid",
                    borderRadius: "5px",
                  }}
                >
                  <FormControlLabel
                    value="Approved"
                    control={
                      <Radio
                        style={{
                          position: "absolute",
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                      />
                    }
                    label="Approve"
                    style={{ marginLeft: "12px" }}
                  />
                </div>
                <div
                  style={{
                    ...radioButtonStyle("Not Approved"),
                    marginLeft: "16px",
                    position: "relative",
                    border: "1px solid",
                    borderRadius: "5px",
                  }}
                >
                  <FormControlLabel
                    value="Not Approved"
                    control={
                      <Radio
                        style={{
                          position: "absolute",
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                      />
                    }
                    label="Decline"
                    style={{ marginLeft: "12px" }}
                  />
                </div>
              </RadioGroup>
              {errors.approvalStatus && (
                <Typography color="error">
                  Approval status is required
                </Typography>
              )}
            </FormControl>
            <TextField
              fullWidth
              label="Comment"
              type="text"
              className="mt-5"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  comment: false,
                }));
              }}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              error={errors.comment}
              helperText={errors.comment ? "Comment is required" : ""}
            />
          </Card>
          <Grid container justifyContent="flex-end" sx={{ marginTop: 3 }}>
            <Button
              variant="contained"
              onClick={handleUpdate}
              sx={{
                backgroundColor: "#007acc",
                color: "#fff",
                "&:hover": { backgroundColor: "#005fa3" },
              }}
            >
              Update
            </Button>
          </Grid>
        </Box>
      </Modal>
      {/* {selectedInvoice && (
        <AccountManagerCommentModel
          open={openCommentModal}
          onClose={handleCloseCommentModal}
          selectedInvoice={selectedInvoice}
          onCommentSubmit={reloadInvoiceData}
        />
      )} */}
    </>
  );
};
export default ApprovalsInvoice;
