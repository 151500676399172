import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Col, Input, Row, Toast } from "reactstrap";
import {
  Checkbox,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import Swal from "sweetalert2";
import { useAsyncError, useNavigate } from "react-router-dom";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModalForFileView from "../../shared/ModalForFileView";
const GymGeneralInfo = ({ onBack }) => {
  const navigate = useNavigate();
  const [environmentStep, setEnvironmentStep] = useState(0);
  const [sizeOfGym, setSizeOfGym] = useState("");
  const [gymestablised, setGymEstablised] = useState("");
  const [locationOfGym, setLocationOfGym] = useState("");
  const [isGroundLevel, setIsGroundLevel] = useState("");
  const [femaleStaffMemberPresent, setFemaleStaffMemberPresent] = useState("");
  const [describeGym, setDescribeGym] = useState("");
  const [gymTime, setGymTime] = useState("");
  const [gymTimeTo, setGymTimeTo] = useState("");
  const [staffMemberPresent, setStaffMemberPresent] = useState("");
  const [femaleOutfitSafety, setFemaleOutfitSafety] = useState("");
  const [memberAgeGroup, setMemberAgeGroup] = useState("");
  const [compositionInGender, setCompositionInGender] = useState("");
  const [memberGymAttract, setMemberGymAttract] = useState("");
  const [uploadEquipment, setUploadEquipment] = useState(null);
  const [uploadProgramFile, setUploadProgramFile] = useState(null);
  const [machineInstruction, setMachineInstruction] = useState("");
  const [timeLimitOnCardio, setTimeLimitOnCardio] = useState("");
  const [explainCardioTimeLimit, setExplainCardioTimeLimit] = useState("");
  const [otherFacility, setOtherFacility] = useState("");
  const [introductoryClinic, setIntroductoryClinic] = useState("");
  const [separateTraning, setSeparateTraining] = useState("");
  const [initialFitnessOrientation, setInitialFitnessOrientation] =
    useState("");
  const [areaDedicated, setAreaDedicated] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [fieldErrors4, setFieldErrors4] = useState({});
  const [fieldErrors5, setFieldErrors5] = useState({});
  const [fieldErrors6, setFieldErrors6] = useState({});
  const [fieldErrors7, setFieldErrors7] = useState({});
  const [fieldErrors8, setFieldErrors8] = useState({});
  const [gymOfferTesting, setGymOfferTesting] = useState("");
  const [freeTrailMembership, setFreeTrailMembership] = useState("");
  const [freeTrailMembershipExplain, setFreeTrailMembershipExplain] =
    useState("");
  const [getTour, setGetTour] = useState("");
  const [getTourTime, setGetTourTime] = useState("");
  const [getTourTimeTo, setGetTourTimeTo] = useState("");
  const [tourTimeNote, setTourTimeNote] = useState("");
  const [refundMembership, setRefundMembership] = useState("");
  const [differentMembersipPlan, setDifferentMembersipPlan] = useState("");
  const [explainDifferentMembershipPlan, setExplainDifferentMembershipPlan] =
    useState("");
  const [trainersQualified, setTrainersQualified] = useState("");
  const [areMaleTrainers, setAreMaleTrainers] = useState("");
  const [maleTrainerExpirence, setMaleTrainerExpirence] = useState("");
  const [maleWeekdaysFrom, setMaleWeekdaysFrom] = useState("");
  const [maleWeekdaysTo, setMaleWeekdaysTo] = useState("");
  const [maleWeekendsFrom, setMaleWeekendsFrom] = useState("");
  const [maleWeekendsTo, setMaleWeekendsTo] = useState("");
  const [maleWeekendsSundayFrom, setMaleWeekendsSundayFrom] = useState("");
  const [maleWeekendsSundayTo, setMaleWeekendsSundayTo] = useState("");
  const [femaleWeekdaysFrom, setFemaleWeekdaysFrom] = useState("");
  const [femaleWeekdaysTo, setFemaleWeekdaysTo] = useState("");
  const [femaleWeekendsFrom, setFemaleWeekendsFrom] = useState("");
  const [femaleWeekendsTo, setFemaleWeekendsTo] = useState("");
  const [femaleWeekendsSundayFrom, setFemaleWeekendsSundayFrom] = useState("");
  const [femaleWeekendsSundayTo, setFemaleWeekendsSundayTo] = useState("");
  const [femaleTrainerExpirence, setFemaleTrainerExpirence] = useState("");
  const [areFemaleTrainer, setAreFemaleTrainer] = useState("");
  const [anyManager, setAnyManager] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerQualification, setManagerQualification] = useState("");
  const [managerExpirence, setManagerExpirence] = useState("");
  const [managerAward, setManaegrAward] = useState("");
  const [explainManagerAward, setExplainManagerAward] = useState("");
  const [gymMaintainCleanliness, setGymMaintainCleanliness] = useState("");
  const [explainCleanliness, setExplainCleanliness] = useState("");
  const [cleaningStationPlaced, setCleaningStationPlaced] = useState("");
  const [hygieneStandard, setHygieneStandard] = useState("");
  const [properlyMaintained, setProperlyMaintained] = useState("");
  const [explainProperlyMaintained, setExplainProperlyMaintained] =
    useState("");
  const [gymOfferAmenities, setGymOfferAmenities] = useState("");
  const [kidsArea, setKidsArea] = useState("");
  const [onlyLadiesWorkoutSpace, setOnlyLadiesWorkoutSpace] = useState("");
  const [sauna, setSauna] = useState("");
  const [functionalTrainingArea, setFunctionalTrainingArea] = useState("");
  const [personalTrainer, setPersoanlTrainer] = useState("");
  const [wifi, setWifi] = useState("");
  const [proShop, setProShop] = useState("");
  const [otherAmenities, setOtherAmenities] = useState("");
  const [variousTrainingProgram, setVariousTrainingProgram] = useState("");
  const [groupFitnessClasses, setGroupFitnessClasses] = useState("");
  const [extraCharge, setExtraCharge] = useState("");
  const [promotionalCurrentPlan, setPromotionalCurrentPlan] = useState("");
  const [paymentPlan, setPaymentPlan] = useState("");
  const [programList, setProgramList] = useState("");
  const [attachPhoto, setAttachPhoto] = useState("");
  const [gymPhoto, setGymPhoto] = useState([]);
  const [gymfileUploading, setgymfileUploading] = useState(false);
  const [selectedGymFile, setselectedGymFile] = useState([]);
  const [gymPhotosModalOpen, setGymPhotosModalOpen] = useState(false);
  const [gymProgramFile, setProgramFile] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [gymName, setGymname] = useState("");
  const [kindOfEquipment, setKindOfEquipment] = useState([]);
  const [classesProgramList, setClassesProgramList] = useState(null);
  const [gymAreaPincode, setGymAreaPincode] = useState([""]);
  const [file, setFile] = useState(null);
  const [licence, setLicence] = useState([]);
  const [selectedLicenseFile, setselectedLicenseFile] = useState([]);
  const [licenseModalOpen, setLicenseModalOpen] = useState(false);
  const [licensefileUploading, setlicensefileUploading] = useState(false);
  const [classDescription, setClassDescription] = useState("");
  const [trainerQualification, setTrainersQualification] = useState({});
  const [genderTrained, setGenderTrained] = useState("");
  const [trainerGoogleRating, setTrainerGoogleRating] = useState("");
  const [trainerAward, setTrainerAward] = useState("");
  const [ageGroupTrained, setAgeGroupTrained] = useState("");
  const [trainerTestimonial, setTrainerTestimonial] = useState("");
  const [trainerDetails, setTrainerDetails] = useState({});
  const [refundMembershipDetail, setRefundMembershipDetail] = useState("");
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedPlansString, setSelectedPlansString] = useState("");
  const [otherPlan, setOtherPlan] = useState("");
  const [triggerEffect, setTriggerEffect] = useState(false);

  const handleAddAreaPincode = () => {
    const newRow = { areaPincode: "" };
    setGymAreaPincode([...gymAreaPincode, ""]);
  };
  const handleAddRow = () => {
    if (kindOfEquipment?.length === 0 || kindOfEquipment === null) {
      const newRow = { equipment: "", type: "", equipmentOld: "" };
      setKindOfEquipment([newRow]);

      // Reset error state
      setFieldErrors2((prevErrors) => ({
        ...prevErrors,
        kindOfEquipment: false,
      }));
    } else {
      const lastRow = kindOfEquipment[kindOfEquipment?.length - 1];
      if (!lastRow?.equipment || !lastRow?.type || !lastRow?.equipmentOld) {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          kindOfEquipment: true,
        }));
        return;
      }
      const newRow = { equipment: "", type: "", equipmentOld: "" };

      setKindOfEquipment((prevRows) => [...prevRows, newRow]);
      setFieldErrors2((prevErrors) => ({
        ...prevErrors,
        kindOfEquipment: false,
      }));
    }
  };

  const handleProgramListAddRow = () => {
    if (classesProgramList?.length === 0 || classesProgramList === null) {
      const newRow = {
        className: "",
        duration: "",
        classTime: "",
        charges: "",
        trainerName: "",
      };
      setClassesProgramList([newRow]);

      // Reset error state
      setFieldErrors7((prevErrors) => ({
        ...prevErrors,
        classesProgramList: false,
      }));
    } else {
      const lastRow = classesProgramList[classesProgramList?.length - 1];
      if (
        !lastRow?.className ||
        !lastRow?.duration ||
        !lastRow?.classTime ||
        !lastRow?.charges ||
        !lastRow?.trainerName
      ) {
        setFieldErrors7((prevErrors) => ({
          ...prevErrors,
          classesProgramList: true,
        }));
        return;
      }
      const newRow = {
        className: "",
        duration: "",
        classTime: "",
        charges: "",
        trainerName: "",
      };

      setClassesProgramList((prevRows) => [...prevRows, newRow]);
      setFieldErrors7((prevErrors) => ({
        ...prevErrors,
        classesProgramList: false,
      }));
    }
  };

  const handleGymName = (event) => {
    setGymname(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymName: false,
    }));
  };

  const handleGymEstablised = (event) => {
    setGymEstablised(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymestablised: false,
    }));
  };

  const handleIsGroundLevel = (event) => {
    const value = event.target.value === "true";
    setIsGroundLevel(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      isGroundLevel: false,
    }));
  };
  const handleSizeOfGym = (event) => {
    setSizeOfGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      sizeOfGym: false,
    }));
  };

  const handleLocationOfGym = (event) => {
    setLocationOfGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locationOfGym: false,
    }));
  };

  const handleDescribeGym = (event) => {
    setDescribeGym(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      describeGym: false,
    }));
  };

  const handleGymTime = (event) => {
    setGymTime(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymTime: false,
    }));
  };

  const handleGymTimeTo = (event) => {
    setGymTimeTo(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      gymTimeTo: false,
    }));
  };

  const handleStaffMemberPresent = (event) => {
    setStaffMemberPresent(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      staffMemberPresent: false,
    }));
  };

  const handleTimeLimitCardio = (event) => {
    const value = event.target.value === "true";
    setTimeLimitOnCardio(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      timeLimitOnCardio: false,
    }));
  };

  const handleUploadEquipment = (event) => {
    const value = event.target.value === "true";
    setUploadEquipment(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      uploadEquipment: false,
    }));
  };

  const handleUploadProgramFile = (event) => {
    const value = event.target.value === "true";
    setUploadProgramFile(value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      uploadProgramFile: false,
    }));
  };

  const handleExplaincardioTimeLimit = (event) => {
    setExplainCardioTimeLimit(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      explainCardioTimeLimit: false,
    }));
  };

  const handleFemaleOutfitSafety = (event) => {
    setFemaleOutfitSafety(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      femaleOutfitSafety: false,
    }));
  };

  const handleMemberAgeGroup = (event) => {
    setMemberAgeGroup(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      memberAgeGroup: false,
    }));
  };
  const handleCompositionInGender = (event) => {
    setCompositionInGender(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      compositionInGender: false,
    }));
  };
  const handleMemberGymAttract = (event) => {
    setMemberGymAttract(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      memberGymAttract: false,
    }));
  };

  const handleMachineInstruction = (event) => {
    setMachineInstruction(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      machineInstruction: false,
    }));
  };

  const handleOtherFacility = (event) => {
    setOtherFacility(event.target.value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      otherFacility: false,
    }));
  };

  const handleIntroductoryClinic = (event) => {
    const value = event.target.value === "true";
    setIntroductoryClinic(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      introductoryClinic: false,
    }));
  };

  const handleAreaDedicated = (event) => {
    const value = event.target.value === "true";
    setAreaDedicated(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      areaDedicated: false,
    }));
  };

  const handleGymOfferTesting = (event) => {
    const value = event.target.value === "true";
    setGymOfferTesting(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      gymOfferTesting: false,
    }));
  };
  const handleFreeTrailMembership = (event) => {
    const value = event.target.value === "true";
    setFreeTrailMembership(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      freeTrailMembership: false,
    }));
  };
  const handleFreeTrailMembershipExplain = (event) => {
    setFreeTrailMembershipExplain(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      freeTrailMembershipExplain: false,
    }));
  };

  const handleGetTour = (event) => {
    const value = event.target.value === "true";
    setGetTour(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getTour: false,
    }));
  };
  const handleGetTourTime = (event) => {
    setGetTourTime(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getTourTime: false,
    }));
  };
  const handleGetTourTimeTo = (event) => {
    setGetTourTimeTo(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getTourTimeTo: false,
    }));
  };
  const handleTourTimeNote = (event) => {
    setTourTimeNote(event.target.value);
  };

  const handleRefundMembership = (event) => {
    const value = event.target.value === "true";
    setRefundMembership(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      refundMembership: false,
    }));
  };

  const handleRefundMembershipDetail = (event) => {
    setRefundMembershipDetail(event.target.value);
  };
  const handleDifferentMembershipPlan = (event) => {
    const value = event.target.value === "true";
    setDifferentMembersipPlan(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      differentMembersipPlan: false,
    }));
  };
  const handleTrainerQualification = (event) => {
    setTrainersQualification(event.target.value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      explainDifferentMembershipPlan: false,
    }));
  };

  const handleFemaleStaffMemberPresent = (event) => {
    const value = event.target.value === "true";
    setFemaleStaffMemberPresent(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      femaleStaffMemberPresent: false,
    }));
  };
  const handleTrainerQualified = (event) => {
    const value = event.target.value === "true";
    setTrainersQualified(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      trainersQualified: false,
    }));
  };
  const handleAreMaleTrainers = (event) => {
    const value = event.target.value === "true";
    setAreMaleTrainers(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      areMaleTrainers: false,
    }));
  };

  const handleMaleTrainerExpirence = (event) => {
    setMaleTrainerExpirence(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      maleTrainerExpirence: false,
    }));
  };
  const handleMaleWeekdaysFrom = (event) => {
    setMaleWeekdaysFrom(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      maleWeekdaysFrom: false,
    }));
  };
  const handleMaleWeekdaysTo = (event) => {
    setMaleWeekdaysTo(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      maleWeekdaysTo: false,
    }));
  };
  const handleMaleWeekendsFrom = (event) => {
    setMaleWeekendsFrom(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      maleWeekendsFrom: false,
    }));
  };
  const handleMaleWeekendsTo = (event) => {
    setMaleWeekendsTo(event.target.value);
  };
  const handleWeekendsSundayFrom = (event) => {
    setMaleWeekendsSundayFrom(event.target.value);
  };
  const handleWeekendsSundayTo = (event) => {
    setMaleWeekendsSundayTo(event.target.value);
  };
  const handleFemaleWeekdaysFrom = (event) => {
    setFemaleWeekdaysFrom(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleWeekdaysFrom: false,
    }));
  };
  const handleFemaleWeekdaysTo = (event) => {
    setFemaleWeekdaysTo(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleWeekdaysTo: false,
    }));
  };
  const handleFemaleWeekendsFrom = (event) => {
    setFemaleWeekendsFrom(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleWeekendsFrom: false,
    }));
  };
  const handleFemaleWeekendsTo = (event) => {
    setFemaleWeekendsTo(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleWeekendsTo: false,
    }));
  };
  const handleFemaleWeekendsSundayFrom = (event) => {
    setFemaleWeekendsSundayFrom(event.target.value);
  };
  const handleFemaleWeekendsSundayTo = (event) => {
    setFemaleWeekendsSundayTo(event.target.value);
  };
  const handleFemaleTrainerExpirence = (event) => {
    setFemaleTrainerExpirence(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      femaleTrainerExpirence: false,
    }));
  };

  const handleAreFemaleTrainer = (event) => {
    const value = event.target.value === "true";
    setAreFemaleTrainer(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      areFemaleTrainer: false,
    }));
  };

  const handleKidsAres = (event) => {
    const value = event.target.value === "true";
    setKidsArea(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      kidsArea: false,
    }));
  };
  const handleOnlyLadiesWorkoutSpace = (event) => {
    const value = event.target.value === "true";
    setOnlyLadiesWorkoutSpace(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      onlyLadiesWorkoutSpace: false,
    }));
  };
  const handleSauna = (event) => {
    const value = event.target.value === "true";
    setSauna(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      sauna: false,
    }));
  };
  const handleFunctionalTrainingArea = (event) => {
    const value = event.target.value === "true";
    setFunctionalTrainingArea(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      functionalTrainingArea: false,
    }));
  };
  const handlePersonalTrainer = (event) => {
    const value = event.target.value === "true";
    setPersoanlTrainer(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      personalTrainer: false,
    }));
  };
  const handleWifi = (event) => {
    const value = event.target.value === "true";
    setWifi(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      wifi: false,
    }));
  };
  const handleProShop = (event) => {
    const value = event.target.value === "true";
    setProShop(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      proShop: false,
    }));
  };
  const handleOtherAmenities = (event) => {
    setOtherAmenities(event.target.value);
  };

  const handleSeparateTraining = (event) => {
    const value = event.target.value === "true";
    setSeparateTraining(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      separateTraning: false,
    }));
  };
  const handleInitialFitnessOrientation = (event) => {
    const value = event.target.value === "true";
    setInitialFitnessOrientation(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      initialFitnessOrientation: false,
    }));
  };
  const handleAnyManager = (event) => {
    const value = event.target.value === "true";
    if (value == false) {
      setManaegrAward(false);
    }
    setAnyManager(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      anyManager: false,
    }));
  };

  const handleManagerName = (event) => {
    setManagerName(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      managerName: false,
    }));
  };
  const handleManagerQualification = (event) => {
    setManagerQualification(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      managerQualification: false,
    }));
  };

  const handleManagerExpirence = (event) => {
    setManagerExpirence(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      managerExpirence: false,
    }));
  };

  const handleManagerAward = (event) => {
    const value = event.target.value === "true";
    setManaegrAward(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      managerAward: false,
    }));
  };
  const handleExplainManagerAward = (event) => {
    setExplainManagerAward(event.target.value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      explainManagerAward: false,
    }));
  };
  const handleGymMaintainCleanliness = (event) => {
    const value = event.target.value === "true";
    setGymMaintainCleanliness(value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      gymMaintainCleanliness: false,
    }));
  };

  const handleExplainCleanliness = (event) => {
    setExplainCleanliness(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      explainCleanliness: false,
    }));
  };

  const handleCleaningStationPlaced = (event) => {
    setCleaningStationPlaced(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      cleaningStationPlaced: false,
    }));
  };

  const handleHygieneStandard = (event) => {
    setHygieneStandard(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      hygieneStandard: false,
    }));
  };

  const handleProperlyMaintained = (event) => {
    const value = event.target.value === "true";
    setProperlyMaintained(value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      properlyMaintained: false,
    }));
  };

  const handleExplainProperlyMaintained = (event) => {
    setExplainProperlyMaintained(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      explainProperlyMaintained: false,
    }));
  };

  const handleGymOfferAmenities = (event) => {
    const value = event.target.value === "true";
    setGymOfferAmenities(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      gymOfferAmenities: false,
    }));
  };
  const handleVariousTrainingProgram = (event) => {
    setVariousTrainingProgram(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      variousTrainingProgram: false,
    }));
  };
  const handleGroupFitnessClasses = (event) => {
    const value = event.target.value === "true";
    setGroupFitnessClasses(value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      groupFitnessClasses: false,
    }));
  };
  const handleExtraCharge = (event) => {
    const value = event.target.value === "true";
    setExtraCharge(value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      extraCharge: false,
    }));
  };
  const handlePromotionalCurrentPlan = (event) => {
    const value = event.target.value === "true";
    setPromotionalCurrentPlan(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      promotionalCurrentPlan: false,
    }));
  };
  const handlePaymentPlan = (event) => {
    const value = event.target.value === "true";
    setPaymentPlan(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      paymentPlan: false,
    }));
  };

  const handleClassDescription = (event, trainerId) => {
    setClassDescription(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      classDescription: false,
    }));
  };

  // const handleTrainerQualification = (event, trainerId) => {
  //   setTrainersQualification((prev) => ({
  //     ...prev,
  //     [trainerId]: event.target.value,
  //   }));
  //   setFieldErrors7((prevErrors) => ({
  //     ...prevErrors,
  //     trainerQualification: false,
  //   }));
  // };

  const handleGenderTrained = (event) => {
    setGenderTrained(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      genderTrained: false,
    }));
  };

  const handleTrainerGoogleRating = (event) => {
    setTrainerGoogleRating(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      trainerGoogleRating: false,
    }));
  };

  const handleTrainertestimonial = (event) => {
    setTrainerTestimonial(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      trainerTestimonial: false,
    }));
  };

  const handleTrainerAward = (event) => {
    setTrainerAward(event.target.value);
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      trainerAward: false,
    }));
  };

  const handleAgeGroupTrained = (event) => {
    setAgeGroupTrained(event.target.value);
  };

  const handleAttachTestimonial = (event, trainerId) => {
    const file = event.target.files[0]; // Capture the file from input
    if (file) {
      // Update the trainerDetails with the file
      setTrainerDetails((prevState) => ({
        ...prevState,
        [trainerId]: {
          ...prevState[trainerId],
          testimonialFile: file, // Store the file in the state
        },
      }));
    }
  };
  const handleTrainerDetailsChange = (event, trainerId, field) => {
    const value = event.target.value;

    setTrainerDetails((prevState) => ({
      ...prevState,
      [trainerId]: {
        ...prevState[trainerId],
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedPlans((prevSelectedPlans) => {
      const updatedPlans = Array.isArray(prevSelectedPlans)
        ? checked
          ? [...prevSelectedPlans, value]
          : prevSelectedPlans.filter((plan) => plan !== value)
        : [value];

      const selectedPlansString = updatedPlans.join(", ");
      setSelectedPlansString(selectedPlansString);

      // If at least one checkbox is selected, reset the selectedPlansString error
      setFieldErrors3((prevErrors) => ({
        ...prevErrors,
        selectedPlansString:
          updatedPlans.length > 0 ? false : prevErrors.selectedPlansString,
      }));

      return updatedPlans;
    });
  };

  const handleOtherPlanChange = (event) => {
    setOtherPlan(event.target.value);
  };

  const handleAttachPhoto = (event, fileType) => {
    event.preventDefault();
    const fileArray = Array.from(event.target.files);
    const file = event.target.files[0];
    const formData = new FormData();
    // formData.append("files", file);
    if (fileType === "GYM_PHOTO") {
      setgymfileUploading(true);
      setselectedGymFile([...selectedGymFile, ...fileArray]);
    } else {
      setlicensefileUploading(true);
      setselectedLicenseFile([...selectedLicenseFile, ...fileArray]);
    }
    for (const file of fileArray) {
      formData.append("files", file); // Ensure the key matches backend expectations
    }
    AxiosInstance.post(`/gym/gym-files-upload/${fileType}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        toast.success("Uploaded Successfully");
        setFileName(null);
        setUploadFlag((prev) => !prev);
      })
      .catch((error) => {
        //setError("An
      })
      .finally(() => {
        setgymfileUploading(false);
        setlicensefileUploading(false);
      });
  };

  const handleAttachClassDescription = (event, id) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    AxiosInstance.post(
      `/gym/gym-class-description-upload?classId=${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        toast.success("Uploaded Successfully");
        setFileName(null);
        setUploadFlag((prev) => !prev);
      })
      .catch((error) => {
        //setError("An
      });
  };

  // const pinCodeList = gymAreaPincode?.map((row) => row.areaPincode).filter((pincode) => pincode !== "");
  const handleEnvironmentStep1 = () => {
    const errors = {};
    if (gymName === "" || gymName === undefined || gymName === null) {
      errors.gymName = true;
    }
    if (
      gymestablised === "" ||
      gymestablised === undefined ||
      gymestablised === null
    ) {
      errors.gymestablised = true;
    }

    if (sizeOfGym === "" || sizeOfGym === undefined || sizeOfGym === null) {
      errors.sizeOfGym = true;
    }

    if (
      locationOfGym === "" ||
      locationOfGym === undefined ||
      locationOfGym === null
    ) {
      errors.locationOfGym = true;
    }

    if (
      isGroundLevel === "" ||
      isGroundLevel === undefined ||
      isGroundLevel === null
    ) {
      errors.isGroundLevel = true;
    }

    if (
      describeGym === "" ||
      describeGym === undefined ||
      describeGym === null
    ) {
      errors.describeGym = true;
    }

    if (gymTime === "" || gymTime === undefined || gymTime === null) {
      errors.gymTime = true;
    }
    if (gymTimeTo === "" || gymTimeTo === undefined || gymTimeTo === null) {
      errors.gymTimeTo = true;
    }
    if (
      staffMemberPresent === "" ||
      staffMemberPresent === undefined ||
      staffMemberPresent === null
    ) {
      errors.staffMemberPresent = true;
    }

    if (
      femaleStaffMemberPresent === "" ||
      femaleStaffMemberPresent === undefined ||
      femaleStaffMemberPresent === null
    ) {
      errors.femaleStaffMemberPresent = true;
    }

    if (
      femaleOutfitSafety === "" ||
      femaleOutfitSafety === undefined ||
      femaleOutfitSafety === null
    ) {
      errors.femaleOutfitSafety = true;
    }

    if (
      memberAgeGroup === "" ||
      memberAgeGroup === undefined ||
      memberAgeGroup === null
    ) {
      errors.memberAgeGroup = true;
    }

    if (
      compositionInGender === "" ||
      compositionInGender === undefined ||
      compositionInGender === null
    ) {
      errors.compositionInGender = true;
    }

    if (
      memberGymAttract === "" ||
      memberGymAttract === undefined ||
      memberGymAttract === null
    ) {
      errors.memberGymAttract = true;
    }
    if (gymAreaPincode.length === 1 && gymAreaPincode[0] === "") {
      errors.gymAreaPincode = true;
    }
    // if ( licence === "" || licence == undefined) {
    //   errors.licence = true;
    // }
    setFieldErrors(errors);
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        getTourTime: getTourTime,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStepKindOfEquipment = () => {
    const errors2 = {};
    const equipmentErrors = kindOfEquipment
      .map((row, index) => {
        const rowErrors = {};
        if (!row.equipment || !row.type || !row.equipmentOld) {
          rowErrors.kindOfEquipment =
            "Please fill all fields for this equipment entry.";
        }
        if (Object.keys(rowErrors).length > 0) {
          return { index, errors: rowErrors };
        }
        return null;
      })
      .filter((error) => error !== null);
    if (equipmentErrors.length > 0) {
      errors2.kindOfEquipment = equipmentErrors.map((error) => ({
        index: error.index,
        message: error.errors.kindOfEquipment,
      }));
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleEnvironmentStep2 = () => {
    const errors2 = {};
    if (
      machineInstruction === "" ||
      machineInstruction == undefined ||
      machineInstruction == null
    ) {
      errors2.machineInstruction = true;
    }
    if (
      timeLimitOnCardio === "" ||
      timeLimitOnCardio == undefined ||
      timeLimitOnCardio == null
    ) {
      errors2.timeLimitOnCardio = true;
    }
    if (timeLimitOnCardio === true) {
      if (
        explainCardioTimeLimit === "" ||
        explainCardioTimeLimit == undefined ||
        explainCardioTimeLimit == null
      ) {
        errors2.explainCardioTimeLimit = true;
      }
    }
    if (
      otherFacility === "" ||
      otherFacility == undefined ||
      otherFacility == null
    ) {
      errors2.otherFacility = true;
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep3 = () => {
    const errors2 = {};
    if (
      introductoryClinic === "" ||
      introductoryClinic == undefined ||
      introductoryClinic == null
    ) {
      errors2.introductoryClinic = true;
    }
    if (
      separateTraning === "" ||
      separateTraning == undefined ||
      separateTraning == null
    ) {
      errors2.separateTraning = true;
    }
    if (
      initialFitnessOrientation === "" ||
      initialFitnessOrientation == undefined ||
      initialFitnessOrientation == null
    ) {
      errors2.initialFitnessOrientation = true;
    }
    if (
      areaDedicated === "" ||
      areaDedicated == undefined ||
      areaDedicated == null
    ) {
      errors2.areaDedicated = true;
    }
    if (
      gymOfferTesting === "" ||
      gymOfferTesting == undefined ||
      gymOfferTesting == null
    ) {
      errors2.gymOfferTesting = true;
    }
    if (
      freeTrailMembership === "" ||
      freeTrailMembership == undefined ||
      freeTrailMembership == null
    ) {
      errors2.freeTrailMembership = true;
    }
    if (freeTrailMembership == true) {
      if (
        freeTrailMembershipExplain === "" ||
        freeTrailMembershipExplain == undefined ||
        freeTrailMembershipExplain == null
      ) {
        errors2.freeTrailMembershipExplain = true;
      }
    }
    if (getTour === "" || getTour == null || getTour == undefined) {
      errors2.getTour = true;
    }
    if (getTour == true) {
      if (
        getTourTime === "" ||
        getTourTime == undefined ||
        getTourTime == null
      ) {
        errors2.getTourTime = true;
      }
      if (
        getTourTimeTo === "" ||
        getTourTimeTo == undefined ||
        getTourTimeTo == null
      ) {
        errors2.getTourTimeTo = true;
      }
    }
    if (
      refundMembership === "" ||
      refundMembership == undefined ||
      refundMembership == null
    ) {
      errors2.refundMembership = true;
    }
    if (selectedPlans.length === 0) {
      // If selectedPlans is empty, validate selectedPlansString
      if (
        selectedPlansString === "" ||
        selectedPlansString === undefined ||
        selectedPlansString === null
      ) {
        errors2.selectedPlansString = true; // Add error for selectedPlansString
      }
    }
    setFieldErrors3(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        classesAndProgram: classesProgramList,
        extraCharge: extraCharge === true ? true : false,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})

        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep4 = () => {
    const errors2 = {};

    if (
      trainersQualified === "" ||
      trainersQualified == undefined ||
      trainersQualified == null
    ) {
      errors2.trainersQualified = true;
    }
    if (
      areMaleTrainers === "" ||
      areMaleTrainers == undefined ||
      areMaleTrainers == null
    ) {
      errors2.areMaleTrainers = true;
    }
    if (areMaleTrainers == true) {
      if (
        maleTrainerExpirence === "" ||
        maleTrainerExpirence == undefined ||
        maleTrainerExpirence == null
      ) {
        errors2.maleTrainerExpirence = true;
      }
      if (
        maleWeekdaysFrom === "" ||
        maleWeekdaysFrom == undefined ||
        maleWeekdaysFrom == null
      ) {
        errors2.maleWeekdaysFrom = true;
      }
      if (
        maleWeekdaysTo === "" ||
        maleWeekdaysTo == undefined ||
        maleWeekdaysTo == null
      ) {
        errors2.maleWeekdaysTo = true;
      }
      if (
        maleWeekendsFrom === "" ||
        maleWeekendsFrom == undefined ||
        maleWeekendsFrom == null
      ) {
        errors2.maleWeekendsFrom = true;
      }
      if (
        maleWeekendsTo === "" ||
        maleWeekendsTo == undefined ||
        maleWeekendsTo == null
      ) {
        errors2.maleWeekendsTo = true;
      }
    }
    if (
      areFemaleTrainer === "" ||
      areFemaleTrainer == undefined ||
      areFemaleTrainer == null
    ) {
      errors2.areFemaleTrainer = true;
    }
    if (areFemaleTrainer == true) {
      if (
        femaleTrainerExpirence === "" ||
        femaleTrainerExpirence == undefined ||
        femaleTrainerExpirence == null
      ) {
        errors2.femaleTrainerExpirence = true;
      }
      if (
        femaleWeekdaysFrom === "" ||
        femaleWeekdaysFrom == undefined ||
        femaleWeekdaysFrom == null
      ) {
        errors2.femaleWeekdaysFrom = true;
      }
      if (
        femaleWeekdaysTo === "" ||
        femaleWeekdaysTo == undefined ||
        femaleWeekdaysTo == null
      ) {
        errors2.femaleWeekdaysTo = true;
      }
      if (
        femaleWeekendsFrom === "" ||
        femaleWeekendsFrom == undefined ||
        femaleWeekendsFrom == null
      ) {
        errors2.femaleWeekendsFrom = true;
      }
      if (
        femaleWeekendsTo === "" ||
        femaleWeekendsTo == undefined ||
        femaleWeekendsTo == null
      ) {
        errors2.femaleWeekendsTo = true;
      }
    }
    if (anyManager === "" || anyManager == undefined || anyManager == null) {
      errors2.anyManager = true;
    }
    if (anyManager == true) {
      if (
        managerName === "" ||
        managerName == undefined ||
        managerName == null
      ) {
        errors2.managerName = true;
      }
      if (
        managerQualification === "" ||
        managerQualification == undefined ||
        managerQualification == null
      ) {
        errors2.managerQualification = true;
      }
      if (
        managerExpirence === "" ||
        managerExpirence == undefined ||
        managerExpirence == null
      ) {
        errors2.managerExpirence = true;
      }
      if (
        managerAward === "" ||
        managerAward == undefined ||
        managerAward == null
      ) {
        errors2.managerAward = true;
      }
      if (managerAward == true) {
        if (
          explainManagerAward === "" ||
          explainManagerAward == undefined ||
          explainManagerAward == null
        ) {
          errors2.explainManagerAward = true;
        }
      }
    }
    setFieldErrors4(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep5 = () => {
    const errors2 = {};

    if (
      gymMaintainCleanliness === "" ||
      gymMaintainCleanliness == undefined ||
      gymMaintainCleanliness == null
    ) {
      errors2.gymMaintainCleanliness = true;
    }
    if (gymMaintainCleanliness == true) {
      if (
        explainCleanliness === "" ||
        explainCleanliness == undefined ||
        explainCleanliness == null
      ) {
        errors2.explainCleanliness = true;
      }
    }
    if (
      cleaningStationPlaced === "" ||
      cleaningStationPlaced == undefined ||
      cleaningStationPlaced == null
    ) {
      errors2.cleaningStationPlaced = true;
    }
    if (
      hygieneStandard === "" ||
      hygieneStandard == null ||
      hygieneStandard == undefined
    ) {
      errors2.hygieneStandard = true;
    }
    if (
      properlyMaintained === "" ||
      properlyMaintained == undefined ||
      properlyMaintained == null
    ) {
      errors2.properlyMaintained = true;
    }
    if (properlyMaintained == true) {
      if (
        explainProperlyMaintained === "" ||
        explainProperlyMaintained == undefined ||
        explainProperlyMaintained == null
      ) {
        errors2.explainProperlyMaintained = true;
      }
    }
    setFieldErrors5(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep6 = () => {
    const errors2 = {};
    if (
      gymOfferAmenities === "" ||
      gymOfferAmenities == undefined ||
      gymOfferAmenities == null
    ) {
      errors2.gymOfferAmenities = true;
    }
    if (gymOfferAmenities == true) {
      if (kidsArea === "" || kidsArea === undefined || kidsArea === null) {
        errors2.kidsArea = true;
      }

      if (
        onlyLadiesWorkoutSpace === "" ||
        onlyLadiesWorkoutSpace === undefined ||
        onlyLadiesWorkoutSpace === null
      ) {
        errors2.onlyLadiesWorkoutSpace = true;
      }

      if (sauna === "" || sauna === undefined || sauna === null) {
        errors2.sauna = true;
      }

      if (
        functionalTrainingArea === "" ||
        functionalTrainingArea === undefined ||
        functionalTrainingArea === null
      ) {
        errors2.functionalTrainingArea = true;
      }

      if (
        personalTrainer === "" ||
        personalTrainer === undefined ||
        personalTrainer === null
      ) {
        errors2.personalTrainer = true;
      }

      if (wifi === "" || wifi === undefined || wifi === null) {
        errors2.wifi = true;
      }

      if (proShop === "" || proShop === undefined || proShop === null) {
        errors2.proShop = true;
      }
    }
    setFieldErrors6(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };
  const handleEnvironmentStep7 = () => {
    const errors2 = {};
    if (
      variousTrainingProgram === "" ||
      variousTrainingProgram === undefined ||
      variousTrainingProgram === null
    ) {
      errors2.variousTrainingProgram = true;
    }

    if (
      groupFitnessClasses === "" ||
      groupFitnessClasses === undefined ||
      groupFitnessClasses === null
    ) {
      errors2.groupFitnessClasses = true;
    }

    if (
      extraCharge === "" ||
      extraCharge === undefined ||
      extraCharge === null
    ) {
      errors2.extraCharge = true;
    }

    setFieldErrors7(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setEnvironmentStep((prev) => prev + 1);
    }
  };

  const handleAttachProgramFile = () => {
    const errors2 = {};
    const uniqueClassesProgramList = Array.from(
      new Set(classesProgramList.map((a) => a.className))
    ).map((className) => {
      return classesProgramList.find((a) => a.className === className);
    });
    const equipmentErrors = classesProgramList
      ?.map((row, index) => {
        const rowErrors = {};
        if (
          !row.className ||
          !row.duration ||
          !row.classTime ||
          !row.charges ||
          !row.trainerName
        ) {
          rowErrors.classesProgramList =
            "Please fill all fields for this equipment entry.";
        }
        if (Object.keys(rowErrors).length > 0) {
          return { index, errors: rowErrors };
        }
        return null;
      })
      .filter((error) => error !== null);
    if (equipmentErrors?.length > 0) {
      errors2.classesProgramList = equipmentErrors?.map((error) => ({
        index: error.index,
        message: error.errors.classesProgramList,
      }));
    }
    setFieldErrors7(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      if (classesProgramList?.length === 0 || classesProgramList == null) {
        setEnvironmentStep((prev) => prev + 3);
      } else {
        setEnvironmentStep((prev) => prev + 1);
        setTriggerEffect(true);
      }
    }
  };
  const handleSaveClassDescription = () => {
    const errors2 = {};
    const formData = {
      gymName: gymName,
      gymEstablished: gymestablised,
      sizeOfGym: sizeOfGym,
      locationOfGym: locationOfGym,
      isGroundLevel: isGroundLevel,
      describeGym: describeGym,
      gymTime: gymTime,
      gymTimeTo: gymTimeTo,
      staffMemberPresent: parseInt(staffMemberPresent, 10),
      femaleStaffMemberPresent: femaleStaffMemberPresent,
      femaleOutfitSafety: femaleOutfitSafety,
      memberAgeGroup: memberAgeGroup,
      compositionInGender: compositionInGender,
      memberGymAttract: memberGymAttract,
      pinCodeList: gymAreaPincode,
      kindOfEquipment: kindOfEquipment,
      machineInstruction: machineInstruction,
      timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
      explainCardioTimeLimit: explainCardioTimeLimit,
      otherFacility: otherFacility,
      introductoryClinic: introductoryClinic,
      separateTraining: separateTraning === true ? true : false,
      initialFitnessOrientation:
        initialFitnessOrientation === true ? true : false,
      areaDedicated: areaDedicated === true ? true : false,
      gymOfferTesting: gymOfferTesting === true ? true : false,
      freeTrailMembership: freeTrailMembership === true ? true : false,
      freeTrailMembershipExplain: freeTrailMembershipExplain,
      getTour: getTour === true ? true : false,
      getTourTime: getTourTime,
      getTourTimeTo: getTourTimeTo,
      tourTimeNote: tourTimeNote,
      refundMembership: refundMembership === true ? true : false,
      refundMembershipDetail: refundMembershipDetail,
      differentMembershipPlan: differentMembersipPlan === true ? true : false,
      explainDifferentMembershipPlan: explainDifferentMembershipPlan,
      trainersQualified: trainersQualified === true ? true : false,
      areMaleTrainers: areMaleTrainers === true ? true : false,
      maleTrainerExperience: maleTrainerExpirence,
      maleWeekdaysFrom: maleWeekdaysFrom,
      maleWeekdaysTo: maleWeekdaysTo,
      maleWeekendsFrom: maleWeekendsFrom,
      maleWeekendsTo: maleWeekendsTo,
      areFemaleTrainer: areFemaleTrainer === true ? true : false,
      femaleTrainerExperience: femaleTrainerExpirence,
      femaleWeekdaysFrom: femaleWeekdaysFrom,
      femaleWeekdaysTo: femaleWeekdaysTo,
      femaleWeekendsFrom: femaleWeekendsFrom,
      femaleWeekendsTo: femaleWeekendsTo,
      anyManager: anyManager === true ? true : false,
      managerName: managerName,
      managerQualification: managerQualification,
      managerExperience: managerExpirence,
      managerAward: managerAward === true ? true : false,
      explainManagerAward: explainManagerAward,
      gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
      explainCleanliness: explainCleanliness,
      cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
      hygieneStandard: hygieneStandard,
      properlyMaintained: properlyMaintained === true ? true : false,
      explainProperlyMaintained: explainProperlyMaintained,
      gymOfferAmenities: gymOfferAmenities === true ? true : false,
      kidsArea: kidsArea === true ? true : false,
      onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
      sauna: sauna === true ? true : false,
      functionalTrainingArea: functionalTrainingArea === true ? true : false,
      personalTrainer: personalTrainer === true ? true : false,
      wifi: wifi === true ? true : false,
      proShop: proShop === true ? true : false,
      otherAmenities: otherAmenities,
      variousTrainingProgram: variousTrainingProgram,
      groupFitnessClasses: groupFitnessClasses === true ? true : false,
      extraCharge: extraCharge === true ? true : false,
      classesAndProgram: classesProgramList,
      promotionalCurrentPlan: promotionalCurrentPlan,
      paymentPlan: paymentPlan,
      selectedPlans: selectedPlansString,
      otherPlan: otherPlan,
      maleWeekendSundayFrom: maleWeekendsSundayFrom,
      maleWeekendSundayTo: maleWeekendsSundayTo,
      femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
      femaleWeekendSundayTo: femaleWeekendsSundayTo,
    };
    AxiosInstance.post("gym/create-profile", formData)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    setEnvironmentStep((prev) => prev + 1);
  };

  const handleTrainerSave = (id, name) => {
    const formData = new FormData();
    formData.append("trainerName", name);
    formData.append("qualificationAndExperience", trainerQualification);
    formData.append("ageGroupTrained", ageGroupTrained);
    formData.append("genderTrained", genderTrained);
    formData.append("googleRating", trainerGoogleRating);
    formData.append("clientTestimonial", trainerTestimonial);
    formData.append("anyAwards", trainerAward);
    // if (testimonialFile) {
    //   formData.append("testimonialFile", testimonialFile);
    // }

    // Make the API call to save the trainer's details
    AxiosInstance.post(`gym/save-gym-trainer-detail/${id}`, formData)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Trainer Detail Saved!",
        });
        console.log(response);
      })
      .catch((error) => {
        console.log("Error saving trainer details:", error);
        // Swal.fire({
        //   icon: "error",
        //   title: "Error Saving Trainer Detail",
        //   text: error.message,
        // });
      });

    setEnvironmentStep((prev) => prev + 1); // Update the environment step after saving
  };

  const handleTrainersScreen = () => {
    const errors2 = {};
    const formData = {
      gymName: gymName,
      gymEstablished: gymestablised,
      sizeOfGym: sizeOfGym,
      locationOfGym: locationOfGym,
      isGroundLevel: isGroundLevel,
      describeGym: describeGym,
      gymTime: gymTime,
      gymTimeTo: gymTimeTo,
      staffMemberPresent: parseInt(staffMemberPresent, 10),
      femaleStaffMemberPresent: femaleStaffMemberPresent,
      femaleOutfitSafety: femaleOutfitSafety,
      memberAgeGroup: memberAgeGroup,
      compositionInGender: compositionInGender,
      memberGymAttract: memberGymAttract,
      pinCodeList: gymAreaPincode,
      kindOfEquipment: kindOfEquipment,
      machineInstruction: machineInstruction,
      timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
      explainCardioTimeLimit: explainCardioTimeLimit,
      otherFacility: otherFacility,
      introductoryClinic: introductoryClinic,
      separateTraining: separateTraning === true ? true : false,
      initialFitnessOrientation:
        initialFitnessOrientation === true ? true : false,
      areaDedicated: areaDedicated === true ? true : false,
      gymOfferTesting: gymOfferTesting === true ? true : false,
      freeTrailMembership: freeTrailMembership === true ? true : false,
      freeTrailMembershipExplain: freeTrailMembershipExplain,
      getTour: getTour === true ? true : false,
      getTourTime: getTourTime,
      getTourTimeTo: getTourTimeTo,
      tourTimeNote: tourTimeNote,
      refundMembership: refundMembership === true ? true : false,
      refundMembershipDetail: refundMembershipDetail,
      differentMembershipPlan: differentMembersipPlan === true ? true : false,
      explainDifferentMembershipPlan: explainDifferentMembershipPlan,
      trainersQualified: trainersQualified === true ? true : false,
      areMaleTrainers: areMaleTrainers === true ? true : false,
      maleTrainerExperience: maleTrainerExpirence,
      maleWeekdaysFrom: maleWeekdaysFrom,
      maleWeekdaysTo: maleWeekdaysTo,
      maleWeekendsFrom: maleWeekendsFrom,
      maleWeekendsTo: maleWeekendsTo,
      areFemaleTrainer: areFemaleTrainer === true ? true : false,
      femaleTrainerExperience: femaleTrainerExpirence,
      femaleWeekdaysFrom: femaleWeekdaysFrom,
      femaleWeekdaysTo: femaleWeekdaysTo,
      femaleWeekendsFrom: femaleWeekendsFrom,
      femaleWeekendsTo: femaleWeekendsTo,
      anyManager: anyManager === true ? true : false,
      managerName: managerName,
      managerQualification: managerQualification,
      managerExperience: managerExpirence,
      managerAward: managerAward === true ? true : false,
      explainManagerAward: explainManagerAward,
      gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
      explainCleanliness: explainCleanliness,
      cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
      hygieneStandard: hygieneStandard,
      properlyMaintained: properlyMaintained === true ? true : false,
      explainProperlyMaintained: explainProperlyMaintained,
      gymOfferAmenities: gymOfferAmenities === true ? true : false,
      kidsArea: kidsArea === true ? true : false,
      onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
      sauna: sauna === true ? true : false,
      functionalTrainingArea: functionalTrainingArea === true ? true : false,
      personalTrainer: personalTrainer === true ? true : false,
      wifi: wifi === true ? true : false,
      proShop: proShop === true ? true : false,
      otherAmenities: otherAmenities,
      variousTrainingProgram: variousTrainingProgram,
      groupFitnessClasses: groupFitnessClasses === true ? true : false,
      extraCharge: extraCharge === true ? true : false,
      classesAndProgram: classesProgramList,
      promotionalCurrentPlan: promotionalCurrentPlan,
      paymentPlan: paymentPlan,
      selectedPlans: selectedPlansString,
      otherPlan: otherPlan,
      maleWeekendSundayFrom: maleWeekendsSundayFrom,
      maleWeekendSundayTo: maleWeekendsSundayTo,
      femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
      femaleWeekendSundayTo: femaleWeekendsSundayTo,
    };
    AxiosInstance.post("gym/create-profile", formData)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    setEnvironmentStep((prev) => prev + 1);
  };

  const handleEnvironmentStepBack = () => {
    setEnvironmentStep((prev) => prev - 1);
  };

  const FinalPageBack = () => {
    if (classesProgramList == null || classesProgramList.length == 0) {
      setEnvironmentStep((prev) => prev - 3);
    } else {
      setEnvironmentStep((prev) => prev - 1);
    }
  };

  const GymFromSubmit = (e) => {
    e.preventDefault();
    const errors3 = {};
    if (
      promotionalCurrentPlan === "" ||
      promotionalCurrentPlan == undefined ||
      promotionalCurrentPlan == null
    ) {
      errors3.promotionalCurrentPlan = true;
    }
    if (paymentPlan === "" || paymentPlan == undefined || paymentPlan == null) {
      errors3.paymentPlan = true;
    }

    setFieldErrors8(errors3);

    if (Object.keys(errors3).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
      });
    } else {
      const formData = {
        gymName: gymName,
        gymEstablished: gymestablised,
        sizeOfGym: sizeOfGym,
        locationOfGym: locationOfGym,
        isGroundLevel: isGroundLevel,
        describeGym: describeGym,
        gymTime: gymTime,
        gymTimeTo: gymTimeTo,
        staffMemberPresent: parseInt(staffMemberPresent, 10),
        femaleStaffMemberPresent: femaleStaffMemberPresent,
        femaleOutfitSafety: femaleOutfitSafety,
        memberAgeGroup: memberAgeGroup,
        compositionInGender: compositionInGender,
        memberGymAttract: memberGymAttract,
        pinCodeList: gymAreaPincode,
        kindOfEquipment: kindOfEquipment,
        machineInstruction: machineInstruction,
        timeLimitOnCardio: timeLimitOnCardio === true ? true : false,
        explainCardioTimeLimit: explainCardioTimeLimit,
        otherFacility: otherFacility,
        introductoryClinic: introductoryClinic,
        separateTraining: separateTraning === true ? true : false,
        initialFitnessOrientation:
          initialFitnessOrientation === true ? true : false,
        areaDedicated: areaDedicated === true ? true : false,
        gymOfferTesting: gymOfferTesting === true ? true : false,
        freeTrailMembership: freeTrailMembership === true ? true : false,
        freeTrailMembershipExplain: freeTrailMembershipExplain,
        getTour: getTour === true ? true : false,
        getTourTime: getTourTime,
        getTourTimeTo: getTourTimeTo,
        tourTimeNote: tourTimeNote,
        refundMembership: refundMembership === true ? true : false,
        refundMembershipDetail: refundMembershipDetail,
        differentMembershipPlan: differentMembersipPlan === true ? true : false,
        explainDifferentMembershipPlan: explainDifferentMembershipPlan,
        trainersQualified: trainersQualified === true ? true : false,
        areMaleTrainers: areMaleTrainers === true ? true : false,
        maleTrainerExperience: maleTrainerExpirence,
        maleWeekdaysFrom: maleWeekdaysFrom,
        maleWeekdaysTo: maleWeekdaysTo,
        maleWeekendsFrom: maleWeekendsFrom,
        maleWeekendsTo: maleWeekendsTo,
        areFemaleTrainer: areFemaleTrainer === true ? true : false,
        femaleTrainerExperience: femaleTrainerExpirence,
        femaleWeekdaysFrom: femaleWeekdaysFrom,
        femaleWeekdaysTo: femaleWeekdaysTo,
        femaleWeekendsFrom: femaleWeekendsFrom,
        femaleWeekendsTo: femaleWeekendsTo,
        anyManager: anyManager === true ? true : false,
        managerName: managerName,
        managerQualification: managerQualification,
        managerExperience: managerExpirence,
        managerAward: managerAward === true ? true : false,
        explainManagerAward: explainManagerAward,
        gymMaintainCleanliness: gymMaintainCleanliness === true ? true : false,
        explainCleanliness: explainCleanliness,
        cleaningStationPlaced: cleaningStationPlaced === true ? true : false,
        hygieneStandard: hygieneStandard,
        properlyMaintained: properlyMaintained === true ? true : false,
        explainProperlyMaintained: explainProperlyMaintained,
        gymOfferAmenities: gymOfferAmenities === true ? true : false,
        kidsArea: kidsArea === true ? true : false,
        onlyLadiesWorkoutSpace: onlyLadiesWorkoutSpace === true ? true : false,
        sauna: sauna === true ? true : false,
        functionalTrainingArea: functionalTrainingArea === true ? true : false,
        personalTrainer: personalTrainer === true ? true : false,
        wifi: wifi === true ? true : false,
        proShop: proShop === true ? true : false,
        otherAmenities: otherAmenities,
        variousTrainingProgram: variousTrainingProgram,
        groupFitnessClasses: groupFitnessClasses === true ? true : false,
        extraCharge: extraCharge === true ? true : false,
        classesAndProgram: classesProgramList,
        promotionalCurrentPlan: promotionalCurrentPlan,
        paymentPlan: paymentPlan,
        selectedPlans: selectedPlansString,
        otherPlan: otherPlan,
        maleWeekendSundayFrom: maleWeekendsSundayFrom,
        maleWeekendSundayTo: maleWeekendsSundayTo,
        femaleWeekendSundayFrom: femaleWeekendsSundayFrom,
        femaleWeekendSundayTo: femaleWeekendsSundayTo,
      };
      AxiosInstance.post("gym/create-profile", formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Form Submitted Successfully!",
          });

          setTimeout(() => {
            navigate("/gym-home-screen");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleDeleteRow = (index) => {
    setKindOfEquipment((prevRows) => {
      const updatedRows = prevRows.filter((_, i) => i !== index);
      if (updatedRows.length === 0) {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          kindOfEquipment: true,
        }));
      } else {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          kindOfEquipment: false,
        }));
      }

      return updatedRows;
    });
  };

  const handleDeleteAreaPincode = (index) => {
    const updatedRows = gymAreaPincode.filter((_, i) => i !== index);
    setGymAreaPincode(updatedRows);

    if (updatedRows.length === 0) {
      setFieldErrors({ gymAreaPincode: true });
    } else {
      setFieldErrors({ gymAreaPincode: false });
    }
  };

  const handleAreaPincode = (e, index) => {
    const { value } = e.target;
    setGymAreaPincode((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = value;
      return updatedRows;
    });
  };

  const handleProgramFileDeleteRow = (index, PROGRAM_FILE) => {
    setClassesProgramList((prevRows) => {
      const updatedRows = prevRows?.filter((_, i) => i !== index);
      const rowDelete = prevRows[index];
      if (rowDelete && rowDelete.id) {
        const id = rowDelete.id;
        const parsedId = parseInt(id, 10);
        if (isNaN(parsedId)) {
          console.log("Invalid id:", id);
          return prevRows;
        }
        AxiosInstance.delete(
          `gym/delete-equipment-and-class/${parsedId}/${PROGRAM_FILE}`
        )
          .then((response) => {
            console.log("Successfully deleted row:", response);
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } else {
        console.log(
          "rowDelete is undefined, index might be out of range or invalid"
        );
      }
      if (updatedRows.length === 0) {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          classesProgramList: true,
        }));
      } else {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          classesProgramList: false,
        }));
      }
      return updatedRows;
    });
  };
  const handleEquipmentFileDelete = (index, EQUIPMENT_FILE) => {
    setKindOfEquipment((prevRows) => {
      const updatedRows = prevRows?.filter((_, i) => i !== index);

      const rowDelete = prevRows[index];

      if (rowDelete && rowDelete.id) {
        const id = rowDelete.id;

        const parsedId = parseInt(id, 10);
        if (isNaN(parsedId)) {
          console.log("Invalid id:", id);
          return prevRows;
        }

        AxiosInstance.delete(
          `gym/delete-equipment-and-class/${parsedId}/${EQUIPMENT_FILE}`
        )
          .then((response) => {
            console.log("Successfully deleted row:", response);
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } else {
        console.log(
          "rowDelete or rowDelete.id is undefined, index might be out of range or invalid"
        );
      }

      if (updatedRows.length === 0) {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          classesProgramList: true,
        }));
      } else {
        setFieldErrors2((prevErrors) => ({
          ...prevErrors,
          classesProgramList: false,
        }));
      }

      return updatedRows;
    });
  };

  useEffect(() => {
    AxiosInstance.get("gym/get-profile")
      .then((response) => {
        setGymname(response?.gymName);
        setGymEstablised(response?.gymEstablished);
        setSizeOfGym(response?.sizeOfGym);
        setLocationOfGym(response?.locationOfGym);
        setIsGroundLevel(response?.isGroundLevel);
        setDescribeGym(response?.describeGym);
        setGymTime(response?.gymTime);
        setGymEstablised(response?.gymEstablished);
        setStaffMemberPresent(response?.staffMemberPresent);
        setFemaleStaffMemberPresent(response?.femaleStaffMemberPresent);
        setFemaleOutfitSafety(response?.femaleOutfitSafety);
        setCompositionInGender(response?.compositionInGender);
        setMemberGymAttract(response?.memberGymAttract);
        setMemberAgeGroup(response?.memberAgeGroup);
        setMachineInstruction(response?.machineInstruction);
        setTimeLimitOnCardio(response?.timeLimitOnCardio);
        setOtherFacility(response?.otherFacility);
        setIntroductoryClinic(response?.introductoryClinic);
        setSeparateTraining(response?.separateTraining);
        setInitialFitnessOrientation(response?.initialFitnessOrientation);
        setAreaDedicated(response?.areaDedicated);
        setGymOfferTesting(response?.gymOfferTesting);
        setFreeTrailMembership(response?.freeTrailMembership);
        setFreeTrailMembershipExplain(response?.freeTrailMembershipExplain);
        setGetTour(response?.getTour);
        setGetTourTime(response?.getTourTime);
        setRefundMembership(response?.refundMembership);
        setDifferentMembersipPlan(response?.differentMembershipPlan);
        setExplainDifferentMembershipPlan(
          response?.explainDifferentMembershipPlan
        );
        setTrainersQualified(response?.trainersQualified);
        setAreMaleTrainers(response?.areMaleTrainers);
        setMaleTrainerExpirence(response?.maleTrainerExperience);
        setMaleWeekdaysFrom(response?.maleWeekdaysFrom);
        setMaleWeekdaysTo(response?.maleWeekdaysTo);
        setMaleWeekendsFrom(response?.maleWeekendsFrom);
        setMaleWeekendsTo(response?.maleWeekendsTo);
        setAreFemaleTrainer(response?.areFemaleTrainer);
        setFemaleTrainerExpirence(response?.femaleTrainerExperience);
        setFemaleWeekdaysFrom(response?.femaleWeekdaysFrom);
        setFemaleWeekdaysTo(response?.femaleWeekdaysTo);
        setFemaleWeekendsFrom(response?.femaleWeekendsFrom);
        setFemaleWeekendsTo(response?.femaleWeekendsTo);
        setAnyManager(response?.anyManager);
        setManagerName(response?.managerName);
        setManagerQualification(response?.managerQualification);
        setManagerExpirence(response?.managerExperience);
        setManaegrAward(response?.managerAward);
        setExplainManagerAward(response?.explainManagerAward);
        setGymMaintainCleanliness(response?.gymMaintainCleanliness);
        setCleaningStationPlaced(response?.cleaningStationPlaced);
        setHygieneStandard(response?.hygieneStandard);
        setProperlyMaintained(response?.properlyMaintained);
        setExplainProperlyMaintained(response?.explainProperlyMaintained);
        setGymOfferAmenities(response?.gymOfferAmenities);
        setKidsArea(response?.kidsArea);
        setOnlyLadiesWorkoutSpace(response?.onlyLadiesWorkoutSpace);
        setSauna(response?.sauna);
        setFunctionalTrainingArea(response?.functionalTrainingArea);
        setPersoanlTrainer(response?.personalTrainer);
        setWifi(response?.wifi);
        setProShop(response?.proShop);
        setOtherAmenities(response?.otherAmenities);
        setVariousTrainingProgram(response?.variousTrainingProgram);
        setGroupFitnessClasses(response?.groupFitnessClasses);
        setExtraCharge(response?.extraCharge);
        setPromotionalCurrentPlan(response?.promotionalCurrentPlan);
        setPaymentPlan(response?.paymentPlan);
        setExplainCardioTimeLimit(response?.explainCardioTimeLimit);
        setExplainCleanliness(response?.explainCleanliness);
        setGymPhoto(response?.gymPhotos || []);
        setLicence(response?.gymLicenseFile || []);
        setProgramFile(response?.gymProgramFile);
        setRefundMembershipDetail(response?.refundMembershipDetail);
        setGymTimeTo(response?.gymTimeTo);
        setGetTourTimeTo(response?.getTourTimeTo);
        setTourTimeNote(response?.tourTimeNote);
        setSelectedPlans(response?.selectedPlans);
        setOtherPlan(response?.otherPlan);
        setMaleWeekendsSundayFrom(response?.maleWeekendSundayFrom);
        setMaleWeekendsSundayTo(response?.maleWeekendSundayTo);
        setFemaleWeekendsSundayFrom(response?.femaleWeekendSundayFrom);
        setFemaleWeekendsSundayTo(response?.femaleWeekendSundayTo);
        if (response?.pinCodes && Array.isArray(response.pinCodes)) {
          setGymAreaPincode(response.pinCodes);
        }
        getExcelResults();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [triggerEffect]);
  // const isAddButtonDisabled = !kindOfEquipment[0]?.equipment || !kindOfEquipment[0]?.type || !kindOfEquipment[0]?.equipmentOld;
  // const isProgramFileAddButtonDisabled =
  //   !classesProgramList[0]?.className || !classesProgramList[0]?.duration || !classesProgramList[0]?.classTime || !classesProgramList[0].charges || !classesProgramList[0]?.trainerName;

  const handleInputChange = (e, index, field) => {
    const newRows = [...kindOfEquipment];
    newRows[index][field] = e.target.value;
    setKindOfEquipment(newRows);
  };
  const handleProgramFileInputChange = (e, index, fieldName) => {
    const { value } = e.target;

    const updatedList = [...classesProgramList];
    updatedList[index] = {
      ...updatedList[index],
      [fieldName]: value,
    };

    setClassesProgramList(updatedList);
  };

  const GYM_PHOTO = "GYM_PHOTO";
  const PROGRAM_FILE = "PROGRAM_FILE";
  const EQUIPMENT_FILE = "EQUIPMENT_FILE";
  const gymEmail = sessionStorage.getItem("email");
  const gymPhoneNumber = sessionStorage.getItem("phoneNumber");

  const getExcelResults = () => {
    AxiosInstance.get("gym/get-equipment-and-classes")
      .then((response) => {
        setTrainersQualification(response?.qualificationAndExperience);
        setTrainerAward(response?.anyAwards);
        setTrainerGoogleRating(response?.googleRating);
        setGenderTrained(response?.genderTrained);
        setAgeGroupTrained(response?.ageGroupTrained);
        setTrainerTestimonial(response?.clientTestimonial);
        if (
          response?.kindOfEquipment &&
          Array.isArray(response.kindOfEquipment)
        ) {
          setKindOfEquipment(response.kindOfEquipment);
        }
        if (
          response?.classesAndProgram &&
          Array.isArray(response.classesAndProgram)
        ) {
          setClassesProgramList(response.classesAndProgram);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const TestReport = async (file, EQUIPMENT_FILE) => {
    let newFormData = new FormData();
    if (file) {
      newFormData.append("file", file);
    } else {
      console.log("File not found");
    }
    await AxiosInstance.post(
      `gym/import-gym-file/${EQUIPMENT_FILE}`,
      newFormData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        toast.success("File uploaded successfully");
        getExcelResults();
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleEquipmentExcel = (event, EQUIPMENT_FILE) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    TestReport(selectedFile, EQUIPMENT_FILE);
  };

  const handleDownload = (filePath) => {
    // if (!filePath) return;

    console.log("Downloading file from:", filePath);

    const downloadApi = "document/download-partner-document/GYM";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const handleDelete = (filePath, gymPhoto) => {
    console.log("filePath", gymPhoto);
    const id = gymPhoto.id;

    const downloadApi = "document/delete-partners-document";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}/${id}/GYM`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>

      {environmentStep == 0 && (
        <Grid container spacing={2} mb={5}>
          <Grid item>
            <div className="register_title">
              <h2>GYM PARTNER QUESTIONNAIRE</h2>
            </div>
          </Grid>
        </Grid>
      )}

      <form onSubmit={GymFromSubmit} className="gym_from">
        {environmentStep == 0 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>1. General Questions </h4>
                </div>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.gymName ? "red" : "inherit",
                    }}
                  >
                    1.1 Enter Gym name
                  </FormLabel>
                  <Input
                    type="text"
                    style={{ textIndent: "10px" }}
                    className="form-control"
                    value={gymName}
                    onChange={(e) => {
                      handleGymName(e);
                    }}
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    1.2 Gym Email address
                  </FormLabel>
                  <Input
                    type="text"
                    className="form-control"
                    value={gymEmail}
                    disabled
                  ></Input>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel>1.3 Gym Phone Number</FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={gymPhoneNumber}
                    disabled
                  ></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.gymestablised ? "red" : "inherit",
                    }}
                  >
                    1.4 When was this gym established ?
                  </FormLabel>
                  <Input
                    type="date"
                    style={{ textIndent: "10px" }}
                    className="form-control"
                    value={gymestablised}
                    onChange={(e) => {
                      handleGymEstablised(e);
                    }}
                  ></Input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.sizeOfGym ? "red" : "inherit",
                    }}
                  >
                    1.5 What is the size of the gym in terms of sq. ft. area?
                  </FormLabel>
                  <Input
                    type="number"
                    className="form-control"
                    value={sizeOfGym}
                    onChange={(e) => {
                      handleSizeOfGym(e);
                    }}
                  ></Input>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.locationOfGym ? "red" : "inherit",
                    }}
                  >
                    1.6 What is the location?{" "}
                  </FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={locationOfGym}
                    onChange={(e) => {
                      handleLocationOfGym(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.isGroundLevel ? "red" : "inherit",
                    }}
                  >
                    1.7 Is the gym at the ground level?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={isGroundLevel}
                    name="radio-buttons-group"
                    onChange={handleIsGroundLevel}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.describeGym ? "red" : "inherit",
                    }}
                  >
                    1.8 Please describe. Give pictures of the gym.
                  </FormLabel>
                  <div style={{ alignItems: "center", gap: "10px" }}>
                    <textarea
                      name="licenses"
                      className="form-control"
                      value={describeGym}
                      onChange={(e) => {
                        handleDescribeGym(e);
                      }}
                      type="text"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                      style={{ maxWidth: "fit-content", color: "white" }}
                    >
                      {gymfileUploading ? (
                        <CircularProgress
                          size={24}
                          color="inherit"
                        ></CircularProgress>
                      ) : (
                        "Attach Photos"
                      )}
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(event) =>
                          handleAttachPhoto(event, GYM_PHOTO)
                        }
                      />
                    </Button>
                    {gymPhoto?.length > 0 && (
                      <div>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => setGymPhotosModalOpen(true)}
                          style={{ maxWidth: "fit-content" }}
                        >
                          View Photos
                        </Button>
                        <ModalForFileView
                          open={gymPhotosModalOpen}
                          onClose={() => setGymPhotosModalOpen(false)}
                          data={gymPhoto}
                          partnerType="GYM"
                        />
                      </div>
                    )}
                  </div>
                  {selectedGymFile.length > 0 && (
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography variant="h6" gutterBottom>
                        Selected Files
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedGymFile.map((file, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              backgroundColor: "#f9f9f9",
                              // maxWidth: "300px",
                              width: "100%",
                            }}
                          >
                            {/* File Name */}
                            <Typography
                              variant="body2"
                              style={{ wordBreak: "break-all" }}
                            >
                              {file.name}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <b> 1.9 Please attach the licence of gym.</b>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ maxWidth: "fit-content", color: "white" }}
                  >
                    {licensefileUploading ? (
                      <CircularProgress
                        size={24}
                        color="inherit"
                      ></CircularProgress>
                    ) : (
                      "Attach License"
                    )}
                    <input
                      type="file"
                      multiple
                      hidden
                      onChange={(event) =>
                        handleAttachPhoto(event, "LICENSE_FILE")
                      }
                    />
                  </Button>
                  {licence?.length > 0 && (
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setLicenseModalOpen(true)}
                        style={{ maxWidth: "fit-content" }}
                      >
                        View License
                      </Button>
                      <ModalForFileView
                        open={licenseModalOpen}
                        onClose={() => setLicenseModalOpen(false)}
                        data={licence}
                        partnerType="GYM"
                      />
                    </div>
                  )}
                </div>
                {selectedLicenseFile.length > 0 && (
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Selected Files
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      {selectedLicenseFile.map((file, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                            backgroundColor: "#f9f9f9",
                            // maxWidth: "300px",
                            width: "100%",
                          }}
                        >
                          {/* File Name */}
                          <Typography
                            variant="body2"
                            style={{ wordBreak: "break-all" }}
                          >
                            {file.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </Box>
                )}
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color:
                        fieldErrors.gymTime || fieldErrors.gymTimeTo
                          ? "red"
                          : "inherit",
                    }}
                  >
                    1.10 What is the general time of the gym ?
                  </FormLabel>
                  <div>
                    <FormLabel style={{ marginBottom: "0px" }}>From</FormLabel>
                    <input
                      type="time"
                      className="form-control"
                      value={gymTime}
                      onChange={(e) => handleGymTime(e)}
                    />
                  </div>

                  {/* To Time Field */}
                  <div className="mt-3">
                    <FormLabel style={{ marginBottom: "0px" }}>To</FormLabel>
                    <input
                      type="time"
                      className="form-control"
                      value={gymTimeTo}
                      onChange={(e) => handleGymTimeTo(e)}
                    />
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.staffMemberPresent ? "red" : "inherit",
                    }}
                  >
                    1.11 How many staff members are present at any time in the
                    gym ?
                  </FormLabel>
                  <input
                    type="number"
                    className="form-control"
                    value={staffMemberPresent}
                    onChange={(e) => {
                      handleStaffMemberPresent(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.femaleStaffMemberPresent
                        ? "red"
                        : "inherit",
                    }}
                  >
                    1.12 Are female staff members always present to assist
                    female members in case of any need?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={femaleStaffMemberPresent}
                    name="radio-buttons-group"
                    onChange={handleFemaleStaffMemberPresent}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.femaleOutfitSafety ? "red" : "inherit",
                    }}
                  >
                    1.13 How does the outfit ensure safety and security of all
                    its members especially female members? Please elaborate on
                    safety and security measures.
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={femaleOutfitSafety}
                    onChange={(e) => {
                      handleFemaleOutfitSafety(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.memberAgeGroup ? "red" : "inherit",
                    }}
                  >
                    1.14 What is the typical age group of all the present
                    members ?
                  </FormLabel>
                  <input
                    type="text"
                    className="form-control"
                    value={memberAgeGroup}
                    onChange={(e) => {
                      handleMemberAgeGroup(e);
                    }}
                  ></input>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.compositionInGender
                        ? "red"
                        : "inherit",
                    }}
                  >
                    1.15 What is the present composition in terms of gender of
                    all present members? (% female vs male members).
                  </FormLabel>
                  <div
                    style={{
                      display: "flex",
                      color: fieldErrors.compositionInGender
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <select
                      value={compositionInGender}
                      style={{ borderRadius: "5px" }}
                      onChange={(e) => {
                        handleCompositionInGender(e);
                      }}
                    >
                      <option value="">Female/Male</option>
                      <option value="10/90">10/90</option>
                      <option value="20/80">20/80</option>
                      <option value="30/70">30/70</option>
                      <option value="40/60">40/60</option>
                      <option value="50/50">50/50</option>
                      <option value="60/40">60/40</option>
                      <option value="70/30">70/30</option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.memberGymAttract ? "red" : "inherit",
                    }}
                  >
                    1.15 Some gyms attract hardcore bodybuilders, some gyms
                    attract members that may not be heavily invested in real
                    change, and some gyms attract “true” fitness communities.
                    What kind of members mostly your gym attracts or has?
                  </FormLabel>
                  <div
                    style={{
                      display: "flex",
                      color: fieldErrors.memberGymAttract ? "red" : "inherit",
                    }}
                  >
                    <select
                      value={memberGymAttract}
                      style={{ borderRadius: "5px" }}
                      onChange={(e) => {
                        handleMemberGymAttract(e);
                      }}
                    >
                      <option value="">Select</option>
                      <option value="Hardcore bodybuilders">
                        Hardcore bodybuilders
                      </option>
                      <option value="Not heavily invested in read change">
                        Not heavily invested in read change
                      </option>
                      <option value="True fitness communities">
                        True fitness communities
                      </option>
                      <option value="Mixture of all three ">
                        Mixture of all three{" "}
                      </option>
                    </select>
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors.gymAreaPincode ? "red" : "inherit",
                    }}
                  >
                    1.16 List all the pin codes from where your members come
                    from.
                  </FormLabel>
                  {gymAreaPincode?.map((row, index) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                      key={index}
                    >
                      <input
                        id={`areaPincode${index}`}
                        type="text"
                        className="form-control"
                        value={row}
                        onChange={(e) => handleAreaPincode(e, index)}
                        style={{ marginRight: "10px" }}
                      />
                      {index === gymAreaPincode.length - 1 && (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddAreaPincode}
                            style={{ marginRight: "10px" }}
                          >
                            Add
                          </Button>
                          {gymAreaPincode.length > 1 && (
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => handleDeleteAreaPincode(index)}
                            >
                              Delete
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep1}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 1 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. About Equipment </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    style={{
                      color: fieldErrors2.uploadEquipment ? "red" : "inherit",
                    }}
                  >
                    2.1 What kind of equipment are available in the gym? List
                    typical equipment available for fitness and strength
                    training. How old are the equipment?
                  </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Button variant="contained" color="primary" onClick={handleAddRow}>
              Add Row
            </Button>
            <hr></hr>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table
                  style={{
                    border: fieldErrors2.kindOfEquipment
                      ? "2px solid red"
                      : "inherit",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Equipment Name</TableCell>
                      <TableCell>Equipment Type</TableCell>
                      <TableCell>How Old Equipment</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {kindOfEquipment?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input
                            type="text"
                            value={row.equipment}
                            onChange={(e) =>
                              handleInputChange(e, index, "equipment")
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.type}
                            onChange={(e) =>
                              handleInputChange(e, index, "type")
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.equipmentOld}
                            onChange={(e) =>
                              handleInputChange(e, index, "equipmentOld")
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              handleEquipmentFileDelete(index, EQUIPMENT_FILE)
                            }
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={5}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStepKindOfEquipment}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 2 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div className="register_title">
                  <h4>2. About Equipment </h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.machineInstruction
                        ? "red"
                        : "inherit",
                    }}
                  >
                    2.2 Are there easy to understand instruction/pictures
                    available or posted on each machine explaining its usage and
                    operation?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={machineInstruction}
                    onChange={(e) => {
                      handleMachineInstruction(e);
                    }}
                    style={{ width: "85%" }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.timeLimitOnCardio ? "red" : "inherit",
                    }}
                  >
                    2.3 Is there is a sign-up sheet or a time limit on cardio
                    equipment?
                  </FormLabel>
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={timeLimitOnCardio}
                      name="radio-buttons-group"
                      onChange={handleTimeLimitCardio}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {timeLimitOnCardio && (
                      <>
                        <FormLabel
                          style={{
                            color: fieldErrors2.explainCardioTimeLimit
                              ? "red"
                              : "inherit",
                          }}
                        >
                          Please explain.
                        </FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          value={explainCardioTimeLimit}
                          onChange={(e) => {
                            handleExplaincardioTimeLimit(e);
                          }}
                        ></input>
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label
                  style={{
                    color: fieldErrors2.otherFacility ? "red" : "inherit",
                  }}
                >
                  2.4 Please describe all other facilities available to members
                  such as changing rooms, personal lockers (if any), Sauna, etc.
                </label>
                <textarea
                  type="text"
                  value={otherFacility}
                  onChange={(e) => {
                    handleOtherFacility(e);
                  }}
                  className="form-control"
                ></textarea>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep2}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 3 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>3. Membership, Training and Testing </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.introductoryClinic
                        ? "red"
                        : "inherit",
                    }}
                  >
                    3.1 Is there any introductory clinic to help members get
                    started?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={introductoryClinic}
                    name="radio-buttons-group"
                    onChange={handleIntroductoryClinic}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.separateTraning ? "red" : "inherit",
                    }}
                  >
                    3.2 Is separate training provided on complex equipment for
                    proper usage?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={separateTraning}
                    name="radio-buttons-group"
                    onChange={handleSeparateTraining}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.initialFitnessOrientation
                        ? "red"
                        : "inherit",
                    }}
                  >
                    3.3 Is an initial fitness orientation, screening or body
                    assessment provided to each member right after joining?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={initialFitnessOrientation}
                    name="radio-buttons-group"
                    onChange={handleInitialFitnessOrientation}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.areaDedicated ? "red" : "inherit",
                    }}
                  >
                    3.4 Are there areas dedicated to stretching or turf areas
                    for training?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={areaDedicated}
                    name="radio-buttons-group"
                    onChange={handleAreaDedicated}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.gymOfferTesting ? "red" : "inherit",
                    }}
                  >
                    3.5 Does the gym offer body fat testing, cardiovascular
                    testing, and strength and flexibility testing
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={gymOfferTesting}
                    name="radio-buttons-group"
                    onChange={handleGymOfferTesting}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.freeTrailMembership
                        ? "red"
                        : "inherit",
                    }}
                  >
                    3.6 Is there any free trial membership available to check
                    out the gym ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={freeTrailMembership}
                    name="radio-buttons-group"
                    onChange={handleFreeTrailMembership}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {freeTrailMembership == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors3.freeTrailMembershipExplain
                            ? "red"
                            : "inherit",
                        }}
                      >
                        How many days or hours? What kind of membership plans
                        are available? What is included, please briefly mention.{" "}
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={freeTrailMembershipExplain}
                        onChange={(e) => {
                          handleFreeTrailMembershipExplain(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.getTour ? "red" : "inherit",
                    }}
                  >
                    3.7 Can I get a Tour of the gym before signing up for the
                    membership?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={getTour}
                    name="radio-buttons-group"
                    onChange={handleGetTour}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {(getTour == true || getTour == "true") && (
                    <>
                      <FormLabel
                        style={{
                          color:
                            fieldErrors3.getTourTime ||
                            fieldErrors3.getTourTimeTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        When can I come for a quick tour?
                      </FormLabel>
                      <div>
                        <FormLabel style={{ marginBottom: "0px" }}>
                          From
                        </FormLabel>
                        <input
                          type="time"
                          className="form-control"
                          value={getTourTime}
                          onChange={(e) => handleGetTourTime(e)}
                        />
                      </div>

                      {/* To Time Field */}
                      <div className="mt-3">
                        <FormLabel style={{ marginBottom: "0px" }}>
                          To
                        </FormLabel>
                        <input
                          type="time"
                          className="form-control"
                          value={getTourTimeTo}
                          onChange={(e) => handleGetTourTimeTo(e)}
                        />
                      </div>

                      {/* Textbox Below */}
                      <div className="mt-3">
                        <FormLabel style={{ marginBottom: "0px" }}>
                          Additional Notes
                        </FormLabel>
                        <textarea
                          className="form-control"
                          value={tourTimeNote}
                          onChange={(e) => handleTourTimeNote(e)}
                        />
                      </div>
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.refundMembership ? "red" : "inherit",
                    }}
                  >
                    3.8 Can I cancel and get refund of membership fees, if for
                    some reason, after few days of training, I do not want to
                    continue with my membership anymore?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={refundMembership}
                    name="radio-buttons-group"
                    onChange={handleRefundMembership}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  <textarea
                    type="text"
                    className="form-control"
                    value={refundMembershipDetail}
                    onChange={(e) => {
                      handleRefundMembershipDetail(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors3.selectedPlans ? "red" : "inherit",
                    }}
                  >
                    3.9 What kind of membership plans the Gym has? (Select all
                    that applies)
                  </FormLabel>
                  <div>
                    <Checkbox
                      value="1 month"
                      checked={selectedPlans?.includes("1 month")}
                      onChange={handleCheckboxChange}
                    />
                    1 month
                  </div>
                  <div>
                    <Checkbox
                      value="2 months"
                      checked={selectedPlans?.includes("2 months")}
                      onChange={handleCheckboxChange}
                    />
                    2 months
                  </div>
                  <div>
                    <Checkbox
                      value="3 months"
                      checked={selectedPlans?.includes("3 months")}
                      onChange={handleCheckboxChange}
                    />
                    3 months
                  </div>
                  <div>
                    <Checkbox
                      value="6 months"
                      checked={selectedPlans?.includes("6 months")}
                      onChange={handleCheckboxChange}
                    />
                    6 months
                  </div>
                  <div>
                    <Checkbox
                      value="12 months"
                      checked={selectedPlans?.includes("12 months")}
                      onChange={handleCheckboxChange}
                    />
                    12 months
                  </div>

                  {/* "Other" Plan */}
                  <div>
                    <Checkbox
                      value="Other"
                      checked={selectedPlans?.includes("Other")}
                      onChange={(e) => {
                        handleCheckboxChange(e);
                      }}
                    />
                    Other
                    {selectedPlans?.includes("Other") && (
                      <TextField
                        placeholder="other plan"
                        value={otherPlan}
                        onChange={handleOtherPlanChange}
                        fullWidth
                      />
                    )}
                  </div>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep3}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 4 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>4. About Trainers </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.trainersQualified ? "red" : "inherit",
                    }}
                  >
                    4.1 Are the Trainers Qualified?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={trainersQualified}
                    name="radio-buttons-group"
                    onChange={handleTrainerQualified}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.areMaleTrainers ? "red" : "inherit",
                    }}
                  >
                    4.2 Are there Male trainers?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={areMaleTrainers}
                    name="radio-buttons-group"
                    onChange={handleAreMaleTrainers}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {areMaleTrainers == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.maleTrainerExpirence
                          ? "red"
                          : "inherit",
                      }}
                    >
                      4.2.1 What are the qualification and experience level of
                      all Male trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={maleTrainerExpirence}
                      onChange={(e) => {
                        handleMaleTrainerExpirence(e);
                      }}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>
                      4.2.2 What days and time Male trainers are available?
                    </FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.maleWeekdaysFrom ||
                            fieldErrors4.maleWeekdaysTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Weekdays (Monday to Friday):
                      </label>
                      <div>
                        From : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekdaysFrom}
                          onChange={(e) => handleMaleWeekdaysFrom(e)}
                        />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekdaysTo}
                          onChange={(e) => handleMaleWeekdaysTo(e)}
                        />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.maleWeekendsFrom ||
                            fieldErrors4.maleWeekendsTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Saturday:
                      </label>
                      <div>
                        From :&nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekendsFrom}
                          onChange={(e) => handleMaleWeekendsFrom(e)}
                        />{" "}
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekendsTo}
                          onChange={(e) => handleMaleWeekendsTo(e)}
                        />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.maleWeekendsFrom ||
                            fieldErrors4.maleWeekendsTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Sunday:
                      </label>
                      <div>
                        From :&nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekendsSundayFrom}
                          onChange={(e) => handleWeekendsSundayFrom(e)}
                        />{" "}
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={maleWeekendsSundayTo}
                          onChange={(e) => handleWeekendsSundayTo(e)}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.areFemaleTrainer ? "red" : "inherit",
                    }}
                  >
                    4.3 Are Female trainers available?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={areFemaleTrainer}
                    name="radio-buttons-group"
                    onChange={handleAreFemaleTrainer}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {areFemaleTrainer == true && (
              <>
                <Grid container spacing={2} mb={5}>
                  <Grid item md={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.femaleTrainerExpirence
                          ? "red"
                          : "inherit",
                      }}
                    >
                      4.3.1 What are the qualification and experience level of
                      all Female trainers in the gym?
                    </FormLabel>
                    <textarea
                      type="text"
                      className="form-control"
                      value={femaleTrainerExpirence}
                      onChange={(e) => {
                        handleFemaleTrainerExpirence(e);
                      }}
                    ></textarea>
                  </Grid>
                  <Grid item md={6}>
                    <FormLabel>
                      4.3.2 What days and time Female trainers are available?
                    </FormLabel>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.femaleWeekdaysFrom ||
                            fieldErrors4.femaleWeekdaysTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Weekdays (Monday to Friday):
                      </label>
                      <div>
                        From : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekdaysFrom}
                          onChange={(e) => handleFemaleWeekdaysFrom(e)}
                        />
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekdaysTo}
                          onChange={(e) => handleFemaleWeekdaysTo(e)}
                        />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.femaleWeekendsFrom ||
                            fieldErrors4.femaleWeekendsTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Saturday:
                      </label>
                      <div>
                        From :&nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekendsFrom}
                          onChange={(e) => handleFemaleWeekendsFrom(e)}
                        />{" "}
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekendsTo}
                          onChange={(e) => handleFemaleWeekendsTo(e)}
                        />
                      </div>
                    </div>
                    <div style={{ border: "1px solid black", padding: "15px" }}>
                      <label
                        style={{
                          color:
                            fieldErrors4.femaleWeekendsFrom ||
                            fieldErrors4.femaleWeekendsTo
                              ? "red"
                              : "inherit",
                        }}
                      >
                        Sunday
                      </label>
                      <div>
                        From :&nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekendsSundayFrom}
                          onChange={(e) => handleFemaleWeekendsSundayFrom(e)}
                        />{" "}
                        &nbsp; &nbsp;&nbsp; To : &nbsp;
                        <input
                          type="time"
                          style={{ width: "30%" }}
                          value={femaleWeekendsSundayTo}
                          onChange={(e) => handleFemaleWeekendsSundayTo(e)}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors4.anyManager ? "red" : "inherit",
                    }}
                  >
                    4.4 Is there any Manager/Lead Trainer who manages the entire
                    operations of the gym?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={anyManager}
                    name="radio-buttons-group"
                    onChange={handleAnyManager}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  {anyManager == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerName ? "red" : "inherit",
                        }}
                      >
                        4.4.1 Name of the Manager/Lead/Trainer of the gym
                      </FormLabel>
                      <input
                        type="text"
                        className="form-control"
                        value={managerName}
                        onChange={(e) => {
                          handleManagerName(e);
                        }}
                      ></input>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerQualification
                            ? "red"
                            : "inherit",
                        }}
                      >
                        4.4.2 Enter Qualification
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={managerQualification}
                        onChange={(e) => {
                          handleManagerQualification(e);
                        }}
                      ></textarea>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerExpirence
                            ? "red"
                            : "inherit",
                        }}
                      >
                        4.4.3 Enter Expirence
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={managerExpirence}
                        onChange={(e) => {
                          handleManagerExpirence(e);
                        }}
                      ></textarea>
                      <FormLabel
                        style={{
                          color: fieldErrors4.managerAward ? "red" : "inherit",
                        }}
                      >
                        4.4.4 Won any award or other recognitions
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={managerAward}
                        name="radio-buttons-group"
                        onChange={handleManagerAward}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </>
                  )}
                  {managerAward == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors4.explainManagerAward
                            ? "red"
                            : "inherit",
                        }}
                      >
                        4.4.4.1 Mention award/recognitions
                      </FormLabel>
                      <textarea
                        type="text"
                        className="form-control"
                        value={explainManagerAward}
                        onChange={(e) => {
                          handleExplainManagerAward(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep4}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 5 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>5. Cleanliness Standards </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.gymMaintainCleanliness
                        ? "red"
                        : "inherit",
                    }}
                  >
                    5.1 Does the gym maintain good sanitation and cleanliness
                    standard?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={gymMaintainCleanliness}
                    name="radio-buttons-group"
                    onChange={handleGymMaintainCleanliness}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {gymMaintainCleanliness == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors5.explainCleanliness
                            ? "red"
                            : "inherit",
                        }}
                      >
                        How is the cleaning schedule like?
                      </FormLabel>
                      <input
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={explainCleanliness}
                        onChange={(e) => {
                          handleExplainCleanliness(e);
                        }}
                      ></input>
                    </>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.cleaningStationPlaced
                        ? "red"
                        : "inherit",
                    }}
                  >
                    5.2 Are sanitation and cleaning stations placed around the
                    GYM and well-stocked.
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={cleaningStationPlaced}
                    name="radio-buttons-group"
                    onChange={handleCleaningStationPlaced}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.hygieneStandard ? "red" : "inherit",
                    }}
                  >
                    5.3 How do staff members enforce standard of hygiene?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={hygieneStandard}
                    onChange={(e) => {
                      handleHygieneStandard(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.properlyMaintained
                        ? "red"
                        : "inherit",
                    }}
                  >
                    5.4 Are locker rooms, showers, toilets, sinks, kept clean
                    and properly maintained?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={properlyMaintained}
                    name="radio-buttons-group"
                    onChange={handleProperlyMaintained}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {properlyMaintained == true && (
                    <>
                      <FormLabel
                        style={{
                          color: fieldErrors5.explainProperlyMaintained
                            ? "red"
                            : "inherit",
                        }}
                      >
                        How often they are cleaned throughout the day?
                      </FormLabel>
                      <textarea
                        type="text"
                        style={{ width: "50%" }}
                        className="form-control"
                        value={explainProperlyMaintained}
                        onChange={(e) => {
                          handleExplainProperlyMaintained(e);
                        }}
                      ></textarea>
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep5}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 6 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>6. AMENITIES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors6.gymOfferAmenities ? "red" : "inherit",
                    }}
                  >
                    6.1 Does the Gym offer any other amenities?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={gymOfferAmenities}
                    name="radio-buttons-group"
                    onChange={handleGymOfferAmenities}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {gymOfferAmenities == true && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">
                        {" "}
                        6.2 Does it offer ?
                      </FormLabel>
                      <Row>
                        <Col lg={10}></Col>
                        <Col lg={2}>
                          <div
                            style={{
                              display: "flex",
                              gap: "65px",
                              marginTop: "-35px",
                            }}
                          >
                            <div style={{ fontWeight: "bold" }}>Yes</div>
                            <div style={{ fontWeight: "bold" }}>No</div>
                          </div>
                        </Col>
                      </Row>
                      <FormGroup>
                        <FormControl style={{ width: "100%" }}>
                          {/* Question 1 */}
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.kidsArea
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                1. A kid's area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="multitask"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                onChange={handleKidsAres}
                                value={kidsArea}
                              >
                                <FormControlLabel
                                  value={"true"}
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value={"false"}
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.onlyLadiesWorkoutSpace
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                2. Ladies-only workout space
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="exhausted"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={onlyLadiesWorkoutSpace}
                                onChange={handleOnlyLadiesWorkoutSpace}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.sauna ? "red" : "inherit",
                                }}
                              >
                                3. A sauna
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="schedule"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={sauna}
                                onChange={handleSauna}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.functionalTrainingArea
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                4. A functional training area
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="focus"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={functionalTrainingArea}
                                onChange={handleFunctionalTrainingArea}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.personalTrainer
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                5. Personal trainers
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="timeOff"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={personalTrainer}
                                onChange={handlePersonalTrainer}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.wifi ? "red" : "inherit",
                                }}
                              >
                                6. WiFi
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="imbalance"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={wifi}
                                onChange={handleWifi}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                  color: fieldErrors6.proShop
                                    ? "red"
                                    : "inherit",
                                }}
                              >
                                7. A pro shop
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue=""
                                name="guilty"
                                style={{
                                  flexDirection: "row",
                                  flexWrap: "nowrap",
                                  gap: "45px",
                                }}
                                value={proShop}
                                onChange={handleProShop}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio />}
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio />}
                                />
                              </RadioGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={10}>
                              <FormLabel
                                style={{
                                  fontWeight: "normal",
                                  marginTop: "8px",
                                }}
                              >
                                8. Other amenities
                              </FormLabel>
                            </Col>
                            <Col lg={2}>
                              <input
                                type="text"
                                style={{
                                  width: "8rem",
                                  borderBottom: "1px solid #000",
                                }}
                                className="form-control"
                                value={otherAmenities}
                                onChange={(e) => {
                                  handleOtherAmenities(e);
                                }}
                              ></input>
                            </Col>
                          </Row>
                        </FormControl>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep6}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 7 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>7. CLASSES AND PROGRAMS </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.variousTrainingProgram
                        ? "red"
                        : "inherit",
                    }}
                  >
                    7.1 What various classes and training programs offered at
                    the gym?
                  </FormLabel>
                  <textarea
                    type="text"
                    className="form-control"
                    value={variousTrainingProgram}
                    onChange={(e) => {
                      handleVariousTrainingProgram(e);
                    }}
                  ></textarea>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.groupFitnessClasses
                        ? "red"
                        : "inherit",
                    }}
                  >
                    7.2 Are group fitness classes offered at the gym?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={groupFitnessClasses}
                    name="radio-buttons-group"
                    onChange={handleGroupFitnessClasses}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.extraCharge ? "red" : "inherit",
                    }}
                  >
                    7.3 Does the gym charges extra for Group, studio classes, or
                    training programs?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={extraCharge}
                    name="radio-buttons-group"
                    onChange={handleExtraCharge}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleEnvironmentStep7}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
        {environmentStep == 8 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>7. CLASSES AND PROGRAMS </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors7.uploadProgramFile ? "red" : "inherit",
                    }}
                  >
                    7.4 Can I get a list of various classes and programs and
                    their duration, time, charges, trainer name, etc.?
                  </FormLabel>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProgramListAddRow}
            >
              Add Row
            </Button>
            <br></br>
            <hr></hr>
            <Grid item xs={12} md={12}>
              <TableContainer component={Paper}>
                <Table
                  style={{
                    border: fieldErrors7.classesProgramList
                      ? "2px solid red"
                      : "inherit",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Classes Name</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Charges</TableCell>
                      <TableCell>Trainer Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {classesProgramList?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <input
                            type="text"
                            value={row.className}
                            onChange={(e) =>
                              handleProgramFileInputChange(
                                e,
                                index,
                                "className"
                              )
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.duration}
                            onChange={(e) =>
                              handleProgramFileInputChange(e, index, "duration")
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.classTime}
                            onChange={(e) =>
                              handleProgramFileInputChange(
                                e,
                                index,
                                "classTime"
                              )
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.charges}
                            onChange={(e) =>
                              handleProgramFileInputChange(e, index, "charges")
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          <input
                            type="text"
                            value={row.trainerName}
                            onChange={(e) =>
                              handleProgramFileInputChange(
                                e,
                                index,
                                "trainerName"
                              )
                            }
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell>
                          {index === classesProgramList.length - 1 && (
                            <>
                              &nbsp;&nbsp;&nbsp;
                              {/* {classesProgramList.length > 1 && ( */}
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  handleProgramFileDeleteRow(
                                    index,
                                    PROGRAM_FILE
                                  )
                                }
                              >
                                Delete
                              </Button>
                              {/* )} */}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid
              container
              spacing={0}
              mb={0}
              mt={5}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleAttachProgramFile}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 9 && (
          <>
            <h5>
              Enter Details about Classes (Upload class description or fill the
              details in the text box)
            </h5>
            <table
              class="table  table-bordered table-striped"
              style={{
                border: fieldErrors7.classDescription
                  ? "2px solid red"
                  : "inherit",
                marginTop: "10px",
              }}
            >
              <thead class="thead-dark">
                <tr>
                  <th
                    scope="col"
                    style={{
                      backgroundColor: "#5353E9",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Classes Name
                  </th>
                  <th
                    scope="col"
                    style={{
                      backgroundColor: "#5353E9",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Class Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {classesProgramList?.length > 0 ? (
                  classesProgramList?.map((test, index) => (
                    <>
                      <tr key={index}>
                        {console.log("test")}
                        <td style={{ textAlign: "center" }}>
                          {test.className}
                        </td>
                        <td>
                          <textarea
                            type="text"
                            className="form-control"
                            value={test.description}
                            onChange={(event) =>
                              handleProgramFileInputChange(
                                event,
                                index,
                                "description"
                              )
                            }
                          ></textarea>
                          <br></br>
                          <label
                            htmlFor="licenses"
                            style={{
                              cursor: "pointer",
                              color: "#5353e9",
                              textDecoration: "underline",
                              fontWeight: "bold",
                            }}
                          >
                            Attach Class description
                          </label>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .pdf, .png, .doc"
                            id="licenses"
                            value={attachPhoto}
                            className="file-upload-input"
                            name="licenses"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleAttachClassDescription(event, test.id)
                            }
                          />
                          {test.descriptionFile && (
                            <div style={{ marginTop: "10px" }}>
                              <span>
                                Description File: <b>{test.descriptionFile}</b>
                              </span>
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={5}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={handleEnvironmentStepBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  type="button"
                  className="base"
                  onClick={handleSaveClassDescription}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}

        {environmentStep == 10 && (
          <>
            <Grid container spacing={2} mb={12}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.promotionalCurrentPlan
                        ? "red"
                        : "inherit",
                    }}
                  >
                    <h5>Enter Details about Trainer</h5>
                  </FormLabel>

                  {classesProgramList?.length > 0 ? (
                    // Group by trainerName (unique trainers)
                    [
                      ...new Map(
                        classesProgramList.map((item) => [
                          item.trainerName,
                          item,
                        ])
                      ).values(),
                    ].map((groupedItem, index) => {
                      return (
                        <>
                          <div key={index}>
                            {/* Render table for each trainer */}
                            <table
                              className="table table-bordered"
                              style={{
                                border: fieldErrors7.classesProgramList
                                  ? "2px solid red"
                                  : "inherit",
                                marginTop: "2%",
                              }}
                            >
                              <thead>
                                <tr className="table-active">
                                  <th
                                    scope="col"
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Trainer Name
                                  </th>
                                  <th
                                    scope="col"
                                    colSpan={2}
                                    style={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Qualifications and Experience Detail
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr key={groupedItem.id}>
                                  {/* Trainer Name */}
                                  <td style={{ textAlign: "center" }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={groupedItem.trainerName}
                                      disabled
                                    />
                                  </td>
                                  <td colSpan={2}>
                                    {groupedItem.gymTrainerDetail &&
                                    groupedItem.gymTrainerDetail.length > 0 ? (
                                      // If gymTrainerDetail is not null and has data, filter the trainer detail based on trainerName
                                      groupedItem.gymTrainerDetail
                                        .filter(
                                          (trainerDetail) =>
                                            trainerDetail.trainerName ===
                                            groupedItem.trainerName
                                        )
                                        .map((trainerDetail) => (
                                          <div key={trainerDetail.id}>
                                            {/* Trainer Qualification */}
                                            <textarea
                                              className="form-control"
                                              value={
                                                trainerDetails[trainerDetail.id]
                                                  ?.trainerQualification ||
                                                trainerDetail.qualificationAndExperience ||
                                                ""
                                              }
                                              onChange={(e) =>
                                                handleTrainerDetailsChange(
                                                  e,
                                                  trainerDetail.id,
                                                  "trainerQualification"
                                                )
                                              }
                                              disabled={
                                                trainerDetails[trainerDetail.id]
                                                  ?.trainerQualification ||
                                                trainerDetail.qualificationAndExperience
                                                  ? true
                                                  : false
                                              }
                                              placeholder="Trainer Qualification"
                                              style={{ width: "100%" }}
                                            />

                                            {/* Gender, Google Rating, and Testimonial */}
                                            <table className="table">
                                              <thead>
                                                <tr className="table-active">
                                                  <th
                                                    scope="col"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Gender Trained
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Google Rating
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Client Testimonials from
                                                    Google/Other
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  {/* Gender Trained */}
                                                  <td>
                                                    <select
                                                      value={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.genderTrained ||
                                                        trainerDetail.genderTrained ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleTrainerDetailsChange(
                                                          e,
                                                          trainerDetail.id,
                                                          "genderTrained"
                                                        )
                                                      }
                                                      className="form-control"
                                                      style={{ width: "100%" }}
                                                      disabled={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.genderTrained ||
                                                        trainerDetail.genderTrained
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      <option value="male">
                                                        Male
                                                      </option>
                                                      <option value="female">
                                                        Female
                                                      </option>
                                                      <option value="both">
                                                        Both
                                                      </option>
                                                    </select>
                                                  </td>

                                                  {/* Google Rating */}
                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.googleRating ||
                                                        trainerDetail.googleRating ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleTrainerDetailsChange(
                                                          e,
                                                          trainerDetail.id,
                                                          "googleRating"
                                                        )
                                                      }
                                                      disabled={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.googleRating ||
                                                        trainerDetail.googleRating
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="Enter Google Rating"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>

                                                  {/* Client Testimonial */}
                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.clientTestimonial ||
                                                        trainerDetail.clientTestimonial ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleTrainerDetailsChange(
                                                          e,
                                                          trainerDetail.id,
                                                          "clientTestimonial"
                                                        )
                                                      }
                                                      disabled={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.clientTestimonial ||
                                                        trainerDetail.clientTestimonial
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="Enter Client Testimonial"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>

                                            {/* Awards and Age Group */}
                                            <table className="table">
                                              <thead>
                                                <tr className="table-active">
                                                  <th
                                                    scope="col"
                                                    colSpan={2}
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Any Awards
                                                  </th>
                                                  <th
                                                    scope="col"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Age Group Trained
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td colSpan={2}>
                                                    <textarea
                                                      className="form-control"
                                                      value={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.anyAwards ||
                                                        trainerDetail.anyAwards ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleTrainerDetailsChange(
                                                          e,
                                                          trainerDetail.id,
                                                          "anyAwards"
                                                        )
                                                      }
                                                      disabled={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.anyAwards ||
                                                        trainerDetail.anyAwards
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="Enter any awards"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>

                                                  <td>
                                                    <textarea
                                                      className="form-control"
                                                      value={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.ageGroupTrained ||
                                                        trainerDetail.ageGroupTrained ||
                                                        ""
                                                      }
                                                      onChange={(e) =>
                                                        handleTrainerDetailsChange(
                                                          e,
                                                          trainerDetail.id,
                                                          "ageGroupTrained"
                                                        )
                                                      }
                                                      disabled={
                                                        trainerDetails[
                                                          trainerDetail.id
                                                        ]?.ageGroupTrained ||
                                                        trainerDetail.ageGroupTrained
                                                          ? true
                                                          : false
                                                      }
                                                      placeholder="Enter age group trained"
                                                      style={{ width: "100%" }}
                                                    />
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        ))
                                    ) : (
                                      <div>
                                        <textarea
                                          className="form-control"
                                          value={trainerQualification}
                                          onChange={(e) =>
                                            handleTrainerQualification(e)
                                          }
                                          placeholder="Enter qualifications and experience"
                                          style={{ width: "100%" }}
                                        />

                                        {/* Gender, Google Rating, and Testimonial */}
                                        <table className="table">
                                          <thead>
                                            <tr className="table-active">
                                              <th
                                                scope="col"
                                                style={{
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Gender trained
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Google Rating
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Client testimonials from Google/
                                                other
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <select
                                                  value={genderTrained}
                                                  onChange={(e) =>
                                                    handleGenderTrained(e)
                                                  }
                                                  className="form-control"
                                                  style={{ width: "100%" }}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  <option value="male">
                                                    Male
                                                  </option>
                                                  <option value="female">
                                                    Female
                                                  </option>
                                                  <option value="both">
                                                    Both
                                                  </option>
                                                </select>
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={trainerGoogleRating}
                                                  onChange={(e) =>
                                                    handleTrainerGoogleRating(e)
                                                  }
                                                  placeholder="Enter Google Rating"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={trainerTestimonial}
                                                  onChange={(e) =>
                                                    handleTrainertestimonial(e)
                                                  }
                                                  placeholder="Enter Client Testimonial"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        {/* Awards and Age Group */}
                                        <table className="table">
                                          <thead>
                                            <tr className="table-active">
                                              <th
                                                scope="col"
                                                colSpan={2}
                                                style={{
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Any awards
                                              </th>
                                              <th
                                                scope="col"
                                                style={{
                                                  textAlign: "center",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                Age group trained
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td colSpan={2}>
                                                <textarea
                                                  className="form-control"
                                                  value={trainerAward}
                                                  onChange={(e) =>
                                                    handleTrainerAward(e)
                                                  }
                                                  placeholder="Enter any awards"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                              <td>
                                                <textarea
                                                  className="form-control"
                                                  value={ageGroupTrained}
                                                  onChange={(e) =>
                                                    handleAgeGroupTrained(e)
                                                  }
                                                  placeholder="Enter age group trained"
                                                  style={{ width: "100%" }}
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <div
                                          className="btn btn-info"
                                          style={{ marginTop: "15px" }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn-info"
                                            fontWeight="large"
                                            onClick={() =>
                                              handleTrainerSave(
                                                groupedItem.id,
                                                groupedItem.trainerName
                                              )
                                            } // Pass the correct ID and trainer name
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <p>No trainers available</p>
                  )}
                  <Grid
                    container
                    spacing={0}
                    mb={0}
                    mt={3}
                    justifyContent="space-between"
                    display={"flex"}
                    gap={5}
                  >
                    <div className="submit-button">
                      <button
                        className="base"
                        onClick={handleEnvironmentStepBack}
                      >
                        Back
                      </button>
                    </div>
                    <div className="submit-button">
                      <button
                        type="button"
                        className="base"
                        onClick={() => handleTrainersScreen()} // Pass the correct ID and trainer name
                      >
                        Next
                      </button>
                    </div>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={12}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.promotionalCurrentPlan
                        ? "red"
                        : "inherit",
                    }}
                  ></FormLabel>
                </FormControl>
              </Grid>
            </Grid>

            <br></br>
          </>
        )}
        {environmentStep == 11 && (
          <>
            <Grid container spacing={2} mb={5}>
              <Grid item>
                <div>
                  <h4>8. COST AND FEES </h4>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.promotionalCurrentPlan
                        ? "red"
                        : "inherit",
                    }}
                  >
                    8.1 Is the gym running any promotional plan currently?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={promotionalCurrentPlan}
                    name="radio-buttons-group"
                    onChange={handlePromotionalCurrentPlan}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors8.paymentPlan ? "red" : "inherit",
                    }}
                  >
                    8.2 Is there a payment plan available to pay the membership
                    fees?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={paymentPlan}
                    name="radio-buttons-group"
                    onChange={handlePaymentPlan}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <br></br>
            <h6>
              * START TODAY ON YOUR “FITNESS AND LIFESTYLE DISEASE
              PREVENTION” PLAN WITH YOUR 7 DAY FREE PASS FROM LIVING SCOPE
              HEALTH.{" "}
            </h6>
            <br></br>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={FinalPageBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button type="button" className="base" onClick={GymFromSubmit}>
                  Submit
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
    </div>
  );
};

export default GymGeneralInfo;
