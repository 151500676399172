import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Spinner from "../../shared/Spinner";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const GymMembershipOrders = () => {
  const [GymData, setGymData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("gym/get-gym-subscription-order/GYM")
      .then((response) => {
        if (response) {
          setGymData(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setLoading(false);
      });
  }, []);
  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : GymData.length > 0 ? (
        <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
          <Table>
            <TableRow>
              <TableCell
                style={{
                  backgroundColor: " rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Order Id</b>
                </p>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: " rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Booking Date</b>
                </p>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Patient UHID</b>
                </p>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Name</b>
                </p>
              </TableCell>

              <TableCell
                style={{
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Phone Number</b>
                </p>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Plan Name</b>
                </p>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor: "rgb(83, 83, 233)",
                  color: "white",
                  border: "1px solid lightgrey",
                  fontSize: "18px",
                }}
              >
                <p style={{ fontSize: "18px" }}>
                  <b>Membership Price</b>
                </p>
              </TableCell>
            </TableRow>
            <TableBody>
              {GymData.map((data) => {
                return (
                  <TableRow
                  // onClick={() => handleRowClick(orders, index)}
                  >
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.orderId}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.bookingDate
                        ? formatDateToDDMMYYYY(data.bookingDate)
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.patientUhId}
                    </TableCell>

                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.patientFirstName} {data.patientLastName}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.patientPhoneNumber}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {data.planDuration}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                        border: "1px solid lightgrey",
                        fontSize: "20px",
                      }}
                    >
                      {parseInt(data.amount)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40vh",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
            No Gym Membership Orders Available.
          </Typography>
          <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
        </Box>
      )}
    </div>
  );
};

export default GymMembershipOrders;
