import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  getLabOrderDetailByIdApi,
  getLabOrderDetailApi,
} from "../../constants/apiList";
import Spinner from "../../shared/Spinner";

const OrderDetailModel = ({ orderId }) => {
  const [orderDetail, setOrderDetail] = useState(null);
  const [patientDetail, setPatientDetail] = useState(null);
  const [labDetail, setLabDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const getPaymentDetail = async () => {
    setLoading(true);
    await AxiosInstance.get(`${getLabOrderDetailByIdApi}/${orderId}`)
      .then((response) => {
        if (response != null) {
          setOrderDetail(response);
          setPatientDetail(response.patientDetail);
          setLabDetail(response.labDetail);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPaymentDetail();
  }, []);

  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Card
          style={{
            padding: "15px",
            border: "1px solid black",
            margin: "10px 0px 10px 0px",
          }}
          elevation={5}
        >
          {patientDetail && (
            <>
              <div style={{ textAlign: "center" }}>
                <h4>Lifestyle Health Risk Test Request Form</h4>
                <hr />
              </div>
              <Row style={{ marginTop: "1%" }}>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Living Scope Order Id : </b>
                    {orderDetail.orderId}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Date : </b>
                    {orderDetail.date}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>patient ID : UHID : </b>
                    {orderDetail.patientId + " : " + patientDetail.patientUpId}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Age : </b>
                    {patientDetail.patientAge}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Name : </b>
                    {patientDetail.patientName}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Gender : </b>
                    {patientDetail.patientGender}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Contact Number : </b>
                    {patientDetail.patientContactNumber}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Lab Name : </b>
                    {labDetail.labName}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Patient Address : </b>
                    {patientDetail.address.addressLine1 +
                      ", " +
                      patientDetail.address.city +
                      " ( " +
                      patientDetail.address.state +
                      ", " +
                      patientDetail.address.zipcode +
                      ", " +
                      patientDetail.address.country +
                      ")"}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Lab Contact Number : </b>
                    {labDetail.labPhoneNumber}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Test Name : </b>
                    {orderDetail.testDetail
                      .map((testName) => testName.testName)
                      .join(", ")}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Test Price : </b>
                    {orderDetail.totalPrice}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Preferred Date : </b>
                    {orderDetail.preferDate
                      ? formatDateToDDMMYYYY(orderDetail.preferDate)
                      : "Not Available"}
                  </CardText>
                </Col>
                <Col lg={6}>
                  <CardText className="mt-1">
                    <b>Preferred Time : </b>
                    {orderDetail.preferTime
                      ? formatTime(orderDetail.preferTime)
                      : "Not Available"}
                  </CardText>
                </Col>
              </Row>
            </>
          )}
          {labDetail && (
            <>
              <Row>
                <Col lg={12}>
                  {orderDetail.testDetail.some(
                    (test) => test.testName === "Lipid Profile"
                  ) && (
                    <div>
                      <CardText>
                        <br></br>
                        <b>Tests to be included (if any):</b> Tests should
                        include Total Cholesterol, LDL Cholesterol, HDL
                        Cholesterol, Triglyceride, Very Low-Density Lipoprotein,
                        Non-HDL Cholesterol.
                      </CardText>
                    </div>
                  )}
                </Col>
              </Row>
            </>
          )}
          {orderDetail && (
            <>
              <Row>
                <Col lg={12}>
                  <CardText className="mt-3">
                    <b>Note to Lab : </b>
                  </CardText>
                  <div>
                    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                      <li>
                        Please call the patient promptly to set up home
                        collection.
                      </li>
                      <li>
                        After tests are done and reports are available, please
                        upload on Uncurl: health app platform mentioning Patient
                        ID.
                      </li>
                      <li>
                        If there are any questions or issues regarding this
                        order, call our customer support team at{" "}
                      </li>
                      <li>
                        Please send bill reflecting Living Scope Order ID as
                        well as Patient ID
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default OrderDetailModel;
