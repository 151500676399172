// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export enum CheckVideoInputFeedback {
  Succeeded,
  Failed,
  PermissionDenied,
}

export default CheckVideoInputFeedback;
