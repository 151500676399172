import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { PutChannelMembershipPreferencesRequestFilterSensitiveLog, PutChannelMembershipPreferencesResponseFilterSensitiveLog, } from "../models/models_0";
import { de_PutChannelMembershipPreferencesCommand, se_PutChannelMembershipPreferencesCommand, } from "../protocols/Aws_restJson1";
export { $Command };
export class PutChannelMembershipPreferencesCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "PutChannelMembershipPreferences", {})
    .n("ChimeSDKMessagingClient", "PutChannelMembershipPreferencesCommand")
    .f(PutChannelMembershipPreferencesRequestFilterSensitiveLog, PutChannelMembershipPreferencesResponseFilterSensitiveLog)
    .ser(se_PutChannelMembershipPreferencesCommand)
    .de(de_PutChannelMembershipPreferencesCommand)
    .build() {
}
