import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Grid,
  FormHelperText,
} from "@mui/material";
import { ModalHeader, ModalBody } from "reactstrap";
import Dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import PriceModal from "./paymentModal";
import { ClassNames } from "@emotion/react";

const FreeSessionModal = ({ open, handleClose, sessionType, userId , trainerData, IsFreeSession}) => {
  console.log("userId", userId)
  console.log("trainer", trainerData)
  const [freeDemoTimingsMorning, setFreeDemoTimingsMorning] = useState([])
  const [freeDemoTimingsEvening, setFreeDemoTimingsEvening] = useState([])
  const [gymPriceList, setGymPriceList] = useState([]);
  const [paymentModal, setPaymentModal] = useState(false); 
   const [formData, setFormData] = useState({
    selectedDate: null,
    selectedTime: "",
    errorDate: "",
    errorTime: "",
  });
  Dayjs.extend(utc);
    const [membershipFormData, setMembershipFormData] = useState({
      id:null,
      selectedPlan: "",
      price: null,
      errors: {
        plan: false,
        price: false,
      },
    });
  
    // const priceMapping = {
    //   "1 Month": 3850,
    //   "3 Months": 7500,
    //   "6 Months": 12000,
    //   "1 Year": 16000,
    // };
    const plans = [
      { duration: "14 sessions/month", price: 1900 , id:5},
      { duration: "22 sessions/month", price: 2400, id:6 },
      { duration: "26 sessions/month", price: 2800, id:7 },
    ];
    const Zumbaplans = [
      { duration: "14 sessions/month", price: 1900 },
      { duration: "22 sessions/month", price: 2400 },
      { duration: "26 sessions/month", price: 2800 },
    ];
    Dayjs.extend(utc);
      const handlePlanChange = (event) => {
        const selectedValue = event.target.value;
    
        // Find the corresponding price
        const selectedPlanObj = plans.find(
          (plan) => plan.duration === selectedValue
        );
        // Update state with selected plan, price, and reset errors
        setMembershipFormData((prev) => ({
          ...prev,
          id: selectedPlanObj.id,
          selectedPlan: selectedValue,
          price: selectedPlanObj ? selectedPlanObj.price : "",
        }));
      };
    
      const handleBookAppointment = (gymData) => {
        let isValid = true;
        let errors = { errorDate: "", errorTime: "" };
    
        if (!formData.selectedDate) {
          errors.errorDate = "Please enter date";
          isValid = false;
        }
    
        if (!formData.selectedTime) {
          errors.errorTime = "Please enter time";
          isValid = false;
        }
    
        setFormData({ ...formData, ...errors });
    
        if (isValid) {
          console.log("Booking Details:", {
            date: formData.selectedDate.format("DD/MM/YYYY"),
            time: formData.selectedTime,
          });
          const sendForMdata = {
            partnerUserId: gymData?.userId,
            sessionDate: formData.selectedDate,
            sessionTime: formData.selectedTime,
          };
    
          AxiosInstance.post("gym/create-free-session", sendForMdata)
            .then((response) => {
              onClose();
              Swal.fire({
                title: "Appointment Booked!",
                text: `Your appointment at ${
                  gymData?.gymName
                } is booked for ${formData.selectedDate.format("DD/MM/YYYY")} at ${
                  formData.selectedTime
                }.`,
                icon: "success",
                confirmButtonText: "OK",
                customClass: {
                  popup: "swal-popup",
                },
                didOpen: () => {
                  const swalPopup = document.querySelector(".swal-popup");
                  if (swalPopup) {
                    swalPopup.style.zIndex = "999999";
                  }
                },
              });
            })
            .catch((error) => {
              console.log("Error:", error);
            });
        }
      };
      const handleBookMembership = () => {
        const { selectedPlan, price } = membershipFormData;
    
        // Validate fields
        const newErrors = {
          plan: !selectedPlan,
          price: !price,
        };
    
        setMembershipFormData((prev) => ({
          ...prev,
          errors: newErrors,
        }));
    
        // Proceed only if both fields are filled
        if (selectedPlan && price) {
          setPaymentModal(true);
        }
      };
      const getPaymentSuccessBaseUrl = () => {
        const baseUrl = window.location.href;
        let arr = baseUrl.split("/");
        let newPath = `${arr[0]}//${arr[2]}/patient-online-consultation`;
        return encodeURI(newPath); // Encode the URL
      };
    
      const handlePayment = (gymUserId) => {
        const formData = {
          // amount: membershipFormData.price,  // Use price here instead of total
          partnerUserId: gymUserId,
          partnerType: "GYM",
          planDuration: membershipFormData.selectedPlan,
          ClassNames: "Excerise"
        };
      
        AxiosInstance.post(
          `payment/create-subscription-gym-and-trainer?successURL=${encodeURIComponent(getPaymentSuccessBaseUrl())}`,
          formData
        )
          .then((response) => {
            setMembershipFormData({
              selectedPlan: "",
              price: null,
              errors: {
                plan: false,
                price: false,
              },
            });
            setPaymentModal(false);
            onClose();
            window.location.href = response.data; // Assuming the response is a URL or location to redirect
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };
      
  useEffect(() => {
    if (trainerData?.freeDemoTimingsMorning) {
      setFreeDemoTimingsMorning(JSON.parse(trainerData?.freeDemoTimingsMorning));
    }
    if (trainerData?.freeDemoTimingsEvening) {
      setFreeDemoTimingsEvening(JSON.parse(trainerData?.freeDemoTimingsEvening));
    }
  }, [trainerData]);

  useEffect(() => {
    AxiosInstance.get("gym/get-all-gym-list")
      .then((response) => {
        setGymPriceList(response[0]?.gymPriceList)
      })
      .catch((error) => {
        console.log(error);

      });
  }, []);
 
  const morningFrom = freeDemoTimingsMorning?.timing?.from;
  const morningTo = freeDemoTimingsMorning?.timing?.to;
  const eveningFrom = freeDemoTimingsEvening?.timing?.from;
  const eveningTo = freeDemoTimingsEvening?.timing?.to;

  const timeSlots = [];
  for (let hour = 11; hour <= 16; hour++) {
    const period = hour >= 12 ? "PM" : "AM";
    const displayHour = hour > 12 ? hour - 12 : hour; 

    timeSlots.push(`${displayHour}:00 ${period}`);
    timeSlots.push(`${displayHour}:30 ${period}`);
  }
  timeSlots.push("5:00 PM");

  return (
    <>    
    {IsFreeSession ? 
     <Modal open={open} onClose={handleClose}>
     <Box
       sx={{
         position: "absolute",
         top: "50%",
         left: "50%",
         transform: "translate(-50%, -50%)",
         width: "50%",
         bgcolor: "background.paper",
         boxShadow: 24,
         p: 4,
         borderRadius: 2,
         textAlign: "center",
       }}
     >
       <Box
         sx={{
           display: "flex",
           justifyContent: "space-between",
           alignItems: "center",
         }}
       >
         <Typography id="modal-title" variant="h6">
          {IsFreeSession ? "Book Free Session" : "Book Session"} 
         </Typography>
         <IconButton onClick={handleClose} size="small">
           <CloseIcon />
         </IconButton>
       </Box>
       <Box
         textAlign="center"
         p={3}
         boxShadow={3}   free 
         borderRadius={2}
         mt={2}
         border="1px solid"
       >
         <Typography variant="h6" gutterBottom>
          {IsFreeSession ? `Free Appointment is available only between ${morningFrom} to ${morningTo} AM & ${eveningFrom} to ${eveningTo} PM` : ""} 
         </Typography>
         <Typography variant="h5" mb={2}>
          {IsFreeSession ? `Book Free Appointment for ${sessionType}` : ""} 
         </Typography>
         {IsFreeSession ?  
         <> <Box
         display="flex"
         justifyContent="center"
         gap={2}
         alignItems="center"
       >
         <Box display="flex" flexDirection="column" alignItems="center">
           <LocalizationProvider dateAdapter={AdapterDayjs}>
             <DatePicker
               value={
                 formData.selectedDate
                   ? Dayjs(formData.selectedDate).utc()
                   : null
               }
               minDate={Dayjs().startOf("day")}
               onChange={(newDate) =>
                 setFormData({
                   ...formData,
                   selectedDate: newDate
                     ? newDate.utc().startOf("day")
                     : null,
                   errorDate: "",
                 })
               }
               format="DD/MM/YYYY"
               renderInput={(params) => (
                 <TextField {...params} variant="standard" fullWidth />
               )}
             />
           </LocalizationProvider>
           {formData.errorDate && (
             <Typography color="error" variant="body2">
               {formData.errorDate}
             </Typography>
           )}
         </Box>
         <Box display="flex" flexDirection="column" alignItems="center">
           <Select
             value={formData.selectedTime}
             onChange={(e) =>
               setFormData({
                 ...formData,
                 selectedTime: e.target.value,
                 errorTime: "",
               })
             }
             displayEmpty
             variant="outlined"
             error={!!formData.errorTime}
           >
             <MenuItem value="" disabled>
               Select Time Slot
             </MenuItem>
             {timeSlots.map((time, index) => (
               <MenuItem key={index} value={time}>
                 {time}
               </MenuItem>
             ))}
           </Select>
           {formData.errorTime && (
             <Typography color="error" variant="body2">
               {formData.errorTime}
             </Typography>
           )}
         </Box>   
       </Box> 
           <Button
           color="primary"
           variant="contained"
           sx={{ mt: 3 }}
           onClick={handleBookAppointment}
         >
           Book
         </Button>
         </>
         : ""
         }
       
       </Box>
     </Box>
   </Modal> : 
    <>
   <Modal open={open} onClose={handleClose}>
   <Box
     sx={{
       position: "absolute",
       top: "50%",
       left: "50%",
       transform: "translate(-50%, -50%)",
       width: "50%",
       bgcolor: "background.paper",
       boxShadow: 24,
       p: 4,
       borderRadius: 2,
       textAlign: "center",
     }}
   >
     <Box
       sx={{
         display: "flex",
         justifyContent: "space-between",
         alignItems: "center",
       }}
     >
       <Typography id="modal-title" variant="h6">
         Book Session 
       </Typography>
       <IconButton onClick={handleClose} size="small">
         <CloseIcon />
       </IconButton>
     </Box>
     <Box
       textAlign="center"
       p={3}
       boxShadow={3}
       borderRadius={2}
       mt={2}
       border="1px solid"
     >
  <Typography variant="h5" mb={2}>
  Book Membership
</Typography>
<Box
  display="flex"
  justifyContent="center"
  gap={2}
  alignItems="center"
>
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Typography variant="subtitle1" mb={1}>
      Select Session
    </Typography>
    <Select
      value=""
      onChange={handlePlanChange}
      displayEmpty
      fullWidth
      // error={membershipFormData.errors.plan} // Highlight field in red if error
      sx={{ minWidth: 200 }}
    >
      <MenuItem value="" disabled>
        Select Session
      </MenuItem>
      {plans.map((plan, index) => (
        <MenuItem key={index} value={plan.duration}>
          {plan.duration}
        </MenuItem>
      ))}
    </Select>
    {membershipFormData.errors.plan && (
      <FormHelperText sx={{ color: "red" }}>
        session is required
      </FormHelperText>
    )}
  </Box>

  {/* Price Field (Read-only) */}
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <Typography variant="subtitle1" mb={1}>
      Membership Price
    </Typography>
    <TextField
      value={membershipFormData.price}
      fullWidth
      disabled
      type="number"
      error={membershipFormData.errors.price} // Highlight field in red if error
      sx={{ minWidth: 200 }}
    />
    {membershipFormData.errors.price && (
      <FormHelperText sx={{ color: "red" }}>
        Price is required
      </FormHelperText>
    )}
  </Box>
     </Box>
     <Button
                          color="primary"
                          variant="contained"
                          sx={{ mt: 3 }}
                          onClick={handleBookMembership}
                        >
                          Book Membership
                        </Button>  
   </Box>
   </Box>
 </Modal>
 {paymentModal && (
  <PriceModal
    open={paymentModal}
    setOpen={setPaymentModal}
    trainerData={trainerData}
    userId={userId} // Pass the payment handler
    membershipFormData={membershipFormData} // Pass necessary data to PriceModal
  />
)}

 </>
    }
  </>

  );
};

export default FreeSessionModal;
