import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration"; // Replace with your actual Axios instance file.
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";
import { Delete as DeleteIcon } from "@mui/icons-material";

const LabBankDetail = () => {
  const [bankList, setBankList] = useState([
    {
      branchName: "",
      accountNumber: "",
      accountHolderName: "",
      ifscCode: "",
      bankName: "",
      primaryAccount: false,
    },
  ]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [bankToDelete, setBankToDelete] = useState(null);
  const [deleting, setDeleting] = useState(false);

  // Fetch existing bank details from API
  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("bank-detail/get-bank-detail")
      .then((response) => {
        if (!(response === null || (response && response.length === 0))) {
          console.log("bank", response);
          setBankList(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bank details:", error);
        setLoading(false);
      });
  }, []);

  // Validation for each field
  const validate = (bank, index) => {
    const newErrors = {};
    if (!bank.branchName.trim())
      newErrors.branchName = "Branch name is required";
    if (!bank.accountNumber.trim()) {
      newErrors.accountNumber = "Account number is required";
    } else if (!/^\d+$/.test(bank.accountNumber)) {
      newErrors.accountNumber = "Account number must be numeric";
    }
    if (!bank.accountHolderName.trim())
      newErrors.accountHolderName = "Account holder name is required";
    if (!bank.ifscCode.trim()) {
      newErrors.ifscCode = "IFSC code is required";
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(bank.ifscCode)) {
      newErrors.ifscCode = "Invalid IFSC code format";
    }
    if (!bank.bankName.trim()) newErrors.bankName = "Bank name is required";

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = newErrors;
      return updatedErrors;
    });

    return Object.keys(newErrors).length === 0;
  };

  // Handle input change
  const handleChange = (e, index) => {
    const { name, value, type, checked } = e.target;
    let updatedBankList = [...bankList];
    // updatedBankList[index][name] = type === "checkbox" ? checked : value;

    if (name === "primaryAccount") {
      updatedBankList = updatedBankList.map((bank, i) => ({
        ...bank,
        primaryAccount: i === index ? checked : false, // Set current as primary and others as false
      }));
    } else {
      updatedBankList[index][name] = type === "checkbox" ? checked : value;
    }

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      if (updatedErrors[index]) {
        updatedErrors[index][name] = "";
      }
      return updatedErrors;
    });

    setBankList(updatedBankList);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = true;

    const validationResults = bankList.map((bank, index) => {
      const isValidForIndex = validate(bank, index);
      if (!isValidForIndex) isValid = false;
      return isValidForIndex;
    });

    if (isValid) {
      console.log("Bank List:", bankList);
      setSubmitting(true);
      AxiosInstance.post("bank-detail/create-bank-detail", bankList)
        .then((response) => {
          toast.success("Bank Saved Successfully");
          setSubmitting(false);
        })
        .catch((error) => {
          console.error("Error adding banks:", error);
          setSubmitting(false);
        });
    }
  };

  // Add a new bank row
  const addBank = () => {
    setBankList((prevList) => [
      ...prevList,
      {
        branchName: "",
        accountNumber: "",
        accountHolderName: "",
        ifscCode: "",
        bankName: "",
        primaryAccount: false,
      },
    ]);
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const handleDelete = () => {
    if (bankToDelete) {
      setDeleting(true);
      AxiosInstance.delete(`bank-detail/delete-bank-detail/${bankToDelete.id}`)
        .then(() => {
          setBankList((prevList) =>
            prevList.filter((bank) => bank.id !== bankToDelete.id)
          );
          toast.success("Bank deleted successfully");
          setDeleteDialogOpen(false);
          setBankToDelete(null);
        })
        .catch((error) => {
          console.error("Error deleting bank:", error);
        })
        .finally(() => {
          setDeleting(false); // Stop the spinner
        });
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box>
            <Typography variant="h5" textAlign="center" className="mb-2">
              Bank Details
            </Typography>
            <form onSubmit={handleSubmit}>
              {bankList.map((bank, index) => (
                <Paper
                  key={index}
                  elevation={3}
                  sx={{
                    mb: 3,
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: "#f9f9f9",
                  }}
                >
                  {/* <Typography variant="h6" mb={2}>
                  Bank #{index + 1}
                </Typography> */}
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" mb={2}>
                      Bank #{index + 1}
                    </Typography>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setBankToDelete(bank);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Branch Name"
                      name="branchName"
                      value={bank.branchName}
                      onChange={(e) => handleChange(e, index)}
                      error={!!errors[index]?.branchName}
                      helperText={errors[index]?.branchName}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Account Number"
                      name="accountNumber"
                      value={bank.accountNumber}
                      onChange={(e) => handleChange(e, index)}
                      error={!!errors[index]?.accountNumber}
                      helperText={errors[index]?.accountNumber}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Account Holder Name"
                      name="accountHolderName"
                      value={bank.accountHolderName}
                      onChange={(e) => handleChange(e, index)}
                      error={!!errors[index]?.accountHolderName}
                      helperText={errors[index]?.accountHolderName}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="IFSC Code"
                      name="ifscCode"
                      value={bank.ifscCode}
                      onChange={(e) => handleChange(e, index)}
                      error={!!errors[index]?.ifscCode}
                      helperText={errors[index]?.ifscCode}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label="Bank Name"
                      name="bankName"
                      value={bank.bankName}
                      onChange={(e) => handleChange(e, index)}
                      error={!!errors[index]?.bankName}
                      helperText={errors[index]?.bankName}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="primaryAccount"
                          checked={bank.primaryAccount}
                          onChange={(e) => handleChange(e, index)}
                        />
                      }
                      label="Set as Primary Account"
                    />
                  </Box>
                </Paper>
              ))}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addBank}
                  sx={{ textTransform: "none", mb: 2 }}
                >
                  Add Bank
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none", fontSize: 16 }}
                >
                  {submitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save All Banks"
                  )}
                </Button>
              </div>
            </form>
          </Box>
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            // sx={{ "& .MuiPaper-root": { width: "100%" } }}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "#f5f5f5",
              }}
            >
              Delete Bank
            </DialogTitle>
            <DialogContent sx={{ textAlign: "center" }}>
              <DialogContentText>
                {/* Are you sure you want to delete this bank? */}
                <Typography>
                  {deleting
                    ? "Deleting the bank account. Please wait..."
                    : "Are you sure you want to delete this bank account?"}
                </Typography>
                {deleting && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 2,
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                )}
              </DialogContentText>
            </DialogContent>
            {!deleting && (
              <DialogActions
                sx={{ justifyContent: "center", paddingBottom: 2 }}
              >
                <Button
                  onClick={() => setDeleteDialogOpen(false)}
                  color="secondary"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#f44336",
                    "&:hover": { backgroundColor: "#d32f2f" },
                  }}
                >
                  No
                </Button>
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  color="error"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#4caf50",
                    "&:hover": { backgroundColor: "#388e3c" },
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default LabBankDetail;
