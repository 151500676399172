import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  List,
  ListItem,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Col, Row } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalForFileView from "../../shared/ModalForFileView";

const PhysiotherapistProfile = () => {
  const [formData, setFormData] = useState({
    name: "",
    drMailId: "",
    drContactNumber: "",
    ownClinic: "no",
    clinicContactNumber: "",
    clinicName: "",
    clinicAddress: "",
    registeredPhysiotherapist: "no",
    educationalBackground: "",
    specialization: "",
    guidelines: "no",
    whichGuidelines: "",
    conditionsTreated: "",
    clientsWorkedWith: "",
    yearsOfExperience: "",
    onlineSessions: "no",
    affiliatedHospital: "no",
    hospitalExperienceYears: "",
    numberOfHospitals: "",
    hospitalName: "",
    homeVisit: "no",
    ageGroupServed: "",
    googleRating: "",
    willingToProvideReferences: "no",
    medicalConditions: [],
    consultingRate: "",
    packageServices: "no",
  });

  const [errors, setErrors] = useState({});
  const [submitted, setsubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testimonialFiles, setTestimonialFiles] = useState([]);
  const [apiTestimonialFiles, setApiTestimonialFiles] = useState([]);
  const [fileUploading, setfileUploading] = useState(false);
  const [rows, setRows] = useState([
    { name: "", file: null, errors: {}, fileUploading: false },
  ]);
  const [licenseFiles, setLicenseFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

  const handleAddRow = () => {
    setRows([...rows, { name: "", file: null, errors: {} }]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const handleDocumentChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    // Remove error if the field is filled correctly
    if (field === "name" && value.trim() !== "") {
      delete updatedRows[index].errors.nameError;
    }
    if (field === "file" && value) {
      if (updatedRows[index].name.trim() === "") {
        updatedRows[index].errors.nameError =
          "Please enter the name of the document.";
        updatedRows[index].file = null; // Reset the file field
        setRows(updatedRows);
        return; // Stop further execution if name is missing
      }
      delete updatedRows[index].errors.fileError;
      const fileType = "LICENSE_FILE"; // Set the file type dynamically if needed
      const formData = new FormData();
      formData.append("files", value); // Append the file
      formData.append("fileName", updatedRows[index].name); // Append the fileName

      updatedRows[index].fileUploading = true; // Show file uploading status
      setRows(updatedRows);
      AxiosInstance.post(
        `physician/physician-document-upload/${fileType}`,
        formData
      )
        .then((response) => {
          toast.success("Document uploaded successfully");
          // updatedRows[index].fileUploading = false;
          // setRows(updatedRows);
          setRows((currentRows) => {
            const newRows = [...currentRows];
            newRows[index].fileUploading = false;
            newRows[index].file = value; // Set the file after successful upload
            return newRows;
          });
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          toast.error("Failed to upload files. Please try again.");
          // updatedRows[index].fileUploading = false;
          // setRows(updatedRows);
          setRows((currentRows) => {
            const newRows = [...currentRows];
            newRows[index].fileUploading = false; // Stop spinner on error
            return newRows;
          });
        });
    }

    setRows(updatedRows);
  };

  const validateRows = () => {
    const updatedRows = [...rows];
    let isValid = true;

    updatedRows.forEach((row, index) => {
      const errors = {};
      if (!row.name.trim()) {
        errors.nameError = "Please enter a name.";
        isValid = false;
      }
      if (!row.file) {
        errors.fileError = "Please attach a file.";
        isValid = false;
      }
      updatedRows[index].errors = errors;
    });

    setRows(updatedRows);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[name]; // Remove the error for the current field
      return updatedErrors;
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    setTestimonialFiles((prevFiles) => [...prevFiles, ...files]);
    // Clear error when valid files are selected

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors.testimonialFiles;
      return updatedErrors;
    });

    if (files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => formData.append("files", file));
      setfileUploading(true);
      const fileType = "TESTIMONIAL";
      // Replace this with your Axios API call
      AxiosInstance.post(
        `physician/physician-document-upload/${fileType}`,
        formData
      )
        .then((response) => {
          toast.success("Testimonial Files uploaded successfully");
          setfileUploading(false);
        })
        .catch((error) => {
          console.error("Error uploading files:", error);
          toast.error("Failed to upload files. Please try again.");
          setfileUploading(false);
        });
    }

    // Call POST API
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedConditions;

    if (value === "Other") {
      updatedConditions = checked
        ? [...formData.medicalConditions, "Other"]
        : formData.medicalConditions.filter(
            (condition) => condition !== "Other"
          );
      setFormData({
        ...formData,
        medicalConditions: updatedConditions,
        otherCondition: checked ? formData.otherCondition : "", // Reset "Other" input if unchecked
      });

      // Clear validation if unchecked
      if (!checked) {
        setErrors((prevErrors) => ({ ...prevErrors, otherCondition: "" }));
      }
    } else {
      updatedConditions = checked
        ? [...formData.medicalConditions, value]
        : formData.medicalConditions.filter((condition) => condition !== value);
      setFormData({ ...formData, medicalConditions: updatedConditions });
    }

    if (
      updatedConditions.length > 0 &&
      !(updatedConditions.length === 1 && updatedConditions[0] === "")
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, medicalConditions: "" }));
    }
  };

  const handleOtherConditionChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      otherCondition: value,
    }));

    // Clear validation when user starts typing
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, otherCondition: "" }));
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.drContactNumber)
      newErrors.drContactNumber = "Contact number is required";
    if (!formData.drMailId) newErrors.drMailId = "Email is required";
    if (formData.ownClinic === "yes" && !formData.clinicName)
      newErrors.clinicName = "Clinic name is required";
    if (formData.ownClinic === "yes" && !formData.clinicAddress)
      newErrors.clinicAddress = "Clinic address is required";
    if (formData.ownClinic === "yes" && !formData.clinicContactNumber)
      newErrors.clinicContactNumber = "Clinic contact number is required";
    if (formData.guidelines === "yes" && !formData.whichGuidelines)
      newErrors.whichGuidelines = "Guidelines are required";
    if (
      formData.affiliatedHospital === "yes" &&
      !formData.hospitalExperienceYears
    ) {
      newErrors.hospitalExperienceYears =
        "Experience in years/months is required";
    }
    if (formData.affiliatedHospital === "yes" && !formData.numberOfHospitals) {
      newErrors.numberOfHospitals = "Number of hospitals is required";
    }
    if (formData.affiliatedHospital === "yes" && !formData.hospitalName) {
      newErrors.hospitalName = "Hospital Name is required";
    }
    if (!formData.ageGroupServed.trim())
      newErrors.ageGroupServed = "Age group is required.";

    if (!formData.googleRating.trim())
      newErrors.googleRating = "Google rating is required.";
    // if (formData.medicalConditions.length === 0)
    //   newErrors.medicalConditions = "Please select at least one condition.";
    const filteredMedicalConditions = formData.medicalConditions.filter(
      (condition) => condition.trim() !== ""
    );

    if (filteredMedicalConditions.length === 0) {
      newErrors.medicalConditions = "Please select at least one condition.";
    } else {
      // Add filteredMedicalConditions to the form data to be sent
      formData.medicalConditions = filteredMedicalConditions;
    }
    if (!formData.consultingRate.trim())
      newErrors.consultingRate = "Consulting rate is required.";

    if (
      formData.willingToProvideReferences === "yes" &&
      apiTestimonialFiles.length === 0 &&
      testimonialFiles.length === 0
    ) {
      newErrors.testimonialFiles = "Please attach the files.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = () => {
    // Proceed with API submission logic
    let finalMedicalConditions = [...formData.medicalConditions];

    // Validation for "Other"
    if (
      finalMedicalConditions.includes("Other") &&
      (!formData.otherCondition || formData.otherCondition.trim() === "")
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        otherCondition: "Please type the disease.",
      }));
      return;
    }

    // Replace "Other" with custom input
    if (finalMedicalConditions.includes("Other") && formData.otherCondition) {
      finalMedicalConditions = finalMedicalConditions.map((condition) =>
        condition === "Other" ? formData.otherCondition : condition
      );
    }

    const booleanFields = [
      "registeredPhysiotherapist",
      "guidelines",
      "onlineSessions",
      "ownClinic",
      "affiliatedHospital",
      "homeVisit",
      "willingToProvideReferences",
      "packageServices",
    ];

    const submittedData = {
      fullName: formData.name,
      email: formData.drMailId,
      contactNumber: formData.drContactNumber,
      clinicName: formData.clinicName || null,
      clinicContactNumber: formData.clinicContactNumber || null,
      clinicAddress: formData.clinicAddress || null,
      educationalBackground: formData.educationalBackground,
      specialization: formData.specialization,
      whichGuidelines: formData.whichGuidelines,
      conditionsTreated: formData.conditionsTreated,
      clientsWorkedWith: formData.clientsWorkedWith,
      yearsOfExperience: formData.yearsOfExperience,
      hospitalExperienceYears: formData.hospitalExperienceYears,
      ageGroupServed: formData.ageGroupServed,
      googleRating: formData.googleRating,
      numberOfHospitals: formData.numberOfHospitals,
      hospitalName: formData.hospitalName,
      medicalConditions: finalMedicalConditions.join(", "),
      consultingRate: formData.consultingRate,
      ...booleanFields.reduce((acc, field) => {
        if (formData[field] === "yes") {
          acc[field] = true;
        } else if (formData[field] === "no") {
          acc[field] = false;
        } else {
          acc[field] = formData[field];
        }
        return acc;
      }, {}),
    };

    AxiosInstance.post("physician/create-profile", submittedData)
      .then((response) => {
        setsubmitted(true);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const hasExistingFiles = rows.some((row) => row.name || row.file);
      // Check if there are license files
      if (licenseFiles.length > 0 && !hasExistingFiles) {
        // Submit the form with the existing license files
        submitForm();
      } else if (validateRows()) {
        // Submit the form with newly added files
        submitForm();
      }
    }
    // Call an API to submit formData if needed
  };

  const predefinedConditions = [
    "Obesity",
    "Hypertension",
    "Diabetes",
    "CVD",
    "Cancers",
    "Chronic Respiratory diseases",
    "PCOS/ PCOD",
    "Thyroid",
  ];

  useEffect(() => {
    setLoading(true);
    AxiosInstance.get("physician/get-profile")
      .then((response) => {
        if (response != null) {
          const conditionsFromAPI = response.medicalConditions
            .split(",")
            .map((condition) => condition.trim());

          const matchedConditions = conditionsFromAPI.filter((condition) =>
            predefinedConditions.includes(condition)
          );
          const unmatchedConditions = conditionsFromAPI.filter(
            (condition) => !predefinedConditions.includes(condition)
          );

          setFormData({
            name: response.fullName || "",
            drMailId: response.email || "",
            drContactNumber: response.contactNumber || "",
            ownClinic: response.ownClinic ? "yes" : "no",
            clinicName: response.clinicName || "",
            clinicContactNumber: response.clinicContactNumber || "",
            clinicAddress: response.clinicAddress || "",
            registeredPhysiotherapist: response.registeredPhysiotherapist
              ? "yes"
              : "no",
            educationalBackground: response.educationalBackground || "",
            specialization: response.specialization || "",
            guidelines: response.guidelines ? "yes" : "no",
            whichGuidelines: response.whichGuidelines || "",
            conditionsTreated: response.conditionsTreated || "",
            clientsWorkedWith: response.clientsWorkedWith || "",
            yearsOfExperience: response.yearsOfExperience || "",
            onlineSessions: response.onlineSessions ? "yes" : "no",
            affiliatedHospital: response.affiliatedHospital ? "yes" : "no",
            hospitalExperienceYears: response.hospitalExperienceYears || "",
            numberOfHospitals: response.numberOfHospitals || "",
            hospitalName: response.hospitalName || "",
            homeVisit: response.homeVisit ? "yes" : "no",
            ageGroupServed: response.ageGroupServed || "",
            googleRating: response.googleRating || "",
            willingToProvideReferences: response.willingToProvideReferences
              ? "yes"
              : "no",
            medicalConditions: matchedConditions,
            otherCondition: unmatchedConditions.join(", ") || "",
            consultingRate: response.consultingRate || "",
            packageServices: response.packageServices ? "yes" : "no",
          });

          // Automatically check "Other" if there are unmatched conditions
          if (unmatchedConditions.length > 0) {
            setFormData((prev) => ({
              ...prev,
              medicalConditions: [...prev.medicalConditions, "Other"],
            }));
          }
          setApiTestimonialFiles(response.testimonialFile || []);
          setLicenseFiles(response.licenseFiles || []);
        } else {
          const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
          if (userDetails) {
            setFormData({
              ...formData,
              name: `${userDetails.firstName} ${userDetails.lastName}`,
              drMailId: userDetails.email || "",
              drContactNumber: userDetails.phoneNumber || "",
            });
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setLoading(false);
      });
  }, []);

  const handleDownload = (filePath) => {
    const downloadApi = "document/download-partner-document/PHYSICIAN";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const handleDelete = (filePath, gymPhoto) => {
    const id = gymPhoto.id;

    const downloadApi = "document/delete-partners-document";
    const encodedUrl = encodeURIComponent(filePath);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}/${id}/PHYSICIAN`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Deleted Successfully!",
            text: "The document was deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              downloadLink.download = filename;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
              window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        mx: "auto",
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
        bgcolor: "background.paper",
      }}
    >
      {submitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              Profile has been submitted Successfully.
            </Typography>
          </div>
        </Box>
      ) : (
        <>
          <Typography variant="h4" mb={2}>
            Physiotherapist Profile
          </Typography>

          {/* Name */}
          {loading ? (
            <Spinner></Spinner>
          ) : (
            <>
              <Row>
                <Col lg={6}>
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="Dr. Contact Number"
                    name="drContactNumber"
                    value={formData.drContactNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.drContactNumber}
                    helperText={errors.drContactNumber}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <TextField
                    label="Dr. Mail ID"
                    name="drMailId"
                    value={formData.drMailId}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.drMailId}
                    helperText={errors.drMailId}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f5f5f5", // Light grey background
                        color: "#000", // Ensure content is visible
                        fontSize: "1rem", // Increase font size for readability
                        fontWeight: "bold", // Make text bold for better visibility
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#888", // Optional: Grey label color to match the style
                      },
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you have your own clinic?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="ownClinic"
                      value={formData.ownClinic}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.ownClinic === "yes" && (
                <>
                  <Row>
                    <Col lg={6}>
                      <TextField
                        label="Clinic Name"
                        name="clinicName"
                        value={formData.clinicName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicName}
                        helperText={errors.clinicName}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Clinic Contact Number"
                        name="clinicContactNumber"
                        value={formData.clinicContactNumber}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicContactNumber}
                        helperText={errors.clinicContactNumber}
                      />
                    </Col>
                    <Col lg={12}>
                      <TextField
                        label="Clinic Address with Pincode"
                        name="clinicAddress"
                        value={formData.clinicAddress}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.clinicAddress}
                        helperText={errors.clinicAddress}
                      />
                    </Col>
                  </Row>
                </>
              )}

              {/* Are you a registered physiotherapist? */}
              <Row>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you a registered physiotherapist?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="registeredPhysiotherapist"
                      value={formData.registeredPhysiotherapist}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <TextField
                    label="What is your educational background for providing physiotherapy services?"
                    name="educationalBackground"
                    value={formData.educationalBackground}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What is your physical therapy specialization in?"
                    name="specialization"
                    value={formData.specialization}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you follow any specific guidelines for your practice?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="guidelines"
                      value={formData.guidelines}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.guidelines === "yes" && (
                <Row>
                  <Col lg="12">
                    <TextField
                      label="Which guideline ?"
                      name="whichGuidelines"
                      value={formData.whichGuidelines}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      error={!!errors.whichGuidelines}
                      helperText={errors.whichGuidelines}
                    />
                  </Col>
                </Row>
              )}

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What medical conditions or diseases have you successfully treated?"
                    name="conditionsTreated"
                    value={formData.conditionsTreated}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="How many clients have you worked with in your career?"
                    name="clientsWorkedWith"
                    value={formData.clientsWorkedWith}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="How many years of experience do you have?"
                    name="yearsOfExperience"
                    value={formData.yearsOfExperience}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you offer online workout sessions?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="onlineSessions"
                      value={formData.onlineSessions}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  {" "}
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you affiliated with any hospital?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="affiliatedHospital"
                      value={formData.affiliatedHospital}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you provide home visit sessions?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="homeVisit"
                      value={formData.homeVisit}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              {formData.affiliatedHospital === "yes" && (
                <>
                  <Row>
                    <Col lg={6}>
                      <TextField
                        label="Experience (Years/Months)"
                        name="hospitalExperienceYears"
                        value={formData.hospitalExperienceYears}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.hospitalExperienceYears}
                        helperText={errors.hospitalExperienceYears}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Number of Hospitals"
                        name="numberOfHospitals"
                        value={formData.numberOfHospitals}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.numberOfHospitals}
                        helperText={errors.numberOfHospitals}
                      />
                    </Col>
                    <Col lg={6}>
                      <TextField
                        label="Hospital Names"
                        name="hospitalName"
                        value={formData.hospitalName}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        error={!!errors.hospitalName}
                        helperText={errors.hospitalName}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row>
                <Col lg={6}>
                  <TextField
                    label="Which age group of people have you provided your services so far?"
                    name="ageGroupServed"
                    value={formData.ageGroupServed}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.ageGroupServed}
                    helperText={errors.ageGroupServed}
                  />
                </Col>
                <Col lg={6}>
                  <TextField
                    label="What is your Google rating?"
                    name="googleRating"
                    value={formData.googleRating}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.googleRating}
                    helperText={errors.googleRating}
                  />
                </Col>
              </Row>

              {/* Willing to Provide References */}

              <Row>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Are you willing to provide references or testimonials from
                      2-3 patients or clients of yours?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="willingToProvideReferences"
                      value={formData.willingToProvideReferences}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {formData.willingToProvideReferences === "yes" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "10px",
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                          >
                            {fileUploading ? (
                              <CircularProgress
                                size={24}
                                color="inherit"
                              ></CircularProgress>
                            ) : (
                              "Attach Testimonials"
                            )}
                            <input
                              type="file"
                              multiple
                              hidden
                              onChange={handleFileChange}
                            />
                          </Button>
                          {apiTestimonialFiles.length > 0 && (
                            <div>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => setIsModalOpen(true)}
                              >
                                View Testimonials
                              </Button>
                              <ModalForFileView
                                open={isModalOpen}
                                onClose={() => setIsModalOpen(false)}
                                data={apiTestimonialFiles}
                                partnerType="PHYSICIAN"
                              />
                            </div>
                          )}
                        </div>
                        {testimonialFiles.length > 0 && (
                          <div style={{ marginTop: "20px" }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                              Attached Testimonials:
                            </Typography>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "10px",
                              }}
                            >
                              {testimonialFiles.map((file, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    backgroundColor: "#f9f9f9",
                                    // maxWidth: "300px",
                                    width: "100%",
                                  }}
                                >
                                  {/* File Name */}
                                  <Typography
                                    variant="body2"
                                    style={
                                      {
                                        // overflow: "hidden",
                                        // textOverflow: "ellipsis",
                                        // whiteSpace: "nowrap",
                                        // maxWidth: "180px",
                                      }
                                    }
                                  >
                                    {file.name}
                                  </Typography>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {/* Error message */}
                        {errors.testimonialFiles && (
                          <Typography
                            color="error"
                            style={{ marginTop: "10px" }}
                          >
                            {errors.testimonialFiles}
                          </Typography>
                        )}
                      </>
                    )}
                  </FormControl>
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Which of the following medical conditions you have
                      experience in treating?
                    </FormLabel>
                    <FormGroup>
                      {[
                        "Obesity",
                        "Hypertension",
                        "Diabetes",
                        "CVD",
                        "Cancers",
                        "Chronic Respiratory diseases",
                        "PCOS/ PCOD",
                        "Thyroid",
                        "Other",
                      ].map((condition) => (
                        <FormControlLabel
                          key={condition}
                          control={
                            <Checkbox
                              value={condition}
                              checked={
                                //   formData.medicalConditions.includes(
                                //   condition
                                // )
                                condition === "Other"
                                  ? formData.medicalConditions.includes("Other")
                                  : formData.medicalConditions.includes(
                                      condition
                                    )
                              }
                              onChange={handleCheckboxChange}
                            />
                          }
                          label={condition}
                        />
                      ))}
                      {formData.medicalConditions.includes("Other") && (
                        <TextField
                          label="Please specify"
                          value={formData.otherCondition || ""}
                          onChange={(e) =>
                            handleOtherConditionChange(e.target.value)
                          }
                          margin="normal"
                          fullWidth
                          error={!!errors.otherCondition}
                          helperText={errors.otherCondition}
                        />
                      )}
                    </FormGroup>
                    {errors.medicalConditions && (
                      <Typography color="error">
                        {errors.medicalConditions}
                      </Typography>
                    )}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <TextField
                    label="What is your first one-time typical consulting charge?"
                    name="consultingRate"
                    value={formData.consultingRate}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.consultingRate}
                    helperText={errors.consultingRate}
                  />
                </Col>
                <Col lg={6}>
                  <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      Do you give monthly, two-month, three-month, and other
                      packages?
                    </FormLabel>
                    <RadioGroup
                      row
                      name="packageServices"
                      value={formData.packageServices}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Typography variant="h6" mb={2}>
                    Attach Licenses/Certificates/and any other Government
                    Certificates
                  </Typography>

                  {rows.map((row, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mb: 2,
                      }}
                    >
                      {/* Name Field */}

                      <TextField
                        label="Name Of Document"
                        value={row.name}
                        onChange={(e) =>
                          handleDocumentChange(index, "name", e.target.value)
                        }
                        error={Boolean(row.errors.nameError)}
                        helperText={row.errors.nameError}
                        variant="outlined"
                      />

                      {/* File Upload Field */}
                      <div>
                        <Button
                          variant="outlined"
                          component="label"
                          startIcon={
                            !row.fileUploading &&
                            !row.file && <UploadFileIcon />
                          } // Hide icon during upload
                          sx={{
                            backgroundColor: row.file
                              ? "#e0f7fa"
                              : "transparent",
                            "&:hover": { backgroundColor: "#b2ebf2" },
                            minWidth: "100px",
                            minHeight: "50px",
                          }}
                          disabled={row.fileUploading}
                          style={{ position: "relative" }}
                        >
                          {row.fileUploading ? (
                            <CircularProgress
                              size={24}
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: -12,
                                marginLeft: -12,
                                color: "#fff",
                              }}
                              color="inherit"
                            />
                          ) : row.file ? (
                            row.file.name
                          ) : (
                            "Attach File"
                          )}
                          <input
                            type="file"
                            hidden
                            onChange={(e) =>
                              handleDocumentChange(
                                index,
                                "file",
                                e.target.files[0]
                              )
                            }
                          />
                        </Button>
                        {row.errors.fileError && (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ mt: 1, display: "block" }}
                          >
                            {row.errors.fileError}
                          </Typography>
                        )}
                      </div>

                      {/* Add Row Button */}
                      {index === rows.length - 1 && (
                        <IconButton
                          color="primary"
                          onClick={handleAddRow}
                          sx={{
                            flexShrink: 0,
                            backgroundColor: "#e3f2fd",
                            "&:hover": { backgroundColor: "#bbdefb" },
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                      {/* Add Delete Button */}
                      {index !== 0 && (
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteRow(index)}
                          sx={{
                            flexShrink: 0,
                            backgroundColor: "#ffebee",
                            "&:hover": { backgroundColor: "#ffcdd2" },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}

                  {licenseFiles.length > 0 && (
                    <div className="mb-2">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setIsDocumentModalOpen(true)}
                      >
                        View Attached Documents
                      </Button>
                      <ModalForFileView
                        open={isDocumentModalOpen}
                        onClose={() => setIsDocumentModalOpen(false)}
                        data={licenseFiles}
                        partnerType="PHYSICIAN"
                      />
                    </div>
                  )}
                </Col>
              </Row>

              <Button
                type="submit"
                variant="contained"
                color="success"
                fullWidth
              >
                Submit
              </Button>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default PhysiotherapistProfile;
