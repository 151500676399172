import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row, Toast } from "reactstrap";
import "./zumbaTrainer.css";
import trainer from "./images/trainer.jpeg";
import { Button } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import Swal from "sweetalert2";
import FreeSessionModal from "./FreeSessionModal";
import BookGymModel from "../../../src/screens/GymScreen/BookGymModel";

const ZumbaTrainer = ({ setCurrentTabIndex }) => {
  const [open, setOpen] = useState(false);
  const [sessionType, setSessionType] = useState("");
  const [isFreeSession, setIsFreeSession] = useState(false)
  const [trainerData, setTrainerData] = useState([]);
  const [userId, setUserId] = useState();
  const [freeDemoTimingsEvening, setFreeDemoTimingsEvening] = useState();
  const [freeDemoTimingsMorning, setFreeDemoTimingsMorning] = useState();
  const [reference, setReference] = useState("");
  const [workDetails, setWorkDetails] = useState()
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingView, setIsLoadingView] = useState(false);
  const [file, setFile]= useState([]);
  

  const handleOpen = (type, isFreeSession) => {
    setSessionType(type);
    setIsFreeSession(isFreeSession)
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const handleZumbaSession = () => {
    Swal.fire({
      text: "Free Zumba session is booked!",
      icon: "success",
    });
  };
  const handleCrossfitSession = () => {
    Swal.fire({
      text: "Free CrossFit session is booked!",
      icon: "success",
    });
  };


    useEffect(() => {
       AxiosInstance.get("trainer/getAll-trainer")
      .then((response) => {
        setTrainerData(response);
        setUserId(response[0]?.userId)
        setFreeDemoTimingsMorning(JSON.parse(response[0]?.freeDemoTimingsMorning))
        setFreeDemoTimingsEvening(JSON.parse(response[0]?.freeDemoTimingsEvening))
        setReference(JSON.parse(response[0].referenceDetail))
        setWorkDetails(JSON.parse(response[0]?.workDetail))
        setFile(response[0]?.trainerImageList[0])
      })
      .catch((error) => {
        console.log("Error:", error);
      });

      if (file) {
        setIsLoadingView(true);
        setSelectedFile(file);
        const authToken = window.sessionStorage.getItem('token');
        const viewApi = `document/download-partner-document/TRAINER_IMAGE`;
        const encodedUrl = encodeURIComponent(file.filePath);
        const requestOptions = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          redirect: 'follow',
        };
  
        fetch(
          `${process.env.REACT_APP_SERVER_URL}${viewApi}?path=${encodedUrl}`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch document: ${response.status}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob);
            setSelectedFile({ ...file, blobUrl });
          })
          .catch((error) => {
            toast.error(`Error fetching the file: ${error}`);
            setViewModalOpen(false); // Close modal if fetch fails
          })
          .finally(() => {
            setIsLoadingView(false); // Stop loader
          });
      }
    }, []);
  return (
    <>
      <div className="card">
        <h3
          style={{
            fontFamily: "auto",
            fontSize: "xx-large",
            color: "firebrick",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <p>{trainerData[0]?.firstName.toUpperCase()}&nbsp;{trainerData[0]?.lastName.toUpperCase()}</p>
        </h3>

        <Row>
          <Col
            md={2}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              paddingTop: "20px",
            }}
          >
             <div>
      {file ? (
        <img
          src={`${process.env.REACT_APP_SERVER_URL}${file.filePath}`} // Use the file path from the state
          alt="Trainer"
          style={{
            maxWidth: '100%',
            height: 'auto',
            borderRadius: '8px',
            border: '1px solid #1976d2',
            padding: '10px',
          }}
        />
      ) : (
        <p>Loading...</p> // Show loading text or placeholder while the image is being fetched
      )}
    </div>
          </Col>

          <Col md={10} style={{ paddingLeft: "20px", marginTop: "20px" }}>
            <p>
              {trainerData[0]?.describeYourself}
            </p>
            <Row>
              <Col md={4}>
                <h4 className="mt-4" style={{ color: "#1976d2" }}>
                  Expertise
                </h4>
                {trainerData[0]?.expertise}
                <h4 style={{ color: "#1976d2", marginTop: "20px" }}>
                Year of working as trainer
                </h4>
                {trainerData[0]?.yearsWorkingAsTrainer}
              </Col>
              <Col md={6}>
                <h4 style={{ color: "#1976d2" }} className="mt-4">
                  Current Engagement
                </h4>
                <p>{trainerData[0]?.currentEngagements}</p>
                <h4 style={{ color: "#1976d2", marginTop: "20px" }}>
                  Certifications & Recognition
                </h4>
                {trainerData[0]?.certifications}
              </Col>
              <Col md={4}>
                <h4 className="mt-4" style={{ color: "#1976d2" }}>
                 Goggle Rating
                </h4>
                {trainerData[0]?.googleRatings}
              </Col>
              <Col md={6}>
                <h4 style={{ color: "#1976d2" }} className="mt-4">
                   Address
                </h4>
                <p>{trainerData[0]?.registeredAddress}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}></Col>
            </Row>
          </Col>
        </Row>
        <br></br>
        <hr></hr>
        <h4 style={{ color: "#1976d2", marginTop: "2%" }}>Zumba Benefits</h4>
        <ul>
          <li>
            Calorie Burner: Zumba can burn up to 400-800 calories per session.
          </li>
          <li>
            Improved Cardiovascular Health: Zumba's aerobic exercise improves
            heart health and increases endurance.
          </li>
          <li>
            Weight Loss: Zumba's high-energy movements help with weight loss and
            maintenance.
          </li>
          <li>
            Increased Metabolism: Zumba's interval training boosts metabolism,
            helping the body burn calories more efficiently.
          </li>
          <li>
            Improved Coordination and Balance: Zumba's dance-based movements
            improve coordination, balance, and overall physical fitness.
          </li>
          <li>
            Stress Relief: Zumba's fun and energetic atmosphere helps reduce
            stress and improve mood.
          </li>
          <li>
            Improved Muscle Tone: Zumba's movements engage multiple muscle
            groups, improving overall muscle tone.
          </li>
        </ul>

        <h4 style={{ color: "#1976d2" }}>CrossFit Benefits</h4>
        <ul>
          <li>
            High-Intensity Calorie Burn: CrossFit's intense workouts burn up to
            700-1000 calories per session.
          </li>
          <li>
            Improved Functional Strength: CrossFit's varied exercises improve
            functional strength, making everyday activities easier.
          </li>
          <li>
            Increased Endurance: CrossFit's high-intensity interval training
            (HIIT) improves cardiovascular endurance and anaerobic capacity.
          </li>
          <li>
            Weight Loss and Management: CrossFit's combination of strength
            training and cardio exercises helps with weight loss and
            maintenance.
          </li>
          <li>
            Improved Flexibility and Mobility: CrossFit's varied movements
            improve flexibility, mobility, and range of motion.
          </li>
          <li>
            Mental Toughness and Accountability: CrossFit's community-driven
            approach fosters mental toughness, accountability, and motivation.
          </li>
          <li>
            Improved Bone Density: CrossFit's strength training exercises help
            improve bone density, reducing the risk of osteoporosis.
          </li>
        </ul>

        <h4 style={{ color: "#1976d2" }}>Combining Zumba and CrossFit</h4>
        <ul>
          <li>
            Well-Rounded Fitness: Combining Zumba and CrossFit provides a
            well-rounded fitness routine, covering cardiovascular exercise,
            strength training, and flexibility.
          </li>
          <li>
            Increased Caloric Burn: Alternating between Zumba and CrossFit
            sessions can increase overall caloric burn and enhance weight loss
            efforts.
          </li>
          <li>
            Improved Mental and Physical Fitness: Combining the fun, energetic
            atmosphere of Zumba with the challenging, results-driven approach of
            CrossFit can improve mental and physical fitness, overall.
          </li>
        </ul>
        <div>
          {trainerData[0]?.registeredBusiness === true ?
          <> 
          <h4 style={{ color: '#1976d2'}}>Registered Organization</h4>
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #1976d2', padding: '10px', marginTop:"7px" }}>
        <thead>
          <tr style={{fontWeight:"bold", borderBottom:"1px solid #1976d2", textAlign:"center"}}>
            <th style={{border:"1px solid #1976d2"}}>Name</th>
            <th style={{border:"1px solid #1976d2"}}>Busniess Timing</th>
            <th style={{border:"1px solid #1976d2"}}>Phone Number</th>
            <th style={{border:"1px solid #1976d2"}}>Address</th>
          </tr>
        </thead>
        <tbody>
            <tr>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{trainerData[0]?.organizationName}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{trainerData[0]?.organizationBusinessTime}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{trainerData[0]?.organizationPhoneNumber}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{trainerData[0]?.organizationAddress}</td>
            </tr>
        </tbody>
      </table></>  : ""}
   
      <h4 style={{ color: '#1976d2', marginTop:"3%"}}>Classes Information</h4>
      <table border="1" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #1976d2', padding: '10px', marginTop:"7px" }}>
        <thead>
          <tr style={{fontWeight:"bold", borderBottom:"1px solid #1976d2", textAlign:"center"}}>
            <th style={{border:"1px solid #1976d2"}}>Class</th>
            <th style={{border:"1px solid #1976d2"}}>Age Group</th>
            <th style={{border:"1px solid #1976d2"}}>Gender</th>
            <th style={{border:"1px solid #1976d2"}}>Mode</th>
            <th style={{border:"1px solid #1976d2"}}>Session</th>
            <th style={{border:"1px solid #1976d2"}}>Timing From</th>
            <th style={{border:"1px solid #1976d2"}}>Timing To</th>
            <th style={{border:"1px solid #1976d2"}}>Weekdays</th>
          </tr>
        </thead>
        <tbody>
          {workDetails?.map((item, index) => (
            <tr key={index}>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.className}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.ageGroup.join(', ')}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.gender}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.mode}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.session}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.timingFrom}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.timingTo}</td>
              <td style={{textAlign:"center", border:"1px solid #1976d2"}}>{item.weekdays.map(day => day.name).join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

        <Row style={{marginTop:"3%"}}>
      <Col md={6} style={{ border: '1px solid #1976d2', padding: '10px' }}>
        <h4 style={{ color: '#1976d2' }}>Free demo session (Morning)</h4>
        <Col md={12} className="mt-3">
          <b>Morning Sessions</b>
          <ul>
            <li><b>From:</b> &nbsp; {freeDemoTimingsMorning?.timing?.from}</li>
            <li><b>To:</b> &nbsp; {freeDemoTimingsMorning?.timing?.to}</li>
            <li><b>Note:</b> &nbsp; {freeDemoTimingsMorning?.timing?.note}</li>
          </ul>
        </Col>
      </Col>
      <Col md={6} style={{ border: '1px solid #1976d2', padding: '10px' }}>
        <h4 style={{ color: '#1976d2' }}>Free demo session (Evening)</h4>
        <Col md={12} className="mt-3">
          <b>Evening Sessions</b>
          <ul>
            <li><b>From:</b>&nbsp; {freeDemoTimingsEvening?.timing?.from}</li>
            <li><b>To:</b> &nbsp; {freeDemoTimingsEvening?.timing?.to}</li>
            <li><b>Note:</b> &nbsp; {freeDemoTimingsEvening?.timing?.note}</li>
          </ul>
        </Col>
      </Col>
    </Row>

    <Row style={{marginTop:"3%"}}>
      <Col md={4} style={{ border: '1px solid #1976d2', padding: '10px'}}>
        <h4 style={{ color: '#1976d2' }}>Reference 1:</h4>
        <Col md={12} className="mt-3">
          <ul>
            <li><b>Name:</b> &nbsp; {reference?.reference1?.name}</li>
            <li><b>Phone Number:</b> &nbsp; {reference?.reference1?.mobile}</li>
          </ul>
        </Col>
      </Col>
      <Col md={4} style={{ border: '1px solid #1976d2', padding: '10px' }}>
        <h4 style={{ color: '#1976d2' }}>Reference 2:</h4>
        <Col md={12} className="mt-3">
          <ul>
          <li><b>Name:</b> &nbsp; {reference?.reference2?.name}</li>
          <li><b>Phone Number:</b> &nbsp; {reference?.reference2?.mobile}</li>
          </ul>
        </Col>
      </Col>
      <Col md={4} style={{ border: '1px solid #1976d2', padding: '10px' }}>
        <h4 style={{ color: '#1976d2' }}>Reference 3:</h4>
        <Col md={12} className="mt-3">
          <ul>
          <li><b>Name:</b> &nbsp; {reference?.reference3?.name}</li>
          <li><b>Phone Number:</b> &nbsp; {reference?.reference3?.mobile}</li>
          </ul>
        </Col>
      </Col>
    </Row>
    <p style={{ color: "crimson", fontSize: "large", marginTop:"10px" }}>
          We focus towards your fitness goals and adjust the intensity and
          frequency of your workouts based on your individual needs and fitness
          level.
        </p>
        <h4 className="mt-2" style={{ color: "crimson" }}>
          Join us for a high-energy workout from the comfort of your own space!
        </h4>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "15px",
          }}
        >
          {/* Left side: CrossFit buttons */}
          <div>
            <Button
              variant="contained"
              color="success"
              type="submit"
              style={{ width: "200px", marginRight: "10px" }}
              onClick={() => handleOpen("CrossFit", true)}
            >
              Book for CrossFit free session
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: "200px" }}
              onClick={() => handleOpen("CrossFit", false)}
            >
              Book for CrossFit Session
            </Button>
          </div>

          {/* Right side: Zumba buttons */}
          <div>
            <Button
              variant="contained"
              color="success"
              type="submit"
              style={{ width: "200px", marginRight: "10px" }}
              onClick={() => handleOpen("Zumba", true)}
            >
              Book for Zumba free session
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: "200px" }}
              onClick={() => handleOpen("Zumba", false)}
            >
              Book for Zumba Session
            </Button>
          </div>
          <FreeSessionModal open={open} handleClose={handleClose} sessionType={sessionType} userId={userId} trainerData={trainerData[0]} IsFreeSession={isFreeSession}/>
          {/* <BookGymModel open={open} handleClose={handleClose} sessionType={sessionType} userId={userId} /> */}
        </div>
      </div>
    </>
  );
};

export default ZumbaTrainer;
