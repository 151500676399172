import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import { toast } from "react-toastify";
import InfoModel from "../../shared/simpleModel/InfoModel";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "reactstrap";
import Spinner from "../../shared/Spinner";

const DieticianDietIntervention = ({ order }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedQuestionnareFile, setselectedQuestionnareFile] =
    useState(null);
  const [communicationLoading, setCommunicationLoading] = useState(false);
  const [dietIntervention, setDietIntervention] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isQuestionnareSubmitted, setIsQuestionnareSubmitted] = useState(false);
  const [isQuestionnareLoading, setIsQuestionnareLoading] = useState(false);
  const [communication, setCommunication] = useState();
  const [answers, setAnswers] = useState([]);
  const [answererrors, setAnswerErrors] = useState({});
  const [answerSubmitted, setAnswerSubmitted] = useState(false);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);
  const [displayFileType, setDisplayFileType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProposalViewModalOpen, setIsProposalViewModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    planPrice: null,
    planName: "",
    planDuration: "",
    howManyWeeks: "",
    howManyMonths: "",
    monitoringFrequency: "",
    callsPerWeek: "",
    callsPerMonth: "",
    briefPlan: "",
  });
  const [errors, setErrors] = useState({});

  const handlePackageInputChange = (e) => {
    const { name, value } = e.target;
    const updatedValue = name === "planPrice" ? parseFloat(value) || "" : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: updatedValue,
    }));
    // setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validatePackageForm = () => {
    const newErrors = {};
    if (!formData.planPrice) newErrors.planPrice = "Price is required";
    if (!formData.planDuration) newErrors.planDuration = "Duration is required";
    if (!formData.monitoringFrequency)
      newErrors.monitoringFrequency = "Monitoring frequency is required";
    if (formData.planDuration === "Weekly" && !formData.howManyWeeks) {
      newErrors.howManyWeeks = "Please specify how many weeks.";
    }

    if (formData.planDuration === "Monthly" && !formData.howManyMonths) {
      newErrors.howManyMonths = "Please specify how many months.";
    }
    if (
      formData.monitoringFrequency === "Weekly Follow Up" &&
      !formData.callsPerWeek
    ) {
      newErrors.callsPerWeek = "Please specify follow-up calls per week.";
    }

    if (
      formData.monitoringFrequency === "Monthly Follow Up" &&
      !formData.callsPerMonth
    ) {
      newErrors.callsPerMonth = "Please specify follow-up calls per month.";
    }
    if (!formData.briefPlan) newErrors.briefPlan = "Brief plan is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const handleProposalViewCloseModal = () => {
    setIsProposalViewModalOpen(false);
  };

  const resetForm = () => {
    setFormData({
      planPrice: null,
      planName: "",
      planDuration: "",
      howManyWeeks: "",
      howManyMonths: "",
      monitoringFrequency: "",
      callsPerWeek: "",
      callsPerMonth: "",
      briefPlan: "",
    });
    setErrors({});
  };

  const handlePackageSubmit = () => {
    if (validatePackageForm()) {
      setIsModalOpen(false);
      setErrors({});
    }
  };

  const handleInputChange = (e) => {
    setDietIntervention(e.target.value);
    if (e.target.value) setError(""); // Clear error when user types
  };

  const closeReportModal = () => {
    setModalOpen(false);
  };

  const validateAnswers = () => {
    let isValid = true;
    let newErrors = {};

    communication.communicationView.forEach((item) => {
      const answer = answers.find((ans) => ans.id === item.id);
      if (!answer || answer.answer.trim() === "") {
        newErrors[item.id] = "This field is required";
        isValid = false;
      }
    });

    setAnswerErrors(newErrors);
    return isValid;
  };

  const handleChange = (id, value) => {
    setAnswerErrors((prevErrors) => ({
      ...prevErrors,
      [id]: value.trim() === "" ? "This field is required" : null, // Reset the error if the field is empty, or remove it
    }));
    setAnswers((prevAnswers) => {
      // Check if the answer with the same id already exists
      const existingAnswerIndex = prevAnswers.findIndex((ans) => ans.id === id);

      if (existingAnswerIndex !== -1) {
        // Update the existing answer
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingAnswerIndex].answer = value;
        return updatedAnswers;
      } else {
        // Add a new answer object
        return [...prevAnswers, { id, answer: value }];
      }
    });
  };

  const handleAnswerSubmit = () => {
    if (validateAnswers()) {
      // if (validateForm()) {
      setIsLoading(true);
      console.log("answers", answers); // This will print the array of answers with their ids
      const userDetails = sessionStorage.getItem("userDetails");
      const parsedData = JSON.parse(userDetails);
      const userType = parsedData.userType;
      AxiosInstance.post(
        `dietitian/sendMassage/${order.orderId}/${userType}`,
        answers
      )
        .then((response) => {
          setIsLoading(false);
          setIsSubmitted(true);
          setAnswerSubmitted(true);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err: ", err);
        });
      // }

      // Call your API or save the data here
    }
  };

  const handleSubmit = async () => {
    // Validate input
    // if (selectedFile || dietIntervention != "") {
    if (selectedFile) {
      if (Object.values(formData).some((value) => value)) {
        const DietProtocolformData = new FormData();
        DietProtocolformData.append("file", selectedFile);
        setIsLoading(true);
        AxiosInstance.post(
          `dietitian/upload-diet-file?orderId=${order.orderId}`,
          DietProtocolformData
        )
          .then((response) => {
            const submittingData = {
              ...formData,
              orderId: order.orderId,
            };
            console.log("Form Submitted:", submittingData);
            AxiosInstance.post("dietitian/create-package-plan", submittingData)
              .then((response) => {
                setIsSubmitted(true);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("err: ", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err: ", err);
          });
      } else {
        const DietProtocolformData = new FormData();
        DietProtocolformData.append("file", selectedFile);
        setIsLoading(true);
        AxiosInstance.post(
          `dietitian/upload-diet-file?orderId=${order.orderId}`,
          DietProtocolformData
        )
          .then((response) => {
            setIsSubmitted(true);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err: ", err);
          });
      }
    } else {
      if (!dietIntervention.trim()) {
        setError("This field is required.");
        return;
      }

      if (Object.values(formData).some((value) => value)) {
        setIsLoading(true);
        const sendConsultData = {
          orderId: order.orderId,
          review: dietIntervention,
        };
        AxiosInstance.post("dietitian/sendReviewForDiet", sendConsultData)
          .then((response) => {
            const submittingData = {
              ...formData,
              orderId: order.orderId,
            };
            console.log("Form Submitted:", submittingData);
            AxiosInstance.post("dietitian/create-package-plan", submittingData)
              .then((response) => {
                setIsSubmitted(true);
                setIsLoading(false);
              })
              .catch((err) => {
                setIsLoading(false);
                console.log("err: ", err);
              });
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err: ", err);
          });
      } else {
        setIsLoading(true);
        const sendConsultData = {
          orderId: order.orderId,
          review: dietIntervention,
        };
        AxiosInstance.post("dietitian/sendReviewForDiet", sendConsultData)
          .then((response) => {
            setIsSubmitted(true);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("err: ", err);
          });
      }
    }
    // }
  };

  const handleOuestionnareFileChange = (event) => {
    setselectedQuestionnareFile(event.target.files[0]);
    const file = event.target.files[0];
    const formData = new FormData();
    const userType = "DIETITIAN";
    formData.append("file", file);
    setIsQuestionnareLoading(true);
    AxiosInstance.post(
      `dietitian/attach-questionnaire-file?orderId=${order.orderId}&userType=${userType}`,
      formData
    )
      .then((response) => {
        setIsQuestionnareLoading(false);
        setIsQuestionnareSubmitted(true);
      })
      .catch((err) => {
        setIsQuestionnareLoading(false);
        console.log("err: ", err);
      });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    // const file = event.target.files[0];
  };

  useEffect(() => {
    if (order && Object.keys(order).length > 0) {
      setCommunicationLoading(true);
      AxiosInstance.get(`dietitian/getCommunication/${order.orderId}`)
        .then((response) => {
          if (response != null) {
            setCommunication(response);
            if (response.packagePlanDetails) {
              setFormData(response.packagePlanDetails);
            }
            // setDietIntervention(response.doctorReview.review);
            const prepopulatedAnswers = response.communicationView.map(
              (item) => ({
                id: item.id,
                answer: item.answer || "", // If there's no answer, set it as an empty string
              })
            );
            setAnswers(prepopulatedAnswers);
          } else {
            setCommunication(null);
          }
          setCommunicationLoading(false);
        })
        .catch((err) => {
          console.log("err: ", err);
          setCommunicationLoading(false);
        });
    }
  }, []);
  const downloadQuestionnaireAnswers = () => {
    console.log(
      "url: ",
      communication?.dietitianQuestionnaire?.fileFromPatient
    );
    const url = communication?.dietitianQuestionnaire?.fileFromPatient;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/download-questionnaire-file";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  const downloadDietPlan = () => {
    console.log("url: ", communication?.dietReviewDto?.dietFile);
    const url = communication?.dietReviewDto?.dietFile;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/downloadDietFile";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };
  console.log("order idie", order, communication);
  return (
    <>
      {communicationLoading ? (
        <Spinner></Spinner>
      ) : isQuestionnareSubmitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              You have successfully submitted your Questionnaire to the patient.
            </Typography>
          </div>
        </Box>
      ) : isSubmitted ? (
        answerSubmitted ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 2,
            }}
          >
            <div>
              <div>
                <MdCheckCircle size={50} color="green" />
              </div>
              <Typography variant="h6" sx={{ color: "green" }}>
                You have successfully submitted your response to the questions
                raised by the client for this paticular consultation.
              </Typography>
              {/* <div style={{ fontSize: "16px" }}>
                This completes the order placed by the patient.
              </div>
              <div style={{ fontSize: "16px" }}>Thanks for your Review!</div> */}
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 2,
            }}
          >
            <div>
              <div>
                <MdCheckCircle size={50} color="green" />
              </div>
              <Typography variant="h6" sx={{ color: "green" }}>
                You have successfully submitted your consultation proposal to
                the patient.
              </Typography>
              <div style={{ fontSize: "16px" }}>
                Please note that the Patient may send you queries, if they have
                any questions after going through your comments or need any
                further clarifications.
              </div>
            </div>
          </Box>
        )
      ) : (
        <div>
          {!communication?.dietReviewDto && (
            <Typography
              variant="h6"
              className="mb-3"
              sx={{ fontWeight: "bold" }}
            >
              Please Type in your nutrition protocol or you can attach protocol
              in a word doc. using the attach diet protocol button.
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              gap: "20px",
              marginBottom: "16px",
            }}
          >
            {communication?.dietitianQuestionnaire == null && (
              <Button variant="contained" component="label" color="secondary">
                {isQuestionnareLoading ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  ></CircularProgress>
                ) : (
                  "Attach Questionnare"
                )}
                <Input
                  type="file"
                  hidden
                  onChange={handleOuestionnareFileChange}
                />
              </Button>
            )}

            {communication?.dietitianQuestionnaire?.fileFromPatient && (
              <Button
                variant="contained"
                component="label"
                color="secondary"
                onClick={downloadQuestionnaireAnswers}
              >
                View Questionnaire Answers
              </Button>
            )}

            {!communication?.dietReviewDto && !dietIntervention && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button variant="contained" component="label" color="primary">
                  Attach Diet Protocol
                  <Input type="file" hidden onChange={handleFileChange} />
                </Button>
                {selectedFile && (
                  <Typography
                    variant="body2"
                    style={{ marginTop: "10px", color: "gray" }}
                  >
                    {selectedFile.name}
                  </Typography>
                )}
              </div>
            )}

            {communication?.dietReviewDto?.dietFile && (
              <Button
                variant="contained"
                component="label"
                color="primary"
                onClick={downloadDietPlan}
              >
                View Diet Protocol
              </Button>
            )}
            {!communication?.packagePlanDetails && (
              <Button
                variant="contained"
                style={{ backgroundColor: "#FF9800", color: "#fff" }}
                onClick={() => setIsModalOpen(true)}
              >
                {Object.values(formData).some((value) => value)
                  ? "Edit Package Plan"
                  : "Attach Package Plan"}
              </Button>
            )}
            {communication?.packagePlanDetails && (
              <Button
                variant="contained"
                style={{ backgroundColor: "#FF9800", color: "#fff" }}
                onClick={() => setIsProposalViewModalOpen(true)}
              >
                View Proposal Plan
              </Button>
            )}
          </div>
          {!communication?.dietReviewDto &&
            !selectedFile &&
            !dietIntervention && (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <Divider style={{ flexGrow: 1 }} />
                <Typography variant="h6" style={{ padding: "0 10px" }}>
                  Or
                </Typography>
                <Divider style={{ flexGrow: 1 }} />
              </Box>
            )}
          {!communication?.dietReviewDto && !selectedFile && (
            <TextField
              label="Type Your Diet Protocol"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              style={{ marginBottom: "20px" }}
              error={!!error}
              helperText={error}
              value={dietIntervention}
              onChange={handleInputChange}
            />
          )}
          {communication?.dietReviewDto?.review && (
            <div>
              <Typography component="div" variant="h6" sx={{ marginBottom: 1 }}>
                Diet Protocol Suggested by You
              </Typography>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                style={{ marginBottom: "20px" }}
                value={communication?.dietReviewDto?.review}
                disabled
                sx={{
                  "& .MuiOutlinedInput-root.Mui-disabled": {
                    backgroundColor: "#d3d3d37a", // Full background color for the input
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black", // Ensures the text is black when disabled
                  },
                }}
              />
            </div>
          )}
          {communication?.communicationView && (
            <div>
              {/* <Typography component="div" variant="h6" sx={{ marginBottom: 1 }}>
            Diet Plan Suggested by You
          </Typography> */}

              <Box
                sx={{
                  padding: "24px",
                  backgroundColor: "#1c1c1c", // Dark background
                  borderRadius: "16px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)",
                  border: "1px solid #333",
                  marginBottom: "20px",
                }}
              >
                {communication?.communicationView?.map((item, index) => (
                  <Box key={item.id} sx={{ marginBottom: "24px" }}>
                    {/* Question Heading */}
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: "#ffa726", // Warm orange for headings
                        fontWeight: "bold",
                      }}
                    >
                      {`Question ${index + 1} raised by patient`}
                    </Typography>
                    {/* Question Text */}
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontWeight: "bold",
                        color: "#ffffff", // White text for contrast
                        backgroundColor: "#424242", // Dark gray background for the question
                        padding: "12px",
                        borderRadius: "8px",
                        border: "1px solid #555",
                      }}
                    >
                      {item.question}
                    </Typography>

                    {/* Your Response Heading */}
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginTop: "8px",
                        color: "#80cbc4", // Soft teal for response heading
                      }}
                    >
                      Your Response
                    </Typography>

                    {/* Answer TextField */}
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      placeholder="Type your response here..."
                      value={
                        answers.find((ans) => ans.id === item.id)?.answer || ""
                      }
                      onChange={(e) => handleChange(item.id, e.target.value)}
                      error={!!answererrors[item.id]}
                      helperText={
                        answererrors[item.id] ? answererrors[item.id] : ""
                      }
                      InputProps={{
                        style: {
                          color: "#ffffff", // White color for the text when typing
                          fontWeight: "bold", // Bold font weight
                        },
                      }}
                      sx={{
                        backgroundColor: "#333", // Darker background for the answer input
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#666", // Matching dark border
                          },
                          "&:hover fieldset": {
                            borderColor: "#ffa726", // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#ffa726", // Border color on focus
                          },
                        },
                      }}
                    />
                    <Divider sx={{ marginTop: "16px", borderColor: "#444" }} />
                  </Box>
                ))}

                {/* Submit Button */}
              </Box>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handleAnswerSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          )}
          {!communication?.packagePlanDetails &&
            (selectedFile ||
              dietIntervention != "" ||
              Object.values(formData).some((value) => value)) && (
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading}
                className="mt-2"
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            )}
          <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={handleCloseModal}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                Package Plan Details
              </Typography>

              <Row>
                <Col lg={4}>
                  <TextField
                    label="Plan Price"
                    name="planPrice"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.planPrice}
                    onChange={handlePackageInputChange}
                    error={!!errors.planPrice}
                    helperText={errors.planPrice}
                    type="number"
                  />
                </Col>
                <Col lg={4}>
                  <TextField
                    label="Plan Name (Optional)"
                    name="planName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.planName}
                    onChange={handlePackageInputChange}
                  />
                </Col>
                <Col lg={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="plan-duration-label">
                      Plan Duration
                    </InputLabel>
                    <Select
                      labelId="plan-duration-label"
                      id="plan-duration-select"
                      name="planDuration"
                      value={formData.planDuration}
                      onChange={handlePackageInputChange}
                    >
                      <MenuItem value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                    {errors.planDuration && (
                      <FormHelperText error>
                        {errors.planDuration}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Col>
              </Row>
              <Row>
                {formData.planDuration === "Weekly" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Weeks"
                      name="howManyWeeks"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.howManyWeeks}
                      onChange={handlePackageInputChange}
                      error={!!errors.howManyWeeks}
                      helperText={errors.howManyWeeks}
                      type="number"
                    ></TextField>
                  </Col>
                )}

                {formData.planDuration === "Monthly" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Months"
                      name="howManyMonths"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.howManyMonths}
                      onChange={handlePackageInputChange}
                      error={!!errors.howManyMonths}
                      helperText={errors.howManyMonths}
                      type="number"
                    ></TextField>
                  </Col>
                )}
                <Col lg={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="monitoring-frequency-label">
                      Monitoring Frequency
                    </InputLabel>
                    <Select
                      labelId="monitoring-frequency-label"
                      id="monitoring-frequency-select"
                      name="monitoringFrequency"
                      value={formData.monitoringFrequency}
                      onChange={handlePackageInputChange}
                    >
                      <MenuItem value="Weekly Follow Up">
                        Weekly Follow Up
                      </MenuItem>
                      <MenuItem value="Monthly Follow Up">
                        Monthly Follow Up
                      </MenuItem>
                    </Select>
                    {errors.monitoringFrequency && (
                      <FormHelperText error>
                        {errors.monitoringFrequency}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Col>

                {formData.monitoringFrequency === "Weekly Follow Up" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Follow Up Calls Per Week?"
                      name="callsPerWeek"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.callsPerWeek}
                      onChange={handlePackageInputChange}
                      error={!!errors.callsPerWeek}
                      helperText={errors.callsPerWeek}
                      select
                    >
                      {["1", "2", "3"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                )}

                {formData.monitoringFrequency === "Monthly Follow Up" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Follow Up Calls Per Month?"
                      name="callsPerMonth"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.callsPerMonth}
                      onChange={handlePackageInputChange}
                      error={!!errors.callsPerMonth}
                      helperText={errors.callsPerMonth}
                      select
                    >
                      {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Col>
                )}
              </Row>

              <TextField
                label="Brief Plan"
                name="briefPlan"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                margin="normal"
                value={formData.briefPlan}
                onChange={handlePackageInputChange}
                error={!!errors.briefPlan}
                helperText={errors.briefPlan}
              />
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={handlePackageSubmit}
                sx={{ mt: 2 }}
              >
                Add
              </Button>
            </Box>
          </Modal>
          <Modal
            open={isProposalViewModalOpen}
            onClose={handleProposalViewCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "relative",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: "8px",
              }}
            >
              <IconButton
                onClick={handleProposalViewCloseModal}
                sx={{ position: "absolute", top: 8, right: 8 }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
                gutterBottom
              >
                Package Plan Details
              </Typography>

              <Row>
                <Col lg={4}>
                  <TextField
                    label="Plan Price"
                    name="planPrice"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.planPrice}
                    onChange={handlePackageInputChange}
                    error={!!errors.planPrice}
                    helperText={errors.planPrice}
                    type="number"
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f9f9f9",
                        WebkitTextFillColor: "black",
                        fontWeight: 500,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#555",
                      },
                    }}
                  />
                </Col>
                <Col lg={4}>
                  <TextField
                    label="Plan Name (Optional)"
                    name="planName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.planName}
                    onChange={handlePackageInputChange}
                    disabled
                    InputProps={{
                      style: {
                        backgroundColor: "#f9f9f9",
                        WebkitTextFillColor: "black",
                        fontWeight: 500,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#555",
                      },
                    }}
                  />
                </Col>
                <Col lg={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      id="plan-duration-label"
                      style={{ color: "#555" }}
                    >
                      Plan Duration
                    </InputLabel>
                    <Select
                      labelId="plan-duration-label"
                      id="plan-duration-select"
                      name="planDuration"
                      value={formData.planDuration}
                      onChange={handlePackageInputChange}
                      disabled
                      sx={{
                        backgroundColor: "#f9f9f9",
                        color: "#333",
                      }}
                    >
                      <MenuItem value="Weekly">Weekly</MenuItem>
                      <MenuItem value="Monthly">Monthly</MenuItem>
                    </Select>
                    {errors.planDuration && (
                      <FormHelperText error>
                        {errors.planDuration}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Col>
              </Row>
              <Row>
                {formData.planDuration === "Weekly" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Weeks"
                      name="howManyWeeks"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.howManyWeeks}
                      onChange={handlePackageInputChange}
                      error={!!errors.howManyWeeks}
                      helperText={errors.howManyWeeks}
                      type="number"
                      disabled
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                          WebkitTextFillColor: "black",
                          fontWeight: 500,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#555",
                        },
                      }}
                    ></TextField>
                  </Col>
                )}

                {formData.planDuration === "Monthly" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Months"
                      name="howManyMonths"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.howManyMonths}
                      onChange={handlePackageInputChange}
                      error={!!errors.howManyMonths}
                      helperText={errors.howManyMonths}
                      type="number"
                      disabled
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                          WebkitTextFillColor: "black",
                          fontWeight: 500,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#555",
                        },
                      }}
                    ></TextField>
                  </Col>
                )}
                <Col lg={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel
                      id="monitoring-frequency-label"
                      style={{ color: "#555" }}
                    >
                      Monitoring Frequency
                    </InputLabel>
                    <Select
                      labelId="monitoring-frequency-label"
                      id="monitoring-frequency-select"
                      name="monitoringFrequency"
                      value={formData.monitoringFrequency}
                      onChange={handlePackageInputChange}
                      disabled
                      sx={{
                        backgroundColor: "#f9f9f9",
                        color: "#333",
                      }}
                    >
                      <MenuItem value="Weekly Follow Up">
                        Weekly Follow Up
                      </MenuItem>
                      <MenuItem value="Monthly Follow Up">
                        Monthly Follow Up
                      </MenuItem>
                    </Select>
                    {errors.monitoringFrequency && (
                      <FormHelperText error>
                        {errors.monitoringFrequency}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Col>

                {formData.monitoringFrequency === "Weekly Follow Up" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Follow Up Calls Per Week?"
                      name="callsPerWeek"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.callsPerWeek}
                      onChange={handlePackageInputChange}
                      error={!!errors.callsPerWeek}
                      helperText={errors.callsPerWeek}
                      select
                      disabled
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                          WebkitTextFillColor: "black",
                          fontWeight: 500,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#555",
                        },
                      }}
                    >
                      {["1", "2", "3"].map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Col>
                )}

                {formData.monitoringFrequency === "Monthly Follow Up" && (
                  <Col lg={4}>
                    <TextField
                      label="How Many Follow Up Calls Per Month?"
                      name="callsPerMonth"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={formData.callsPerMonth}
                      onChange={handlePackageInputChange}
                      error={!!errors.callsPerMonth}
                      helperText={errors.callsPerMonth}
                      select
                      disabled
                      InputProps={{
                        style: {
                          backgroundColor: "#f9f9f9",
                          WebkitTextFillColor: "black",
                          fontWeight: 500,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#555",
                        },
                      }}
                    >
                      {["1", "2", "3", "4", "5", "6", "7", "8"].map(
                        (option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      )}
                    </TextField>
                  </Col>
                )}
              </Row>

              <TextField
                label="Brief Plan"
                name="briefPlan"
                variant="outlined"
                fullWidth
                multiline
                rows={6}
                margin="normal"
                value={formData.briefPlan}
                onChange={handlePackageInputChange}
                error={!!errors.briefPlan}
                helperText={errors.briefPlan}
                disabled
                InputProps={{
                  style: {
                    backgroundColor: "#f9f9f9",
                    WebkitTextFillColor: "black",
                    fontWeight: 500,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "#555",
                  },
                }}
              />
            </Box>
          </Modal>
        </div>
      )}

      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="File Popup"
        haveCloseIcon={true}
      >
        {reportUrl && displayFileType === "iframe" && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
        {reportUrl && displayFileType === "download" && (
          <div>
            <p>File cannot be previewed. Click below to download:</p>
            <a href={reportUrl} download>
              Download File
            </a>
          </div>
        )}
      </InfoModel>
    </>
  );
};

export default DieticianDietIntervention;
