import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import "./LifeStyleRiskAssessment.css";
import RiskScore from "./components/RiskScore";
import RisksDiseaseMap from "./components/RisksDiseaseMap";
import SilverScreensProfileCards from "../profileRegistration/SilverScreensProfileCards";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useMediaQuery } from "@mui/material";
import Spinner from "../../shared/Spinner";

const LifeStyleRiskAssessment = () => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [familyInfo, setFamilyInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };
  useEffect(() => {
    setIsLoading(true);
    AxiosInstance.get("profile/get")
      .then((response) => {
        setFamilyInfo(response?.profile);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }, []);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <>
      {/* <h4 className="risk-management-heading">Lifestyle Risk Factors Assessment</h4> */}

      {isLoading ? (
        <Spinner />
      ) : (
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={currentTabIndex}
            onChange={handleTabChange}
            sx={{
              backgroundColor: "steelblue",
              display: { xs: "flex", sm: "flex", md: "block" }, // Flex only on small screens
              flexWrap: { xs: "wrap", sm: "wrap", md: "nowrap" }, // Wrap only on small screens
              "& .MuiTabs-flexContainer": {
                justifyContent: {
                  xs: "space-around",
                  sm: "space-around",
                  md: "flex-start",
                }, // Space-around only for small screens
              },
            }}
            variant="scrollable" // Makes tabs scrollable on smaller screens
            scrollButtons="auto"
            // className="MobileViewTabs"
          >
            {!isSmallScreen && (
              <Tab
                label="Health Sketch"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  color: currentTabIndex === 0 ? "white" : "black",
                  padding: { xs: "8px", sm: "12px" },
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
            )}

            {(familyInfo?.age >= 3 || familyInfo == undefined) && (
              <Tab
                label="Risk Score"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: { xs: "14px", sm: "16px", md: "18px" },
                  color: currentTabIndex === 1 ? "white" : "black",
                  padding: { xs: "8px", sm: "12px" },
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
            )}

            {!isSmallScreen &&
              (familyInfo?.age >= 3 || familyInfo == undefined) && (
                <Tab
                  label="Medical Condition Risk"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 600,
                    fontSize: { xs: "14px", sm: "16px", md: "18px" },
                    color: currentTabIndex === 2 ? "white" : "black",
                    padding: { xs: "8px", sm: "12px" },
                    "&.Mui-selected": {
                      color: "white",
                    },
                  }}
                />
              )}
          </Tabs>
          {!isSmallScreen && currentTabIndex === 0 && (
            <Box sx={{ p: 1 }}>
              <SilverScreensProfileCards
                RiskScoreNext={() => {
                  setCurrentTabIndex((prev) => prev + 1);
                }}
              />
            </Box>
          )}
          {isSmallScreen
            ? currentTabIndex === 0 && (
                <Box sx={{ p: 3 }}>
                  <RiskScore
                    familyAge={familyInfo && familyInfo?.age}
                    RiskScoreNext={() => {
                      setCurrentTabIndex((prev) => prev + 1);
                    }}
                  />
                </Box>
              )
            : currentTabIndex === 1 && (
                <Box sx={{ p: 3 }}>
                  <RiskScore
                    familyAge={familyInfo?.age}
                    RiskScoreNext={() => {
                      setCurrentTabIndex((prev) => prev + 1);
                    }}
                  />
                </Box>
              )}

          {!isSmallScreen && currentTabIndex === 2 && (
            <Box sx={{ p: 3 }}>
              <RisksDiseaseMap
                RiskDiseaseMapNext={() => {
                  setCurrentTabIndex((prev) => prev + 1);
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default LifeStyleRiskAssessment;
