import React, { useEffect, useState } from "react";
import { Col, Row, Card, CardText, FormGroup, Label, Input } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  getLabOrderDetailByIdApi,
  getLabOrderDetailApi,
} from "../../constants/apiList";
import "./style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";

const CorporateOrderDetailModel = ({ order }) => {
  const [downloading, setDownloading] = useState(false);
  const handleInvoiceDownload = async (docLink) => {
    setDownloading(true);
    const downloadApi = "corporate-order/downloadReport/";
    let token = window.sessionStorage.getItem("token");
    let myHeaders = new Headers();
    myHeaders.append("accept", "multipart/mixed");
    myHeaders.append("Authorization", "Bearer " + token);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const encodedPath = encodeURIComponent(docLink);
      const responseDoc = await fetch(
        `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedPath}`,
        requestOptions
      );

      if (!responseDoc.ok) {
        throw new Error(`Network response was not ok: ${responseDoc.status}`);
      }

      const contentDispositionHeader = responseDoc.headers.get(
        "Content-Disposition"
      );

      if (contentDispositionHeader) {
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
          contentDispositionHeader
        );
        if (matches != null && matches[1]) {
          const filename = matches[1].replace(/['"]/g, "");
          responseDoc.blob().then((blobData) => {
            const blobUrl = window.URL.createObjectURL(blobData);

            const a = document.createElement("a");
            a.href = blobUrl;
            a.download = filename;
            a.click();

            window.URL.revokeObjectURL(blobUrl);
            setDownloading(false);
          });
        } else {
          console.error("Filename not found in Content-Disposition header");
          setDownloading(false);
        }
      } else {
        console.error("Content-Disposition header not found in the response");
        setDownloading(false);
      }
    } catch (error) {
      console.error("Error fetching or downloading the file:", error);
      setDownloading(false);
    }
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  const formatDateToDDMMYYYY = (date) => {
    const dates = new Date(date);
    const day = String(dates.getDate()).padStart(2, "0");
    const month = String(dates.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = dates.getFullYear();
    return `${day}-${month}-${year}`;
  };
  console.log("specific order", order);
  return (
    <>
      <div className="PurchaseOrderHeading">Purchase Order</div>
      <Row>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Phone</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value={7506753773}
              type="number"
            />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Email</b>
            </Label>
            <Input disabled value="anjan@livingscopehealth.com" type="email" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>State</b>
            </Label>
            <Input disabled value="Maharastra" type="text" />
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>GSTIN</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="27AAFCL3118G1ZP"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup className="purchaseOrderInformationCard">
            <Label for="exampleEmail">
              <b>Address</b>
            </Label>
            <Input
              disabled
              id=""
              name="name"
              value="HD 090, Chromium, Milind Nagar, Powai-400076"
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #000000" }}></hr>
      <Card className="LabOrderDetailCard">
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="todayDate">
                <b>Date:</b>
              </Label>
              <div>
                {order.date
                  ? formatDateToDDMMYYYY(order.date)
                  : "Not Available"}
              </div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientType">
                <b>Type of Client:</b>
              </Label>
              <div>{order.typeOfClient}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientName">
                <b>Client Name:</b>
              </Label>
              <div>{order.clientName}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientEmail">
                <b>Client Email:</b>
              </Label>
              <div>{order.clientEmail}</div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientContactNo">
                <b>Client Contact No:</b>
              </Label>
              <div>{order.clientContact}</div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="testCollectionDate">
                <b>Test Collection Date:</b>
              </Label>
              <div>
                {order.testCollectionDate
                  ? formatDateToDDMMYYYY(order.testCollectionDate)
                  : "Not Available"}
              </div>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="testCollectionTime">
                <b>Test Collection Time:</b>
              </Label>
              <div>
                {order?.testCollectionTime
                  ? formatTime(order.testCollectionTime)
                  : "Not Available"}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="typeOfTest">
                <b>Type Of Test:</b>
              </Label>
              <div>{order.typeOfTest}</div>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="medicalCondition">
                <b>Medical Condition:</b>
              </Label>
              <div>
                {order.medicalCondition
                  ? order.medicalCondition
                  : "Not Available"}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup className="purchaseOrderInformationCard">
              <Label for="clientAddress">
                <b>Client Address (Test collection site):</b>
              </Label>
              <div>
                {order.clientAddress ? order.clientAddress : "Not Available"}
              </div>
            </FormGroup>
          </Col>
        </Row>
      </Card>

      <TableContainer
        component={Paper}
        sx={{ border: "1px solid black", borderRadius: "5px" }}
        elevation={5}
        className="mt-3 mb-3"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Test Name
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                HSN/SAC
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Quantity
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Price Unit
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Taxable Amount
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                GST (18%)
              </TableCell>
              <TableCell
                sx={{
                  borderRight: "1px solid black",
                  backgroundColor: "#c60176",
                  color: "white",
                }}
              >
                Total Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ borderRight: "1px solid black" }}>
                {order.testNames}
              </TableCell>
              <>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.hsnSac}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.quantity}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.priceUnit}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.taxableAmount}
                </TableCell>
                <TableCell
                  //   rowSpan={testNames.length}
                  sx={{ borderRight: "1px solid black" }}
                >
                  {order.gst}
                </TableCell>
                <TableCell
                // rowSpan={testNames.length}
                >
                  {order.totalAmount}
                </TableCell>
              </>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Row>
        <b style={{ textDecoration: "underline" }}>Notes to Lab:</b>
        <ul className="Labnotes">
          <li>
            Please call our client promptly at the contact number provided above
            to coordinate the tests collection as per date and time mentioned
            above
          </li>
          <li>
            After tests are done and reports are available, please upload them
            on Uncurl: health app platform.
          </li>
          <li>
            If there are any questions regarding this order or the PO amount
            shown above does not match as per your quote, then please contact
            Living Scope Health at the number provided and resolve.
            <b>
              You are not supposed to discuss anything with our client regarding
              this purchase order or the amount stated in it
            </b>
          </li>
          <li>
            Invoice also to be attached on Uncurl: health app platform. Payment
            will be done though our payment portal within seven days after
            submission of invoice and all test reports on our Uncurl: health
            platform.
          </li>
        </ul>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <b>
            Note: This is an electronically generated order and so does not
            require any signature for approval
          </b>
          {order.employeeFile && (
            <Button
              component="span"
              sx={{
                background:
                  "linear-gradient(45deg, royalblue, mediumvioletred)", // Define your gradient here
                color: "white",
                padding: "10px 20px",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  background:
                    "linear-gradient(45deg, mediumvioletred, royalblue)", // Optional hover effect
                },
              }}
              onClick={() => handleInvoiceDownload(order.employeeFile)}
            >
              {downloading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                " Download Employees Excel File"
              )}
            </Button>
          )}
        </div>
      </Row>
    </>
  );
};

export default CorporateOrderDetailModel;
