import React from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { ModalFooter } from "reactstrap";
import Swal from "sweetalert2";

const CorporateApproval = ({
  selectedUser,
  approval,
  onCloseOpenModel,
  getAllUnApprovedPartners,
}) => {
  const handleSubmit = async (status) => {
    const partnerType = approval;
    const userId = selectedUser.userId;
    const formData = {
      approvedDetail: null,
      userId: userId,
      approveFlag: status,
      userTypeId: partnerType,
    };
    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          if (status == true) {
            Swal.fire({
              icon: "success",
              title: "Approve!",
              text: "Profile Approved SuccessFully!",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Decline!",
              text: "Profile Decline SuccessFully!",
            });
          }
          onCloseOpenModel();
          getAllUnApprovedPartners();
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  console.log("user", selectedUser);

  const organizationName = selectedUser?.organizationName || "N/A";
  const contactName = `${selectedUser?.contactName || ""}`.trim() || "N/A";
  const corporatePhoneNumber = selectedUser?.corporatePhoneNumber || "N/A";
  const contactMobile = selectedUser?.contactMobile || "N/A";
  const email = selectedUser?.email || "N/A";
  return (
    <Box sx={{ p: 3 }}>
      <Card sx={{ mx: "auto", boxShadow: 3 }}>
        <CardContent>
          <Typography
            variant="h5"
            gutterBottom
            textAlign="center"
            fontWeight="bold"
          >
            Corporate Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Organization Name:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {organizationName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Contact Name:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {contactName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Corporate Phone Number:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {corporatePhoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Email:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {email}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Contact Mobile:
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {contactMobile}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <ModalFooter>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmit(true)}
            sx={{ mr: 2 }}
          >
            Approve
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleSubmit(false)}
          >
            Decline
          </Button>
        </ModalFooter>
      </Card>
    </Box>
  );
};

export default CorporateApproval;
