import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useNavigate, Link } from "react-router-dom";
const YogaTrainer = ({ setCurrentTabIndex }) => {
  useEffect(() => {}, []);

  return (
    <>
      YOGA
    </>
  );
};

export default YogaTrainer;
