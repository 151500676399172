import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { MdCheckCircle } from "react-icons/md";
import InfoModel from "../../shared/simpleModel/InfoModel";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import Spinner from "../../shared/Spinner";

const DietIntervention = ({ selectedOrder, onSubmit, onScheduleCall }) => {
  const [askFurtherQuestions, setAskFurtherQuestions] = useState("");
  const [communication, setCommunication] = useState();
  const [communicationLoading, setCommunicationLoading] = useState(false);
  const [questions, setQuestions] = useState([""]);
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [orderClosed, setOrderClosed] = useState(false);
  const [modalIsOpen, setModalOpen] = useState(false);
  const [reportUrl, setReportUrl] = useState(null);
  const [displayFileType, setDisplayFileType] = useState("");
  const [openPackageModal, setopenPackageModal] = useState(false);
  const [isQuestionnareLoading, setisQuestionnareLoading] = useState(false);
  const [isQuestionnareSubmitted, setIsQuestionnareSubmitted] = useState(false);
  const [isQuestionnaredownloading, setIsQuestionnaredownloading] =
    useState(false);
  const [hasQuestion, setHasQuestion] = useState("");
  const navigate = useNavigate();
  const handleRadioChange = (e) => {
    setAskFurtherQuestions(e.target.value);
    setQuestions([""]); // reset the question fields when option changes
    setErrors({});
  };

  const handleAddQuestion = () => {
    // if (questions.length < 3) {
    setQuestions([...questions, ""]);
    // }
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index] = value;
    setQuestions(updatedQuestions);
  };

  const validateForm = () => {
    let formErrors = {};
    if (!askFurtherQuestions) {
      formErrors.askFurtherQuestions = "Please select an option";
    }
    if (askFurtherQuestions === "Yes") {
      questions.forEach((question, index) => {
        if (!question) {
          formErrors[`question${index}`] = "Question is required";
        }
      });
    }
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return false;
    }
    setErrors({});
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (askFurtherQuestions === "No") {
      AxiosInstance.post(`dietitian/order-accept/${selectedOrder.orderId}`)
        .then((response) => {
          setIsSubmitted(true);
          onSubmit();
          setOrderClosed(true);
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      if (communication?.communicationView == null) {
        AxiosInstance.post(
          `riskAssessment/reviewAndUnderstood/${selectedOrder.lrId}`
        )
          .then((response) => {})
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      if (validateForm()) {
        const userDetails = sessionStorage.getItem("userDetails");
        const parsedData = JSON.parse(userDetails);
        const userType = parsedData.userType;
        const formattedQuestions = questions.map((question) => ({
          question: question,
        }));
        console.log("form questions", questions, formattedQuestions);
        AxiosInstance.post(
          `dietitian/sendMassage/${selectedOrder.orderId}/${userType}`,
          formattedQuestions
        )
          .then((response) => {
            setIsSubmitted(true);
            onSubmit();
          })
          .catch((err) => {
            console.log("err: ", err);
          });
        if (communication?.communicationView == null) {
          AxiosInstance.post(
            `riskAssessment/reviewAndUnderstood/${selectedOrder.lrId}`
          )
            .then((response) => {})
            .catch((error) => {
              console.log("error", error);
            });
        }
      }
    }
  };

  const downloadQuestionnaire = () => {
    console.log(
      "url: ",
      communication?.dietitianQuestionnaire?.fileFromDietitian
    );
    const url = communication?.dietitianQuestionnaire?.fileFromDietitian;
    if (url === null) {
      return;
    }
    setIsQuestionnaredownloading(true);
    const downloadApi = "dietitian/download-questionnaire-file";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        // setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              const link = document.createElement("a");
              link.href = blobUrl;
              link.download = filename;
              document.body.appendChild(link); // Append to the body
              link.click(); // Trigger download
              link.remove(); // Clean up

              // Revoke the blob URL after a short delay
              setTimeout(() => window.URL.revokeObjectURL(blobUrl), 100);
              // return () => window.URL.revokeObjectURL(blobUrl);
              setIsQuestionnaredownloading(false);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
            setIsQuestionnaredownloading(false);
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
          setIsQuestionnaredownloading(false);
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
        setIsQuestionnaredownloading(false);
      });
  };

  const downloadDietPlan = () => {
    console.log("url: ", communication?.dietReviewDto?.dietFile);
    const url = communication?.dietReviewDto?.dietFile;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/downloadDietFile";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const downloadQuestionnaireAnswers = () => {
    console.log(
      "url: ",
      communication?.dietitianQuestionnaire?.fileFromPatient
    );
    const url = communication?.dietitianQuestionnaire?.fileFromPatient;
    if (url === null) {
      return;
    }

    const downloadApi = "dietitian/download-questionnaire-file";
    const encodedUrl = encodeURIComponent(url);
    const authToken = window.sessionStorage.getItem("token");
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}${downloadApi}?path=${encodedUrl}`,
      requestOptions
    )
      .then((response) => {
        console.log("Response:", response);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        setModalOpen(true);
        const contentType = response.headers.get("Content-Type");
        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );
        if (contentDispositionHeader) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
            contentDispositionHeader
          );
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, "");
            response.blob().then((blobData) => {
              const blobUrl = window.URL.createObjectURL(blobData);
              setReportUrl(blobUrl); // Set the URL for the iframe
              // Note: No need to handle filename here, just display in iframe
              // Clean up object URL when component unmounts

              if (
                contentType === "application/pdf" ||
                contentType === "application/msword" ||
                contentType ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                // PDF/Word display in iframe
                setDisplayFileType("iframe");
              } else if (
                contentType ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                contentType === "application/vnd.ms-excel"
              ) {
                // Excel file handling
                setDisplayFileType("download");
              } else {
                // Handle other types as download
                setDisplayFileType("download");
              }
              return () => window.URL.revokeObjectURL(blobUrl);
            });
          } else {
            toast.error("Filename not found in Content-Disposition header");
          }
        } else {
          toast.error("Content-Disposition header not found in the response");
        }
      })
      .catch((error) => {
        toast.error(`Error fetching or downloading the file: ${error}`);
      });
  };

  const handleOuestionnareFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    const userType = "PATIENT";
    formData.append("file", file);
    setisQuestionnareLoading(true);
    AxiosInstance.post(
      `dietitian/attach-questionnaire-file?orderId=${selectedOrder.orderId}&userType=${userType}`,
      formData
    )
      .then((response) => {
        setisQuestionnareLoading(false);
        setIsQuestionnareSubmitted(true);
      })
      .catch((err) => {
        setisQuestionnareLoading(false);
        console.log("err: ", err);
      });
  };

  const viewPackagePlan = () => {
    setopenPackageModal(true);
  };

  const closeReportModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (selectedOrder) {
      setCommunicationLoading(true);
      AxiosInstance.get(`dietitian/getCommunication/${selectedOrder.orderId}`)
        .then((response) => {
          if (response != null) {
            setCommunication(response);
          } else {
            setCommunication(null);
          }
          setCommunicationLoading(false);
        })
        .catch((err) => {
          console.log("err: ", err);
          setCommunicationLoading(false);
        });
    }
  }, []);

  const handleScheduleCall = () => {
    const dietitianDetails = {
      dietitianName: selectedOrder.dietitianName,
      dietitianId: selectedOrder.dietitianId,
      orderId: selectedOrder.orderId,
    };
    onScheduleCall(dietitianDetails); // Pass the data to the parent
  };
  console.log("communication", communication, selectedOrder);
  return (
    <div>
      {communicationLoading ? (
        <Spinner></Spinner>
      ) : communication ? (
        <>
          {isQuestionnareSubmitted ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: 2,
              }}
            >
              <div>
                <div>
                  <MdCheckCircle size={50} color="green" />
                </div>
                <Typography variant="h6" sx={{ color: "green" }}>
                  You have successfully submitted your answers for the
                  Questionnaire to the Nutritionist.
                </Typography>
              </div>
            </Box>
          ) : isSubmitted ? (
            orderClosed ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    Order has been Successfully Closed.
                  </Typography>
                </div>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 2,
                }}
              >
                <div>
                  <div>
                    <MdCheckCircle size={50} color="green" />
                  </div>
                  <Typography variant="h6" sx={{ color: "green" }}>
                    You have successfully submitted your queries to the
                    Dietitian.
                  </Typography>
                  <div style={{ fontSize: "16px" }}>
                    You can expect a response from the Dietitian within 24-36
                    hours. Please check back later to see the response to your
                    queries.
                  </div>
                </div>
              </Box>
            )
          ) : (
            <>
              <Box>
                {communication.dietitianQuestionnaire &&
                  communication?.dietitianQuestionnaire?.fileFromPatient ==
                    null && (
                    <>
                      <Typography variant="h6" className="mb-2">
                        Please Download the questionnaire and fill it completely
                        and then attach it to send it to the dietitian
                      </Typography>
                      <div
                        style={{
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={downloadQuestionnaire}
                          sx={{
                            fontWeight: "bolder",
                            fontSize: "14px",
                            textTransform: "none", // To disable uppercase transformation in MUI buttons
                          }}
                        >
                          {isQuestionnaredownloading ? (
                            <CircularProgress
                              size={24}
                              color="inherit"
                            ></CircularProgress>
                          ) : (
                            "Download Questionnaire"
                          )}
                        </Button>

                        <Button
                          variant="contained"
                          component="label"
                          color="secondary"
                          sx={{
                            fontWeight: "bolder",
                            fontSize: "14px",
                            textTransform: "none", // To disable uppercase transformation in MUI buttons
                          }}
                        >
                          {isQuestionnareLoading ? (
                            <CircularProgress
                              size={24}
                              color="inherit"
                            ></CircularProgress>
                          ) : (
                            "Attach Filled Questionnaire"
                          )}
                          <Input
                            type="file"
                            hidden
                            onChange={handleOuestionnareFileChange}
                          />
                        </Button>
                      </div>
                    </>
                  )}
                {communication?.dietitianQuestionnaire?.fileFromPatient && (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      component="label"
                      color="secondary"
                      onClick={downloadQuestionnaireAnswers}
                      sx={{
                        fontWeight: "bolder",
                        fontSize: "14px",
                        textTransform: "none", // To disable uppercase transformation in MUI buttons
                      }}
                    >
                      View Questionnaire Answers
                    </Button>
                  </div>
                )}
                {communication.dietReviewDto && (
                  <Typography
                    component="div"
                    variant="h6"
                    sx={{ marginBottom: 1 }}
                  >
                    Nutritionist Diet Protocol
                  </Typography>
                )}
                {communication?.dietReviewDto?.review ? (
                  <>
                    <TextField
                      // value={communication?.doctorReview.review}
                      value={communication?.dietReviewDto.review}
                      multiline
                      rows={4}
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          backgroundColor: "#d3d3d37a", // Full background color for the input
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: "black", // Ensures the text is black when disabled
                        },
                      }}
                    />
                    {communication?.packagePlanDetails && (
                      <div>
                        <Button
                          variant="contained"
                          // color="secondary"
                          onClick={viewPackagePlan}
                          sx={{
                            fontWeight: "bolder",
                            fontSize: "14px",
                            backgroundColor: "rgb(255, 152, 0)",
                            textTransform: "none", // To disable uppercase transformation in MUI buttons
                          }}
                        >
                          View Proposal
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      {communication?.dietReviewDto?.dietFile && (
                        <div>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadDietPlan}
                            sx={{
                              fontWeight: "bolder",
                              fontSize: "14px",
                              textTransform: "none", // To disable uppercase transformation in MUI buttons
                            }}
                          >
                            View Diet Protocol
                          </Button>
                        </div>
                      )}
                      {communication?.packagePlanDetails && (
                        <div>
                          <Button
                            variant="contained"
                            // color="secondary"
                            onClick={viewPackagePlan}
                            sx={{
                              fontWeight: "bolder",
                              fontSize: "14px",
                              backgroundColor: "rgb(255, 152, 0)",
                              textTransform: "none", // To disable uppercase transformation in MUI buttons
                            }}
                          >
                            View Proposal
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </Box>
              {communication.communicationView?.length > 0 && (
                <Box
                  sx={{
                    padding: "24px",
                    backgroundColor: "#1c1c1c", // Dark background
                    borderRadius: "16px", // Smoother rounded corners
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.5)", // Slightly darker shadow
                    border: "1px solid #333", // Darker border for structure
                    marginTop: "20px",
                  }}
                >
                  <h6 style={{ textAlign: "center", color: "white" }}>
                    Queries raised by patient
                  </h6>
                  {communication.communicationView?.map((item, index) => (
                    <Box key={item.id} sx={{ marginBottom: "24px" }}>
                      {/* Question Heading */}
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          color: "#ffa726", // Warm orange for headings
                          fontWeight: "bold",
                        }}
                      >
                        {`Question ${index + 1}`}
                      </Typography>
                      {/* Question Text */}
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontWeight: "bold",
                          color: "#ffffff", // White text for contrast
                          backgroundColor: "#424242", // Dark gray background for the question
                          padding: "12px",
                          borderRadius: "8px",
                          border: "1px solid #555", // Soft border for better separation
                        }}
                      >
                        {item.question}
                      </Typography>
                      {/* Doctor Response Heading */}
                      <Typography
                        variant="h6"
                        sx={{
                          marginTop: "8px",
                          color: "#80cbc4", // Soft teal for response heading
                          fontWeight: "bold",
                        }}
                      >
                        Doctor Response
                      </Typography>
                      {/* Doctor's Answer */}
                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          color: "#e0f2f1", // Light teal for answer text
                          backgroundColor: "#333", // Darker background for the answer
                          padding: "12px",
                          borderRadius: "8px",
                          border: "1px solid #666", // Matching dark border
                        }}
                      >
                        {item.answer}
                      </Typography>
                      <Divider
                        sx={{ marginTop: "16px", borderColor: "#444" }}
                      />{" "}
                      {/* Subtle gray divider */}
                    </Box>
                  ))}
                </Box>
              )}
              {communication?.dietReviewDto &&
                communication?.packagePlanDetails == null && (
                  <form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        marginTop: 2,
                      }}
                    >
                      <FormControl
                        component="fieldset"
                        required
                        error={!!errors.additionalTestsRequired}
                      >
                        <FormLabel component="legend">
                          Do you want to ask any further questions to the
                          Nutritionist regarding the Diet Protocol and his or
                          her review comments ?
                        </FormLabel>
                        <RadioGroup
                          value={askFurtherQuestions}
                          onChange={handleRadioChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                        {errors.askFurtherQuestions && (
                          <Typography color="error" variant="caption">
                            {errors.askFurtherQuestions}
                          </Typography>
                        )}
                      </FormControl>

                      {askFurtherQuestions === "Yes" &&
                        questions.map((question, index) => (
                          <TextField
                            key={index}
                            value={question}
                            onChange={(e) =>
                              handleQuestionChange(index, e.target.value)
                            }
                            placeholder={`Question ${index + 1}`}
                            required
                            fullWidth
                            sx={{ marginBottom: 1, marginRight: 1 }}
                            error={!!errors[`question${index}`]}
                            helperText={errors[`question${index}`]}
                          />
                        ))}

                      {askFurtherQuestions === "Yes" && (
                        <Box>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddQuestion}
                          >
                            Add More Question
                          </Button>
                        </Box>
                      )}
                      <Button type="submit" variant="contained" color="success">
                        Submit
                      </Button>
                    </Box>
                  </form>
                )}
            </>
          )}
        </>
      ) : (
        "No Diet Plan"
      )}

      <InfoModel
        open={modalIsOpen}
        setOpen={closeReportModal}
        maxWidth={"md"}
        contentLabel="File Popup"
        haveCloseIcon={true}
      >
        {reportUrl && displayFileType === "iframe" && (
          <iframe
            src={reportUrl}
            title="Report Viewer"
            style={{ width: "100%", height: "600px", border: "none" }}
          />
        )}
        {reportUrl && displayFileType === "download" && (
          <div>
            <p>File cannot be previewed. Click below to download:</p>
            <a href={reportUrl} download>
              Download File
            </a>
          </div>
        )}
      </InfoModel>
      <Modal
        open={openPackageModal}
        onClose={() => setopenPackageModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <IconButton
            onClick={() => setopenPackageModal(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Package Plan Details
          </Typography>

          <Row>
            <Col lg={4}>
              <TextField
                label="Plan Price"
                name="planPrice"
                variant="outlined"
                fullWidth
                margin="normal"
                value={communication?.packagePlanDetails?.planPrice}
              />
            </Col>
            {communication?.packagePlanDetails?.planName != "" && (
              <Col lg={4}>
                <TextField
                  label="Plan Name"
                  name="planName"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={communication?.packagePlanDetails?.planName}
                />
              </Col>
            )}
            <Col lg={4}>
              <TextField
                label="Plan Duration"
                name="planDuration"
                variant="outlined"
                fullWidth
                margin="normal"
                value={communication?.packagePlanDetails?.planDuration}
              />
            </Col>
            {communication?.packagePlanDetails?.howManyMonths && (
              <Col lg={4}>
                <TextField
                  label="No. Of Months"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={communication?.packagePlanDetails?.howManyMonths}
                />
              </Col>
            )}
            {communication?.packagePlanDetails?.howManyWeeks && (
              <Col lg={4}>
                <TextField
                  label="No. Of Weeks"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={communication?.packagePlanDetails?.howManyWeeks}
                />
              </Col>
            )}
            <Col lg={4}>
              <TextField
                label="Monitoring Frequency"
                name="monitoringFrequency"
                variant="outlined"
                fullWidth
                margin="normal"
                value={communication?.packagePlanDetails?.monitoringFrequency}
              />
            </Col>
            {communication?.packagePlanDetails?.callsPerMonth != "" && (
              <Col lg={4}>
                <TextField
                  label="Calls Per Months"
                  name="callsPerMonth"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={communication?.packagePlanDetails?.callsPerMonth}
                />
              </Col>
            )}
            {communication?.packagePlanDetails?.callsPerWeek != "" && (
              <Col lg={4}>
                <TextField
                  label="Calls Per Week"
                  name="callsPerWeek"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={communication?.packagePlanDetails?.callsPerWeek}
                />
              </Col>
            )}
          </Row>

          <TextField
            label="Brief Plan"
            name="briefPlan"
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            margin="normal"
            value={communication?.packagePlanDetails?.briefPlan}
          />

          <Box
            sx={{
              mt: 1,
              p: 1,
              bgcolor: "#f0f4ff",
              borderRadius: "8px",
              border: "1px solid #d0d8f0",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "#333",
                fontWeight: 500,
              }}
            >
              Do you have questions regarding proposal?
            </Typography>
            <RadioGroup
              row
              value={hasQuestion}
              onChange={(e) => setHasQuestion(e.target.value)}
            >
              <FormControlLabel
                value="yes"
                control={<Radio sx={{ color: "#1976d2" }} />}
                label="Yes"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                }}
              />
              <FormControlLabel
                value="no"
                control={<Radio sx={{ color: "#d32f2f" }} />}
                label="No"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                }}
              />
            </RadioGroup>

            {hasQuestion === "yes" && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleScheduleCall}
                sx={{
                  mt: 2,
                  bgcolor: "#1976d2",
                  "&:hover": {
                    bgcolor: "#105bb5",
                  },
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: "8px",
                }}
              >
                Schedule Call with{" "}
                {selectedOrder.dietitianName
                  ? selectedOrder.dietitianName
                  : "Dietitian"}
              </Button>
            )}
          </Box>

          {/* <div style={{ fontSize: "16px" }}>
            if you are satisfied, please go ahead and book the proposal in
            <Typography
              component="span"
              onClick={() => navigate("/preventive-care")}
              sx={{
                color: "blue",
                // textDecoration: "underline",
                cursor: "pointer",
                transition: "color 0.3s ease, transform 0.2s ease",
                "&:hover": {
                  color: "darkblue",
                  transform: "scale(1.05)",
                },
                "&:active": {
                  color: "lightblue",
                },
              }}
            >
              Preventive Care
            </Typography>{" "}
          </div> */}
          {hasQuestion === "" ||
            (hasQuestion === "no" && (
              <Box
                sx={{
                  mt: 1,
                  p: 2,
                  bgcolor: "#e3f2fd",
                  borderRadius: "8px",
                  border: "1px solid #bbdefb",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#0d47a1",
                    mb: 1,
                  }}
                >
                  If you are satisfied, please go ahead and book the proposal
                  in:
                </Typography>
                <Typography
                  component="span"
                  onClick={() => navigate("/preventive-care")}
                  sx={{
                    display: "inline-block",
                    px: 3,
                    py: 1,
                    bgcolor: "#1e88e5",
                    color: "#ffffff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    cursor: "pointer",
                    textDecoration: "none",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      bgcolor: "#1565c0",
                      transform: "scale(1.05)",
                    },
                    "&:active": {
                      bgcolor: "#0d47a1",
                    },
                  }}
                >
                  Preventive Care
                </Typography>
              </Box>
            ))}
        </Box>
      </Modal>
    </div>
  );
};

export default DietIntervention;
