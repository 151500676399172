import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { ListChannelMembershipsRequestFilterSensitiveLog, ListChannelMembershipsResponseFilterSensitiveLog, } from "../models/models_0";
import { de_ListChannelMembershipsCommand, se_ListChannelMembershipsCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class ListChannelMembershipsCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "ListChannelMemberships", {})
    .n("ChimeSDKMessagingClient", "ListChannelMembershipsCommand")
    .f(ListChannelMembershipsRequestFilterSensitiveLog, ListChannelMembershipsResponseFilterSensitiveLog)
    .ser(se_ListChannelMembershipsCommand)
    .de(de_ListChannelMembershipsCommand)
    .build() {
}
