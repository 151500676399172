import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useState, useEffect } from "react";
import DieticianDiet from "./DieticianDiet";
import DieticianDietIntervention from "./DieticianDietIntervention";
import { useLocation } from "react-router-dom";
import DieticianPhysicalExercise from "./DieticianPhysicalExercise";
import DietitianEnvironment from "./DietitianEnvironment.";
import DietitianLifestyle from "./DietitianLifestyle";
import DietitianDiagnosis from "./DietitianDiagnosis";
import DietitianNotes from "./DietitianNotes";

const DieticianOnlineConsultation = () => {
  const location = useLocation();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [order, setOrder] = useState(location?.state?.order || {});

  const handleTabChange = (e, tabIndex) => {
    console.log(tabIndex);
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    if (Object.keys(order).length === 0) {
      setOrder(location?.state?.order || {});
    }
  }, [location.state]);

  return (
    <>
      {order && Object.keys(order).length > 0 ? (
        <Box sx={{ width: "100%" }}>
          {/* Tabs Header */}
          <Box sx={{ display: "flex", backgroundColor: "steelblue" }}>
            {/* Left-aligned Tabs */}
            <Tabs
              value={currentTabIndex}
              onChange={handleTabChange}
              sx={{ flex: 1 }}
            >
              <Tab
                label="Diagnosis"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 0 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Nutrition"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 1 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Physical Exercise"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 2 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Environment"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 3 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Lifestyle"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 4 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
              <Tab
                label="Dietitian Notes"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: 600,
                  fontSize: "large",
                  color: currentTabIndex === 5 ? "white" : "black",
                  "&.Mui-selected": {
                    color: "white",
                  },
                }}
              />
            </Tabs>

            {/* Right-aligned Tab */}
            <Tab
              label="Diet Protocol"
              value={5} // Ensure this works with the index logic
              onClick={() => setCurrentTabIndex(6)}
              sx={{
                textTransform: "capitalize",
                fontWeight: 600,
                fontSize: "large",
                // backgroundColor: "dodgerblue",
                color: currentTabIndex === 6 ? "white" : "black",
                borderRadius: "4px",
                opacity: "unset",
                padding: "8px 16px",
                "&:hover": {
                  backgroundColor: "lightblue",
                },
                "&.Mui-selected": {
                  color: "white",
                },
              }}
            />
          </Box>

          {/* Tabs Content */}
          <Box sx={{ p: 3 }}>
            {currentTabIndex === 0 && <DietitianDiagnosis order={order} />}
            {currentTabIndex === 1 && <DieticianDiet order={order} />}
            {currentTabIndex === 2 && (
              <DieticianPhysicalExercise order={order} />
            )}
            {currentTabIndex === 3 && <DietitianEnvironment order={order} />}
            {currentTabIndex === 4 && <DietitianLifestyle order={order} />}
            {currentTabIndex === 5 && <DietitianNotes />}
            {currentTabIndex === 6 && (
              <DieticianDietIntervention order={order} />
            )}
          </Box>
        </Box>
      ) : (
        "No Patient for Online Consultation"
      )}
    </>
  );
};

export default DieticianOnlineConsultation;
