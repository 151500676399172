import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import bronzeImage from "../../../static/img/bronze-plan.jpg";
import silver1Image from "../../../static/img/silver-plan01.jpg";
import BronzeHealthPopup from "../../components/BronzeHealthPopup/BronzeHealthPopup";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { strings } from "../../constants/strings";
import InfoModel from "../../shared/simpleModel/InfoModel";
import "./HomePageAfterLogin.css";

export const HomePageAfterLogin = () => {
  const [isExploreTheServicesInfoModeOpen, setExploreTheServicesIsInfoModeOpen] = useState(false);
  const [isBronzeHealthPlanInfoModeOpen, setBronzeHealthPlanIsInfoModeOpen] = useState(false);
  const [isWhereIsMyMedicalHistoryVideoInfoModeOpen, setWhereIsMyMedicalHistoryVideoIsInfoModeOpen] = useState(false);
  const [isInfoModeOpen, setIsInfoModeOpen] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(new Date());

  // const searchParams = new URLSearchParams(location.search);
  // const patientToken = searchParams.get("token");
  // if (patientToken) {
  //   sessionStorage.setItem("token", patientToken);
  // }

  const urlParams = new URLSearchParams(window.location.search);
  const serializedObject = urlParams.get("token");
  const doctorLoginStatus = urlParams.get("doctorLogin");
  // const familyLogin = urlParams.get("familyLogin");


  if (serializedObject) {
    const decodedObject = decodeURIComponent(serializedObject);
    const myObject = JSON.parse(decodedObject);
    sessionStorage.setItem("token", myObject.token);
    sessionStorage.setItem("userDetails", JSON.stringify(myObject));
    sessionStorage.setItem("doctorLoginStatus", doctorLoginStatus);
    // sessionStorage.setItem("familyLoginStatus", familyLogin);
  }

  useEffect(() => {
    // This effect runs when the component mounts (screen entry)
    setStartTime(new Date());

    // This function will run when the component unmounts (screen exit)
    return () => {
      const endTime = new Date();
      const formatDateTime = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };

      const data = {
        screenName: "Home Screen",
        startTime: formatDateTime(startTime),
        endTime: formatDateTime(endTime),
      };

      // Send data to your API using fetch or Axios here
      // Example using fetch:
      AxiosInstance.post("general/save-app-usage-detail", data)
        .then((response) => {})
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error);
        });
    };
  }, []); // Empty dependency array ensures that this effect runs only once (on mount and unmount)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isUserSignedUp = searchParams.get("isSignedUp");
    setIsSignedUp(isUserSignedUp);
  }, []);

  const ExploreTheServicesPopup = () => {
    setExploreTheServicesIsInfoModeOpen((prev) => !prev);
  };

  const BronzeHealthPlanPopup = () => {
    setBronzeHealthPlanIsInfoModeOpen((prev) => !prev);
  };

  const WhereIsMyMedicalHistoryVideoPopup = () => {
    setWhereIsMyMedicalHistoryVideoIsInfoModeOpen((prev) => !prev);
  };

  return (
    <div className="HomePageAfterLogin">
      <div className="manage-your-health">Explore Our Health Services</div>
      <Row>
        <Col lg={6}>
          <div className="homeoverlap-group">
            <div
              className="rectangle-wrapper"
              style={{ marginBottom: "20px" }}
            >
              <img
                className="img"
                alt="Rectangle"
                src={bronzeImage}
              />
            </div>
            <div className="span-wrapper">Tier-1: BRONZE</div>
            <div className="manage-all-your">Manage all your health records for easy access.</div>
            <div className="start-for-free-wrapper">
              <button className="start-for-free">Start here</button>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <p className="learn-more">
                <span
                  className="hometext-wrapper-3"
                  onClick={BronzeHealthPlanPopup}
                >
                  Learn more
                </span>
              </p>

              <InfoModel
                open={isBronzeHealthPlanInfoModeOpen}
                setOpen={setBronzeHealthPlanIsInfoModeOpen}
                buttonText="Ok"
              >
                <BronzeHealthPopup></BronzeHealthPopup>
              </InfoModel>

              <p className="learn-more">
                <span
                  className="hometext-wrapper-3"
                  onClick={WhereIsMyMedicalHistoryVideoPopup}
                >
                  Watch here
                </span>
              </p>

              <InfoModel
                open={isWhereIsMyMedicalHistoryVideoInfoModeOpen}
                setOpen={setWhereIsMyMedicalHistoryVideoIsInfoModeOpen}
                buttonText="Ok"
              >
                <video
                  id="myVideo"
                  autoPlay
                  controls
                  muted
                >
                  <source
                    src="/img/WhereIsMyMedicalRecord.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </InfoModel>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="overlap-2">
            <div
              className="rectangle-wrapper"
              style={{ marginBottom: "40px" }}
            >
              <img
                className="img"
                alt="Rectangle"
                src={silver1Image}
              />
            </div>

            <div className="span-wrapper">Tier-2: SILVER</div>
            <div className="understand-and-start">Understand and Start Managing your health.</div>
            <div
              className="exploreTheServices">
              <button
                className="exploreTheServicesbutton"
                onClick={ExploreTheServicesPopup}
              >
                Explore the Services
              </button>
            </div>

            <InfoModel
              open={isExploreTheServicesInfoModeOpen}
              setOpen={setExploreTheServicesIsInfoModeOpen}
              buttonText="Ok"
            >
              <div className="popup-description">
                <p>
                  Please join BRONZE health service first. You will be promoted to join this advanced health service only after you have received a minimum participation performance score (PPS) in
                  Bronze service, showing your personal commitment to improve your health.
                </p>
              </div>
            </InfoModel>
          </div>
        </Col>
      </Row>
      {isSignedUp && (
        <InfoModel
          open={isInfoModeOpen}
          setOpen={setIsInfoModeOpen}
          // navigateTo="/profile-register"
          headerIcon={<CheckCircleIcon style={{ color: "green", fontSize: "40px" }} />}
          heading={strings.header.bronzeHealthPlan}
          buttonText="Ok"
        >
          <div className="popup-description"> {strings.purchasedPlan.youHaveSuccessfully}</div>
        </InfoModel>
      )}
    </div>
  );
};
