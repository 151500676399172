import { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { Col, Input, Row } from "reactstrap";
import "../LifeStyleModifications/LifeStyle.css";
import { Button, Card } from "@mui/material";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";

const DietitianLifestyle = ({ order }) => {
  const [myLifestyle, setMyLifestyle] = useState();
  const [loading, setLoading] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  useEffect(() => {
    if (order) {
      setLoading(true);
      AxiosInstance.get(`dietitian/getPatientNutrition/${order.patientId}`)
        .then((response) => {
          if (response !== null) {
            setMyLifestyle(response.lifestyleSketchAndDiet);
          }
          AxiosInstance.get(
            `/dietitian/get-review-and-understood/${order.orderId}`
          )
            .then((response) => {
              setReviewData(response);
              setLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, []);
  const ReviewAndUnderstood = (e) => {
    e.preventDefault();
    const formData = {
      ...reviewData,
      orderId: order.orderId,
      lifeStyleSketch: true,
    };
    AxiosInstance.post("dietitian/create-review-and-understood", formData)
      .then((response) => {
        toast.success("Reviewed Patient Lifestyle");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("diet", myLifestyle);
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-2 dietHeadings">
              <h5>My Habits</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    Ever used tobacco products :
                  </label>
                  <span className="inputDiet">
                    {myLifestyle?.usedTobaccoProducts ? "Yes" : "No"}
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    Drink alcohol (include beer & wine) :
                    <span className="inputDiet">
                      {myLifestyle?.drinkAlcohol ? "Yes" : "No"}
                    </span>
                  </label>
                </Col>
              </Row>
              {myLifestyle?.drinkAlcohol && (
                <Row className="mb-3">
                  <Col lg="6">
                    <label className="questionLabel">
                      How often do I consumes Alcohol :
                      <span className="inputDiet">
                        {myLifestyle?.howOftenDoYouConsumeAlcohol}
                      </span>
                    </label>
                  </Col>

                  <Col lg="6">
                    <label className="questionLabel">
                      Which Drink do I prefer:{" "}
                      <span className="inputDiet">
                        {myLifestyle?.drinkDoYouPrefer}
                      </span>
                    </label>
                  </Col>
                </Row>
              )}
              {myLifestyle?.drinkAlcohol &&
                myLifestyle?.howOftenDoYouConsumeAlcohol !==
                  "Only On Social Occasions" && (
                  <Row className="mb-3">
                    <Col lg="6">
                      <label className="questionLabel">
                        Drinks per day :{" "}
                        <span className="inputDiet">
                          {myLifestyle?.drinksPerDay}
                        </span>
                      </label>
                    </Col>
                  </Row>
                )}
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel"> Taking Folate:</label>
                  <span className="inputDiet">
                    {myLifestyle?.areYouTakingFolate ? "Yes" : "No"}
                  </span>
                </Col>
                {myLifestyle?.areYouTakingFolate &&
                  myLifestyle?.howMuchFolate && (
                    <Col lg="6">
                      <label className="questionLabel">How Much Folate :</label>
                      <span className="inputDiet">
                        {myLifestyle?.howMuchFolate} microgram
                      </span>
                    </Col>
                  )}
              </Row>
              <Row className="mb-3">
                <Col lg={6}>
                  <label className="questionLabel">
                    Do you use recreational drugs :
                  </label>
                  <span className="inputDiet">
                    {myLifestyle?.usedRecreationalDrugs ? "Yes" : "No"}
                  </span>
                </Col>
                {myLifestyle?.usedRecreationalDrugs &&
                  myLifestyle?.drugType && (
                    <Col lg={6}>
                      <label className="questionLabel"> Type of drug :</label>
                      <span className="inputDiet">{myLifestyle?.drugType}</span>
                    </Col>
                  )}
              </Row>
              <Row className="mb-3">
                <Col lg={6}>
                  <label className="questionLabel">
                    Do you smoke (even if 1 puff):
                    <span className="inputDiet">
                      {myLifestyle?.everSmokedPuff ? "Yes" : "No"}
                    </span>
                  </label>
                </Col>
                {myLifestyle?.everSmokedPuff ? (
                  <Col lg={6}>
                    <label className="questionLabel">
                      Your smoking applies:
                      <span className="inputDiet">
                        {myLifestyle?.smokingPerDay}
                      </span>
                    </label>
                  </Col>
                ) : (
                  <Col lg={6}>
                    <label className="questionLabel">
                      Are you a ex-smoker :
                    </label>
                    <span className="inputDiet">
                      {myLifestyle?.areYouExSmoker ? "Yes" : "No"}
                    </span>
                  </Col>
                )}
              </Row>
            </div>
          </Card>

          <Card
            sx={{ marginBottom: "15px", border: "1px solid black" }}
            elevation={5}
          >
            <div className="mb-2 dietHeadings">
              <h5>My Sleep</h5>
            </div>
            <div className="dietContent">
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    1. Hours of sleep do I typically get in a day:
                  </label>
                  <span className="inputDiet">
                    {myLifestyle?.hoursOfSleepingPerDay} hours
                  </span>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    2. I usually go to sleep around the same time everyday :
                    <span className="inputDiet">
                      {myLifestyle?.sleepAroundSameTime}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    3. I am satisfied with my sleep :
                    <span className="inputDiet">
                      {myLifestyle?.satisfiedWithSleep}
                    </span>
                  </label>
                </Col>
                <Col lg="6">
                  <label className="questionLabel">
                    4. I have difficulty falling asleep.:{" "}
                    <span className="inputDiet">
                      {myLifestyle?.difficultyWithSleep}
                    </span>
                  </label>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="6">
                  <label className="questionLabel">
                    5. I feel refreshed after sleep :{" "}
                    <span className="inputDiet">
                      {myLifestyle?.feelRefreshedAfterSleep}
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Card>
          {(reviewData == null || reviewData.lifeStyleSketch == false) && (
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={ReviewAndUnderstood}
                sx={{
                  background: "#5353e9",
                  color: "#fff",
                  minWidth: "180px",

                  "&:hover": { background: "#c60176" },
                }}
              >
                Reviewed and Understood
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DietitianLifestyle;
