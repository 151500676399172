import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import axios from "axios";
import { toast } from "react-toastify";
import "./Admin.css";
import {
  Card,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CorporateApproval from "./CorporateApproval";
import DietitianDieasesApproval from "./DietiticanDieasesAprrova;";
import TrainingApproval from "./TrainingApproval";
import MedicalLabApproval from "./MedicalLabApproval";
import Spinner from "../../shared/Spinner";
import { Box } from "@mui/material";

const ProfileApprovalScreen = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [partnerName, setPartnerName] = useState("");
  const [partnerId, setPartnerId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/get-partner-type`)
      .then((response) => {
        const transformedRoles = response.data.data.map((role) => {
          let transformedRole = role.partnerType
            .toLowerCase()
            .replace(/_/g, " ");
          transformedRole =
            transformedRole
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ") || role.partnerType;

          if (role.partnerType === "PHYSICIAN") {
            transformedRole = "Physiotherapist";
          } else if (role.partnerType === "TESTLAB") {
            transformedRole = "Medical Lab";
          }

          return {
            id: role.id,
            partnerType: role.partnerType,
            displayName: transformedRole,
          };
        });
        setRoles(transformedRoles);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, []);

  const getAllUnApprovedPartners = async () => {
    setLoading(true);
    const [roleId, roleName] = selectedRole.split(",");
    await AxiosInstance.get(`admin/get-all-unApproved-partners/${roleId}`)
      .then((response) => {
        processRoleDetails(response, roleName);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const handleRoleChange = (event) => {
    const selectedRole = event.target.value;
    const [roleId, roleName] = selectedRole.split(",");
    setSelectedRole(selectedRole);
    setPartnerId(roleId);
    setPartnerName(roleName);
    setLoading(true);
    AxiosInstance.get(`admin/get-all-unApproved-partners/${roleId}`)
      .then((response) => {
        processRoleDetails(response, roleName);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  function getBaseUrl() {
    const baseUrl = window.location.href;
    let arr = baseUrl.split("/");
    let newPath = arr[0] + "//" + arr[2];
    return newPath;
  }

  const handleOptionSelect = (event, user, type) => {
    event.preventDefault(); // Prevent the form from submitting and refreshing the page
    setSelectedUser(user);
    if (type === "view") {
      AxiosInstance.post(`admin/login-partner/${user.userId}`)
        .then((response) => {
          console.log("response", response);
          // Map partner names to their respective URLs
          if (response) {
            const urlMap = {
              "Medical Lab": "lab-general-info",
              Dietitian: "dietician-general-info",
              Doctor: "doctor-home",
              Corporate: "corporate-general-info",
              Physiotherapist: "physiotherapist-general-info",
              Gym: "gym-general-info",
              Trainer: "fitness-trainer-general-info",
            };
            const targetUrl = urlMap[partnerName];
            if (targetUrl) {
              const newTab = window.open(
                `${getBaseUrl()}/${targetUrl}`,
                "_blank"
              );

              // Set sessionStorage for the new tab after it loads
              if (newTab) {
                newTab.onload = () => {
                  newTab.localStorage.clear(); //* Optional use case
                  newTab.sessionStorage.setItem(
                    "userDetails",
                    JSON.stringify(response)
                  );
                  newTab.sessionStorage.setItem("token", response.token);
                };
              } else {
                console.error("Failed to open a new tab.");
              }
            } else {
              console.error("Invalid partner name:", partnerName);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setModalType(type);
      setOpenModel(true);
    }
  };

  const processRoleDetails = (data, roleName) => {
    let newOptions = [];
    let userData = [];

    if (Array.isArray(data)) {
      data.forEach((partner) => {
        if (Array.isArray(partner)) {
          partner.forEach((item) => {
            if (roleName === "Doctor" || roleName === "Trainer") {
              if (item.firstName && item.lastName) {
                newOptions.push(`${item.firstName} ${item.lastName}`);
                userData.push(item);
              }
            } else if (roleName === "Corporate") {
              if (item.organizationName) {
                newOptions.push(item.organizationName);
                userData.push(item);
              }
            } else if (roleName === "Physiotherapist") {
              if (item.fullName) {
                newOptions.push(item.fullName);
                userData.push(item);
              }
            } else if (roleName === "Gym") {
              if (item.gymName) {
                newOptions.push(item.gymName);
                userData.push(item);
              }
            } else if (roleName === "Dietitian") {
              if (item.firstName && item.lastName) {
                newOptions.push(`${item.firstName} ${item.lastName}`);
                userData.push(item);
              }
            } else if (roleName == "Medical Lab") {
              if (item.firstName && item.lastName) {
                newOptions.push(`${item.firstName} ${item.lastName}`);
                userData.push(item);
              }
            }
          });
        }
      });
      setSelectedUserData(userData);
    }
  };
  const onCloseOpenModel = () => {
    setOpenModel(false);
    setModalType(null);
  };
  console.log("roles", roles, partnerName, selectedUserData);
  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        }}
      >
        <h5>Profile Approvals</h5>
        <hr style={{ border: "1px solid #000000", width: "12%" }} />
        <Card className="LabOrderDetailCard">
          <FormGroup>
            <Label for="roleSelect">
              <b>Select Partner:</b>
            </Label>
            <br />
            <select
              className="custom-select"
              id="roleSelect"
              aria-label="Example select with button addon"
              style={{
                textAlign: "center",
                marginTop: "10px",
                width: "40%",
              }}
              onChange={handleRoleChange}
              value={selectedRole} // Ensure this binds to the selectedRole state
            >
              <option value="" disabled>
                Select
              </option>{" "}
              {/* Placeholder option */}
              {roles?.map((role) => (
                <option key={role.id} value={`${role.id},${role.displayName}`}>
                  {role.displayName}
                </option>
              ))}
            </select>
          </FormGroup>
        </Card>

        {loading ? (
          <Spinner />
        ) : selectedUserData.length > 0 ? (
          <div
            style={{
              padding: "20px",
              border: "1px solid black",
              display: "flex",
              overflow: "auto",
            }}
            className="mt-4"
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <Label for="userTable">
                <b>Users:</b>
              </Label>
              <br />

              {selectedUserData.length > 0 && partnerName == "Medical Lab" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>First Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Last Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Lab Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.firstName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.lastName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.labName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 && partnerName == "Dietitian" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>First Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Last Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Hospital Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.firstName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.lastName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.hospitalName === "null"
                            ? "Not Available"
                            : user.hospitalName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 && partnerName == "Doctor" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>First Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Last Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Email</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Phone Number</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.firstName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.lastName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.email}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.phoneNumber}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 && partnerName == "Corporate" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Organization Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Contact Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Email</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Contact Number</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.organizationName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.contactName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.email}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.contactMobile}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 &&
              partnerName == "Physiotherapist" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Full Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Affilated Hospital</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Email</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Contact Number</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.fullName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.affiliatedHospital == true ? "Yes" : "No"}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.email}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.contactNumber}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 && partnerName == "Gym" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Gym Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Training Programs</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.gymName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.variousTrainingProgram}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}

              {selectedUserData.length > 0 && partnerName == "Trainer" ? (
                <table
                  className="table table-striped"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>First Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Last Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Email</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Phone Number</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Partner Name</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>View</b>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid black",
                          textAlign: "center",
                        }}
                      >
                        <b>Approval</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUserData.map((user, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.firstName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.lastName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.email}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {user.phoneNumber}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          {partnerName}
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) => handleOptionSelect(e, user, "view")}
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid black",
                            textAlign: "center",
                          }}
                        >
                          <button
                            type="button" // Add type="button" to prevent form submission
                            onClick={(e) =>
                              handleOptionSelect(e, user, "approval")
                            }
                            className="btn btn-primary"
                          >
                            <RemoveRedEyeIcon />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p></p>
              )}
            </div>
          </div>
        ) : (
          partnerName != "" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "background.default",
                color: "text.secondary",
                textAlign: "center",
                padding: 3,
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 2,
                boxShadow: 3,
              }}
            >
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#d32f2f", // MUI error.main equivalent
                  marginBottom: "8px",
                }}
              >
                No User Found
              </div>
              <div style={{ fontSize: "16px", color: "#616161" }}>
                No users are available for approval at the moment. Please check
                back later.
              </div>
            </Box>
          )
        )}
      </Form>

      {partnerName === "Medical Lab" && selectedUser && (
        <>
          <Modal isOpen={openModel} toggle={onCloseOpenModel}>
            <ModalHeader toggle={onCloseOpenModel}>
              <b>Medical Lab Approval</b>
            </ModalHeader>
            <ModalBody>
              {selectedUser && (
                <MedicalLabApproval
                  selectedUser={selectedUser}
                  approval={parseInt(partnerId)}
                  onCloseOpenModel={onCloseOpenModel}
                  getAllUnApprovedPartners={getAllUnApprovedPartners}
                />
              )}
            </ModalBody>
          </Modal>
        </>
      )}

      {partnerName === "Doctor" && selectedUser && (
        <>
          {/* Modal for DietitianDiseasesApproval */}
          {modalType === "approval" && (
            <Modal
              isOpen={openModel}
              toggle={onCloseOpenModel}
              style={{ maxHeight: "90vh", overflowY: "auto" }}
            >
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Doctor Diseases Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <DietitianDieasesApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}

      {partnerName === "Corporate" && selectedUser && (
        <>
          {modalType === "approval" && (
            <Modal isOpen={openModel} toggle={onCloseOpenModel}>
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Corporate Approval</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <CorporateApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {partnerName === "Physiotherapist" && selectedUser && (
        <>
          {/* Modal for DietitianDiseasesApproval */}
          {modalType === "approval" && (
            <Modal
              isOpen={openModel}
              toggle={onCloseOpenModel}
              style={{ maxHeight: "90vh", overflowY: "auto" }}
            >
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Physiotherapist Diseases Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <DietitianDieasesApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {partnerName === "Gym" && selectedUser && (
        <>
          {/* Modal for DietitianDiseasesApproval */}
          {modalType === "approval" && (
            <Modal isOpen={openModel} toggle={onCloseOpenModel}>
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Gym Training Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <TrainingApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {partnerName === "Dietitian" && selectedUser && (
        <>
          {/* Modal for DietitianDiseasesApproval */}
          {modalType === "approval" && (
            <Modal
              isOpen={openModel}
              toggle={onCloseOpenModel}
              style={{ maxHeight: "90vh", overflowY: "auto" }}
            >
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Dietician Diseases Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <DietitianDieasesApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}
      {partnerName === "Trainer" && selectedUser && (
        <>
          {" "}
          {/* Modal for DietitianDiseasesApproval */}
          {modalType === "approval" && (
            <Modal isOpen={openModel} toggle={onCloseOpenModel}>
              <ModalHeader toggle={onCloseOpenModel}>
                <b>Training Approval Information</b>
              </ModalHeader>
              <ModalBody>
                {selectedUser && (
                  <TrainingApproval
                    selectedUser={selectedUser}
                    approval={parseInt(partnerId)}
                    onCloseOpenModel={onCloseOpenModel}
                    getAllUnApprovedPartners={getAllUnApprovedPartners}
                  />
                )}
              </ModalBody>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default ProfileApprovalScreen;
