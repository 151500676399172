import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./formStyle.css";
import {
  Checkbox,
  FormGroup,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Col, Input, Row, Toast } from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";
import ToastMessage from "../../shared/ToastMessage";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { FlashOnTwoTone } from "@mui/icons-material";
import * as Yup from "yup";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";
import { event, exception } from "react-ga";
import { eventNames } from "process";
import { json } from "react-router-dom";

const initialExerciseData = [
  {
    name: "Walking (1 mile in 15 min)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Biking 1 mile in 6 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Swimming (laps for 20 minutes)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Running one and a half miles in 15 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Doing water aerobics for 30 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Playing volleyball for 45 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Playing basketball for 20 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Jumping rope for 15 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Walking stairs for 15 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Washing your car for 45 minutes to an hour",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Gardening for 30 to 45 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Raking leaves for 30 minutes",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  { name: "Dancing for 30 minutes", days: "", timeEachDay: "", totalTime: "" },
  { name: "Tennis (Doubles)", days: "", timeEachDay: "", totalTime: "" },
  { name: "Badminton (Doubles)", days: "", timeEachDay: "", totalTime: "" },
];

const initialVigorousExerciseData = [
  {
    name: "Hiking",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Jogging at 6 mph",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Shoveling",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Carrying heavy loads",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Bicycling fast (14-16 mph)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Basketball game",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Soccer game",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Football game",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Tennis singles",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Cricket",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Hockey",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Martial Arts",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
];

const initialMildExerciseData = [
  {
    name: "Walking slowly",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Sitting using computer",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Standing light work (cooking, washing dishes)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Fishing sitting",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Playing most instrument",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Art and craft (e.g. pottery, sewing, painting)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Doing homework",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Listen to music",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Playing board games/ cards",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Playing computer games",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Reading",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  {
    name: "Sitting talking",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  { name: "Talk on the phone", days: "", timeEachDay: "", totalTime: "" },
  {
    name: "Tavel by bus or car to school (to and fro)",
    days: "",
    timeEachDay: "",
    totalTime: "",
  },
  { name: "Watching TV/videos", days: "", timeEachDay: "", totalTime: "" },
];

const ChildrenLifestyle3to13Question = ({ onBack }) => {
  const [height, setHeight] = useState(null);
  const [weight, setWeight] = useState(null);
  const [bmi, setBmi] = useState(null);
  const [medicalProblem, setMedicalProblem] = useState(null);
  const [dentailProblem, setDentailProblem] = useState(null);
  const [childTakeMedicine, setChildTakeMedicine] = useState(null);
  const [specialDiet, setSpecialDiet] = useState(null);
  const [childsDiet, setChildsDiet] = useState("");
  const [milkDrinkEachDay, setMilkDrinkEachDay] = useState("");
  const [kindOfMilkChildDrink, setKindMilkChildDrink] = useState("");
  const [beverages, setBeverages] = useState("");
  const [waterToBeverages, setWaterToBeverages] = useState(null);
  const [childDrinkPlainWater, setChildDrinkPlainWater] = useState(null);
  const [howMuchDrinkEachDay, setHowMuchDrinkEachDay] = useState("");
  const [childHaveProblem, setChildHaveProblem] = useState("");
  const [allergyOfFood, setAllergyOfFood] = useState(null);
  const [allergyOfWhichFood, setAllergyOfWhichFood] = useState("");
  const [childNumberOfMeals, setChildNumberOfMeals] = useState("");
  const [besideSomethingtoEat, setBesideSomethingtoEat] = useState("");
  const [offerFoodasReward, setOfferFoodasReward] = useState(null);
  const [whichFoodAsReward, setwhichFoodAsReward] = useState("");
  const [doYouRequireToEatCertain, setDoYouRequireToEatCertain] =
    useState(null);
  const [getAnyConcernGettingToEat, setGetAnyConcernGettingToEat] =
    useState("");
  const [besideUsuallyEat, setBesideUsuallyEat] = useState("");
  const [eatMeatChickenFish, setEatMeatChickenFish] = useState("");
  const [eatGrains, setEatGrains] = useState("");
  const [eatFruits, setEatFruits] = useState("");
  const [eatEggs, setEatEggs] = useState("");
  const [eatVegetables, setEatVegetable] = useState("");
  const [eatPeanutButter, setEatPeanutButter] = useState("");
  const [eatCheese, setEatCheese] = useState("");
  const [eatBeans, setEatBeans] = useState("");
  const [eatSnacksTimesInDay, setEatSnacksTimesInDay] = useState("");
  const [eatForSnacks, setEatForSnacks] = useState("");
  const [eatFastFoodTime, setEatFastFoodTime] = useState("");
  const [playingGameUsingPhoneHours, setPlayingGameUsingPhoneHours] =
    useState("");
  const [doesYourChildEatAnyFood16A, setDoesYourChildEatAnyFood16A] =
    useState("");
  const [doesYourChildEatAnyFood16B, setDoesYourChildEatAnyFood16B] =
    useState("");
  const [anyOneSmokeInsideHome, setAnyOneSmokeInsideHome] = useState(null);
  const [doesYourChildEatAnyFood16C, setDoesYourChildEatAnyFood16C] =
    useState("");
  const [diet, setDiet] = useState("");
  const [multivitaminsDiet, setmultivitaminsDiet] = useState("");
  const [listMedicalProblems, setListMedicalProblem] = useState("");
  const [childMedicineList, setChildMedicineList] = useState("");
  const [specialDietList, setSpecialDietList] = useState("");
  const [dietLimitReason, setDietLimitReason] = useState("");
  const [otherdietValue, setOtherdietValue] = useState("");
  const [otherMultivitaminValue, setOtherMultivitaminValue] = useState("");
  const [otherKindOfMilk, setOtherKindOfMilk] = useState("");
  const [receivedDietCounselling, setreceivedDietCounselling] = useState("");
  const [numberOfMeals, setnumberOfMeals] = useState("");
  const [numberOfSnacks, setnumberOfSnacks] = useState("");
  const [qesAboutNutrition, setQesAboutNutrition] = useState("");
  const [doYouHaveBreakfastRegularly, setdoYouHaveBreakfastRegularly] =
    useState("");
  const [exercise, setExercise] = useState("");
  const [exercise2, setExercise2] = useState("");
  const [exercise3, setExercise3] = useState("");
  const [youStoppedDoingPhysicalActivity, setYouStoppedDoingPhysicalActivity] =
    useState({
      physicalActivity4A: "",
      physicalActivity4B: "",
      physicalActivity4C: "",
      physicalActivity4D: "",
      physicalActivity4E: "",
      physicalActivity4F: "",
    });
  const [agreeStatement, setAgreeStatement] = useState({
    agreeStatement5A: "",
    agreeStatement5B: "",
    agreeStatement5C: "",
    agreeStatement5D: "",
    agreeStatement5E: "",
    agreeStatement5F: "",
  });
  const [
    doYouFeelDoingPhysicalActOrSportWill,
    setDoYouFeelDoingPhysicalActOrSportWill,
  ] = useState({
    doYouFeelDoingPhysicalActOrSportWill6A: "",
    doYouFeelDoingPhysicalActOrSportWill6B: "",
    doYouFeelDoingPhysicalActOrSportWill6C: "",
    doYouFeelDoingPhysicalActOrSportWill6D: "",
    doYouFeelDoingPhysicalActOrSportWill6E: "",
    doYouFeelDoingPhysicalActOrSportWill6F: "",
  });
  const [duringTypicalWeek, setDuringTypicalWeek] = useState({
    duringTypicalWeek7A: "",
    duringTypicalWeek7B: "",
    duringTypicalWeek7C: "",
    duringTypicalWeek7D: "",
    duringTypicalWeek7E: "",
    duringTypicalWeek7F: "",
  });
  const [agreeStatementSecond, setAgreeStatementSecond] = useState({
    agreeStatementSecond8A: "",
    agreeStatementSecond8B: "",
    agreeStatementSecond8C: "",
    agreeStatementSecond8D: "",
    agreeStatementSecond8E: "",
    agreeStatementSecond8F: "",
    agreeStatementSecond8G: "",
    agreeStatementSecond8H: "",
    agreeStatementSecond8I: "",
  });
  const [
    duringTypicalWeekSomeoneInFamily,
    setDuringTypicalWeekSomeoneInFamily,
  ] = useState({
    duringTypicalWeekSomeoneInFamily9A: "",
    duringTypicalWeekSomeoneInFamily9B: "",
    duringTypicalWeekSomeoneInFamily9C: "",
    duringTypicalWeekSomeoneInFamily9D: "",
    duringTypicalWeekSomeoneInFamily9E: "",
    duringTypicalWeekSomeoneInFamily9F: "",
  });
  const [comparedWithOtherChild, setComparedWithOtherChild] = useState("");
  const [howDoYouTravelToSchool, setHowDoYouTravelToSchool] = useState("");
  const [whoDoYouTravelWithSchool, setWhoDoYouTravelWithSchool] = useState("");
  const [doYouGoAnywhereElseFromHome, setDoYouGoAnywhereElseFromHome] =
    useState("");
  const [journeyToAndFromSchool, setJourneyToAndFromSchool] = useState({
    journeyToAndFromSchool10A: "",
    journeyToAndFromSchool10B: "",
    journeyToAndFromSchool10C: "",
    journeyToAndFromSchool10D: "",
    journeyToAndFromSchool10E: "",
    journeyToAndFromSchool10F: "",
    journeyToAndFromSchool10G: "",
  });
  const [howDoYouTravel, setHowDoYouTravel] = useState({
    howDoYouTravel11A: "",
    howDoYouTravel11B: "",
    howDoYouTravel11C: "",
    howDoYouTravel11D: "",
  });
  const [whatDoYouDoInSchool, setWhatDoYouDoInSchool] = useState({
    whatDoYouDoInSchool12A: "",
    whatDoYouDoInSchool12B: "",
  });
  const [timeOfDinner, settimeOfDinner] = useState("");
  const [appetite, setAppetite] = useState("");
  const [includedInTheMeals, setincludedInTheMeals] = useState("");
  const [describeChildDiet, setDescribeChildDiet] = useState("");
  const [otherMealsValue, setotherMealsValue] = useState("");
  const [selectDiet, setselectDiet] = useState("");
  const [includedInTheDiet, setincludedInTheDiet] = useState("");
  const [mixedIncludedInYourDiet, setmixedIncludedInYourDiet] = useState("");
  const [consultWithDietitian, setconsultWithDietitian] = useState(null);
  const [
    comparisonLastThreeMonthsToSevenDays,
    setcomparisonLastThreeMonthsToSevenDays,
  ] = useState("");
  const [selectedActivity, setselectedActivity] = useState("");
  const [hoursOfExercise, sethoursOfExercise] = useState("");
  const [totalHoursOfSitting, settotalHoursOfSitting] = useState("");
  const [usedTobaccoProducts, setusedTobaccoProducts] = useState(null);
  const [drinkAlcohol, setdrinkAlcohol] = useState(null);
  const [howOftenConsumeAlcohol, sethowOftenConsumeAlcohol] = useState("");
  const [drinkDoYouPrefer, setdrinkDoYouPrefer] = useState("");
  const [drinksPerDay, setdrinksPerDay] = useState("");
  const [usedRecreationalDrugs, setusedRecreationalDrugs] = useState(null);
  const [drugType, setdrugType] = useState("");
  const [smoked, setSmoked] = useState(null);
  const [areYouExSmoker, setareYouExSmoker] = useState();
  const [smokingPerDay, setsmokingPerDay] = useState("");
  const [hoursOfSleep, setHoursOfSleep] = useState("");
  const [sleepAroundSameTime, setsleepAroundSameTime] = useState("");
  const [satisfiedWithSleep, setsatisfiedWithSleep] = useState(null);
  const [difficultyWithSleep, setdifficultyWithSleep] = useState(null);
  const [feelRefreshedAfterSleep, setfeelRefreshedAfterSleep] = useState(null);
  const [preventiveScreeningTestDone, setpreventiveScreeningTestDone] =
    useState("");
  const [glucometerDevice, setGlucometerDevice] = useState("");
  const [bpDevice, setbpDevice] = useState("");
  const [bloodSugarCheckedLast, setbloodSugarCheckedLast] = useState("");
  const [bloodPressureCheckedLast, setbloodPressureCheckedLast] = useState("");
  const [
    concernRegardingIllnessTreatment,
    setconcernRegardingIllnessTreatment,
  ] = useState("");
  const [moreInfoAboutYou, setmoreInfoAboutYou] = useState("");
  const [questionsForUs, setquestionsForUs] = useState("");
  const [preferLowCarbDiet, setpreferLowCarbDiet] = useState("");
  const [preferVegetarianFood, setpreferVegetarianFood] = useState("");
  const [avoidProcessedMeat, setavoidProcessedMeat] = useState("");
  const [avoidHighlyProcessedFoods, setavoidHighlyProcessedFoods] =
    useState("");
  const [
    doNotDrinkSugarSweetenedBeverages,
    setdoNotDrinkSugarSweetenedBeverages,
  ] = useState("");
  const [
    regularlyConsumeWholeGrainsLegumes,
    setregularlyConsumeWholeGrainsLegumes,
  ] = useState("");
  const [drinkCoffee, setdrinkCoffee] = useState("");
  const [limitIntakeOfDairyProducts, setlimitIntakeOfDairyProducts] =
    useState("");
  const [eatDietWhichIsLowInAddedSugars, seteatDietWhichIsLowInAddedSugars] =
    useState("");
  const [oftenEatMeat, setoftenEatMeat] = useState("");
  const [oftenEatYogurt, setoftenEatYogurt] = useState("");
  const [oftenEatLowFatMilk, setoftenEatLowFatMilk] = useState("");
  const [oftenEatCheese, setoftenEatCheese] = useState("");
  const [oftenEatEggs, setoftenEatEggs] = useState("");
  const [oftenEatFortifiedCereals, setoftenEatFortifiedCereals] = useState("");
  const [areYouTakingFolate, setareYouTakingFolate] = useState(null);
  const [howMuchFolate, sethowMuchFolate] = useState();
  const [limitOrDecreaseSaltInMyMeals, setlimitOrDecreaseSaltInMyMeals] =
    useState("");
  const [highInFiber, sethighInFiber] = useState("");
  const [limitOrDecreaseSugarInMyDiet, setlimitOrDecreaseSugarInMyDiet] =
    useState("");
  const [profile, setProfile] = useState(null);
  const [familyHistory, setFamilyHistory] = useState(null);
  const [LifestyleStep, setLifestyleStep] = useState(0);
  const [errorHeight, setErrorHeight] = useState("");
  const [errorWeight, setErrorWeight] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldErrors2, setFieldErrors2] = useState({});
  const [fieldErrors3, setFieldErrors3] = useState({});
  const [fieldErrors4, setFieldErrors4] = useState({});
  const [fieldErrors5, setFieldErrors5] = useState({});
  const [fieldErrors6, setFieldErrors6] = useState({});
  const [fieldErrors7, setFieldErrors7] = useState({});
  const [fieldErrors8, setFieldErrors8] = useState({});
  const [fieldErrors9, setFieldErrors9] = useState({});
  const [fieldErrors11, setFieldErrors11] = useState({});
  const [fieldErrors12, setFieldErrors12] = useState({});
  const [familyRelation, setFamilyRelation] = useState();
  const [familyInfo, setFamilyInfo] = useState();
  const [exerciseData, setExerciseData] = useState(initialExerciseData);
  const [vigorousexerciseData, setvigorousexerciseData] = useState(
    initialVigorousExerciseData
  );
  const [mildexerciseData, setmildExerciseData] = useState(
    initialMildExerciseData
  );
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMildIntensityAddRow = () => {
    setmildExerciseData([
      ...mildexerciseData,
      { name: "", days: "", timeEachDay: "", totalTime: "" },
    ]);
  };

  const handleMildIntensityInputChange = (index, field, value) => {
    const updatedData = mildexerciseData.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        if (field === "days" || field === "timeEachDay") {
          const days = field === "days" ? value : item.days;
          const timeEachDay =
            field === "timeEachDay" ? value : item.timeEachDay;
          updatedItem.totalTime = days && timeEachDay ? days * timeEachDay : "";
        }
        return updatedItem;
      }
      return item;
    });
    setmildExerciseData(updatedData);
  };

  const handleVigorousIntensityAddRow = () => {
    setvigorousexerciseData([
      ...vigorousexerciseData,
      { name: "", days: "", timeEachDay: "", totalTime: "" },
    ]);
  };

  const handleVigorousIntensityInputChange = (index, field, value) => {
    const updatedData = vigorousexerciseData.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        if (field === "days" || field === "timeEachDay") {
          const days = field === "days" ? value : item.days;
          const timeEachDay =
            field === "timeEachDay" ? value : item.timeEachDay;
          updatedItem.totalTime = days && timeEachDay ? days * timeEachDay : "";
        }
        return updatedItem;
      }
      return item;
    });
    setvigorousexerciseData(updatedData);
  };

  const handleAddRow = () => {
    setExerciseData([
      ...exerciseData,
      { name: "", days: "", timeEachDay: "", totalTime: "" },
    ]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = exerciseData.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };
        if (field === "days" || field === "timeEachDay") {
          const days = field === "days" ? value : item.days;
          const timeEachDay =
            field === "timeEachDay" ? value : item.timeEachDay;
          updatedItem.totalTime = days && timeEachDay ? days * timeEachDay : "";
        }
        return updatedItem;
      }
      return item;
    });
    setExerciseData(updatedData);
  };

  const handleoftenEatMeatChange = (event) => {
    setoftenEatMeat(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatMeat: false,
    }));
  };

  const handlemixedIncludedInYourDietChange = (event) => {
    const value = event.target.value;
    setmixedIncludedInYourDiet((prevMembers) => {
      if (prevMembers?.includes(value)) {
        return prevMembers.filter((member) => member !== value);
      } else {
        return [...prevMembers, value];
      }
    });
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      mixedIncludedInYourDiet: false,
    }));
  };

  const handleselectDietChange = (event) => {
    setselectDiet(event.target.value);
    if (event.target.value === "Strictly vegetarian (Eggs not included)") {
      setoftenEatEggs("");
      setoftenEatMeat("");
      setmixedIncludedInYourDiet("");
    } else if (event.target.value === "Eggetarian (veg + eggs)") {
      setincludedInTheDiet(""); // Reset included in the diet checkboxes
      setoftenEatYogurt("");
      setoftenEatCheese("");
      setoftenEatLowFatMilk("");
      setoftenEatFortifiedCereals("");
      setoftenEatMeat(""); // Reset often eat meat radio group
      setmixedIncludedInYourDiet([]); // Reset mixed diet checkboxes
    } else if (
      event.target.value === "Mixed (includes veg, meat and poultry)"
    ) {
      setincludedInTheDiet(""); // Reset included in the diet checkboxes
      setoftenEatEggs(""); // Reset often eat eggs radio group
      setoftenEatYogurt("");
      setoftenEatCheese("");
      setoftenEatLowFatMilk("");
      setoftenEatFortifiedCereals("");
    }
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      selectDiet: false,
    }));
  };

  const handlelimitOrDecreaseSugarInMyDietChange = (event) => {
    setlimitOrDecreaseSugarInMyDiet(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      limitOrDecreaseSugarInMyDiet: false,
    }));
  };

  const handlehighInFiberChange = (event) => {
    sethighInFiber(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      highInFiber: false,
    }));
  };

  const handlelimitOrDecreaseSaltInMyMealsChange = (event) => {
    setlimitOrDecreaseSaltInMyMeals(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      limitOrDecreaseSaltInMyMeals: false,
    }));
  };

  const handlehowMuchFolateChange = (event) => {
    sethowMuchFolate(event.target.value);
  };
  const handleareYouTakingFolateChange = (event) => {
    const value = event.target.value === "true";
    setareYouTakingFolate(value);
  };

  const handledrinksPerDayChange = (event) => {
    setdrinksPerDay(event.target.value);
  };

  const handledrinkDoYouPreferChange = (event) => {
    setdrinkDoYouPrefer(event.target.value);
  };

  const handledrugTypeChange = (event) => {
    setdrugType(event.target.value);
  };

  const handleoftenEatFortifiedCerealsChange = (event) => {
    setoftenEatFortifiedCereals(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatFortifiedCereals: false,
    }));
  };

  const handleQesAboutNutrition = (event) => {
    setQesAboutNutrition(event.target.value);
  };

  const handleoftenEatEggsChange = (event) => {
    setoftenEatEggs(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatEggs: false,
    }));
  };

  const handleoftenEatCheeseChange = (event) => {
    setoftenEatCheese(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatCheese: false,
    }));
  };

  const handleoftenEatLowFatMilkChange = (event) => {
    setoftenEatLowFatMilk(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatLowFatMilk: false,
    }));
  };

  const handleoftenEatYogurtChange = (event) => {
    setoftenEatYogurt(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      oftenEatYogurt: false,
    }));
  };

  const handleeatDietWhichIsLowInAddedSugarsChange = (event) => {
    seteatDietWhichIsLowInAddedSugars(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      eatDietWhichIsLowInAddedSugars: false,
    }));
  };

  const handlelimitIntakeOfDairyProductsChange = (event) => {
    setlimitIntakeOfDairyProducts(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      limitIntakeOfDairyProducts: false,
    }));
  };

  const handledrinkCoffeeChange = (event) => {
    setdrinkCoffee(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      drinkCoffee: false,
    }));
  };

  const handleregularlyConsumeWholeGrainsLegumesChange = (event) => {
    setregularlyConsumeWholeGrainsLegumes(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      regularlyConsumeWholeGrainsLegumes: false,
    }));
  };

  const handledoNotDrinkSugarSweetenedBeveragesChange = (event) => {
    setdoNotDrinkSugarSweetenedBeverages(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      doNotDrinkSugarSweetenedBeverages: false,
    }));
  };

  const handleavoidHighlyProcessedFoodstChange = (event) => {
    setavoidHighlyProcessedFoods(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      avoidHighlyProcessedFoods: false,
    }));
  };

  const handleavoidProcessedMeatChange = (event) => {
    setavoidProcessedMeat(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      avoidProcessedMeat: false,
    }));
  };

  const handlepreferVegetarianFoodChange = (event) => {
    setpreferVegetarianFood(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      preferVegetarianFood: false,
    }));
  };

  const handlepreferLowCarbDietChange = (event) => {
    setpreferLowCarbDiet(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      preferLowCarbDiet: false,
    }));
  };

  const handleMedicalProblem = (event) => {
    const value = event.target.value === "true";
    setMedicalProblem(value);
    if (value === false) {
      setListMedicalProblem("");
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      medicalProblem: false,
    }));
    // If "Other" is selected, clear otherValue
    // if (value !== "Other") {
    //   setOtherApplies("");
    // }
  };

  const handleMedicalProblemList = (event) => {
    const value = event.target.value;
    setListMedicalProblem(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      listMedicalProblems: false,
    }));
  };

  const handleChildTakeMedicine = (event) => {
    const value = event.target.value === "true";
    setChildTakeMedicine(value);
    if (value === false) {
      setChildMedicineList("");
    }
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childTakeMedicine: false,
    }));
    // If "Other" is selected, clear otherValue
    // if (value !== "Other") {
    //   setOtherApplies("");
    // }
  };

  const handleChildMedicineList = (event) => {
    const value = event.target.value;
    setChildMedicineList(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childMedicineList: false,
    }));
  };

  const handleDentialProblem = (event) => {
    const value = event.target.value === "true";
    setDentailProblem(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      dentailProblem: false,
    }));

    // If "Other" is selected, clear otherValue
    //  if (value !== "Other") {
    //    setOtherApplies("");
    //  }
  };

  const handleSpecialDiet = (event) => {
    const value = event.target.value === "true";
    if (value === false) {
      setSpecialDietList("");
    }
    setSpecialDiet(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      specialDiet: false,
    }));

    // If "Other" is selected, clear otherValue
    // if (value !== "Other") {
    //   setOtherApplies("");
    // }
  };

  const handleSpecialDietList = (event) => {
    const value = event.target.value;
    setSpecialDietList(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      specialDietList: false,
    }));
  };

  const handlecomparisonLastThreeMonthsToSevenDaysChange = (event) => {
    setcomparisonLastThreeMonthsToSevenDays(event.target.value);
    setFieldErrors11((prevErrors) => ({
      ...prevErrors,
      comparisonLastThreeMonthsToSevenDays: false,
    }));
  };

  const handleusedTobaccoProductsChange = (event) => {
    const value = event.target.value === "true";
    setusedTobaccoProducts(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedTobaccoProducts: false,
    }));
  };

  const handlehowOftenConsumeAlcoholChange = (event) => {
    sethowOftenConsumeAlcohol(event.target.value);
  };

  const handledrinkAlcoholChange = (event) => {
    const value = event.target.value === "true";
    setdrinkAlcohol(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedTobaccoProducts: false,
    }));
  };

  const handleusedRecreationalDrugsChange = (event) => {
    const value = event.target.value === "true";
    setusedRecreationalDrugs(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      usedRecreationalDrugs: false,
    }));
  };

  const handlesleepAroundSameTimeChange = (event) => {
    setsleepAroundSameTime(event.target.value);
    setFieldErrors12((prevErrors) => ({
      ...prevErrors,
      sleepAroundSameTime: false,
    }));
  };

  const handlehoursOfSleepChange = (event) => {
    setHoursOfSleep(event.target.value);
    setFieldErrors12((prevErrors) => ({
      ...prevErrors,
      totalHourSleep: false,
    }));
  };

  const handlesatisfiedWithSleepChange = (event) => {
    setsatisfiedWithSleep(event.target.value);
    setFieldErrors12((prevErrors) => ({
      ...prevErrors,
      satisfiedWithSleep: false,
    }));
  };

  const handledifficultyWithSleepChange = (event) => {
    setdifficultyWithSleep(event.target.value);
    setFieldErrors12((prevErrors) => ({
      ...prevErrors,
      difficultyWithSleep: false,
    }));
  };

  const handlefeelRefreshedAfterSleepChange = (event) => {
    setfeelRefreshedAfterSleep(event.target.value);
    setFieldErrors12((prevErrors) => ({
      ...prevErrors,
      feelRefreshedAfterSleep: false,
    }));
  };

  const handleconcernRegardingIllnessTreatmentChange = (event) => {
    const selectValue = event.target.value;
    setconcernRegardingIllnessTreatment(selectValue);
  };

  const handlemoreInfoAboutYouChange = (event) => {
    const selectValue = event.target.value;
    setmoreInfoAboutYou(selectValue);
  };

  const handlequestionsForUsChange = (event) => {
    const selectValue = event.target.value;
    setquestionsForUs(selectValue);
  };

  const onHeightChange = (e) => {
    const newHeight = parseFloat(e.target.value);
    setHeight(newHeight);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      height: false,
    }));
  };

  const onWeightChange = (e) => {
    const newWeight = parseFloat(e.target.value);
    setWeight(newWeight);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      weight: false,
    }));
  };

  const handleselectedActivityCheckboxChange = (option) => {
    if (option === "None") {
      // If "None" is selected, uncheck all other options
      setselectedActivity("None");
    } else {
      // Toggle the selected state for the clicked option
      setselectedActivity((prevOptions) => {
        if (prevOptions === "None") {
          // If 'None' was previously selected, remove it
          return option;
        } else if (prevOptions?.includes(option)) {
          // If the option is already selected, remove it
          return prevOptions?.replace(`${option},`, "").trim();
        } else {
          // If the option is not selected, add it to the string
          return `${prevOptions},${option}`.trim();
        }
      });
    }
    setFieldErrors11((prevErrors) => ({
      ...prevErrors,
      selectedActivity: false,
    }));
  };

  const handleLifestyleStep = () => {
    setLifestyleStep((prev) => prev + 1);
  };

  const handleLifestyleStepBack = () => {
    setLifestyleStep((prev) => prev - 1);
  };

  const handlerecieveddietcounsellingChange = (event) => {
    setreceivedDietCounselling(event.target.value);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      receivedDietCounselling: false,
    }));
  };

  const handlenumberOfMealsChange = (event) => {
    const selectedValue = event.target.value;
    setnumberOfMeals(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      numberOfMeals: false,
    }));
  };

  const handlenumberOfSnacksChange = (event) => {
    const selectedValue = event.target.value;
    setnumberOfSnacks(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      numberOfSnacks: false,
    }));
  };

  const handledoYouHaveBreakfastRegularlyChange = (event) => {
    const selectedValue = event.target.value;
    setdoYouHaveBreakfastRegularly(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      doYouHaveBreakfastRegularly: false,
    }));
  };

  const handletimeOfDinnerChange = (event) => {
    const selectedValue = event.target.value;
    settimeOfDinner(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      timeOfDinner: false,
    }));
  };

  const handleAppetiteChange = (event) => {
    const selectedValue = event.target.value;
    setAppetite(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      appetite: false,
    }));
  };

  const handleconsultWithDietitianChange = (event) => {
    const selectedValue = event.target.value;
    setconsultWithDietitian(selectedValue);
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      consultWithDietitian: false,
    }));
  };

  const handlehoursOfExerciseChange = (event) => {
    const selectedValue = event.target.value;
    sethoursOfExercise(selectedValue);
    setFieldErrors11((prevErrors) => ({
      ...prevErrors,
      hoursOfExercise: false,
    }));
  };

  const handletotalHoursOfSitting = (event) => {
    const selectedValue = event.target.value;
    settotalHoursOfSitting(selectedValue);
    setFieldErrors11((prevErrors) => ({
      ...prevErrors,
      totalHoursOfSitting: false,
    }));
  };

  const handleDietChange = (event) => {
    const { value, type, checked } = event.target;

    setChildsDiet((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childsDiet: false,
    }));
  };

  const handleDietLimitReason = (event) => {
    const value = event.target.value;
    setDietLimitReason(value);
  };

  const handleMultivitaminsDiet = (event) => {
    const { value, type, checked } = event.target;

    setmultivitaminsDiet((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      multivitaminsDiet: false,
    }));
  };

  const handleMilkDrinkEachDay = (event) => {
    const value = event.target.value;
    setMilkDrinkEachDay(value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      milkDrinkEachDay: false,
    }));
  };
  const handleKindOfMilkChildDrink = (event) => {
    const { value, type, checked } = event.target;
    setKindMilkChildDrink((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      kindOfMilkChildDrink: false,
    }));
  };

  const handleBeveragesChange = (event) => {
    const { value, type, checked } = event.target;
    setBeverages((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      beverages: false,
    }));
  };

  const handleWaterToBeverages = (event) => {
    const value = event.target.value === "true";
    setWaterToBeverages(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      waterToBeverages: false,
    }));
  };

  const handleChildDrinkPlainWater = (event) => {
    const value = event.target.value === "true";
    setChildDrinkPlainWater(value);
    if (value === false) {
      setHowMuchDrinkEachDay("");
    }
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      childDrinkPlainWater: false,
    }));
  };
  const handleHowMuchDrinkEachDay = (event) => {
    const value = event.target.value;
    setHowMuchDrinkEachDay(value);
    setFieldErrors2((prevErrors) => ({
      ...prevErrors,
      howMuchDrinkEachDay: false,
    }));
  };

  const handleChildProblem = (event) => {
    const { value, type, checked } = event.target;
    setChildHaveProblem((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      return prevMembers;
    });
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      childHaveProblem: false,
    }));
  };

  const handleAllergyOfWhichFood = (event) => {
    const { value, type, checked } = event.target;
    setAllergyOfWhichFood((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      allergyOfWhichFood: false,
    }));
  };

  const handleChildNumberOfMeal = (event) => {
    const value = event.target.value;
    setChildNumberOfMeals(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      childNumberOfMeals: false,
    }));
  };

  const handleAllergyOfFood = (event) => {
    const value = event.target.value === "true";
    setAllergyOfFood(value);
    if (allergyOfFood === false) {
      setAllergyOfWhichFood("");
    }
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      allergyOfFood: false,
    }));
  };

  const handleBesideSomethingtoEat = (event) => {
    const value = event.target.value;
    setBesideSomethingtoEat(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      besideSomethingtoEat: false,
    }));
  };

  const handleOfferFoodasReward = (event) => {
    const value = event.target.value === "true";
    setOfferFoodasReward(value);
    if (offerFoodasReward === false) {
      setwhichFoodAsReward("");
    }
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      offerFoodasReward: false,
    }));
  };

  const handleWhichFoodAsReward = (event) => {
    const value = event.target.value;
    setwhichFoodAsReward(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      whichFoodAsReward: false,
    }));
  };

  const handleDoYouRequireToEatCertain = (event) => {
    const value = event.target.value === "true";
    setDoYouRequireToEatCertain(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      doYouRequireToEatCertain: false,
    }));
  };

  const handlegetAnyConcernGettingToEat = (event) => {
    const { value, type, checked } = event.target;
    setGetAnyConcernGettingToEat((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      getAnyConcernGettingToEat: false,
    }));
  };

  const handleBesideUsuallyEat = (event) => {
    const value = event.target.value;
    setBesideUsuallyEat(value);
    setFieldErrors3((prevErrors) => ({
      ...prevErrors,
      besideUsuallyEat: false,
    }));
  };

  const handleEatMeatChickenFish = (event) => {
    const value = event.target.value;
    setEatMeatChickenFish(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatMeatChickenFish: false,
    }));
  };

  const handleEatGrains = (event) => {
    const value = event.target.value;
    setEatGrains(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatGrains: false,
    }));
  };

  const handleEatFruits = (event) => {
    const value = event.target.value;
    setEatFruits(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatFruits: false,
    }));
  };

  const handleEatEggs = (event) => {
    const value = event.target.value;
    setEatEggs(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatEggs: false,
    }));
  };

  const handleEatVegetable = (event) => {
    const value = event.target.value;
    setEatVegetable(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatVegetables: false,
    }));
  };

  const handleEatPeanutButter = (event) => {
    const value = event.target.value;
    setEatPeanutButter(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatPeanutButter: false,
    }));
  };

  const handleEatCheese = (event) => {
    const value = event.target.value;
    setEatCheese(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatCheese: false,
    }));
  };

  const handleEatSnacksTimesInDays = (event) => {
    const value = event.target.value;
    setEatSnacksTimesInDay(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatSnacksTimesInDay: false,
    }));
  };

  const handleEatForSnaks = (event) => {
    const { value, type, checked } = event.target;
    setEatForSnacks((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatForSnacks: false,
    }));
  };

  const handleEatFastFoodTime = (event) => {
    const { value, type, checked } = event.target;
    setEatFastFoodTime((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "Never") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "Never" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "Never"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }
      return prevMembers;
    });
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatFastFoodTime: false,
    }));
  };

  const handleDoesYourChildEatAnyFood16A = (event) => {
    const { value, type, checked } = event.target;
    setDoesYourChildEatAnyFood16A((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      doesYourChildEatAnyFood16A: false,
    }));
  };

  const handleDoesYourChildEatAnyFood16B = (event) => {
    const { value, type, checked } = event.target;
    setDoesYourChildEatAnyFood16B((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      doesYourChildEatAnyFood16B: false,
    }));
  };

  const handleAnyOneSmokeInsideHome = (event) => {
    const value = event.target.value === "true";
    setAnyOneSmokeInsideHome(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      anyOneSmokeInsideHome: false,
    }));
  };

  const handleComparedWithOtherChild = (event) => {
    const value = event.target.value;
    setComparedWithOtherChild(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      comparedWithOtherChild: false,
    }));
  };

  const handlehowDoYouTravelToSchool = (event) => {
    const value = event.target.value;
    setHowDoYouTravelToSchool(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      howDoYouTravelToSchool: false,
    }));
  };

  const handlewhoDoYouTravelWithSchool = (event) => {
    const value = event.target.value;
    setWhoDoYouTravelWithSchool(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      whoDoYouTravelWithSchool: false,
    }));
  };

  const handledoYouGoAnywhereElseFromHome = (event) => {
    const value = event.target.value;
    setDoYouGoAnywhereElseFromHome(value);
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      doYouGoAnywhereElseFromHome: false,
    }));
  };

  const handleDoesYourChildEatAnyFood16C = (event) => {
    const { value, type, checked } = event.target;
    setDoesYourChildEatAnyFood16C((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      doesYourChildEatAnyFood16C: false,
    }));
  };

  const handleChildDiet = (event) => {
    const { value, type, checked } = event.target;
    setDescribeChildDiet((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      describeChildDiet: false,
    }));
  };

  const handleEatBeans = (event) => {
    const value = event.target.value;
    setEatBeans(value);
    setFieldErrors4((prevErrors) => ({
      ...prevErrors,
      eatBeans: false,
    }));
  };

  const handleincludedInTheMealsChange2 = (event) => {
    const other = event.target.value;
    setotherMealsValue(other);
  };

  const handleInputBlur = () => {
    const trimmedValue = otherMealsValue.trim();
    if (trimmedValue !== "" && !includedInTheMeals.includes(trimmedValue)) {
      setincludedInTheMeals([
        ...includedInTheMeals,
        "otherValue:" + trimmedValue,
      ]);
    }
  };

  const handleInputBlurDiet = () => {
    const trimmedValue = otherdietValue.trim();
    if (trimmedValue !== "" && !childsDiet.includes(trimmedValue)) {
      setChildsDiet(["otherValue:" + trimmedValue]);
    }
  };

  const handleInputBlurMultivitamin = () => {
    const trimmedValue = otherMultivitaminValue.trim();
    if (trimmedValue !== "" && !multivitaminsDiet.includes(trimmedValue)) {
      setmultivitaminsDiet(["otherValue:" + trimmedValue]);
    }
  };

  const handleInputBlurKindOfMilk = () => {
    const trimmedValue = otherKindOfMilk.trim();
    if (trimmedValue !== "" && !kindOfMilkChildDrink.includes(trimmedValue)) {
      setKindMilkChildDrink(["otherValue:" + trimmedValue]);
    }
  };

  const handleincludedInTheMealsChange = (event) => {
    const { value, type, checked } = event.target;
    setincludedInTheMeals((prevMembers) => {
      const membersArray = prevMembers ? prevMembers.split(";") : [];

      if (type === "checkbox") {
        if (value === "None") {
          // If "None" is checked, clear all other selections and keep only "None"
          return checked ? "None" : "";
        }

        // If any other checkbox is checked while "None" is selected, remove "None"
        const filteredArray = membersArray.filter(
          (member) => member !== "None"
        );

        if (checked) {
          return [...filteredArray, value].join(";");
        } else {
          return filteredArray.filter((member) => member !== value).join(";");
        }
      }

      // Handle "Other" input field
      if (type === "text" && value.startsWith("Other:")) {
        const filteredArray = membersArray.filter(
          (member) => !member.startsWith("Other")
        );
        return [...filteredArray, value].join(";");
      }

      return prevMembers;
    });
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      includedInTheMeals: false,
    }));
  };
  function onBlurHeight(event) {
    const newHeight = event.target.value;
    if (isNaN(newHeight) || newHeight < 0 || newHeight.length > 3) {
      setErrorHeight("Please enter valid Height (not more then 3 number)");
    } else {
      setHeight(newHeight);
      setErrorHeight("");
    }
  }
  function onBlurWeight(event) {
    const newWeight = event.target.value;
    if (isNaN(newWeight) || newWeight < 0 || newWeight.length > 3) {
      setErrorWeight("Please enter valid Weight (not more then 3 number)");
    } else {
      setWeight(newWeight);
      setErrorWeight("");
    }
  }
  const handleincludedInTheDietChange = (event) => {
    const value = event.target.value;

    // Toggle the selected family member
    setincludedInTheDiet((prevMembers) => {
      if (value === "None Of the Above") {
        return prevMembers.includes(value) ? [] : [value];
      } else {
        const updatedMembers = prevMembers.includes(value)
          ? prevMembers.filter((member) => member !== value)
          : [...prevMembers, value];
        return updatedMembers.filter(
          (member) => member !== "None Of the Above"
        );
      }
    });
    setFieldErrors5((prevErrors) => ({
      ...prevErrors,
      includedInTheDiet: false,
    }));
  };

  const handleExcersieQuestions = (event) => {
    const value = event.target.value;
    setExercise(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      exercise: false, // Set the error to false for the selected field
    }));
  };

  const handleExcersieQuestions2 = (event) => {
    const value = event.target.value;
    setExercise2(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      exercise2: false, // Set the error to false for the selected field
    }));
  };

  const handleExcersieQuestions3 = (event) => {
    const value = event.target.value;
    setExercise3(value);
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      exercise3: false, // Set the error to false for the selected field
    }));
  };

  const handlePhysicalActivity = (event) => {
    const { name, value } = event.target;

    setYouStoppedDoingPhysicalActivity((prevExercise) => ({
      ...prevExercise,
      [name]: value,
    }));
    setFieldErrors6((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleAgreeStatement = (event) => {
    const { name, value } = event.target;
    setAgreeStatement((prev) => ({ ...prev, [name]: value }));

    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };
  const handleDoYouFeelDoingPhysicalActOrSportWill = (event) => {
    const { name, value } = event.target;
    setDoYouFeelDoingPhysicalActOrSportWill((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };
  const handleDuringTypicalWeek = (event) => {
    const { name, value } = event.target;
    setDuringTypicalWeek((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setFieldErrors7((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleAgreeStatementSecond = (event) => {
    const { name, value } = event.target;
    setAgreeStatementSecond((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleDuringTypicalWeekSomeoneInFamily = (event) => {
    const { name, value } = event.target;
    setDuringTypicalWeekSomeoneInFamily((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFieldErrors8((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleJourneyToTheSchool = (event) => {
    const { name, value } = event.target;
    setJourneyToAndFromSchool((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFieldErrors9((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };
  const handlehowDoYouTravelTo = (event) => {
    const { name, value } = event.target;
    setHowDoYouTravel((prevState) => ({
      ...prevState,
      [name]: value, // Use the 'name' attribute to update the corresponding key dynamically
    }));

    setFieldErrors9((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleWhatDoYouDoInSchool = (event) => {
    const { name, value } = event.target;
    setWhatDoYouDoInSchool((prevState) => ({
      ...prevState,
      [name]: value, // Use the 'name' attribute to update the correct key
    }));
    setFieldErrors9((prevErrors) => ({
      ...prevErrors,
      [name]: false, // Set the error to false for the selected field
    }));
  };

  const handleOtherValueChange2 = (event) => {
    setOtherdietValue(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childsDiet: false,
    }));
  };

  const handleOtherMultiVitaminValueChange = (event) => {
    setOtherMultivitaminValue(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childsDiet: false,
    }));
  };

  const handleOtherOtherKindOfMilkValueChange = (event) => {
    setOtherKindOfMilk(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      childsDiet: false,
    }));
  };

  const calculateBmi = (height, weight) => {
    if (!height || !weight) return "";
    const bmi = weight / ((height / 100) * (height / 100));
    return parseFloat(bmi.toFixed(1));
  };
  const bmiValue = calculateBmi(height, weight);
  const handleSave1 = (e) => {
    e.preventDefault();
    let error1 = {};
    if (medicalProblem == null) {
      error1.medicalProblem = true;
    }
    if (medicalProblem === true) {
      if (listMedicalProblems == "" || listMedicalProblems == null) {
        error1.listMedicalProblems = true;
      }
    }
    if (dentailProblem == null) {
      error1.dentailProblem = true;
    }
    if (childTakeMedicine == null) {
      error1.childTakeMedicine = true;
    }
    if (childTakeMedicine === true) {
      if (childMedicineList == "" || childMedicineList == null) {
        error1.childMedicineList = true;
      }
    }
    if (specialDiet == null) {
      error1.specialDiet = true;
    }
    if (specialDiet === true) {
      if (specialDietList == "" || specialDietList == null) {
        error1.specialDietList = true;
      }
    }
    if (childsDiet == "" || childsDiet.length === 0) {
      error1.childsDiet = true;
    }
    if (multivitaminsDiet == "" || multivitaminsDiet.length === 0) {
      error1.multivitaminsDiet = true;
    }
    if (milkDrinkEachDay == "") {
      error1.milkDrinkEachDay = true;
    }
    if (kindOfMilkChildDrink == "" || kindOfMilkChildDrink.length === 0) {
      error1.kindOfMilkChildDrink = true;
    }
    setFieldErrors(error1);
    if (Object.keys(error1).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };
  const handleSave2 = (e) => {
    e.preventDefault();
    let errors2 = {};
    if (beverages == "" || beverages.length === 0) {
      errors2.beverages = true;
    }
    if (waterToBeverages == null) {
      errors2.waterToBeverages = true;
    }
    if (childDrinkPlainWater == null) {
      errors2.childDrinkPlainWater = true;
    }
    if (childDrinkPlainWater === true) {
      if (
        howMuchDrinkEachDay == undefined ||
        howMuchDrinkEachDay == null ||
        howMuchDrinkEachDay == ""
      ) {
        errors2.howMuchDrinkEachDay = true;
      }
    }
    setFieldErrors2(errors2);
    if (Object.keys(errors2).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave3 = (e) => {
    e.preventDefault();
    let errors3 = {};
    if (childHaveProblem == "" || childHaveProblem.length === 0) {
      errors3.childHaveProblem = true;
    }
    if (allergyOfFood == null) {
      errors3.allergyOfFood = true;
    }
    if (allergyOfFood === true) {
      if (
        allergyOfWhichFood == "" ||
        allergyOfWhichFood == null ||
        allergyOfWhichFood == undefined
      ) {
        errors3.allergyOfWhichFood = true;
      }
    }

    if (childNumberOfMeals == "") {
      errors3.childNumberOfMeals = true;
    }
    if (besideSomethingtoEat == "") {
      errors3.besideSomethingtoEat = true;
    }
    if (offerFoodasReward == null) {
      errors3.offerFoodasReward = true;
    }
    if (offerFoodasReward === true) {
      if (
        whichFoodAsReward == "" ||
        whichFoodAsReward == null ||
        whichFoodAsReward == undefined
      ) {
        errors3.whichFoodAsReward = true;
      }
    }
    if (doYouRequireToEatCertain == null) {
      errors3.doYouRequireToEatCertain = true;
    }
    if (
      getAnyConcernGettingToEat == "" ||
      getAnyConcernGettingToEat.length === 0
    ) {
      errors3.getAnyConcernGettingToEat = true;
    }
    if (besideUsuallyEat == "") {
      errors3.besideUsuallyEat = true;
    }
    setFieldErrors3(errors3);
    if (Object.keys(errors3).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave4 = (e) => {
    e.preventDefault();
    let error4 = {};
    if (eatMeatChickenFish == "") {
      error4.eatMeatChickenFish = true;
    }
    if (eatGrains == "") {
      error4.eatGrains = true;
    }
    if (eatFruits == "") {
      error4.eatFruits = true;
    }
    if (eatEggs == "") {
      error4.eatEggs = true;
    }
    if (eatVegetables == "") {
      error4.eatVegetables = true;
    }
    if (eatPeanutButter == "") {
      error4.eatPeanutButter = true;
    }
    if (eatCheese == "") {
      error4.eatCheese = true;
    }
    if (eatBeans == "") {
      error4.eatBeans = true;
    }
    if (eatSnacksTimesInDay == "") {
      error4.eatSnacksTimesInDay = true;
    }
    if (eatForSnacks == "" || eatForSnacks.length === 0) {
      error4.eatForSnacks = true;
    }
    if (eatFastFoodTime == "" || eatFastFoodTime.length === 0) {
      error4.eatFastFoodTime = true;
    }

    if (
      doesYourChildEatAnyFood16A == "" ||
      doesYourChildEatAnyFood16A.length === 0
    ) {
      error4.doesYourChildEatAnyFood16A = true;
    }
    if (
      doesYourChildEatAnyFood16B == "" ||
      doesYourChildEatAnyFood16B.length === 0
    ) {
      error4.doesYourChildEatAnyFood16B = true;
    }
    if (anyOneSmokeInsideHome == null) {
      error4.anyOneSmokeInsideHome = true;
    }
    if (
      doesYourChildEatAnyFood16C == "" ||
      doesYourChildEatAnyFood16C.length === 0
    ) {
      error4.doesYourChildEatAnyFood16C = true;
    }

    setFieldErrors4(error4);
    if (Object.keys(error4).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave5 = (e) => {
    e.preventDefault();
    let error5 = {};
    if (numberOfMeals == "") {
      error5.numberOfMeals = true;
    }
    if (includedInTheMeals == "" || includedInTheMeals.length === 0) {
      error5.includedInTheMeals = true;
    }
    if (describeChildDiet == "" || describeChildDiet.length === 0) {
      error5.describeChildDiet = true;
    }
    if (
      doYouHaveBreakfastRegularly == "" ||
      doYouHaveBreakfastRegularly == null
    ) {
      error5.doYouHaveBreakfastRegularly = true;
    }
    if (appetite == "") {
      error5.appetite = true;
    }
    if (timeOfDinner == "" || timeOfDinner == null) {
      error5.timeOfDinner = true;
    }
    if (numberOfSnacks == "") {
      error5.numberOfSnacks = true;
    }
    if (height == null) {
      error5.height = true;
    }
    if (weight == null) {
      error5.weight = true;
    }
    if (receivedDietCounselling == "") {
      error5.receivedDietCounselling = true;
    }
    if (preferLowCarbDiet == "") {
      error5.preferLowCarbDiet = true;
    }
    if (preferVegetarianFood == "") {
      error5.preferVegetarianFood = true;
    }
    if (avoidProcessedMeat == "") {
      error5.avoidProcessedMeat = true;
    }
    if (avoidHighlyProcessedFoods == "" || avoidHighlyProcessedFoods == null) {
      error5.avoidHighlyProcessedFoods = true;
    }
    if (doNotDrinkSugarSweetenedBeverages == "") {
      error5.doNotDrinkSugarSweetenedBeverages = true;
    }
    if (regularlyConsumeWholeGrainsLegumes == "") {
      error5.regularlyConsumeWholeGrainsLegumes = true;
    }
    if (drinkCoffee == "") {
      error5.drinkCoffee = true;
    }
    if (limitIntakeOfDairyProducts == "") {
      error5.limitIntakeOfDairyProducts = true;
    }
    if (eatDietWhichIsLowInAddedSugars == "") {
      error5.eatDietWhichIsLowInAddedSugars = true;
    }
    if (limitOrDecreaseSaltInMyMeals == "") {
      error5.limitOrDecreaseSaltInMyMeals = true;
    }
    if (highInFiber == "") {
      error5.highInFiber = true;
    }
    if (limitOrDecreaseSugarInMyDiet == "") {
      error5.limitOrDecreaseSugarInMyDiet = true;
    }
    if (selectDiet == "") {
      error5.selectDiet = true;
    }
    if (selectDiet == "Strictly vegetarian (Eggs not included)") {
      if (includedInTheDiet == "" || includedInTheDiet == null) {
        error5.includedInTheDiet = true;
      }
      if (includedInTheDiet.includes("Yogurt")) {
        if (oftenEatYogurt == "" || oftenEatYogurt == null) {
          error5.oftenEatYogurt = true;
        }
      }
      if (includedInTheDiet.includes("Low fat milk")) {
        if (oftenEatLowFatMilk == "" || oftenEatLowFatMilk == null) {
          error5.oftenEatLowFatMilk = true;
        }
      }
      if (includedInTheDiet.includes("Cheese")) {
        if (oftenEatCheese == "" || oftenEatCheese == null) {
          error5.oftenEatCheese = true;
        }
      }
      if (includedInTheDiet.includes("Fortified cereals")) {
        if (
          oftenEatFortifiedCereals == "" ||
          oftenEatFortifiedCereals == null
        ) {
          error5.oftenEatFortifiedCereals = true;
        }
      }
    }

    if (selectDiet?.includes("Eggetarian (veg + eggs)")) {
      if (oftenEatEggs == "" || oftenEatEggs == null) {
        error5.oftenEatEggs = true;
      }
    }

    if (selectDiet?.includes("Mixed (includes veg, meat and poultry)")) {
      if (oftenEatMeat == "" || oftenEatMeat == null) {
        error5.oftenEatMeat = true;
      }
      if (mixedIncludedInYourDiet == "" || mixedIncludedInYourDiet == null) {
        error5.mixedIncludedInYourDiet = true;
      }
    }
    if (consultWithDietitian == null) {
      error5.consultWithDietitian = true;
    }
    setFieldErrors5(error5);
    if (Object.keys(error5).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,

        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave6 = (e) => {
    e.preventDefault();
    let error6 = {};

    if (exercise == null || exercise == "") {
      error6.exercise = true;
    }

    if (exercise2 == null || exercise2 == "") {
      error6.exercise2 = true;
    }

    if (exercise3 == null || exercise3 == "") {
      error6.exercise3 = true;
    }

    if (!youStoppedDoingPhysicalActivity.physicalActivity4A) {
      error6.physicalActivity4A = true;
    }
    if (!youStoppedDoingPhysicalActivity.physicalActivity4B) {
      error6.physicalActivity4B = true;
    }
    if (!youStoppedDoingPhysicalActivity.physicalActivity4C) {
      error6.physicalActivity4C = true;
    }
    if (!youStoppedDoingPhysicalActivity.physicalActivity4D) {
      error6.physicalActivity4D = true;
    }
    if (!youStoppedDoingPhysicalActivity.physicalActivity4E) {
      error6.physicalActivity4E = true;
    }
    if (!youStoppedDoingPhysicalActivity.physicalActivity4F) {
      error6.physicalActivity4F = true;
    }
    setFieldErrors6(error6);
    if (Object.keys(error6).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave7 = (e) => {
    e.preventDefault();
    let error7 = {};
    if (!agreeStatement.agreeStatement5A) {
      error7.agreeStatement5A = true;
    }
    if (!agreeStatement.agreeStatement5B) {
      error7.agreeStatement5B = true;
    }
    if (!agreeStatement.agreeStatement5C) {
      error7.agreeStatement5C = true;
    }
    if (!agreeStatement.agreeStatement5D) {
      error7.agreeStatement5D = true;
    }
    if (!agreeStatement.agreeStatement5E) {
      error7.agreeStatement5E = true;
    }
    if (!agreeStatement.agreeStatement5F) {
      error7.agreeStatement5F = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6A
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6A = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6B
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6B = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6C
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6C = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6D
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6D = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6E
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6E = true;
    }

    if (
      !doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6F
    ) {
      error7.doYouFeelDoingPhysicalActOrSportWill6F = true;
    }

    if (!duringTypicalWeek.duringTypicalWeek7A) {
      error7.duringTypicalWeek7A = true;
    }
    if (!duringTypicalWeek.duringTypicalWeek7B) {
      error7.duringTypicalWeek7B = true;
    }
    if (!duringTypicalWeek.duringTypicalWeek7C) {
      error7.duringTypicalWeek7C = true;
    }
    if (!duringTypicalWeek.duringTypicalWeek7D) {
      error7.duringTypicalWeek7D = true;
    }
    if (!duringTypicalWeek.duringTypicalWeek7E) {
      error7.duringTypicalWeek7E = true;
    }
    if (!duringTypicalWeek.duringTypicalWeek7F) {
      error7.duringTypicalWeek7F = true;
    }

    setFieldErrors7(error7);
    if (Object.keys(error7).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };
  const handleSave8 = (e) => {
    e.preventDefault();
    let errors8 = {};

    // Check each field for an empty value
    if (agreeStatementSecond.agreeStatementSecond8A === "") {
      errors8.agreeStatementSecond8A = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8B === "") {
      errors8.agreeStatementSecond8B = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8C === "") {
      errors8.agreeStatementSecond8C = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8D === "") {
      errors8.agreeStatementSecond8D = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8E === "") {
      errors8.agreeStatementSecond8E = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8F === "") {
      errors8.agreeStatementSecond8F = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8G === "") {
      errors8.agreeStatementSecond8G = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8H === "") {
      errors8.agreeStatementSecond8H = true;
    }
    if (agreeStatementSecond.agreeStatementSecond8I === "") {
      errors8.agreeStatementSecond8I = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9A === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9A = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9B === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9B = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9C === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9C = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9D === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9D = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9E === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9E = true;
    }
    if (
      duringTypicalWeekSomeoneInFamily.duringTypicalWeekSomeoneInFamily9F === ""
    ) {
      errors8.duringTypicalWeekSomeoneInFamily9F = true;
    }

    if (comparedWithOtherChild == "" || comparedWithOtherChild == null) {
      errors8.comparedWithOtherChild = true;
    }

    if (howDoYouTravelToSchool == "" || howDoYouTravelToSchool == null) {
      errors8.howDoYouTravelToSchool = true;
    }

    if (whoDoYouTravelWithSchool == "" || whoDoYouTravelWithSchool == null) {
      errors8.whoDoYouTravelWithSchool = true;
    }

    if (
      doYouGoAnywhereElseFromHome == "" ||
      doYouGoAnywhereElseFromHome == null
    ) {
      errors8.doYouGoAnywhereElseFromHome = true;
    }

    setFieldErrors8(errors8);
    if (Object.keys(errors8).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };
  const handleSave9 = (e) => {
    e.preventDefault();
    let errors9 = {};

    // Check each field for an empty value
    if (journeyToAndFromSchool.journeyToAndFromSchool10A === "") {
      errors9.journeyToAndFromSchool10A = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10B === "") {
      errors9.journeyToAndFromSchool10B = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10C === "") {
      errors9.journeyToAndFromSchool10C = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10D === "") {
      errors9.journeyToAndFromSchool10D = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10E === "") {
      errors9.journeyToAndFromSchool10E = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10F === "") {
      errors9.journeyToAndFromSchool10F = true;
    }
    if (journeyToAndFromSchool.journeyToAndFromSchool10G === "") {
      errors9.journeyToAndFromSchool10G = true;
    }

    if (!howDoYouTravel.howDoYouTravel11A) errors9.howDoYouTravel11A = true;
    if (!howDoYouTravel.howDoYouTravel11B) errors9.howDoYouTravel11B = true;
    if (!howDoYouTravel.howDoYouTravel11C) errors9.howDoYouTravel11C = true;
    if (!howDoYouTravel.howDoYouTravel11D) errors9.howDoYouTravel11D = true;

    if (!whatDoYouDoInSchool.whatDoYouDoInSchool12A) {
      errors9.whatDoYouDoInSchool12A = true;
    }
    if (!whatDoYouDoInSchool.whatDoYouDoInSchool12B) {
      errors9.whatDoYouDoInSchool12B = true;
    }

    // Update the fieldErrors state
    setFieldErrors9(errors9);
    if (Object.keys(errors9).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave10 = (e) => {
    e.preventDefault();
    const formData = {
      medicalProblem: medicalProblem,
      describeMedicalProblem: listMedicalProblems,
      dentalProblem: dentailProblem,
      takeMedicine: childTakeMedicine,
      takeMedicineList: childMedicineList,
      spacialDiet: specialDiet,
      spacialDietList: specialDietList,
      dietLimit: childsDiet,
      dietLimitReason: dietLimitReason,
      takeVitamins: multivitaminsDiet,
      milkDrink: milkDrinkEachDay,
      whichMilkDrink: kindOfMilkChildDrink,
      drinkBeverages: beverages,
      drinkBeveragesAddWater: waterToBeverages,
      drinkPlainWater: childDrinkPlainWater,
      drinkPlainWaterHowMuch: howMuchDrinkEachDay,
      problemWithFood: childHaveProblem,
      whichAllergicFoods: allergyOfWhichFood,
      eatHowManyMealsEachDay: childNumberOfMeals,
      eatBesideMealTime: besideSomethingtoEat,
      doYouOfferBesideFood: offerFoodasReward,
      whichBesideFoodDoYouOffer: whichFoodAsReward,
      doYouRequireToEatCertain: doYouRequireToEatCertain,
      getAnyConcernGettingToEat: getAnyConcernGettingToEat,
      besideUsuallyEat: besideUsuallyEat,
      eatMeatChickenFish: eatMeatChickenFish,
      eatGrains: eatGrains,
      eatFruits: eatFruits,
      eatEggs: eatEggs,
      eatVegetables: eatVegetables,
      eatPeanutButter: eatPeanutButter,
      eatCheese: eatCheese,
      eatBeans: eatBeans,
      eatSnacksTimesInDay: eatSnacksTimesInDay,
      eatForSnacks: eatForSnacks,
      eatFastFoodTime: eatFastFoodTime,
      playingGameUsingPhoneHours: playingGameUsingPhoneHours,
      doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
      doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
      anyOneSmokeInsideHome: anyOneSmokeInsideHome,
      doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
      numberOfMealsPerDay: numberOfMeals,
      includedMeals: includedInTheMeals,
      yourChildDiet: describeChildDiet,
      breakfastRegularly: doYouHaveBreakfastRegularly,
      appetite: appetite,
      dinnerTime: timeOfDinner,
      numberOfSnackPerDay: numberOfSnacks,
      currentHeight: parseInt(height, 10) || 0,
      currentWeight: parseInt(weight, 10) || 0,
      bmi: parseInt(bmiValue, 10) || 0,
      concernDiet: receivedDietCounselling,
      preferLowCarbDiet: preferLowCarbDiet,
      preferVegetarianFood: preferVegetarianFood,
      avoidProcessedMeat: avoidProcessedMeat,
      avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
      doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
      regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
      drinkCoffee: drinkCoffee,
      limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
      eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
      limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
      highInFiber: highInFiber,
      limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
      termOfChildDiet: selectDiet,
      itemsIncludeInDiet: includedInTheDiet
        ? includedInTheDiet.join(";")
        : includedInTheDiet,
      oftenEatEggs: oftenEatEggs,
      oftenEatMeat: oftenEatMeat,
      mixedIncludedInYourDiet: mixedIncludedInYourDiet
        ? mixedIncludedInYourDiet.join(";")
        : mixedIncludedInYourDiet,
      oftenEatYogurt: oftenEatYogurt,
      oftenEatLowFatMilk: oftenEatLowFatMilk,
      oftenEatCheese: oftenEatCheese,
      oftenEatFortifiedCereals: oftenEatFortifiedCereals,
      questionsForChildDiet: qesAboutNutrition,
      consultDietitianInNetwork: consultWithDietitian,
      afterSchoolWouldPreferTo: exercise,
      watchingTvIs: exercise2,
      walkingToGetSomeWhere: exercise3,
      youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
      agreeStatement: agreeStatement,
      doYouFeelDoingPhysicalActOrSportWill:
        doYouFeelDoingPhysicalActOrSportWill,
      duringTypicalWeek: duringTypicalWeek,
      duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
      agreeStatementSecond: agreeStatementSecond,
      comparedWithOtherChild: comparedWithOtherChild,
      howDoYouTravelToSchool: howDoYouTravelToSchool,
      whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
      doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
      journeyToAndFromSchool: journeyToAndFromSchool,
      howDoYouTravel: howDoYouTravel,
      whatDoYouDoInSchool: whatDoYouDoInSchool,
      moderateIntensity: exerciseData,
      vigorousIntensity: vigorousexerciseData,
      mildIntensity: mildexerciseData,
      lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
      activityUndertakeRegularly: selectedActivity,
      exerciseLastWeekPerDay: hoursOfExercise,
      sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
      totalHourSleep: hoursOfSleep,
      sleepTime: sleepAroundSameTime,
      satisfiedWithSleep: satisfiedWithSleep,
      difficultyWithSleep: difficultyWithSleep,
      wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
      allergicFood: allergyOfFood,
    };
    AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    setLifestyleStep((prev) => prev + 1);
  };

  const handleSave11 = (e) => {
    e.preventDefault();
    let errors11 = {};

    if (
      comparisonLastThreeMonthsToSevenDays == "" ||
      comparisonLastThreeMonthsToSevenDays == null
    ) {
      errors11.comparisonLastThreeMonthsToSevenDays = true;
    }

    if (selectedActivity == "" || selectedActivity == null) {
      errors11.selectedActivity = true;
    }

    if (hoursOfExercise == "" || hoursOfExercise == null) {
      errors11.hoursOfExercise = true;
    }

    if (totalHoursOfSitting == "" || totalHoursOfSitting == null) {
      errors11.totalHoursOfSitting = true;
    }

    setFieldErrors11(errors11);
    if (Object.keys(errors11).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
      setLifestyleStep((prev) => prev + 1);
    }
  };

  const handleSave12 = (e) => {
    e.preventDefault();
    let errors12 = {};

    if (hoursOfSleep == "" || hoursOfSleep == null) {
      errors12.totalHourSleep = true;
    }

    if (sleepAroundSameTime == "" || sleepAroundSameTime == null) {
      errors12.sleepAroundSameTime = true;
    }

    if (satisfiedWithSleep == "" || satisfiedWithSleep == null) {
      errors12.satisfiedWithSleep = true;
    }

    if (difficultyWithSleep == "" || difficultyWithSleep == null) {
      errors12.difficultyWithSleep = true;
    }

    if (feelRefreshedAfterSleep == "" || feelRefreshedAfterSleep == null) {
      errors12.feelRefreshedAfterSleep = true;
    }

    setFieldErrors12(errors12);
    if (Object.keys(errors12).length > 0) {
      Swal.fire({
        icon: "error",
        text: "Please fill in all mandatory fields.",
      });
    } else {
      const formData = {
        medicalProblem: medicalProblem,
        describeMedicalProblem: listMedicalProblems,
        dentalProblem: dentailProblem,
        takeMedicine: childTakeMedicine,
        takeMedicineList: childMedicineList,
        spacialDiet: specialDiet,
        spacialDietList: specialDietList,
        dietLimit: childsDiet,
        dietLimitReason: dietLimitReason,
        takeVitamins: multivitaminsDiet,
        milkDrink: milkDrinkEachDay,
        whichMilkDrink: kindOfMilkChildDrink,
        drinkBeverages: beverages,
        drinkBeveragesAddWater: waterToBeverages,
        drinkPlainWater: childDrinkPlainWater,
        drinkPlainWaterHowMuch: howMuchDrinkEachDay,
        problemWithFood: childHaveProblem,
        whichAllergicFoods: allergyOfWhichFood,
        eatHowManyMealsEachDay: childNumberOfMeals,
        eatBesideMealTime: besideSomethingtoEat,
        doYouOfferBesideFood: offerFoodasReward,
        whichBesideFoodDoYouOffer: whichFoodAsReward,
        doYouRequireToEatCertain: doYouRequireToEatCertain,
        getAnyConcernGettingToEat: getAnyConcernGettingToEat,
        besideUsuallyEat: besideUsuallyEat,
        eatMeatChickenFish: eatMeatChickenFish,
        eatGrains: eatGrains,
        eatFruits: eatFruits,
        eatEggs: eatEggs,
        eatVegetables: eatVegetables,
        eatPeanutButter: eatPeanutButter,
        eatCheese: eatCheese,
        eatBeans: eatBeans,
        eatSnacksTimesInDay: eatSnacksTimesInDay,
        eatForSnacks: eatForSnacks,
        eatFastFoodTime: eatFastFoodTime,
        playingGameUsingPhoneHours: playingGameUsingPhoneHours,
        doesYourChildEatAnyFood16A: doesYourChildEatAnyFood16A,
        doesYourChildEatAnyFood16B: doesYourChildEatAnyFood16B,
        anyOneSmokeInsideHome: anyOneSmokeInsideHome,
        doesYourChildEatAnyFood16C: doesYourChildEatAnyFood16C,
        numberOfMealsPerDay: numberOfMeals,
        includedMeals: includedInTheMeals,
        yourChildDiet: describeChildDiet,
        breakfastRegularly: doYouHaveBreakfastRegularly,
        appetite: appetite,
        dinnerTime: timeOfDinner,
        numberOfSnackPerDay: numberOfSnacks,
        currentHeight: parseInt(height, 10) || 0,
        currentWeight: parseInt(weight, 10) || 0,
        bmi: parseInt(bmiValue, 10) || 0,
        concernDiet: receivedDietCounselling,
        preferLowCarbDiet: preferLowCarbDiet,
        preferVegetarianFood: preferVegetarianFood,
        avoidProcessedMeat: avoidProcessedMeat,
        avoidHighlyProcessedFoods: avoidHighlyProcessedFoods,
        doNotDrinkSugarSweetenedBeverage: doNotDrinkSugarSweetenedBeverages,
        regularlyConsumeWholeGrainsLegumes: regularlyConsumeWholeGrainsLegumes,
        drinkCoffee: drinkCoffee,
        limitIntakeOfDairyProducts: limitIntakeOfDairyProducts,
        eatDietWhichIsLowInAddedSugars: eatDietWhichIsLowInAddedSugars,
        limitOrDecreaseSaltInMyMeals: limitOrDecreaseSaltInMyMeals,
        highInFiber: highInFiber,
        limitOrDecreaseSugarInMyDiet: limitOrDecreaseSugarInMyDiet,
        termOfChildDiet: selectDiet,
        itemsIncludeInDiet: includedInTheDiet
          ? includedInTheDiet.join(";")
          : includedInTheDiet,
        oftenEatEggs: oftenEatEggs,
        oftenEatMeat: oftenEatMeat,
        mixedIncludedInYourDiet: mixedIncludedInYourDiet
          ? mixedIncludedInYourDiet.join(";")
          : mixedIncludedInYourDiet,
        oftenEatYogurt: oftenEatYogurt,
        oftenEatLowFatMilk: oftenEatLowFatMilk,
        oftenEatCheese: oftenEatCheese,
        oftenEatFortifiedCereals: oftenEatFortifiedCereals,
        questionsForChildDiet: qesAboutNutrition,
        consultDietitianInNetwork: consultWithDietitian,
        afterSchoolWouldPreferTo: exercise,
        watchingTvIs: exercise2,
        walkingToGetSomeWhere: exercise3,
        youStoppedDoingPhysicalActivity: youStoppedDoingPhysicalActivity,
        agreeStatement: agreeStatement,
        doYouFeelDoingPhysicalActOrSportWill:
          doYouFeelDoingPhysicalActOrSportWill,
        duringTypicalWeek: duringTypicalWeek,
        duringTypicalWeekSomeoneInFamily: duringTypicalWeekSomeoneInFamily,
        agreeStatementSecond: agreeStatementSecond,
        comparedWithOtherChild: comparedWithOtherChild,
        howDoYouTravelToSchool: howDoYouTravelToSchool,
        whoDoYouTravelWithSchool: whoDoYouTravelWithSchool,
        doYouGoAnywhereElseFromHome: doYouGoAnywhereElseFromHome,
        journeyToAndFromSchool: journeyToAndFromSchool,
        howDoYouTravel: howDoYouTravel,
        whatDoYouDoInSchool: whatDoYouDoInSchool,
        moderateIntensity: exerciseData,
        vigorousIntensity: vigorousexerciseData,
        mildIntensity: mildexerciseData,
        lastWeekActiveStatus: comparisonLastThreeMonthsToSevenDays,
        activityUndertakeRegularly: selectedActivity,
        exerciseLastWeekPerDay: hoursOfExercise,
        sedentaryExerciseTotalHourPerDay: totalHoursOfSitting,
        totalHourSleep: hoursOfSleep,
        sleepTime: sleepAroundSameTime,
        satisfiedWithSleep: satisfiedWithSleep,
        difficultyWithSleep: difficultyWithSleep,
        wackUpRefreshedAfterSleep: feelRefreshedAfterSleep,
        allergicFood: allergyOfFood,
        lifestyleSketchCompleteFlag: true,
      };
      AxiosInstance.post("form/addLifestyleSketchDietForChildren", formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Your Form Submitted Successfully!",
          });
          setTimeout(() => {
            onBack();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    AxiosInstance.get("form/lifestyleSketchDietForChildren")
      .then((response) => {
        setMedicalProblem(response?.medicalProblem);
        setListMedicalProblem(response?.describeMedicalProblem);
        setDentailProblem(response?.dentalProblem);
        setChildTakeMedicine(response?.takeMedicine);
        setChildMedicineList(response?.takeMedicineList);
        setChildsDiet(() => {
          const dietLimits = response?.dietLimit;

          if (
            dietLimits === null ||
            dietLimits === undefined ||
            dietLimits.trim() === ""
          ) {
            return childsDiet; // Return an empty array if it's null, undefined, or empty
          } else {
            return dietLimits; // Split the string by ";" and filter out any empty values
          }
        });
        setSpecialDiet(response?.spacialDiet);
        setSpecialDietList(response?.spacialDietList);
        setDietLimitReason(response?.dietLimitReason);
        setmultivitaminsDiet(() => {
          const takeVitamins = response?.takeVitamins;

          if (
            takeVitamins === null ||
            takeVitamins === undefined ||
            takeVitamins.trim() === ""
          ) {
            return multivitaminsDiet; // Return an empty array if it's null, undefined, or empty
          } else {
            return takeVitamins; // Split the string by ";" and filter out any empty values
          }
        });
        setMilkDrinkEachDay(response?.milkDrink);
        setKindMilkChildDrink(() => {
          const whichMilkDrink = response?.whichMilkDrink;

          if (
            whichMilkDrink === null ||
            whichMilkDrink === undefined ||
            whichMilkDrink.trim() === ""
          ) {
            return kindOfMilkChildDrink; // Return an empty array if it's null, undefined, or empty
          } else {
            return whichMilkDrink; // Split the string by ";" and filter out any empty values
          }
        });
        setBeverages(() => {
          const drinkBeverages = response?.drinkBeverages;

          if (
            drinkBeverages === null ||
            drinkBeverages === undefined ||
            drinkBeverages.trim() === ""
          ) {
            return beverages; // Return an empty array if it's null, undefined, or empty
          } else {
            return drinkBeverages; // Split the string by ";" and filter out any empty values
          }
        });
        setWaterToBeverages(response?.drinkBeveragesAddWater);
        setChildDrinkPlainWater(response?.drinkPlainWater);
        setHowMuchDrinkEachDay(response?.drinkPlainWaterHowMuch);
        setChildHaveProblem(() => {
          const problemWithFood = response?.problemWithFood;

          if (
            problemWithFood === null ||
            problemWithFood === undefined ||
            problemWithFood.trim() === ""
          ) {
            return childHaveProblem; // Return an empty array if it's null, undefined, or empty
          } else {
            return problemWithFood; // Split the string by ";" and filter out any empty values
          }
        });
        setAllergyOfFood(response?.allergicFood);
        setAllergyOfWhichFood(() => {
          const allergyOfWhichFoods = response?.whichAllergicFoods;

          if (
            allergyOfWhichFoods === null ||
            allergyOfWhichFoods === undefined ||
            allergyOfWhichFoods.trim() === ""
          ) {
            return allergyOfWhichFood; // Return an empty array if it's null, undefined, or empty
          } else {
            return allergyOfWhichFoods; // Split the string by ";" and filter out any empty values
          }
        });
        setChildNumberOfMeals(response?.eatHowManyMealsEachDay);
        setBesideSomethingtoEat(response?.eatBesideMealTime);
        setOfferFoodasReward(response?.doYouOfferBesideFood);
        setwhichFoodAsReward(response?.whichBesideFoodDoYouOffer);
        setDoYouRequireToEatCertain(response?.doYouRequireToEatCertain);
        setGetAnyConcernGettingToEat(() => {
          const AnyConcernGettingToEat = response?.getAnyConcernGettingToEat;

          if (
            AnyConcernGettingToEat === null ||
            AnyConcernGettingToEat === undefined ||
            AnyConcernGettingToEat.trim() === ""
          ) {
            return getAnyConcernGettingToEat; // Return an empty array if it's null, undefined, or empty
          } else {
            return AnyConcernGettingToEat; // Split the string by ";" and filter out any empty values
          }
        });
        setBesideUsuallyEat(response?.besideUsuallyEat);
        setEatMeatChickenFish(response?.eatMeatChickenFish);
        setEatGrains(response?.eatGrains);
        setEatFruits(response?.eatFruits);
        setEatEggs(response?.eatEggs);
        setEatVegetable(response?.eatVegetables);
        setEatPeanutButter(response?.eatPeanutButter);
        setEatCheese(response?.eatCheese);
        setEatBeans(response?.eatBeans);
        setEatSnacksTimesInDay(response?.eatSnacksTimesInDay);
        setEatForSnacks(() => {
          const eatForSnack = response?.eatForSnacks;

          if (
            eatForSnack === null ||
            eatForSnack === undefined ||
            eatForSnack.trim() === ""
          ) {
            return eatForSnacks; // Return an empty array if it's null, undefined, or empty
          } else {
            return eatForSnack; // Split the string by ";" and filter out any empty values
          }
        });
        setEatFastFoodTime(() => {
          const eatsFastFoodTime = response?.eatFastFoodTime;

          if (
            eatsFastFoodTime === null ||
            eatsFastFoodTime === undefined ||
            eatsFastFoodTime.trim() === ""
          ) {
            return eatFastFoodTime; // Return an empty array if it's null, undefined, or empty
          } else {
            return eatsFastFoodTime; // Split the string by ";" and filter out any empty values
          }
        });
        setPlayingGameUsingPhoneHours(response?.playingGameUsingPhoneHours);
        setDoesYourChildEatAnyFood16A(() => {
          const doesYourChildEatsAnyFood16A =
            response?.doesYourChildEatAnyFood16A;

          if (
            doesYourChildEatsAnyFood16A === null ||
            doesYourChildEatsAnyFood16A === undefined ||
            doesYourChildEatsAnyFood16A.trim() === ""
          ) {
            return doesYourChildEatAnyFood16A; // Return an empty array if it's null, undefined, or empty
          } else {
            return doesYourChildEatsAnyFood16A; // Split the string by ";" and filter out any empty values
          }
        });
        setDoesYourChildEatAnyFood16B(() => {
          const doesYourChildEatsAnyFood16B =
            response?.doesYourChildEatAnyFood16B;

          if (
            doesYourChildEatsAnyFood16B === null ||
            doesYourChildEatsAnyFood16B === undefined ||
            doesYourChildEatsAnyFood16B.trim() === ""
          ) {
            return doesYourChildEatAnyFood16B; // Return an empty array if it's null, undefined, or empty
          } else {
            return doesYourChildEatsAnyFood16B; // Split the string by ";" and filter out any empty values
          }
        });
        setAnyOneSmokeInsideHome(response?.anyOneSmokeInsideHome);
        setDoesYourChildEatAnyFood16C(() => {
          const doesYourChildEatsAnyFood16C =
            response?.doesYourChildEatAnyFood16C;

          if (
            doesYourChildEatsAnyFood16C === null ||
            doesYourChildEatsAnyFood16C === undefined ||
            doesYourChildEatsAnyFood16C.trim() === ""
          ) {
            return doesYourChildEatAnyFood16C; // Return an empty array if it's null, undefined, or empty
          } else {
            return doesYourChildEatsAnyFood16C; // Split the string by ";" and filter out any empty values
          }
        });
        setnumberOfMeals(response?.numberOfMealsPerDay);
        setincludedInTheMeals(() => {
          const includedInTheMeal = response?.includedMeals;

          if (
            includedInTheMeal === null ||
            includedInTheMeal === undefined ||
            includedInTheMeal.trim() === ""
          ) {
            return includedInTheMeals; // Return an empty array if it's null, undefined, or empty
          } else {
            return includedInTheMeal; // Split the string by ";" and filter out any empty values
          }
        });
        setDescribeChildDiet(() => {
          const describeChildDiets = response?.yourChildDiet;

          if (
            describeChildDiets === null ||
            describeChildDiets === undefined ||
            describeChildDiets.trim() === ""
          ) {
            return describeChildDiet; // Return an empty array if it's null, undefined, or empty
          } else {
            return describeChildDiets; // Split the string by ";" and filter out any empty values
          }
        });
        setdoYouHaveBreakfastRegularly(response?.breakfastRegularly);
        setAppetite(response?.appetite);
        settimeOfDinner(response?.dinnerTime);
        setnumberOfSnacks(response?.numberOfSnackPerDay);
        setHeight(response?.currentHeight);
        setWeight(response?.currentWeight);
        setreceivedDietCounselling(response?.concernDiet);
        setpreferLowCarbDiet(response?.preferLowCarbDiet);
        setpreferVegetarianFood(response?.preferVegetarianFood);
        setavoidProcessedMeat(response?.avoidProcessedMeat);
        setavoidHighlyProcessedFoods(response?.avoidHighlyProcessedFoods);
        setdoNotDrinkSugarSweetenedBeverages(
          response?.doNotDrinkSugarSweetenedBeverage
        );
        setregularlyConsumeWholeGrainsLegumes(
          response?.regularlyConsumeWholeGrainsLegumes
        );
        setdrinkCoffee(response?.drinkCoffee);
        setlimitIntakeOfDairyProducts(response?.limitIntakeOfDairyProducts);
        seteatDietWhichIsLowInAddedSugars(
          response?.eatDietWhichIsLowInAddedSugars
        );
        setlimitOrDecreaseSaltInMyMeals(response?.limitOrDecreaseSaltInMyMeals);
        sethighInFiber(response?.highInFiber);
        setlimitOrDecreaseSugarInMyDiet(response?.limitOrDecreaseSugarInMyDiet);
        setselectDiet(response?.termOfChildDiet);
        setincludedInTheDiet(
          response?.itemsIncludeInDiet
            ? response?.itemsIncludeInDiet.split(";")
            : includedInTheDiet
        );
        setoftenEatEggs(response?.oftenEatEggs);
        setoftenEatYogurt(response?.oftenEatYogurt);
        setoftenEatCheese(response?.oftenEatCheese);
        setoftenEatLowFatMilk(response?.oftenEatLowFatMilk);
        setoftenEatFortifiedCereals(response?.oftenEatFortifiedCereals);
        setoftenEatMeat(response?.oftenEatMeat);
        setmixedIncludedInYourDiet(
          response?.mixedIncludedInYourDiet
            ? response?.mixedIncludedInYourDiet.split(";")
            : mixedIncludedInYourDiet
        );
        setQesAboutNutrition(response?.questionsForChildDiet);
        setconsultWithDietitian(response?.consultDietitianInNetwork);
        setExercise(response?.afterSchoolWouldPreferTo);
        setExercise2(response?.watchingTvIs);
        setExercise3(response?.walkingToGetSomeWhere);
        setYouStoppedDoingPhysicalActivity(
          response?.youStoppedDoingPhysicalActivity
            ? JSON.parse(response?.youStoppedDoingPhysicalActivity)
            : youStoppedDoingPhysicalActivity
        );
        setAgreeStatement(
          response?.agreeStatement
            ? JSON.parse(response?.agreeStatement)
            : agreeStatement
        );
        setDoYouFeelDoingPhysicalActOrSportWill(
          response?.doYouFeelDoingPhysicalActOrSportWill
            ? JSON.parse(response?.doYouFeelDoingPhysicalActOrSportWill)
            : doYouFeelDoingPhysicalActOrSportWill
        );
        setDuringTypicalWeek(
          response?.duringTypicalWeek
            ? JSON.parse(response?.duringTypicalWeek)
            : duringTypicalWeek
        );
        setAgreeStatementSecond(
          response?.agreeStatementSecond
            ? JSON.parse(response?.agreeStatementSecond)
            : agreeStatementSecond
        );
        setDuringTypicalWeekSomeoneInFamily(
          response?.duringTypicalWeekSomeoneInFamily
            ? JSON.parse(response?.duringTypicalWeekSomeoneInFamily)
            : duringTypicalWeekSomeoneInFamily
        );
        setComparedWithOtherChild(response?.comparedWithOtherChild);
        setHowDoYouTravelToSchool(response?.howDoYouTravelToSchool);
        setWhoDoYouTravelWithSchool(response?.whoDoYouTravelWithSchool);
        setDoYouGoAnywhereElseFromHome(response?.doYouGoAnywhereElseFromHome);
        setJourneyToAndFromSchool(
          response?.journeyToAndFromSchool
            ? JSON.parse(response?.journeyToAndFromSchool)
            : journeyToAndFromSchool
        );
        setHowDoYouTravel(
          response?.howDoYouTravel
            ? JSON.parse(response?.howDoYouTravel)
            : howDoYouTravel
        );
        setWhatDoYouDoInSchool(
          response?.whatDoYouDoInSchool
            ? JSON.parse(response?.whatDoYouDoInSchool)
            : whatDoYouDoInSchool
        );
        setExerciseData(
          response?.moderateIntensity
            ? JSON.parse(response?.moderateIntensity)
            : exerciseData
        );
        setvigorousexerciseData(
          response?.vigorousIntensity
            ? JSON.parse(response?.vigorousIntensity)
            : vigorousexerciseData
        );
        setmildExerciseData(
          response?.mildIntensity
            ? JSON.parse(response?.mildIntensity)
            : mildexerciseData
        );
        setcomparisonLastThreeMonthsToSevenDays(response?.lastWeekActiveStatus);
        setselectedActivity(response?.activityUndertakeRegularly);
        sethoursOfExercise(response?.exerciseLastWeekPerDay);
        settotalHoursOfSitting(response?.sedentaryExerciseTotalHourPerDay);
        setHoursOfSleep(response?.totalHourSleep);
        setsleepAroundSameTime(response?.sleepTime);
        setsatisfiedWithSleep(response?.satisfiedWithSleep);
        setdifficultyWithSleep(response?.difficultyWithSleep);
        setfeelRefreshedAfterSleep(response?.wackUpRefreshedAfterSleep);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    AxiosInstance.get("profile/get")
      .then((response) => {
        setProfile(response?.profile);
      })
      .catch((error) => {
        console.log(error);
      });
    AxiosInstance.get("form/personalFamilyHistory")
      .then((response) => {
        setFamilyHistory(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const handleModerateIntensityClick = () => {
    const moderateIntensityActivities = [
      "Walking two miles in 30 minutes.",
      "Biking five miles in 30 minutes.",
      "Swimming laps for 20 minutes.",
      "Running one and a half miles in 15 minutes.",
      "Doing water aerobics for 30 minutes.",
      "Playing volleyball for 45 minutes.",
      "Playing basketball for 20 minutes.",
      "Jumping rope for 15 minutes.",
      "Walking stairs for 15 minutes.",
      "Washing your car for 45 minutes to an hour.",
      "Gardening for 30 to 45 minutes.",
      "Raking leaves for 30 minutes.",
      "Dancing for 30 minutes.",
    ];

    const formattedActivities = moderateIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Moderate Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };

  const handleVigorousIntensityClick = () => {
    const vergorousIntensityActivities = [
      "Hiking",
      "Jogging at 6 mph",
      "Shoveling",
      "Carrying heavy loads",
      "Bicycling fast (14-16 mph)",
      "Basketball game",
      "Soccer game",
      "Tennis singles",
      "Cricket",
    ];
    const formattedActivities = vergorousIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Vigorous Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };
  const handleMildIntensityClick = () => {
    const mildIntensityActivities = [
      "Walking slowly",
      "Sitting using computer",
      "Standing light work (cooking, washing dishes)",
      "Fishing sitting",
      "Playing most instrument",
    ];
    const formattedActivities = mildIntensityActivities
      .map((activity, index) => `${index + 1}. ${activity}`)
      .join("\n");

    Swal.fire({
      title: "Activities for Mild Intensity",
      html: `<pre>${formattedActivities}</pre>`,
      icon: "info",
    });
  };

  const getRelationship = () => {
    AxiosInstance.get("/family/getPatientFamilyProfile")
      .then((response) => {
        setFamilyRelation(response?.relationShipWithUser);
        setFamilyInfo(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRelationship();
    AxiosInstance.get("profile/get")
      .then((response) => {
        // setFamilyAge(response);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <div className="profile_section">
      <ToastMessage></ToastMessage>
      <form className="registration_tier1">
        {familyRelation !== "wife" && familyRelation != null && (
          <>
            <Typography
              variant={isSmallScreen ? "h6" : "h5"} // Smaller font size for mobile
              color="primary"
              sx={{
                mb: 2,
                wordBreak: "break-all",
              }}
            >
              The health Insights/Information presented below is for your child{" "}
              <Typography
                component="span"
                variant={isSmallScreen ? "h5" : "h4"} // Bigger font size for child's name
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {familyInfo?.firstName &&
                  familyInfo.firstName.charAt(0).toUpperCase() +
                    familyInfo.firstName.slice(1).toLowerCase()}
              </Typography>
            </Typography>

            <Typography
              variant={isSmallScreen ? "h6" : "h5"} // Smaller font size for mobile
              color="primary"
              sx={{ mb: 2 }}
            >
              Please fill this form for your child{" "}
              <Typography
                component="span"
                variant={isSmallScreen ? "h5" : "h4"} // Bigger font size for child's name
                color="primary"
                sx={{ fontWeight: "bold" }}
              >
                {familyInfo?.firstName &&
                  familyInfo.firstName.charAt(0).toUpperCase() +
                    familyInfo.firstName.slice(1).toLowerCase()}
              </Typography>
            </Typography>
          </>
        )}
        {LifestyleStep == 5 ? (
          ""
        ) : (
          <span style={{ float: "right", color: "red" }}>
            Note : All Fields are Mandatory
          </span>
        )}
        {/* <form
         onSubmit={formik.handleSave1}
         > */}
        {LifestyleStep == 0 && (
          <>
            <Grid container spacing={2} mb={2}>
              <Grid item>
                <div className="register_title">
                  <h4>1. Diet</h4>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.medicalProblem ? "red" : "inherit",
                    }}
                  >
                    1(a). Does your child have any medical problems ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={medicalProblem}
                    onChange={handleMedicalProblem}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {medicalProblem === true && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors.listMedicalProblems
                          ? "red"
                          : "inherit",
                      }}
                    >
                      1(b). Please list or describe :
                    </FormLabel>
                    <Input
                      value={listMedicalProblems}
                      onChange={handleMedicalProblemList}
                    ></Input>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.dentailProblem ? "red" : "inherit",
                    }}
                  >
                    1(c). Dental problems or cavities ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="dentalProblem"
                    value={dentailProblem}
                    onChange={handleDentialProblem}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.childTakeMedicine ? "red" : "inherit",
                    }}
                  >
                    1(d). Does your child take any medicine ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="childTakeMedicine"
                    value={childTakeMedicine}
                    onChange={handleChildTakeMedicine}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {childTakeMedicine === true && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        color: fieldErrors.childMedicineList
                          ? "red"
                          : "inherit",
                      }}
                    >
                      1(e). Please list :
                    </FormLabel>
                    <Input
                      value={childMedicineList}
                      onChange={handleChildMedicineList}
                    ></Input>
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.specialDiet ? "red" : "inherit",
                    }}
                  >
                    2(a). Is your child on a special diet such as Vegetarian or
                    Macrobiotic ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={specialDiet}
                    onChange={handleSpecialDiet}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {specialDiet === true && (
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        color: fieldErrors.specialDietList ? "red" : "inherit",
                      }}
                    >
                      2(b). Please list :
                    </FormLabel>
                    <Input
                      value={specialDietList}
                      onChange={handleSpecialDietList}
                    ></Input>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.childsDiet ? "red" : "inherit",
                    }}
                  >
                    2(c). Do you limit any of the following in your child’s
                    diet?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={childsDiet.includes("Sugar")} />
                      }
                      label="Sugar"
                      value="Sugar"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={childsDiet.includes("Calories")} />
                      }
                      label="Calories"
                      value="Calories"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={childsDiet.includes("Salt")} />
                      }
                      label="Salt"
                      value="Salt"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={childsDiet.includes("Fat(Saturated)")}
                        />
                      }
                      label="Fat(Saturated)"
                      value="Fat(Saturated)"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={childsDiet.includes("Carbohydrate")}
                        />
                      }
                      label="Carbohydrate"
                      value="Carbohydrate"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={childsDiet.includes("None")} />
                      }
                      label="None"
                      value="None"
                      onChange={handleDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={childsDiet.includes("Other")} />
                      }
                      label="Other"
                      value="Other"
                      onChange={handleDietChange}
                    />
                  </FormGroup>
                  {childsDiet.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        childsDiet
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleDietChange({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.timeOfDinner ? "red" : "inherit",
                    }}
                  >
                    2(d). Reason :
                  </FormLabel>
                  <Input
                    value={dietLimitReason}
                    onChange={handleDietLimitReason}
                  ></Input>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mb={5}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.multivitaminsDiet ? "red" : "inherit",
                    }}
                  >
                    3. Does your child take any of the following ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes("Multivitamins")}
                        />
                      }
                      label="Multivitamins"
                      value="Multivitamins"
                      onChange={handleMultivitaminsDiet}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes("Fluoride")}
                        />
                      }
                      label="Fluoride"
                      value="Fluoride"
                      onChange={handleMultivitaminsDiet}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes("Vitamin D")}
                        />
                      }
                      label="Vitamin D"
                      value="Vitamin D"
                      onChange={handleMultivitaminsDiet}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes("Iron")}
                        />
                      }
                      label="Iron"
                      value="Iron"
                      onChange={handleMultivitaminsDiet}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes(
                            "Herbal teas/supplements"
                          )}
                        />
                      }
                      label="Herbal teas/supplements"
                      value="Herbal teas/supplements"
                      onChange={handleMultivitaminsDiet}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={multivitaminsDiet.includes("Other")}
                        />
                      }
                      label="Other"
                      value="Other"
                      onChange={handleMultivitaminsDiet}
                    />
                  </FormGroup>
                  {multivitaminsDiet.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        multivitaminsDiet
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleMultivitaminsDiet({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors.milkDrinkEachDay ? "red" : "inherit",
                    }}
                  >
                    4(a). How much milk does your child drink each day ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={milkDrinkEachDay}
                    onChange={handleMilkDrinkEachDay}
                  >
                    <FormControlLabel
                      value="1 cup"
                      control={<Radio />}
                      label="1 cup"
                    />
                    <FormControlLabel
                      value="1 - 1.5 cup"
                      control={<Radio />}
                      label="1 - 1.5 cup"
                    />
                    <FormControlLabel
                      value="1.5 - 2 cup"
                      control={<Radio />}
                      label="1.5 - 2 cup"
                    />
                    <FormControlLabel
                      value="2 - 3 cup"
                      control={<Radio />}
                      label="2 - 3 cup"
                    />
                    <FormControlLabel
                      value="3 - 4 cup"
                      control={<Radio />}
                      label="3 - 4 cup"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors.kindOfMilkChildDrink
                        ? "red"
                        : "inherit",
                    }}
                  >
                    4(b). Check which kinds of milk your child drinks :
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Cow’s milk")}
                        />
                      }
                      label="Cow’s milk"
                      value="Cow’s milk"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Whole")}
                        />
                      }
                      label="Whole"
                      value="Whole"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("2%")}
                        />
                      }
                      label="2%"
                      value="2%"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("1%")}
                        />
                      }
                      label="1%"
                      value="1%"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Skim")}
                        />
                      }
                      label="Skim"
                      value="Skim"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes(
                            "Lactose free"
                          )}
                        />
                      }
                      label="Lactose free"
                      value="Lactose free"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes(
                            "Chocolate/Strawberry"
                          )}
                        />
                      }
                      label="Chocolate/Strawberry"
                      value="Chocolate/Strawberry"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Goat’s milk")}
                        />
                      }
                      label="Goat’s milk"
                      value="Goat’s milk"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Soy milk")}
                        />
                      }
                      label="Soy milk"
                      value="Soy milk"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Almond milk")}
                        />
                      }
                      label="Almond milk"
                      value="Almond milk"
                      onChange={handleKindOfMilkChildDrink}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={kindOfMilkChildDrink.includes("Other")}
                        />
                      }
                      label="Other"
                      value="Other"
                      onChange={handleKindOfMilkChildDrink}
                    />
                  </FormGroup>
                  {kindOfMilkChildDrink.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        kindOfMilkChildDrink
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleKindOfMilkChildDrink({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              mb={0}
              mt={3}
              justifyContent="space-between"
              display={"flex"}
              gap={5}
            >
              <div className="submit-button">
                <button className="base" onClick={onBack}>
                  Back
                </button>
              </div>
              <div className="submit-button">
                <button
                  style={{ minWidth: "200px" }}
                  type="button"
                  className=""
                  onClick={handleSave1}
                >
                  Next
                </button>
              </div>
            </Grid>
          </>
        )}
      </form>
      {LifestyleStep == 1 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors2.beverages ? "red" : "inherit",
                  }}
                >
                  5(a). Check what other beverages your child drinks :
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={beverages.includes("Soda/Pop")} />
                    }
                    label="Soda/Pop"
                    value="Soda/Pop"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={beverages.includes("Kool-Aid")} />
                    }
                    label="Kool-Aid"
                    value="Kool-Aid"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={beverages.includes("100% Juice")} />
                    }
                    label="100% Juice"
                    value="100% Juice"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={beverages.includes(
                          "Drinks in boxes, pouches, etc"
                        )}
                      />
                    }
                    label="Drinks in boxes, pouches, etc"
                    value="Drinks in boxes, pouches, etc"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={beverages.includes(
                          "Juice drinks (punch, cocktail, etc.)"
                        )}
                      />
                    }
                    label="Juice drinks (punch, cocktail, etc.)"
                    value="Juice drinks (punch, cocktail, etc.)"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={beverages.includes("Tea")} />}
                    label="Tea"
                    value="Tea"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={beverages.includes("Gatorade")} />
                    }
                    label="Gatorade"
                    value="Gatorade"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={beverages.includes("Energy drinks")} />
                    }
                    label="Energy drinks"
                    value="Energy drinks"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={beverages.includes("None")} />}
                    label="None"
                    value="None"
                    onChange={handleBeveragesChange}
                  />
                  <FormControlLabel
                    control={<Checkbox checked={beverages.includes("Other")} />}
                    label="Other"
                    value="Other"
                    onChange={handleBeveragesChange}
                  />
                </FormGroup>
                {beverages.includes("Other") && (
                  <input
                    type="text"
                    style={{ border: "1px solid black" }}
                    value={
                      beverages
                        .split(";")
                        .find((item) => item.startsWith("Other:"))
                        ?.replace("Other:", "") || ""
                    }
                    onChange={(e) =>
                      handleBeveragesChange({
                        target: {
                          value: `Other:${e.target.value}`,
                          type: "text",
                        },
                      })
                    }
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors2.waterToBeverages ? "red" : "inherit",
                  }}
                >
                  5(b). Do you add water to these beverages ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={waterToBeverages}
                  onChange={handleWaterToBeverages}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors2.childDrinkPlainWater
                      ? "red"
                      : "inherit",
                  }}
                >
                  6(a). Does your child drink plain water ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={childDrinkPlainWater}
                  onChange={handleChildDrinkPlainWater}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {childDrinkPlainWater === true && (
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors2.howMuchDrinkEachDay
                        ? "red"
                        : "inherit",
                    }}
                  >
                    6(b). How much each day ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={howMuchDrinkEachDay}
                    onChange={handleHowMuchDrinkEachDay}
                  >
                    <FormControlLabel
                      value="1 glass"
                      control={<Radio />}
                      label="1 glass"
                    />
                    <FormControlLabel
                      value="2 glass"
                      control={<Radio />}
                      label="2 glass"
                    />
                    <FormControlLabel
                      value="3 glass"
                      control={<Radio />}
                      label="3 glass"
                    />
                    <FormControlLabel
                      value="4 glass"
                      control={<Radio />}
                      label="4 glass"
                    />
                    <FormControlLabel
                      value="5 glass"
                      control={<Radio />}
                      label="5 glass"
                    />
                    <FormControlLabel
                      value="6 glass"
                      control={<Radio />}
                      label="6 glass"
                    />
                    <FormControlLabel
                      value="More than 6 glass"
                      control={<Radio />}
                      label="More than 6 glass"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={onBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className=""
                onClick={handleSave2}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 2 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors3.childHaveProblem ? "red" : "inherit",
                  }}
                >
                  7. Is your child having any problems with:
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Poor appetite")}
                      />
                    }
                    label="Poor appetite"
                    value="Poor appetite"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Food textures")}
                      />
                    }
                    label="Food textures"
                    value="Food textures"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Chewing food")}
                      />
                    }
                    label="Chewing food"
                    value="Chewing food"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Swallowing food")}
                      />
                    }
                    label="Swallowing food"
                    value="Swallowing food"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes(
                          "Nausea or vomiting"
                        )}
                      />
                    }
                    label="Nausea or vomiting"
                    value="Nausea or vomiting"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Diarrhea")}
                      />
                    }
                    label="Diarrhea"
                    value="Diarrhea"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={childHaveProblem.includes("Constipation")}
                      />
                    }
                    label="Constipation"
                    value="Constipation"
                    onChange={handleChildProblem}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={childHaveProblem.includes("None")} />
                    }
                    label="None of these"
                    value="None"
                    onChange={handleChildProblem}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors3.allergyOfFood ? "red" : "inherit",
                  }}
                >
                  8(a). Is your child allergic to any foods ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={allergyOfFood}
                  onChange={handleAllergyOfFood}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {allergyOfFood === true && (
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors3.allergyOfWhichFood
                        ? "red"
                        : "inherit",
                    }}
                  >
                    8(b). Which foods ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Seafood")}
                        />
                      }
                      label="Seafood"
                      value="Seafood"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Peanuts")}
                        />
                      }
                      label="Peanuts"
                      value="Peanuts"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Nuts")}
                        />
                      }
                      label="Nuts"
                      value="Nuts"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Eggs")}
                        />
                      }
                      label="Eggs"
                      value="Eggs"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Wheat")}
                        />
                      }
                      label="Wheat"
                      value="Wheat"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Soy")}
                        />
                      }
                      label="Soy"
                      value="Soy"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Milk")}
                        />
                      }
                      label="Milk"
                      value="Milk"
                      onChange={handleAllergyOfWhichFood}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allergyOfWhichFood.includes("Other")}
                        />
                      }
                      label="Other"
                      value="Other"
                      onChange={handleAllergyOfWhichFood}
                    />
                    {allergyOfWhichFood.includes("Other") && (
                      <input
                        type="text"
                        style={{ border: "1px solid black" }}
                        value={
                          allergyOfWhichFood
                            .split(";")
                            .find((item) => item.startsWith("Other:"))
                            ?.replace("Other:", "") || ""
                        }
                        onChange={(e) =>
                          handleAllergyOfWhichFood({
                            target: {
                              value: `Other:${e.target.value}`,
                              type: "text",
                            },
                          })
                        }
                      />
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors3.childNumberOfMeals ? "red" : "inherit",
                  }}
                >
                  9(a). How many meals does your child eat each day ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={childNumberOfMeals}
                  onChange={handleChildNumberOfMeal}
                >
                  <FormControlLabel value="1" control={<Radio />} label="1" />
                  <FormControlLabel value="2" control={<Radio />} label="2" />
                  <FormControlLabel
                    value="3 or more"
                    control={<Radio />}
                    label="3 or more"
                  />
                  <FormControlLabel
                    value="4 or more"
                    control={<Radio />}
                    label="4 or more"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors3.besideSomethingtoEat
                      ? "red"
                      : "inherit",
                  }}
                >
                  9(b). Besides meal time, when is your child given something to
                  eat ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={besideSomethingtoEat}
                  onChange={handleBesideSomethingtoEat}
                >
                  <FormControlLabel
                    value="At snacks"
                    control={<Radio />}
                    label="At snacks"
                  />
                  <FormControlLabel
                    value="When fussy or crying"
                    control={<Radio />}
                    label="When fussy or crying"
                  />
                  <FormControlLabel
                    value="3 or more"
                    control={<Radio />}
                    label="3 or more"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors3.offerFoodasReward ? "red" : "inherit",
                  }}
                >
                  9(c). Do you offer food as a reward ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={offerFoodasReward}
                  onChange={handleOfferFoodasReward}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {offerFoodasReward === true && (
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      color: fieldErrors3.whichFoodAsReward ? "red" : "inherit",
                    }}
                  >
                    9(d). If yes, what foods ?
                  </FormLabel>
                  <Input
                    value={whichFoodAsReward}
                    onChange={handleWhichFoodAsReward}
                  ></Input>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors3.doYouRequireToEatCertain
                      ? "red"
                      : "inherit",
                  }}
                >
                  9(e). Do you require your child to eat certain foods or finish
                  plate ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={doYouRequireToEatCertain}
                  onChange={handleDoYouRequireToEatCertain}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors3.getAnyConcernGettingToEat
                      ? "red"
                      : "inherit",
                  }}
                >
                  10. Check any concerns you have with getting your child to eat
                  well :
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes(
                          "Picky eater"
                        )}
                      />
                    }
                    label="Picky eater"
                    value="Picky eater"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes(
                          "Leaves food on the plate"
                        )}
                      />
                    }
                    label="Leaves food on the plate"
                    value="Leaves food on the plate"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes(
                          "Wants the same foods all the time"
                        )}
                      />
                    }
                    label="Wants the same foods all the time"
                    value="Wants the same foods all the time"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes(
                          "Begs for snacks between meals"
                        )}
                      />
                    }
                    label="Begs for snacks between meals"
                    value="Begs for snacks between meals"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes(
                          "Wants milk or juice all day long"
                        )}
                      />
                    }
                    label="Wants milk or juice all day long"
                    value="Wants milk or juice all day long"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes("None")}
                      />
                    }
                    label="None of these"
                    value="None"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={getAnyConcernGettingToEat.includes("Other")}
                      />
                    }
                    label="Other"
                    value="Other"
                    onChange={handlegetAnyConcernGettingToEat}
                  />
                  {getAnyConcernGettingToEat.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        getAnyConcernGettingToEat
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handlegetAnyConcernGettingToEat({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors.diet ? "red" : "inherit",
                  }}
                >
                  11. Besides your home, where does your child usually eat ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={besideUsuallyEat}
                  onChange={handleBesideUsuallyEat}
                >
                  <FormControlLabel
                    value="Day care/baby sitter"
                    control={<Radio />}
                    label="Day care/baby sitter"
                  />
                  <FormControlLabel
                    value="Head start"
                    control={<Radio />}
                    label="Head start"
                  />
                  <FormControlLabel
                    value="Relatives"
                    control={<Radio />}
                    label="Relatives"
                  />
                  <FormControlLabel
                    value="Usually at home"
                    control={<Radio />}
                    label="Usually at home"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={onBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className=""
                onClick={handleSave3}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 3 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel>
                  12. Check how often your child eats the foods listed below :
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatMeatChickenFish
                          ? "red"
                          : "inherit",
                      }}
                    >
                      Meats, chicken, fish :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatMeatChickenFish}
                      onChange={handleEatMeatChickenFish}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatGrains ? "red" : "inherit",
                      }}
                    >
                      Grains (pasta, rice, bread, cereal, tortilla) :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatGrains}
                      onChange={handleEatGrains}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatFruits ? "red" : "inherit",
                      }}
                    >
                      Fruits :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatFruits}
                      onChange={handleEatFruits}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatEggs ? "red" : "inherit",
                      }}
                    >
                      Eggs :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatEggs}
                      onChange={handleEatEggs}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatVegetables ? "red" : "inherit",
                      }}
                    >
                      Vegetables :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatVegetables}
                      onChange={handleEatVegetable}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatPeanutButter ? "red" : "inherit",
                      }}
                    >
                      Peanut butter :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatPeanutButter}
                      onChange={handleEatPeanutButter}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatCheese ? "red" : "inherit",
                      }}
                    >
                      Cheese :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatCheese}
                      onChange={handleEatCheese}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={6}>
                    <FormLabel
                      style={{
                        color: fieldErrors4.eatBeans ? "red" : "inherit",
                      }}
                    >
                      Beans (pinto, kidney, etc) :
                    </FormLabel>
                  </Col>
                  <Col lg={6}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      style={{ flexDirection: "row" }}
                      value={eatBeans}
                      onChange={handleEatBeans}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Some days"
                        control={<Radio />}
                        label="Some days"
                      />
                      <FormControlLabel
                        value="Never"
                        control={<Radio />}
                        label="Never"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors4.eatSnacksTimesInDay ? "red" : "inherit",
                  }}
                >
                  13(a). How many times a day does your child eat snacks ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={eatSnacksTimesInDay}
                  onChange={handleEatSnacksTimesInDays}
                >
                  <FormControlLabel value="1" control={<Radio />} label="1" />
                  <FormControlLabel value="2" control={<Radio />} label="2" />
                  <FormControlLabel
                    value="3 or more"
                    control={<Radio />}
                    label="3 or more"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors4.eatForSnacks ? "red" : "inherit",
                  }}
                >
                  13(b). Check the foods your child eats for snacks :
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Cookies")} />
                    }
                    label="Cookies"
                    value="Cookies"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Crackers")} />
                    }
                    label="Crackers"
                    value="Crackers"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Chips")} />
                    }
                    label="Chips"
                    value="Chips"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Pretzels")} />
                    }
                    label="Pretzels"
                    value="Pretzels"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Cereal")} />
                    }
                    label="Cereal"
                    value="Cereal"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eatForSnacks.includes("Cereal bars")}
                      />
                    }
                    label="Cereal bars"
                    value="Cereal bars"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Candy")} />
                    }
                    label="Candy"
                    value="Candy"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Cheese")} />
                    }
                    label="Cheese"
                    value="Cheese"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Yogurt")} />
                    }
                    label="Yogurt"
                    value="Yogurt"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Fruit")} />
                    }
                    label="Fruit"
                    value="Fruit"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Pudding")} />
                    }
                    label="Pudding"
                    value="Pudding"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Vegetables")} />
                    }
                    label="Vegetables"
                    value="Vegetables"
                    onChange={handleEatForSnaks}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatForSnacks.includes("Other")} />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleEatForSnaks}
                  />
                  {eatForSnacks.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        eatForSnacks
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleEatForSnaks({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors4.eatFastFoodTime ? "red" : "inherit",
                  }}
                >
                  14. How often does your child eat at fast food places such as
                  Burger King or McDonalds ?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eatFastFoodTime.includes("Everyday")}
                      />
                    }
                    label="Everyday"
                    value="Everyday"
                    onChange={handleEatFastFoodTime}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eatFastFoodTime.includes("A few times a week")}
                      />
                    }
                    label="A few times a week"
                    value="A few times a week"
                    onChange={handleEatFastFoodTime}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eatFastFoodTime.includes("Once a week")}
                      />
                    }
                    label="Once a week"
                    value="Once a week"
                    onChange={handleEatFastFoodTime}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eatFastFoodTime.includes("Once a month")}
                      />
                    }
                    label="Once a month"
                    value="Once a month"
                    onChange={handleEatFastFoodTime}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={eatFastFoodTime.includes("Never")} />
                    }
                    label="Never"
                    value="Never"
                    onChange={handleEatFastFoodTime}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors4.doesYourChildEatAnyFood16A
                      ? "red"
                      : "inherit",
                  }}
                >
                  16(a). Does your child eat any of these foods ?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes("Popcorn")}
                      />
                    }
                    label="Popcorn"
                    value="Popcorn"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Whole grapes"
                        )}
                      />
                    }
                    label="Whole grapes"
                    value="Whole grapes"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Hard candy"
                        )}
                      />
                    }
                    label="Hard candy"
                    value="Hard candy"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Lollipops"
                        )}
                      />
                    }
                    label="Lollipops"
                    value="Lollipops"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Raw vegetables"
                        )}
                      />
                    }
                    label="Raw vegetables"
                    value="Raw vegetables"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Nuts or seeds"
                        )}
                      />
                    }
                    label="Nuts or seeds"
                    value="Nuts or seeds"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Peanut butter"
                        )}
                      />
                    }
                    label="Peanut butter"
                    value="Peanut butter"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes("Gummies")}
                      />
                    }
                    label="Gummies"
                    value="Gummies"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Jelly beans"
                        )}
                      />
                    }
                    label="Jelly beans"
                    value="Jelly beans"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Hot dogs"
                        )}
                      />
                    }
                    label="Hot dogs"
                    value="Hot dogs"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Pretzels"
                        )}
                      />
                    }
                    label="Pretzels"
                    value="Pretzels"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes("Chips")}
                      />
                    }
                    label="Chips"
                    value="Chips"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes(
                          "Raisins/dried fruit"
                        )}
                      />
                    }
                    label="Raisins/dried fruit"
                    value="Raisins/dried fruit"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16A.includes("Other")}
                      />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleDoesYourChildEatAnyFood16A}
                  />
                  {doesYourChildEatAnyFood16A.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        doesYourChildEatAnyFood16A
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleDoesYourChildEatAnyFood16A({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors4.doesYourChildEatAnyFood16B
                      ? "red"
                      : "inherit",
                  }}
                >
                  16(b). Does your child eat any of these foods ?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Raw cookie dough or cake batter"
                        )}
                      />
                    }
                    label="Raw cookie dough or cake batter"
                    value="Raw cookie dough or cake batter"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Hot dogs, deli or lunch meats"
                        )}
                      />
                    }
                    label="Hot dogs, deli or lunch meats"
                    value="Hot dogs, deli or lunch meats"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Raw or undercooked eggs, meat, or fish"
                        )}
                      />
                    }
                    label="Raw or undercooked eggs, meat, or fish"
                    value="Raw or undercooked eggs, meat, or fish"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Soft cheese like feta or brie"
                        )}
                      />
                    }
                    label="Soft cheese like feta or brie"
                    value="Soft cheese like feta or brie"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Bean sprouts"
                        )}
                      />
                    }
                    label="Bean sprouts"
                    value="Bean sprouts"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes(
                          "Milk, juice or cider from mill or farm (if unpasteurized)"
                        )}
                      />
                    }
                    label="Milk, juice or cider from mill or farm (if unpasteurized)"
                    value="Milk, juice or cider from mill or farm (if unpasteurized)"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16B.includes("Other")}
                      />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleDoesYourChildEatAnyFood16B}
                  />
                  {doesYourChildEatAnyFood16B.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        doesYourChildEatAnyFood16B
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleDoesYourChildEatAnyFood16B({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors4.anyOneSmokeInsideHome
                      ? "red"
                      : "inherit",
                  }}
                >
                  17. Does anyone smoke inside your home ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={anyOneSmokeInsideHome}
                  onChange={handleAnyOneSmokeInsideHome}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors4.doesYourChildEatAnyFood16C
                      ? "red"
                      : "inherit",
                  }}
                >
                  18. Does your child eat any of the following ?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Laundry starch"
                        )}
                      />
                    }
                    label="Laundry starch"
                    value="Laundry starch"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes("Soil")}
                      />
                    }
                    label="Soil"
                    value="Soil"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes("Chalk")}
                      />
                    }
                    label="Chalk"
                    value="Chalk"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Paint chips"
                        )}
                      />
                    }
                    label="Paint chips"
                    value="Paint chips"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Cigarette ashes"
                        )}
                      />
                    }
                    label="Cigarette ashes"
                    value="Cigarette ashes"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Ice (in large quantities)"
                        )}
                      />
                    }
                    label="Ice (in large quantities)"
                    value="Ice (in large quantities)"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Burnt matches"
                        )}
                      />
                    }
                    label="Burnt matches"
                    value="Burnt matches"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes("Clay")}
                      />
                    }
                    label="Clay"
                    value="Clay"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Carpet fibers"
                        )}
                      />
                    }
                    label="Carpet fibers"
                    value="Carpet fibers"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Cornstarch"
                        )}
                      />
                    }
                    label="Cornstarch"
                    value="Cornstarch"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes(
                          "Foam rubber"
                        )}
                      />
                    }
                    label="Foam rubber"
                    value="Foam rubber"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={doesYourChildEatAnyFood16C.includes("Other")}
                      />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleDoesYourChildEatAnyFood16C}
                  />
                  {doesYourChildEatAnyFood16C.includes("Other") && (
                    <input
                      type="text"
                      style={{ border: "1px solid black" }}
                      value={
                        doesYourChildEatAnyFood16C
                          .split(";")
                          .find((item) => item.startsWith("Other:"))
                          ?.replace("Other:", "") || ""
                      }
                      onChange={(e) =>
                        handleDoesYourChildEatAnyFood16C({
                          target: {
                            value: `Other:${e.target.value}`,
                            type: "text",
                          },
                        })
                      }
                    />
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={onBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className=""
                onClick={handleSave4}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 4 && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                A. Think of what you ate last week (7 days) and answer the
                following.
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.numberOfMeals ? "red" : "inherit",
                  }}
                >
                  Number of meals in a day for your Child.
                </FormLabel>
                <Select
                  value={numberOfMeals}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handlenumberOfMealsChange}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"More than 4"}>More than 4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors5.includedInTheMeals ? "red" : "inherit",
                  }}
                >
                  What was included in your Child's meals ?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includedInTheMeals.includes("Vegetables only")}
                      />
                    }
                    label="Vegetables only"
                    value="Vegetables only"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includedInTheMeals.includes(
                          "Mixed (Veg + Nonveg)"
                        )}
                      />
                    }
                    label="Mixed (Veg + Nonveg)"
                    value="Mixed (Veg + Nonveg)"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={includedInTheMeals.includes("Eggs")} />
                    }
                    label="Eggs"
                    value="Eggs"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={includedInTheMeals.includes("Fish")} />
                    }
                    label="Fish"
                    value="Fish"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includedInTheMeals.includes("Dairy")}
                      />
                    }
                    label="Dairy"
                    value="Dairy"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includedInTheMeals.includes("Fresh Fruits")}
                      />
                    }
                    label="Fresh Fruits"
                    value="Fresh Fruits"
                    onChange={handleincludedInTheMealsChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includedInTheMeals.includes("Other")}
                      />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleincludedInTheMealsChange}
                  />
                </FormGroup>
                {includedInTheMeals.includes("Other") && (
                  <input
                    type="text"
                    style={{ border: "1px solid black" }}
                    value={
                      includedInTheMeals
                        .split(";")
                        .find((item) => item.startsWith("Other:"))
                        ?.replace("Other:", "") || ""
                    }
                    onChange={(e) =>
                      handleincludedInTheMealsChange({
                        target: {
                          value: `Other:${e.target.value}`,
                          type: "text",
                        },
                      })
                    }
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors5.describeChildDiet ? "red" : "inherit",
                  }}
                >
                  How would you describe your Child's diet?
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("Regular")}
                      />
                    }
                    label="Regular"
                    value="Regular"
                    onChange={handleChildDiet}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("Diabetic")}
                      />
                    }
                    label="Diabetic"
                    value="Diabetic"
                    onChange={handleChildDiet}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("Low Salt")}
                      />
                    }
                    label="Low Salt"
                    value="Low Salt"
                    onChange={handleChildDiet}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("Low Fat")}
                      />
                    }
                    label="Low Fat"
                    value="Low Fat"
                    onChange={handleChildDiet}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("Low Cholesterol")}
                      />
                    }
                    label="Low Cholesterol"
                    value="Low Cholesterol"
                    onChange={handleChildDiet}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={describeChildDiet.includes("High in Fiber")}
                      />
                    }
                    label="High in Fiber"
                    value="High in Fiber"
                    onChange={handleChildDiet}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={describeChildDiet.includes("Other")} />
                    }
                    label="Other"
                    value="Other"
                    onChange={handleChildDiet}
                  />
                </FormGroup>
                {describeChildDiet.includes("Other") && (
                  <input
                    type="text"
                    style={{ border: "1px solid black" }}
                    value={
                      describeChildDiet
                        .split(";")
                        .find((item) => item.startsWith("Other:"))
                        ?.replace("Other:", "") || ""
                    }
                    onChange={(e) =>
                      handleChildDiet({
                        target: {
                          value: `Other:${e.target.value}`,
                          type: "text",
                        },
                      })
                    }
                  />
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.doYouHaveBreakfastRegularly
                      ? "red"
                      : "inherit",
                  }}
                >
                  Does your Child have breakfast regularly?
                </FormLabel>
                <Select
                  value={doYouHaveBreakfastRegularly}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handledoYouHaveBreakfastRegularlyChange}
                >
                  <MenuItem value={""}>Select</MenuItem>
                  <MenuItem value={"true"}>Yes</MenuItem>
                  <MenuItem value={"false"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.appetite ? "red" : "inherit",
                  }}
                >
                  Child's appetite is :
                </FormLabel>
                <Select
                  value={appetite}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleAppetiteChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"Very Poor"}>Very Poor</MenuItem>
                  <MenuItem value={"Poor"}>Poor</MenuItem>
                  <MenuItem value={"Average"}>Average</MenuItem>
                  <MenuItem value={"Good"}>Good</MenuItem>
                  <MenuItem value={"Very Good"}>Very Good</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.timeOfDinner ? "red" : "inherit",
                  }}
                >
                  What time does your Child have dinner ?
                </FormLabel>
                <Select
                  value={timeOfDinner}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handletimeOfDinnerChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"Between 5-6pm"}>Between 5-6pm</MenuItem>
                  <MenuItem value={"6-7pm"}>6-7pm</MenuItem>
                  <MenuItem value={"7-8pm"}>7-8pm</MenuItem>
                  <MenuItem value={"8-9pm"}>8-9pm</MenuItem>
                  <MenuItem value={"9-10pm"}>9-10pm</MenuItem>
                  <MenuItem value={"10-11pm"}>10-11pm</MenuItem>
                  <MenuItem value={"After 11pm"}>After 11pm</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.numberOfSnacks ? "red" : "inherit",
                  }}
                >
                  Number of snacks per day for your Child.
                </FormLabel>
                <Select
                  value={numberOfSnacks}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handlenumberOfSnacksChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"1"}>1</MenuItem>
                  <MenuItem value={"2"}>2</MenuItem>
                  <MenuItem value={"3"}>3</MenuItem>
                  <MenuItem value={"4"}>4</MenuItem>
                  <MenuItem value={"More than 4"}>More than 4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>B. General Questions</div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <label
                style={{
                  color: fieldErrors5.height ? "red" : "inherit",
                }}
              >
                What is your Child's current height(cm) ?
              </label>
              <Input
                type="number"
                onChange={(event) => {
                  onHeightChange(event);
                  onBlurHeight(event);
                }}
                value={height}
              ></Input>
              {errorHeight && (
                <div className="error" style={{ color: "red" }}>
                  {errorHeight}
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <label
                style={{
                  color: fieldErrors.weight ? "red" : "inherit",
                }}
              >
                What is your Child's current weight(kg) ?
              </label>
              <Input
                type="number"
                onChange={(event) => {
                  onWeightChange(event);
                  onBlurWeight(event);
                }}
                value={weight}
              ></Input>
              {errorWeight && (
                <div className="error" style={{ color: "red" }}>
                  {errorWeight}
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <label>BMI</label>
              <Input
                disabled
                style={{ backgroundColor: "lightgray", textIndent: "10px" }}
                value={calculateBmi(height, weight)}
              ></Input>
            </Grid>
            {/* {age >= 2 && ( */}
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.receivedDietCounselling
                      ? "red"
                      : "inherit",
                  }}
                >
                  From whom have your Child received diet counselling ?
                </FormLabel>
                <Select
                  value={receivedDietCounselling}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handlerecieveddietcounsellingChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"None"}>None</MenuItem>
                  <MenuItem value={"Doctor"}>Doctor</MenuItem>
                  <MenuItem value={"Dietitian"}>Dietitian</MenuItem>
                  <MenuItem value={"Family Members / Friends"}>
                    Family Members / Friends
                  </MenuItem>
                  <MenuItem value={"Online"}>Online</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                Please rate your Child's Diet choices and behavior on a scale of
                1 to 5 for questions 1 to 12 as explained below WHERE (1=
                Strongly agree. 2= Agree 3= Neutral 4= Disagree 5= Strongly
                disagree).
              </div>
            </Grid>
          </Grid>
          <div className="desktop-view">
            <Row>
              <Col lg={7}></Col>
              <Col lg={5}>
                <div style={{ display: "flex", gap: "85px" }}>
                  <div>1</div>
                  <div>2</div>
                  <div>3</div>
                  <div>4</div>
                  <div>5</div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="desktop-view">
            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.preferLowCarbDiet ? "red" : "inherit",
                    }}
                  >
                    1. Prefer to have a low carb diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={preferLowCarbDiet}
                    onChange={handlepreferLowCarbDietChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.preferVegetarianFood
                        ? "red"
                        : "inherit",
                    }}
                  >
                    2. Mostly prefer Vegetarian food and try to have more
                    vegetables and fruits in my daily diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={preferVegetarianFood}
                    onChange={handlepreferVegetarianFoodChange}
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.avoidProcessedMeat
                        ? "red"
                        : "inherit",
                    }}
                  >
                    3. Tries to avoid processed meat (Hamburgers, sausages, Hot
                    dogs, etc.).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={avoidProcessedMeat}
                    onChange={handleavoidProcessedMeatChange}
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.avoidHighlyProcessedFoods
                        ? "red"
                        : "inherit",
                    }}
                  >
                    4. Tries to avoid highly processed foods (convenience meals,
                    fast goods, cookies, chips, pretzels, etc).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={avoidHighlyProcessedFoods}
                    onChange={handleavoidHighlyProcessedFoodstChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.doNotDrinkSugarSweetenedBeverages
                        ? "red"
                        : "inherit",
                    }}
                  >
                    5. Does not drink sugar sweetened beverages (Pepsi, coke,
                    etc.).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={doNotDrinkSugarSweetenedBeverages}
                    onChange={handledoNotDrinkSugarSweetenedBeveragesChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.regularlyConsumeWholeGrainsLegumes
                        ? "red"
                        : "inherit",
                    }}
                  >
                    6. Regularly consumes whole grains, legumes(Peas and beans)
                    and nuts(walnuts, almond, pecans, etc.) and seeds (Chia,
                    flax, pumpkin,, etc.) as part of my healthy diet practice.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={regularlyConsumeWholeGrainsLegumes}
                    onChange={handleregularlyConsumeWholeGrainsLegumesChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.drinkCoffee ? "red" : "inherit",
                    }}
                  >
                    7. Drinks coffee at least once daily.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={drinkCoffee}
                    onChange={handledrinkCoffeeChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitIntakeOfDairyProducts
                        ? "red"
                        : "inherit",
                    }}
                  >
                    8. Likes to limit intake of dairy products (cheese, cream,
                    Butter and Ghee, Yogurt, etc.) in my diet but do take few
                    times every week.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={limitIntakeOfDairyProducts}
                    onChange={handlelimitIntakeOfDairyProductsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.eatDietWhichIsLowInAddedSugars
                        ? "red"
                        : "inherit",
                    }}
                  >
                    9. Eats a diet which is low in added sugars, sodium,
                    saturated fats, trans fats, and cholesterol.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={eatDietWhichIsLowInAddedSugars}
                    onChange={handleeatDietWhichIsLowInAddedSugarsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitOrDecreaseSaltInMyMeals
                        ? "red"
                        : "inherit",
                    }}
                  >
                    10. Consciously try to limit or decrease salt in my meals.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={limitOrDecreaseSaltInMyMeals}
                    onChange={handlelimitOrDecreaseSaltInMyMealsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.highInFiber ? "red" : "inherit",
                    }}
                  >
                    11. Consciously try to eat food or fruits that are high in
                    fiber.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={highInFiber}
                    onChange={handlehighInFiberChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitOrDecreaseSugarInMyDiet
                        ? "red"
                        : "inherit",
                    }}
                  >
                    12. Consciously try to limit or decrease sugar in my diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      gap: "45px",
                    }}
                    value={limitOrDecreaseSugarInMyDiet}
                    onChange={handlelimitOrDecreaseSugarInMyDietChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      // label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      // label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      // label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      // label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      // label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>
          </div>

          <div className="mobile-view">
            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.preferLowCarbDiet ? "red" : "inherit",
                    }}
                  >
                    1. Prefer to have a low carb diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={preferLowCarbDiet}
                    onChange={handlepreferLowCarbDietChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.preferVegetarianFood
                        ? "red"
                        : "inherit",
                    }}
                  >
                    2. Mostly prefer Vegetarian food and try to have more
                    vegetables and fruits in my daily diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={preferVegetarianFood}
                    onChange={handlepreferVegetarianFoodChange}
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.avoidProcessedMeat
                        ? "red"
                        : "inherit",
                    }}
                  >
                    3. Tries to avoid processed meat (Hamburgers, sausages, Hot
                    dogs, etc.).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={avoidProcessedMeat}
                    onChange={handleavoidProcessedMeatChange}
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.avoidHighlyProcessedFoods
                        ? "red"
                        : "inherit",
                    }}
                  >
                    4. Tries to avoid highly processed foods (convenience meals,
                    fast goods, cookies, chips, pretzels, etc).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={avoidHighlyProcessedFoods}
                    onChange={handleavoidHighlyProcessedFoodstChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.doNotDrinkSugarSweetenedBeverages
                        ? "red"
                        : "inherit",
                    }}
                  >
                    5. Does not drink sugar sweetened beverages (Pepsi, coke,
                    etc.).
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={doNotDrinkSugarSweetenedBeverages}
                    onChange={handledoNotDrinkSugarSweetenedBeveragesChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.regularlyConsumeWholeGrainsLegumes
                        ? "red"
                        : "inherit",
                    }}
                  >
                    6. Regularly consumes whole grains, legumes(Peas and beans)
                    and nuts(walnuts, almond, pecans, etc.) and seeds (Chia,
                    flax, pumpkin,, etc.) as part of my healthy diet practice.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={regularlyConsumeWholeGrainsLegumes}
                    onChange={handleregularlyConsumeWholeGrainsLegumesChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.drinkCoffee ? "red" : "inherit",
                    }}
                  >
                    7. Drinks coffee at least once daily.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={drinkCoffee}
                    onChange={handledrinkCoffeeChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitIntakeOfDairyProducts
                        ? "red"
                        : "inherit",
                    }}
                  >
                    8. Likes to limit intake of dairy products (cheese, cream,
                    Butter and Ghee, Yogurt, etc.) in my diet but do take few
                    times every week.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={limitIntakeOfDairyProducts}
                    onChange={handlelimitIntakeOfDairyProductsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.eatDietWhichIsLowInAddedSugars
                        ? "red"
                        : "inherit",
                    }}
                  >
                    9. Eats a diet which is low in added sugars, sodium,
                    saturated fats, trans fats, and cholesterol.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={eatDietWhichIsLowInAddedSugars}
                    onChange={handleeatDietWhichIsLowInAddedSugarsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitOrDecreaseSaltInMyMeals
                        ? "red"
                        : "inherit",
                    }}
                  >
                    10. Consciously try to limit or decrease salt in my meals.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={limitOrDecreaseSaltInMyMeals}
                    onChange={handlelimitOrDecreaseSaltInMyMealsChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.highInFiber ? "red" : "inherit",
                    }}
                  >
                    11. Consciously try to eat food or fruits that are high in
                    fiber.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={highInFiber}
                    onChange={handlehighInFiberChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>

            <FormControl style={{ width: "-webkit-fill-available" }}>
              <Row>
                <Col lg={7}>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.limitOrDecreaseSugarInMyDiet
                        ? "red"
                        : "inherit",
                    }}
                  >
                    12. Consciously try to limit or decrease sugar in my diet.
                  </FormLabel>
                </Col>
                <Col lg={5}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    style={{
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                    value={limitOrDecreaseSugarInMyDiet}
                    onChange={handlelimitOrDecreaseSugarInMyDietChange}
                  >
                    <FormControlLabel
                      value="Strongly agree"
                      control={<Radio />}
                      label="1"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Agree"
                      control={<Radio />}
                      label="2"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Neutral"
                      control={<Radio />}
                      label="3"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Disagree"
                      control={<Radio />}
                      label="4"
                      style={{ display: "flex" }}
                    />
                    <FormControlLabel
                      value="Strongly disagree"
                      control={<Radio />}
                      label="5"
                      style={{ display: "flex" }}
                    />
                  </RadioGroup>
                </Col>
              </Row>
            </FormControl>
          </div>

          <Grid container spacing={2} mb={5} mt={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors5.selectDiet ? "red" : "inherit",
                  }}
                >
                  In terms of your Child's diet, select one of the following:
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={selectDiet}
                  onChange={handleselectDietChange}
                >
                  <FormControlLabel
                    value="Strictly vegetarian (Eggs not included)"
                    control={<Radio />}
                    label="Strictly vegetarian (Eggs not included)"
                  />
                  <FormControlLabel
                    value="Eggetarian (veg + eggs)"
                    control={<Radio />}
                    label="Eggetarian (veg + eggs)"
                  />
                  <FormControlLabel
                    value="Mixed (includes veg, meat and poultry)"
                    control={<Radio />}
                    label="Mixed (includes veg, meat and poultry)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {selectDiet?.includes(
              "Strictly vegetarian (Eggs not included)"
            ) && (
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.includedInTheDiet ? "red" : "inherit",
                    }}
                  >
                    Which of these do you include in your Child's diet ?
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheDiet?.includes("Yogurt")}
                        />
                      }
                      label="Yogurt"
                      value="Yogurt"
                      onChange={handleincludedInTheDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheDiet?.includes("Low fat milk")}
                        />
                      }
                      label="Low fat milk"
                      value="Low fat milk"
                      onChange={handleincludedInTheDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheDiet?.includes("Cheese")}
                        />
                      }
                      label="Cheese"
                      value="Cheese"
                      onChange={handleincludedInTheDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheDiet?.includes(
                            "Fortified cereals"
                          )}
                        />
                      }
                      label="Fortified cereals"
                      value="Fortified cereals"
                      onChange={handleincludedInTheDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={includedInTheDiet?.includes(
                            "None Of the Above"
                          )}
                        />
                      }
                      label="None Of the Above"
                      value="None Of the Above"
                      onChange={handleincludedInTheDietChange}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
            {selectDiet?.includes("Eggetarian (veg + eggs)") && (
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.oftenEatEggs ? "red" : "inherit",
                    }}
                  >
                    How Often does your Child eat Eggs ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={oftenEatEggs}
                    onChange={handleoftenEatEggsChange}
                  >
                    <FormControlLabel
                      value="Daily"
                      control={<Radio />}
                      label="Daily"
                    />
                    <FormControlLabel
                      value="Once a week"
                      control={<Radio />}
                      label="Once a week"
                    />
                    <FormControlLabel
                      value="Twice a week"
                      control={<Radio />}
                      label="Twice a week"
                    />
                    <FormControlLabel
                      value="Three times a week"
                      control={<Radio />}
                      label="Three times a week"
                    />
                    <FormControlLabel
                      value="> 3 times a week"
                      control={<Radio />}
                      label="> 3 times a week"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            {selectDiet?.includes("Mixed (includes veg, meat and poultry)") && (
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.oftenEatMeat ? "red" : "inherit",
                    }}
                  >
                    How Often does your Child eat Meat ?
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue=""
                    name="radio-buttons-group"
                    value={oftenEatMeat}
                    onChange={handleoftenEatMeatChange}
                  >
                    <FormControlLabel
                      value="Daily"
                      control={<Radio />}
                      label="Daily"
                    />
                    <FormControlLabel
                      value="Once a week"
                      control={<Radio />}
                      label="Once a week"
                    />
                    <FormControlLabel
                      value="Twice a week"
                      control={<Radio />}
                      label="Twice a week"
                    />
                    <FormControlLabel
                      value="Three times a week"
                      control={<Radio />}
                      label="Three times a week"
                    />
                    <FormControlLabel
                      value="> 3 times a week"
                      control={<Radio />}
                      label="> 3 times a week"
                    />
                    <FormControlLabel
                      value="Once a month"
                      control={<Radio />}
                      label="Once a month"
                    />
                    <FormControlLabel
                      value="Less frequently"
                      control={<Radio />}
                      label="Less frequently"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            {selectDiet?.includes("Mixed (includes veg, meat and poultry)") && (
              <Grid item xs={12} md={3}>
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-label"
                    style={{
                      color: fieldErrors5.mixedIncludedInYourDiet
                        ? "red"
                        : "inherit",
                    }}
                  >
                    which of the following you mostly include in your Child's
                    diet ? (check all applicable)
                  </FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mixedIncludedInYourDiet.includes("Eggs")}
                        />
                      }
                      label="Eggs"
                      value="Eggs"
                      onChange={handlemixedIncludedInYourDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mixedIncludedInYourDiet.includes("Fish")}
                        />
                      }
                      label="Fish"
                      value="Fish"
                      onChange={handlemixedIncludedInYourDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mixedIncludedInYourDiet.includes(
                            "Poultry (chicken, Turkey)"
                          )}
                        />
                      }
                      label="Poultry (chicken, Turkey)"
                      value="Poultry (chicken, Turkey)"
                      onChange={handlemixedIncludedInYourDietChange}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={mixedIncludedInYourDiet.includes(
                            "Red Meat (Goat, pork, lamb)"
                          )}
                        />
                      }
                      label="Red Meat (Goat, pork, lamb)"
                      value="Red Meat (Goat, pork, lamb)"
                      onChange={handlemixedIncludedInYourDietChange}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
            {selectDiet?.includes("Strictly vegetarian (Eggs not included)") &&
              includedInTheDiet?.includes("Yogurt") && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors5.oftenEatYogurt ? "red" : "inherit",
                      }}
                    >
                      How Often does your Child eat Yogurt ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={oftenEatYogurt}
                      onChange={handleoftenEatYogurtChange}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Once a week"
                        control={<Radio />}
                        label="Once a week"
                      />
                      <FormControlLabel
                        value="Twice a week"
                        control={<Radio />}
                        label="Twice a week"
                      />
                      <FormControlLabel
                        value="Three times a week"
                        control={<Radio />}
                        label="Three times a week"
                      />
                      <FormControlLabel
                        value="> 3 times a week"
                        control={<Radio />}
                        label="> 3 times a week"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            {selectDiet?.includes("Strictly vegetarian (Eggs not included)") &&
              includedInTheDiet?.includes("Low fat milk") && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors5.oftenEatLowFatMilk
                          ? "red"
                          : "inherit",
                      }}
                    >
                      How Often does your Child drink Low fat milk ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={oftenEatLowFatMilk}
                      onChange={handleoftenEatLowFatMilkChange}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Once a week"
                        control={<Radio />}
                        label="Once a week"
                      />
                      <FormControlLabel
                        value="Twice a week"
                        control={<Radio />}
                        label="Twice a week"
                      />
                      <FormControlLabel
                        value="Three times a week"
                        control={<Radio />}
                        label="Three times a week"
                      />
                      <FormControlLabel
                        value="> 3 times a week"
                        control={<Radio />}
                        label="> 3 times a week"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
            {selectDiet?.includes("Strictly vegetarian (Eggs not included)") &&
              includedInTheDiet?.includes("Cheese") && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors5.oftenEatCheese ? "red" : "inherit",
                      }}
                    >
                      How Often does your Child eat Cheese ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={oftenEatCheese}
                      onChange={handleoftenEatCheeseChange}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Once a week"
                        control={<Radio />}
                        label="Once a week"
                      />
                      <FormControlLabel
                        value="Twice a week"
                        control={<Radio />}
                        label="Twice a week"
                      />
                      <FormControlLabel
                        value="Three times a week"
                        control={<Radio />}
                        label="Three times a week"
                      />
                      <FormControlLabel
                        value="> 3 times a week"
                        control={<Radio />}
                        label="> 3 times a week"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

            {selectDiet?.includes("Strictly vegetarian (Eggs not included)") &&
              includedInTheDiet?.includes("Fortified cereals") && (
                <Grid item xs={12} md={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      style={{
                        color: fieldErrors5.oftenEatFortifiedCereals
                          ? "red"
                          : "inherit",
                      }}
                    >
                      How Often does your Child eat Fortified cereals ?
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="radio-buttons-group"
                      value={oftenEatFortifiedCereals}
                      onChange={handleoftenEatFortifiedCerealsChange}
                    >
                      <FormControlLabel
                        value="Daily"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="Once a week"
                        control={<Radio />}
                        label="Once a week"
                      />
                      <FormControlLabel
                        value="Twice a week"
                        control={<Radio />}
                        label="Twice a week"
                      />
                      <FormControlLabel
                        value="Three times a week"
                        control={<Radio />}
                        label="Three times a week"
                      />
                      <FormControlLabel
                        value="> 3 times a week"
                        control={<Radio />}
                        label="> 3 times a week"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel>
                  What questions do you have today about your child’s nutrition
                  or diet ?
                </FormLabel>
                <Input
                  value={qesAboutNutrition}
                  onChange={handleQesAboutNutrition}
                ></Input>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors5.consultWithDietitian
                      ? "red"
                      : "inherit",
                  }}
                >
                  Would you like to consult a expert dietitian in our network?
                </FormLabel>
                <Select
                  value={consultWithDietitian}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleconsultWithDietitianChange}
                >
                  <MenuItem value={null}>Select</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              click here to read about expert dietitians in our network who can
              recommend for your child.
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={onBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className=""
                onClick={handleSave5}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 5 && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                <h4>2. Exercise</h4>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors6.exercise ? "red" : "inherit",
                  }}
                >
                  1. After school I would normally prefer to…
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="excersie1a"
                  value={exercise}
                  onChange={handleExcersieQuestions}
                >
                  <FormControlLabel
                    value="Watch TV"
                    control={<Radio />}
                    label="Watch TV"
                  />
                  <FormControlLabel
                    value="Do other activities at home"
                    control={<Radio />}
                    label="Do other activities at home"
                  />
                  <FormControlLabel
                    value="Play Indoors"
                    control={<Radio />}
                    label="Play Indoors"
                  />
                  <FormControlLabel
                    value="Take a walk with friends"
                    control={<Radio />}
                    label="Take a walk with friends"
                  />
                  <FormControlLabel
                    value="Play outdoors"
                    control={<Radio />}
                    label="Play Outdoors"
                  />
                  <FormControlLabel
                    value="Run around with friends"
                    control={<Radio />}
                    label="Run around with friends"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors6.exercise2 ? "red" : "inherit",
                  }}
                >
                  2. Watching TV is…
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="exercise2"
                  value={exercise2}
                  onChange={handleExcersieQuestions2}
                >
                  <FormControlLabel
                    value="Boring"
                    control={<Radio />}
                    label="Boring"
                  />
                  <FormControlLabel
                    value="Unimportant"
                    control={<Radio />}
                    label="Unimportant"
                  />
                  <FormControlLabel
                    value="Important"
                    control={<Radio />}
                    label="Important"
                  />
                  <FormControlLabel
                    value="Unhealthy"
                    control={<Radio />}
                    label="Unhealthy"
                  />
                  <FormControlLabel
                    value="Healthy"
                    control={<Radio />}
                    label="Healthy"
                  />
                  <FormControlLabel
                    value="Fun"
                    control={<Radio />}
                    label="Fun"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors6.exercise3 ? "red" : "inherit",
                  }}
                >
                  3. Walking or cycling to get somewhere (such as going to
                  school, shops or friends) is :
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="exercise3"
                  value={exercise3}
                  onChange={handleExcersieQuestions3}
                >
                  <FormControlLabel
                    value="Unhealthy"
                    control={<Radio />}
                    label="Unhealthy"
                  />
                  <FormControlLabel
                    value="Healthy"
                    control={<Radio />}
                    label="Healthy"
                  />
                  <FormControlLabel
                    value="Unimportant"
                    control={<Radio />}
                    label="Unimportant"
                  />
                  <FormControlLabel
                    value="Important"
                    control={<Radio />}
                    label="Important"
                  />
                  <FormControlLabel
                    value="Fun"
                    control={<Radio />}
                    label="Fun"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel>
                  4. Are you ever stopped from doing a physical activity
                  because… ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. There is a programme on TV that you want to watch ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4A"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4A
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. You don’t think you are very good at physical activity
                      ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4B"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4B
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. Other children make fun of you when you are physically
                      active ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4C"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4C
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. You don’t like physical activity ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4D"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4D
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. You don’t have the equipment you need ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4E"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4E
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors6?.physicalActivity4F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. You are scared that you might get hurt ?
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="physicalActivity4F"
                      style={{ flexDirection: "row" }}
                      value={
                        youStoppedDoingPhysicalActivity?.physicalActivity4F
                      }
                      onChange={handlePhysicalActivity}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave6}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 6 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  5. Do you agree with the following statements ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. I can ask my parent or another adult to sign me up for
                      a sport, dance class, or other physical activity
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5A"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5A}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. I can do something active even if it is hot or cold
                      outside
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5B"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5B}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. I can do something active even if I have a lot of
                      homework
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5C"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5C}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. I can ask my parent or another adult to take me to play
                      a sport or do a physical activity.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5D"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5D}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. I can ask my best friend to do something active with
                      me.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5E"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5E}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.agreeStatement5F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. I can do something active no matter how tired I feel.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatement5F"
                      style={{ flexDirection: "row" }}
                      value={agreeStatement.agreeStatement5F}
                      onChange={handleAgreeStatement}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  6. Do you feel that doing physical activity or sports will ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6A
                            ? "red"
                            : "inherit",
                      }}
                    >
                      a. …make you stronger
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6A"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6A
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6B
                            ? "red"
                            : "inherit",
                      }}
                    >
                      b. …keep you from getting too heavy.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6B"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6B
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6C
                            ? "red"
                            : "inherit",
                      }}
                    >
                      c. …make you very tired.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6C"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6C
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6D
                            ? "red"
                            : "inherit",
                      }}
                    >
                      d. …make you feel like you are not good at sports.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6D"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6D
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6E
                            ? "red"
                            : "inherit",
                      }}
                    >
                      e. …make you look better.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6E"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6E
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color:
                          fieldErrors7.doYouFeelDoingPhysicalActOrSportWill6F
                            ? "red"
                            : "inherit",
                      }}
                    >
                      f. …take up too much time.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="doYouFeelDoingPhysicalActOrSportWill6F"
                      style={{ flexDirection: "row" }}
                      value={
                        doYouFeelDoingPhysicalActOrSportWill.doYouFeelDoingPhysicalActOrSportWill6F
                      }
                      onChange={handleDoYouFeelDoingPhysicalActOrSportWill}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  7. During a typical week, do the following things happen ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. My friends do physical activities or play sports with
                      me.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7A"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7A}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. I ask my friends to play outside or play sports with
                      me.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7B"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7B}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. My friends ask me to play outside or play sports with
                      them.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7C"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7C}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. The teacher talks about exercise and sports in lessons.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7D"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7D}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. The teachers organise or play games with us, apart from
                      PE.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7E"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7E}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors7.duringTypicalWeek7F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. The teacher tells me to exercise or play sports.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeek7F"
                      style={{ flexDirection: "row" }}
                      value={duringTypicalWeek.duringTypicalWeek7F}
                      onChange={handleDuringTypicalWeek}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave7}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}
      {LifestyleStep == 7 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  8. Do you agree with the following statements ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. I am not allowed to play outside because my parents
                      think it’s not safe.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8A"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8A}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. At school there are playgrounds or fields where I can
                      run around.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8B"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8B}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. There is somewhere at home where I can go outside and
                      play.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8C"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8C}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. It is safe to walk or play alone in my neighbourhood
                      during the day.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8D"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8D}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. There are other children near my home for me to go out
                      and play with.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8E"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8E}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. There are playgrounds, parks, or sports halls close to
                      my home that I can use.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8F"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8F}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>

              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8G
                          ? "red"
                          : "inherit",
                      }}
                    >
                      g. It is difficult to walk or play near my house because I
                      don’t feel safe.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8G"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8G}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>

              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8H
                          ? "red"
                          : "inherit",
                      }}
                    >
                      h. I always have to tell my parents where I am where I am
                      going.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8H"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8H}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>

              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.agreeStatementSecond8I
                          ? "red"
                          : "inherit",
                      }}
                    >
                      i. If I am going out I always have to be back by a certain
                      time.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="agreeStatementSecond8I"
                      style={{ flexDirection: "row" }}
                      value={agreeStatementSecond?.agreeStatementSecond8I}
                      onChange={handleAgreeStatementSecond}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  9. During a normal week, someone in my family (like my parents
                  or other family members)…
                </FormLabel>
              </FormControl>
            </Grid>

            <div
              className="desktop-view"
              style={{ width: "100%", marginTop: "15px" }}
            >
              <FormControl fullWidth>
                <Row>
                  <Col lg={5}></Col>
                  <Col lg={7}>
                    <Row>
                      <Col lg={3}>Never/Hardly ever</Col>
                      <Col lg={3}>Once/Twice a week</Col>
                      <Col lg={3}>Nearly everyday</Col>
                      <Col lg={3}>Everyday</Col>
                    </Row>
                  </Col>
                </Row>
              </FormControl>
            </div>

            <div style={{ paddingLeft: "16px" }} className="desktop-view">
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. …encourages me to do physical activities or play
                      sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9A"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9A
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. …does a physical activity or plays sports with me.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9B"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9B
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. …takes me to a place where I can do activities or play
                      sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9C"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9C
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. …watches me take part in physical activities or sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9D"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9D
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. …tells me that I am doing well in physical activities
                      or sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9E"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9E
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. …tells me that physical activity is good for my health.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9F"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9F
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            // label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            // label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            // label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3} style={{ textAlign: "center" }}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            // label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>

            <div
              style={{ paddingLeft: "16px", marginTop: "15px" }}
              className="mobile-view"
            >
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. …encourages me to do physical activities or play
                      sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9A"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9A
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. …does a physical activity or plays sports with me.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9B"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9B
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. …takes me to a place where I can do activities or play
                      sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9C"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9C
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. …watches me take part in physical activities or sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9D"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9D
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. …tells me that I am doing well in physical activities
                      or sports.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9E"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9E
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors8.duringTypicalWeekSomeoneInFamily9F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. …tells me that physical activity is good for my health.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="duringTypicalWeekSomeoneInFamily9F"
                      style={{ flexDirection: "row" }}
                      value={
                        duringTypicalWeekSomeoneInFamily?.duringTypicalWeekSomeoneInFamily9F
                      }
                      onChange={handleDuringTypicalWeekSomeoneInFamily}
                    >
                      <Row style={{ width: "100%" }}>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Never/Hardly ever"
                            control={<Radio />}
                            label="Never/Hardly ever"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Once/Twice a week"
                            control={<Radio />}
                            label="Once/Twice a week"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Nearly everyday"
                            control={<Radio />}
                            label="Nearly everyday"
                          />
                        </Col>
                        <Col lg={3}>
                          <FormControlLabel
                            value="Everyday"
                            control={<Radio />}
                            label="Everyday"
                          />
                        </Col>
                      </Row>
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors8.comparedWithOtherChild
                      ? "red"
                      : "inherit",
                  }}
                >
                  10. Compared with other boys or girls your age, would you say
                  that you were…
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="comparedWithOtherChild"
                  value={comparedWithOtherChild}
                  onChange={handleComparedWithOtherChild}
                >
                  <FormControlLabel
                    value="Much more active"
                    control={<Radio />}
                    label="Much more active"
                  />
                  <FormControlLabel
                    value="More active"
                    control={<Radio />}
                    label="More active"
                  />
                  <FormControlLabel
                    value="About average"
                    control={<Radio />}
                    label="About average"
                  />
                  <FormControlLabel
                    value="Less active"
                    control={<Radio />}
                    label="Less active"
                  />
                  <FormControlLabel
                    value="Much less active"
                    control={<Radio />}
                    label="Much less active"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors8.howDoYouTravelToSchool
                      ? "red"
                      : "inherit",
                  }}
                >
                  11. How do you usually travel to school ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="howDoYouTravelToSchool"
                  value={howDoYouTravelToSchool}
                  onChange={handlehowDoYouTravelToSchool}
                >
                  <FormControlLabel
                    value="By Car"
                    control={<Radio />}
                    label="By Car"
                  />
                  <FormControlLabel
                    value="By Bus or train"
                    control={<Radio />}
                    label="By Bus or train"
                  />
                  <FormControlLabel
                    value="By bicycle"
                    control={<Radio />}
                    label="By bicycle"
                  />
                  <FormControlLabel
                    value="On foot"
                    control={<Radio />}
                    label="On foot"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors8.whoDoYouTravelWithSchool
                      ? "red"
                      : "inherit",
                  }}
                >
                  12. Who do you usually travel to school with ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="whoDoYouTravelWithSchool"
                  value={whoDoYouTravelWithSchool}
                  onChange={handlewhoDoYouTravelWithSchool}
                >
                  <FormControlLabel
                    value="Alone"
                    control={<Radio />}
                    label="Alone"
                  />
                  <FormControlLabel
                    value="A brother or sister"
                    control={<Radio />}
                    label="A brother or sister"
                  />
                  <FormControlLabel
                    value="A parent or another adult"
                    control={<Radio />}
                    label="A parent or another adult"
                  />
                  <FormControlLabel
                    value="Friend(s)"
                    control={<Radio />}
                    label="Friend(s)"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors8.doYouGoAnywhereElseFromHome
                      ? "red"
                      : "inherit",
                  }}
                >
                  13. Do you usually go anywhere else on your way home from
                  school?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="doYouGoAnywhereElseFromHome"
                  value={doYouGoAnywhereElseFromHome}
                  onChange={handledoYouGoAnywhereElseFromHome}
                >
                  <FormControlLabel
                    value="No, I go straight home"
                    control={<Radio />}
                    label="No, I go straight home"
                  />
                  <FormControlLabel
                    value="Go to a friend’s house"
                    control={<Radio />}
                    label="Go to a friend’s house"
                  />
                  <FormControlLabel
                    value="Go to the shops"
                    control={<Radio />}
                    label="Go to the shops"
                  />
                  <FormControlLabel
                    value="Go to the park"
                    control={<Radio />}
                    label="Go to the park"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave8}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}
      {LifestyleStep == 8 && (
        <>
          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  14. Do you agree with the following statements about your
                  journey to and from school ?
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. I can chat to my friends on my journey to school.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10A"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10A}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. My parents think it is not safe to walk or cycle to
                      school.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10B"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10B}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. My friends encourage me to walk or cycle to school.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10C"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10C}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. My journey to school gives me exercise.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10D"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10D}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10E
                          ? "red"
                          : "inherit",
                      }}
                    >
                      e. There are nice things to look at on my way to school.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10E"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10E}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10F
                          ? "red"
                          : "inherit",
                      }}
                    >
                      f. Walking or cycling to school takes up too much time.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10F"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10F}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>

              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={8}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.journeyToAndFromSchool10G
                          ? "red"
                          : "inherit",
                      }}
                    >
                      g. My parents encourage me to walk or cycle to school.
                    </FormLabel>
                  </Col>
                  <Col lg={4}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="journeyToAndFromSchool10G"
                      style={{ flexDirection: "row" }}
                      value={journeyToAndFromSchool?.journeyToAndFromSchool10G}
                      onChange={handleJourneyToTheSchool}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  15. How you do you usually travel to…
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.howDoYouTravel11A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. Friends in the neighbourhood
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="howDoYouTravel11A"
                      style={{ flexDirection: "row" }}
                      value={howDoYouTravel?.howDoYouTravel11A}
                      onChange={handlehowDoYouTravelTo}
                    >
                      <FormControlLabel
                        value="Car"
                        control={<Radio />}
                        label="Car"
                      />

                      <FormControlLabel
                        value="Bus/train"
                        control={<Radio />}
                        label="Bus/train"
                      />

                      <FormControlLabel
                        value="Bicycle"
                        control={<Radio />}
                        label="Bicycle"
                      />

                      <FormControlLabel
                        value="Walk"
                        control={<Radio />}
                        label="Walk"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.howDoYouTravel11B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. The park.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="howDoYouTravel11B"
                      style={{ flexDirection: "row" }}
                      value={howDoYouTravel?.howDoYouTravel11B}
                      onChange={handlehowDoYouTravelTo}
                    >
                      <FormControlLabel
                        value="Car"
                        control={<Radio />}
                        label="Car"
                      />

                      <FormControlLabel
                        value="Bus/train"
                        control={<Radio />}
                        label="Bus/train"
                      />

                      <FormControlLabel
                        value="Bicycle"
                        control={<Radio />}
                        label="Bicycle"
                      />

                      <FormControlLabel
                        value="Walk"
                        control={<Radio />}
                        label="Walk"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.howDoYouTravel11C
                          ? "red"
                          : "inherit",
                      }}
                    >
                      c. Other members of your family.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="howDoYouTravel11C"
                      style={{ flexDirection: "row" }}
                      value={howDoYouTravel?.howDoYouTravel11C}
                      onChange={handlehowDoYouTravelTo}
                    >
                      <FormControlLabel
                        value="Car"
                        control={<Radio />}
                        label="Car"
                      />

                      <FormControlLabel
                        value="Bus/train"
                        control={<Radio />}
                        label="Bus/train"
                      />

                      <FormControlLabel
                        value="Bicycle"
                        control={<Radio />}
                        label="Bicycle"
                      />

                      <FormControlLabel
                        value="Walk"
                        control={<Radio />}
                        label="Walk"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={5}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.howDoYouTravel11D
                          ? "red"
                          : "inherit",
                      }}
                    >
                      d. The shops.
                    </FormLabel>
                  </Col>
                  <Col lg={7}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="howDoYouTravel11D"
                      style={{ flexDirection: "row" }}
                      value={howDoYouTravel?.howDoYouTravel11D}
                      onChange={handlehowDoYouTravelTo}
                    >
                      <FormControlLabel
                        value="Car"
                        control={<Radio />}
                        label="Car"
                      />

                      <FormControlLabel
                        value="Bus/train"
                        control={<Radio />}
                        label="Bus/train"
                      />

                      <FormControlLabel
                        value="Bicycle"
                        control={<Radio />}
                        label="Bicycle"
                      />

                      <FormControlLabel
                        value="Walk"
                        control={<Radio />}
                        label="Walk"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: "inherit",
                  }}
                >
                  16. When you are at school what do you mostly do at…
                </FormLabel>
              </FormControl>
            </Grid>

            <div style={{ paddingLeft: "16px" }}>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={3}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.whatDoYouDoInSchool12A
                          ? "red"
                          : "inherit",
                      }}
                    >
                      a. Morning break
                    </FormLabel>
                  </Col>
                  <Col lg={9}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="whatDoYouDoInSchool12A"
                      style={{ flexDirection: "row" }}
                      value={whatDoYouDoInSchool?.whatDoYouDoInSchool12A}
                      onChange={handleWhatDoYouDoInSchool}
                    >
                      <FormControlLabel
                        value="Sit down Talking/reading"
                        control={<Radio />}
                        label="Sit down Talking/reading"
                      />

                      <FormControlLabel
                        value="Stand or Walk around"
                        control={<Radio />}
                        label="Stand or Walk around"
                      />

                      <FormControlLabel
                        value="Run around Playing games"
                        control={<Radio />}
                        label="Run around Playing games"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
              <FormControl fullWidth>
                <Row style={{ alignItems: "center" }}>
                  <Col lg={3}>
                    <FormLabel
                      style={{
                        color: fieldErrors9.whatDoYouDoInSchool12B
                          ? "red"
                          : "inherit",
                      }}
                    >
                      b. Lunch break (apart from eating lunch).
                    </FormLabel>
                  </Col>
                  <Col lg={9}>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue=""
                      name="whatDoYouDoInSchool12B"
                      style={{ flexDirection: "row" }}
                      value={whatDoYouDoInSchool?.whatDoYouDoInSchool12B}
                      onChange={handleWhatDoYouDoInSchool}
                    >
                      <FormControlLabel
                        value="Sit down Talking/reading"
                        control={<Radio />}
                        label="Sit down Talking/reading"
                      />

                      <FormControlLabel
                        value="Stand or Walk around"
                        control={<Radio />}
                        label="Stand or Walk around"
                      />

                      <FormControlLabel
                        value="Run around Playing games"
                        control={<Radio />}
                        label="Run around Playing games"
                      />
                    </RadioGroup>
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave9}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 9 && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                <p>
                  A.{" "}
                  <a href="#" onClick={handleModerateIntensityClick}>
                    Moderate intensity
                  </a>{" "}
                  &nbsp; exercise includes physical activities that get you
                  breathing harder and your heart beating faster. Examples of
                  exercise include setting aside time for things like: jogging,
                  dancing, bike riding, aerobic classes, swimming, working out
                  to an exercise video. Exercise does not include what you do at
                  work. Use this definition to answer the questions below.
                </p>
              </div>
              <div>
                Use the above definition and tell us how many days in the past
                week (last 7 days) and how much time each day{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>{" "}
                does one or more of these sports/exercises.{" "}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <TableContainer component={Paper} sx={{ border: "1px solid #ccc" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Sports/Exercise</TableCell>
                    <TableCell>No. of days (in a week)</TableCell>
                    <TableCell>Time each day (min.)</TableCell>
                    <TableCell>Total Time (min)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exerciseData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          value={row.name}
                          onChange={(e) =>
                            handleInputChange(index, "name", e.target.value)
                          }
                          placeholder="Enter Exercise Name"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.days}
                          onChange={(e) =>
                            handleInputChange(index, "days", e.target.value)
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.timeEachDay}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "timeEachDay",
                              e.target.value
                            )
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.totalTime}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Button onClick={handleAddRow}>Other + (Add)</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                <p>
                  B.{" "}
                  <a href="#" onClick={handleVigorousIntensityClick}>
                    Vigorous Intensity{" "}
                  </a>
                  exercise includes physical activities like Race walking,
                  jogging, running , Bicycling 10 mph or faster , Swimming laps
                  , Aerobic dancing , Jumping rope , Heavy gardening (continuous
                  digging or hoeing with heart rate increases) , Hiking uphill
                  or with a heavy backpack.
                </p>
              </div>
              <div>
                Use the above definition and tell us how many days in the past
                week (last 7 days) and how much time each day{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>{" "}
                does one or more of these sports/exercises.{" "}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <TableContainer component={Paper} sx={{ border: "1px solid #ccc" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Sports/Exercise</TableCell>
                    <TableCell>No. of days (in a week)</TableCell>
                    <TableCell>Time each day (min.)</TableCell>
                    <TableCell>Total Time (min)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vigorousexerciseData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          value={row.name}
                          onChange={(e) =>
                            handleVigorousIntensityInputChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                          placeholder="Enter Exercise Name"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.days}
                          onChange={(e) =>
                            handleVigorousIntensityInputChange(
                              index,
                              "days",
                              e.target.value
                            )
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.timeEachDay}
                          onChange={(e) =>
                            handleVigorousIntensityInputChange(
                              index,
                              "timeEachDay",
                              e.target.value
                            )
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.totalTime}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Button onClick={handleVigorousIntensityAddRow}>
                        Other + (Add)
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave10}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 10 && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                <p>
                  C.{" "}
                  <a href="#" onClick={handleMildIntensityClick}>
                    Regular mild or Mild Intensity{" "}
                  </a>
                  is any physical exercise that gets your heart rate less than
                  50% higher than your resting heart rate. Examples includes-
                  walking slowly, using computer, standing light work (cooking,
                  washing dishes), fishing, playing most instruments, etc. Use
                  this definition to answer the questions below.
                </p>
              </div>
              <div>
                Use the above definition and tell us how many days in the past
                week (last 7 days) and how much time each day{" "}
                <span style={{ fontSize: "larger" }}>
                  {familyInfo?.firstName &&
                    familyInfo.firstName.charAt(0).toUpperCase() +
                      familyInfo.firstName.slice(1).toLowerCase()}
                </span>{" "}
                does one or more of these sports/exercises.{" "}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <TableContainer component={Paper} sx={{ border: "1px solid #ccc" }}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                    <TableCell>Sports/Exercise</TableCell>
                    <TableCell>No. of days (in a week)</TableCell>
                    <TableCell>Time each day (min.)</TableCell>
                    <TableCell>Total Time (min)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mildexerciseData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          value={row.name}
                          onChange={(e) =>
                            handleMildIntensityInputChange(
                              index,
                              "name",
                              e.target.value
                            )
                          }
                          placeholder="Enter Exercise Name"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.days}
                          onChange={(e) =>
                            handleMildIntensityInputChange(
                              index,
                              "days",
                              e.target.value
                            )
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={row.timeEachDay}
                          onChange={(e) =>
                            handleMildIntensityInputChange(
                              index,
                              "timeEachDay",
                              e.target.value
                            )
                          }
                          // fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              padding: "8px 12px",
                              fontSize: "0.875rem",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>{row.totalTime}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Button onClick={handleMildIntensityAddRow}>
                        Other + (Add)
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container spacing={2} mb={5}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors11.comparisonLastThreeMonthsToSevenDays
                      ? "red"
                      : "inherit",
                  }}
                >
                  Compared to how physically active{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  have been over the last 3 months, how would you describe the
                  last 7 days ?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={comparisonLastThreeMonthsToSevenDays}
                  onChange={handlecomparisonLastThreeMonthsToSevenDaysChange}
                >
                  <FormControlLabel
                    value="More active"
                    control={<Radio />}
                    label="More active"
                  />
                  <FormControlLabel
                    value="About the same"
                    control={<Radio />}
                    label="About the same"
                  />
                  <FormControlLabel
                    value="Less Active"
                    control={<Radio />}
                    label="Less Active"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{
                    color: fieldErrors11.selectedActivity ? "red" : "inherit",
                  }}
                >
                  What forms of activity does{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  undertake regularly?
                </FormLabel>
                <FormGroup>
                  {[
                    "Walking",
                    "Running",
                    "Yoga",
                    "Aerobic",
                    "Resistance / Weight training",
                    "None",
                  ].map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedActivity?.includes(option)}
                          onChange={() =>
                            handleselectedActivityCheckboxChange(option)
                          }
                        />
                      }
                      label={option.charAt(0).toUpperCase() + option.slice(1)} // Capitalize the first letter
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors11.hoursOfExercise ? "red" : "inherit",
                  }}
                >
                  How many hours did{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  exercise in the last 7 days ?
                </FormLabel>
                <Select
                  value={hoursOfExercise}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handlehoursOfExerciseChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"0"}>0</MenuItem>
                  <MenuItem value={"1-2"}>1-2</MenuItem>
                  <MenuItem value={"2-3"}>2-3</MenuItem>
                  <MenuItem value={"3-4"}>3-4</MenuItem>
                  <MenuItem value={"4-5"}>4-5</MenuItem>
                  <MenuItem value={"6"}>more than 5 hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2} mt={2}>
            <Grid item>
              <div>
                E.
                <span style={{ color: "blue" }}>Sedentary Exercise</span>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <FormLabel
                  style={{
                    color: fieldErrors11.totalHoursOfSitting
                      ? "red"
                      : "inherit",
                  }}
                >
                  How many total hours{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  sit (sitting time) in a day? (include time sitting while
                  studying, watching movies or video, talking on mobile, sitting
                  in school, etc.) ?
                </FormLabel>
                <Select
                  value={totalHoursOfSitting}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handletotalHoursOfSitting}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"4"}>&lt; 4</MenuItem>
                  <MenuItem value={"5"}>5</MenuItem>
                  <MenuItem value={"6"}>6</MenuItem>
                  <MenuItem value={"7"}>7</MenuItem>
                  <MenuItem value={"8"}>8</MenuItem>
                  <MenuItem value={"9"}>9</MenuItem>
                  <MenuItem value={"10"}>10</MenuItem>
                  <MenuItem value={"11"}>11</MenuItem>
                  <MenuItem value={"12"}>12</MenuItem>
                  <MenuItem value={"13"}>&gt; 12</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={onBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className=""
                onClick={handleSave11}
              >
                Next
              </button>
            </div>
          </Grid>
        </>
      )}

      {LifestyleStep == 11 && (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item>
              <div>
                <h4>3. Sleep</h4>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors12.totalHourSleep ? "red" : "inherit",
                  }}
                >
                  How many total hours of sleep does{" "}
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  typically gets in a day ?
                </FormLabel>
                <Select
                  value={hoursOfSleep}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handlehoursOfSleepChange}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"3"}>&lt; 4 hours</MenuItem>
                  <MenuItem value={"4"}>4 hours</MenuItem>
                  <MenuItem value={"5"}>5 hours</MenuItem>
                  <MenuItem value={"6"}>6 hours</MenuItem>
                  <MenuItem value={"7"}>7 hours</MenuItem>
                  <MenuItem value={"8"}>8 hours</MenuItem>
                  <MenuItem value={"9"}>9 hours</MenuItem>
                  <MenuItem value={"10"}>&gt; 9 hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors12.sleepAroundSameTime
                      ? "red"
                      : "inherit",
                  }}
                >
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  usually go to sleep around the same time everyday.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={sleepAroundSameTime}
                  onChange={handlesleepAroundSameTimeChange}
                >
                  <FormControlLabel
                    value="Always"
                    control={<Radio />}
                    label="Always"
                  />
                  <FormControlLabel
                    value="Often"
                    control={<Radio />}
                    label="Often"
                  />
                  <FormControlLabel
                    value="Sometimes"
                    control={<Radio />}
                    label="Sometimes"
                  />
                  <FormControlLabel
                    value="Rarely"
                    control={<Radio />}
                    label="Rarely"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors12.satisfiedWithSleep ? "red" : "inherit",
                  }}
                >
                  You are satisfied with NAME’s sleep.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={satisfiedWithSleep}
                  onChange={handlesatisfiedWithSleepChange}
                >
                  <FormControlLabel
                    value="Always"
                    control={<Radio />}
                    label="Always"
                  />
                  <FormControlLabel
                    value="Often"
                    control={<Radio />}
                    label="Often"
                  />
                  <FormControlLabel
                    value="Sometimes"
                    control={<Radio />}
                    label="Sometimes"
                  />
                  <FormControlLabel
                    value="Rarely"
                    control={<Radio />}
                    label="Rarely"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors12.difficultyWithSleep
                      ? "red"
                      : "inherit",
                  }}
                >
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  have difficulty falling asleep.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={difficultyWithSleep}
                  onChange={handledifficultyWithSleepChange}
                >
                  <FormControlLabel
                    value="Always"
                    control={<Radio />}
                    label="Always"
                  />
                  <FormControlLabel
                    value="Often"
                    control={<Radio />}
                    label="Often"
                  />
                  <FormControlLabel
                    value="Sometimes"
                    control={<Radio />}
                    label="Sometimes"
                  />
                  <FormControlLabel
                    value="Rarely"
                    control={<Radio />}
                    label="Rarely"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={3}>
              <FormControl>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  style={{
                    color: fieldErrors12.feelRefreshedAfterSleep
                      ? "red"
                      : "inherit",
                  }}
                >
                  <span style={{ fontSize: "larger" }}>
                    {familyInfo?.firstName &&
                      familyInfo.firstName.charAt(0).toUpperCase() +
                        familyInfo.firstName.slice(1).toLowerCase()}
                  </span>{" "}
                  wakes up refreshed after sleep.
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue=""
                  name="radio-buttons-group"
                  value={feelRefreshedAfterSleep}
                  onChange={handlefeelRefreshedAfterSleepChange}
                >
                  <FormControlLabel
                    value="Always"
                    control={<Radio />}
                    label="Always"
                  />
                  <FormControlLabel
                    value="Often"
                    control={<Radio />}
                    label="Often"
                  />
                  <FormControlLabel
                    value="Sometimes"
                    control={<Radio />}
                    label="Sometimes"
                  />
                  <FormControlLabel
                    value="Rarely"
                    control={<Radio />}
                    label="Rarely"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            mb={0}
            mt={3}
            justifyContent="space-between"
            display={"flex"}
            gap={5}
          >
            <div className="submit-button">
              <button className="base" onClick={handleLifestyleStepBack}>
                Back
              </button>
            </div>
            <div className="submit-button">
              <button
                style={{ minWidth: "200px" }}
                type="button"
                className="base"
                onClick={handleSave12}
              >
                Submit
              </button>
            </div>
          </Grid>
        </>
      )}
      {/* </form> */}
    </div>
  );
};

export default ChildrenLifestyle3to13Question;
