import React from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { ModalFooter } from "reactstrap";
import Swal from "sweetalert2";

const MedicalLabApproval = ({
  selectedUser,
  approval,
  onCloseOpenModel,
  getAllUnApprovedPartners,
}) => {
  const handleSubmit = async (status) => {
    const partnerType = approval;
    const userId = selectedUser.userId;
    const formData = {
      approvedDetail: "",
      userId: userId,
      approveFlag: status,
      userTypeId: partnerType,
    };
    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          if (status == true) {
            Swal.fire({
              icon: "success",
              title: "Approve!",
              text: "Profile Approved SuccessFully!",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Decline!",
              text: "Profile Decline SuccessFully!",
            });
          }
          onCloseOpenModel();
          getAllUnApprovedPartners();
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  const labName = selectedUser?.labName || "N/A";
  const adminName =
    `${selectedUser?.firstName || ""} ${selectedUser?.lastName || ""}`.trim() ||
    "N/A";
  const phoneNumber = selectedUser?.phoneNumber || "N/A";
  const email = selectedUser?.email || "N/A";

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Card sx={{ mx: "auto", boxShadow: 3 }}>
          <CardContent>
            <Typography
              variant="h5"
              gutterBottom
              textAlign="center"
              fontWeight="bold"
            >
              Lab Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Lab Name:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {labName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Administrator/Coordinator Name:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {adminName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Phone Number:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Email:
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {email}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
          <ModalFooter>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleSubmit(true)}
              sx={{ mr: 2 }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleSubmit(false)}
            >
              Decline
            </Button>
          </ModalFooter>
        </Card>
      </Box>
    </>
  );
};
export default MedicalLabApproval;
