import React, { useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Swal from "sweetalert2";
import {
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { FormLabel } from "@mui/material";

const DietitianDieasesApproval = ({
  selectedUser,
  approval,
  onCloseOpenModel,
  getAllUnApprovedPartners,
}) => {
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectedGender, setSelectedGender] = useState({});
  const [selectedAgeGroups, setSelectedAgeGroups] = useState({});
  const handleSubmit = async (status) => {
    const userId = selectedUser?.userId;
    const partnerType = approval;
    const result = getSelectedData();
    const approveDetail =
      Object.keys(result).length === 0 ? null : JSON.stringify(result);
    const formData = {
      approvedDetail: approveDetail,
      userId: userId,
      approveFlag: status,
      userTypeId: partnerType,
    };

    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          if (status == true) {
            Swal.fire({
              icon: "success",
              title: "Approve!",
              text: "Profile Approved SuccessFully!",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Decline!",
              text: "Profile Decline SuccessFully!",
            });
          }
          onCloseOpenModel();
          getAllUnApprovedPartners();
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  // List of diseases, gender options, and age groups
  const diseases = [
    "Hypertension",
    "Breast Cancer",
    "Cervical Cancer",
    "Colorectal Cancer",
    "Lung Cancer",
    "CVD",
    "Obesity",
    "Diabetes",
    "CKD",
    "PCOD",
    "Thyroid",
    "High Cholesterol",
    "Acute Renal Failure/Kidney Injury",
    "Congenital Heart Disease",
    "Prostate Cancer",
    "Rheumatoid Arthritis",
    "Myocardial Infarction (Heart Attack)",
  ];

  const genderOptions = ["Male", "Female", "Both"];

  const ageGroups = ["0-3", "3-13", "13-21", "Greater than 21"];

  // Handler for checking/unchecking diseases
  const handleDiseaseChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDiseases((prev) =>
      checked ? [...prev, value] : prev.filter((disease) => disease !== value)
    );
  };

  // Handler for selecting gender for a particular disease
  const handleGenderChange = (disease, event) => {
    const { value } = event.target;
    setSelectedGender((prev) => ({
      ...prev,
      [disease]: value,
    }));
  };

  // Handler for checking/unchecking age groups for a particular disease
  const handleAgeGroupChange = (disease, event) => {
    const { value, checked } = event.target;
    setSelectedAgeGroups((prev) => ({
      ...prev,
      [disease]: checked
        ? [...(prev[disease] || []), value]
        : prev[disease].filter((ageGroup) => ageGroup !== value),
    }));
  };

  // Function to format the selected data into the desired JSON structure
  const getSelectedData = () => {
    const result = {};

    // Loop through selected diseases and create the structure
    selectedDiseases.forEach((disease) => {
      result[disease] = {
        gender: selectedGender[disease] || "", // Default to empty string if no gender is selected
        ageGroup: selectedAgeGroups[disease]?.join(", ") || "", // Join age groups if multiple are selected
      };
    });
    return result;
  };

  return (
    <Form
      style={{
        border: "1px solid lightgrey",
        padding: "15px",
        marginTop: "25px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <h5>Internal Diseases Approval</h5>

      <Table striped bordered style={{ marginTop: "20px" }}>
        <thead>
          <tr>
            <th>Diseases</th>
            <th>Gender</th>
            <th>Age Group</th>
          </tr>
        </thead>
        <tbody>
          {diseases.map((disease, index) => (
            <tr key={index}>
              <td>
                <FormGroup check inline>
                  {" "}
                  <Input
                    type="checkbox"
                    value={disease}
                    checked={selectedDiseases.includes(disease)}
                    onChange={handleDiseaseChange}
                  />
                  <Label>{disease}</Label>
                </FormGroup>
              </td>
              <td>
                {genderOptions.map((gender) => (
                  <FormGroup check inline key={gender}>
                    <Input
                      type="radio"
                      name={`gender-${disease}`}
                      value={gender}
                      checked={selectedGender[disease] === gender}
                      onChange={(event) => handleGenderChange(disease, event)}
                    />
                    <Label>{gender}</Label>
                  </FormGroup>
                ))}
              </td>
              <td>
                {ageGroups.map((ageGroup) => (
                  <FormGroup check inline key={ageGroup}>
                    <Input
                      type="checkbox"
                      value={ageGroup}
                      checked={
                        selectedAgeGroups[disease]?.includes(ageGroup) || false
                      }
                      onChange={(event) => handleAgeGroupChange(disease, event)}
                    />
                    <Label>{ageGroup}</Label>
                  </FormGroup>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Footer with the Approve/Reject Buttons */}
      <ModalFooter>
        <Button color="success" onClick={() => handleSubmit(true)}>
          Approve
        </Button>
        <Button color="danger" onClick={() => handleSubmit(false)}>
          Decline
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default DietitianDieasesApproval;
