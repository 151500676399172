import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { DescribeChannelModeratorResponseFilterSensitiveLog, } from "../models/models_0";
import { de_DescribeChannelModeratorCommand, se_DescribeChannelModeratorCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class DescribeChannelModeratorCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "DescribeChannelModerator", {})
    .n("ChimeSDKMessagingClient", "DescribeChannelModeratorCommand")
    .f(void 0, DescribeChannelModeratorResponseFilterSensitiveLog)
    .ser(se_DescribeChannelModeratorCommand)
    .de(de_DescribeChannelModeratorCommand)
    .build() {
}
