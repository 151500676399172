import React, { useEffect, useState } from "react";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  Form,
  FormGroup,
  FormFeedback,
  FormControlLabel,
  FormText,
  Label,
  Input,
  Row,
  Col,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getAddressInfo } from "../../constants/apiList";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Spinner from "../../shared/Spinner";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

const stateList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands (UT)",
  "Chandigarh (UT)",
  "Dadra and Nagar Haveli and Daman & Diu (UT)",
  "Delhi (UT)",
  "Jammu & Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
  "Other Territory (UT)",
];

const LabAddress = ({ handleTabChange }) => {
  const [fieldErrors, setFieldErrors] = useState({});
  const [fileNames, setFileNames] = useState(null);
  const [fileErrors, setFileErrors] = useState(null);
  const [uploadingIndex, setUploadingIndex] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setsubmitting] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [open, setOpen] = useState(false);
  const [visibleInputs, setVisibleInputs] = useState([]);

  const [formData, setFormData] = useState({
    id: null,
    labAddress: "",
    stateAdminName: "",
    stateAdminEmail: "",
    numberOfCityLocation: "",
    state: "",
    labPhoneNumber: "",
    labPinCode: "",
    coordinatorDetails: [
      {
        id: null,
        adminName: "",
        adminPhoneNumber: "",
        adminEmail: "",
        accessNeeded: false,
      },
    ],
    timings: {
      Monday: { from: "", to: "" },
      Tuesday: { from: "", to: "" },
      Wednesday: { from: "", to: "" },
      Thursday: { from: "", to: "" },
      Friday: { from: "", to: "" },
      Saturday: { from: "", to: "" },
      Sunday: { from: "", to: "" },
    },
    locationLabPinCode: {
      serviceAreaPinCode: [],
      sampleCollectionTiming: {
        Monday: { from: "", to: "" },
        Tuesday: { from: "", to: "" },
        Wednesday: { from: "", to: "" },
        Thursday: { from: "", to: "" },
        Friday: { from: "", to: "" },
        Saturday: { from: "", to: "" },
        Sunday: { from: "", to: "" },
      },
    },
  });

  const [addressrow, setAddressRow] = useState([
    // {
    //   id: null,
    //   labAddress: "",
    //   stateAdminName: "",
    //   stateAdminEmail: "",
    //   numberOfCityLocation: "",
    //   state: "",
    //   labPhoneNumber: "",
    //   labPinCode: "",
    //   coordinatorDetails: [
    //     {
    //       id: null,
    //       adminName: "",
    //       adminPhoneNumber: "",
    //       adminEmail: "",
    //       accessNeeded: false,
    //     },
    //   ],
    //   timings: {
    //     Monday: { from: "", to: "" },
    //     Tuesday: { from: "", to: "" },
    //     Wednesday: { from: "", to: "" },
    //     Thursday: { from: "", to: "" },
    //     Friday: { from: "", to: "" },
    //     Saturday: { from: "", to: "" },
    //     Sunday: { from: "", to: "" },
    //   },
    //   locationLabPinCode: {
    //     serviceAreaPinCode: [],
    //     sampleCollectionTiming: {
    //       Monday: { from: "", to: "" },
    //       Tuesday: { from: "", to: "" },
    //       Wednesday: { from: "", to: "" },
    //       Thursday: { from: "", to: "" },
    //       Friday: { from: "", to: "" },
    //       Saturday: { from: "", to: "" },
    //       Sunday: { from: "", to: "" },
    //     },
    //   },
    // },
  ]);
  const addAddressRow = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetFormData();
    setOpen(false);
  };

  // const addPincode = (addressIndex) => {
  //   const newAddressRows = Array.from(addressrow);
  //   // Ensure locationLabPinCode is always treated as an array
  //   newAddressRows[addressIndex].locationLabPinCode =
  //     newAddressRows[addressIndex].locationLabPinCode || {};
  //   newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode =
  //     newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode || [];

  //   // Add a new empty string to serviceAreaPinCode array
  //   newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode = [
  //     ...newAddressRows[addressIndex].locationLabPinCode.serviceAreaPinCode,
  //     "",
  //   ];
  //   setAddressRow(newAddressRows);
  //   setAddingPincodeFor((prev) => ({
  //     ...prev,
  //     [addressIndex]: true,
  //   }));
  // };
  const addPincode = () => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.locationLabPinCode.serviceAreaPinCode.push(""); // Add a new empty string to serviceAreaPinCode
      return updatedData;
    });
    setVisibleInputs([...visibleInputs, ""]);
  };
  const handleAddressChange = (index, e) => {
    const newRows = [...addressrow];
    newRows[index][e.target.name] = e.target.value;
    setAddressRow(newRows);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFieldErrors((prevErrors) => {
      if (prevErrors[name]) {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      }
      return prevErrors;
    });
  };

  const handleAdminChange = (adminIndex, field, value) => {
    setFormData((prevData) => {
      const updatedFormData = { ...prevData };
      updatedFormData.coordinatorDetails[adminIndex][field] = value;
      return updatedFormData;
    });
  };

  const addAdminRow = () => {
    setFormData((prevData) => {
      const updatedFormData = { ...prevData };
      updatedFormData.coordinatorDetails.push({
        id: null,
        adminName: "",
        adminPhoneNumber: "",
        adminEmail: "",
        accessNeeded: false,
      });
      return updatedFormData;
    });
  };

  const handlePincodeChange = (index, event) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.locationLabPinCode.serviceAreaPinCode[index] =
        event.target.value;
      return updatedData;
    });
  };
  const handleLabAddress = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labAddress;
      }

      return updatedErrors;
    });
  };
  const handlestateAdminName = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].stateAdminName;
      }

      return updatedErrors;
    });
  };
  const handlestateAdminEmail = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].stateAdminEmail;
      }

      return updatedErrors;
    });
  };
  const handlenumberOfCityLocation = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].numberOfCityLocation;
      }

      return updatedErrors;
    });
  };
  const handleAdminState = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].state;
      }

      return updatedErrors;
    });
  };
  const handlelabPhoneNumber = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labPhoneNumber;
      }

      return updatedErrors;
    });
  };
  const handlePinCode = (event, index) => {
    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (updatedErrors[index]) {
        // Remove labAddress error for this index if it exists
        delete updatedErrors[index].labPinCode;
      }

      return updatedErrors;
    });
  };
  const uploadFile = async (file, serviceAreaAddressId) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      // Notify the user if upload is successful
      AxiosInstance.post(
        `lab-partner/service-pinCode-import?serviceAreaAddressId=${serviceAreaAddressId}`,
        formData
      )
        .then((response) => {
          toast.success("File uploaded successfully!");
          setUploadingIndex(false);
          setFileNames(null);
        })
        .catch((error) => {
          console.log(error);
          setUploadingIndex(false);
        });
    } catch (error) {
      console.error("File upload failed:", error);
      toast.error("File upload failed. Please try again.");
      setUploadingIndex(false);
    }
  };
  const handleFileChange = (e, serviceAreaAddressId) => {
    const file = e.target.files[0]; // Get the selected file
    const allowedExtensions = /(\.xlsx|\.xls|\.csv)$/i;
    if (file && allowedExtensions.test(file.name)) {
      // Clear previous errors
      setFileErrors(null);

      // Store the file name
      setFileNames(file);
      setUploadingIndex(true);

      if (serviceAreaAddressId != null) {
        uploadFile(file, serviceAreaAddressId);
      } else {
        setUploadingIndex(false);
        toast.success("File Attached Successfully");
      }

      // Send the file to the API
    } else {
      // Show error message if the file is not an Excel file
      setFileErrors("Only Excel files (.xlsx, .xls, .csv) are allowed.");
      setFileNames(null);
    }
    console.log("serviceAreaAddressId", serviceAreaAddressId);
  };
  const handleLocPinCode = (event) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      locPincode: false,
    }));
  };

  const handleTimingChange = (day, field, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.timings[day][field] = value;
      return updatedData;
    });

    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // Clean up the field errors for timings
      if (updatedErrors.timings) {
        delete updatedErrors.timings[day];
        if (Object.keys(updatedErrors.timings).length === 0) {
          delete updatedErrors.timings;
        }
      }

      return updatedErrors;
    });
  };

  const handleSampleCollectionTimingChange = (day, field, value) => {
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.locationLabPinCode.sampleCollectionTiming[day][field] = value;
      return updatedData;
    });

    setFieldErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      // Clean up the field errors for timings
      if (updatedErrors.sampleTimings) {
        delete updatedErrors.sampleTimings[day];
        if (Object.keys(updatedErrors.sampleTimings).length === 0) {
          delete updatedErrors.sampleTimings;
        }
      }

      return updatedErrors;
    });
  };
  const transformTimingsToLabTiming = (timings) => {
    return Object.keys(timings).map((day) => ({
      day: day,
      startTime: timings[day].from,
      endTime: timings[day].to,
    }));
  };
  const transformLabTimingToTimings = (labTimingArray) => {
    return labTimingArray.reduce((acc, item) => {
      acc[item.day] = {
        from: item.startTime,
        to: item.endTime,
      };
      return acc;
    }, {});
  };
  const resetFormData = () => {
    setFormData({
      id: null,
      labAddress: "",
      stateAdminName: "",
      stateAdminEmail: "",
      numberOfCityLocation: "",
      state: "",
      labPhoneNumber: "",
      labPinCode: "",
      coordinatorDetails: [
        {
          id: null,
          adminName: "",
          adminPhoneNumber: "",
          adminEmail: "",
          accessNeeded: false,
        },
      ],
      timings: {
        Monday: { from: "", to: "" },
        Tuesday: { from: "", to: "" },
        Wednesday: { from: "", to: "" },
        Thursday: { from: "", to: "" },
        Friday: { from: "", to: "" },
        Saturday: { from: "", to: "" },
        Sunday: { from: "", to: "" },
      },
      locationLabPinCode: {
        serviceAreaPinCode: [],
        sampleCollectionTiming: {
          Monday: { from: "", to: "" },
          Tuesday: { from: "", to: "" },
          Wednesday: { from: "", to: "" },
          Thursday: { from: "", to: "" },
          Friday: { from: "", to: "" },
          Saturday: { from: "", to: "" },
          Sunday: { from: "", to: "" },
        },
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let error = {};

    if (!formData.stateAdminName.trim()) {
      error.stateAdminName = true;
    }
    if (!formData.labAddress.trim()) {
      error.labAddress = true;
    }
    if (formData.state == undefined || formData.state == "") {
      error.state = true;
    }

    if (formData.labPhoneNumber == undefined || formData.labPhoneNumber == "") {
      error.labPhoneNumber = true;
    }
    if (
      formData.stateAdminEmail == undefined ||
      formData.stateAdminEmail == ""
    ) {
      error.stateAdminEmail = true;
    }
    if (formData.labPinCode == undefined || formData.labPinCode == "") {
      error.labPinCode = true;
    }
    if (
      formData.numberOfCityLocation == undefined ||
      formData.numberOfCityLocation == ""
    ) {
      error.numberOfCityLocation = true;
    }

    const isTimingsValid = Object.values(formData.timings).some(
      (day) => day.from.trim() !== "" && day.to.trim() !== ""
    );

    if (!isTimingsValid) {
      error.timings = true;
    }
    const isSampleTimingsValid = Object.values(
      formData.locationLabPinCode.sampleCollectionTiming
    ).some((day) => day.from.trim() !== "" && day.to.trim() !== "");
    if (!isSampleTimingsValid) {
      error.sampleTimings = true;
    }

    setFieldErrors(error);
    if (Object.keys(error).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Fill all the Mandatory Fields!",
        customClass: {
          container: "swal-container-above-modal", // Custom class for Swal
        },
      });
    } else {
      const transformedData = {
        id: formData?.id ? formData?.id : null,
        labPinCode: formData.labPinCode,
        labAddress: formData.labAddress,
        stateAdminName: formData.stateAdminName,
        state: formData.state,
        stateAdminEmail: formData.stateAdminEmail,
        numberOfCityLocation: parseInt(formData.numberOfCityLocation),
        labPhoneNumber: formData.labPhoneNumber,
        labTiming: transformTimingsToLabTiming(formData.timings),
        coordinatorDetails: formData.coordinatorDetails,
        locationLabPinCode: {
          serviceAreaPinCode: formData.locationLabPinCode?.serviceAreaPinCode
            ?.filter((loc) => loc !== null && loc !== undefined && loc !== "")
            .map((loc) => loc),
          sampleCollectionTiming: transformTimingsToLabTiming(
            formData.locationLabPinCode.sampleCollectionTiming
          ),
        },
      };
      console.log("tranformed data ", transformedData);
      setsubmitting(true);
      if (formData.id != null) {
        AxiosInstance.put(
          "lab-partner/update-lab-location-address",
          transformedData
        )
          .then((response) => {
            toast.success("Information Updated successfully");
            setsubmitting(false);
            handleClose();
            GetAddressInfo();
          })
          .catch((error) => {
            console.log(error);
            setsubmitting(false);
          });
      } else {
        AxiosInstance.post("lab-partner/lab-location-address", transformedData)
          .then((response) => {
            console.log("getresponse", response);

            if (fileNames != null) {
              const documentformData = new FormData();
              documentformData.append("file", fileNames);
              const serviceAreaAddressId = response.id;

              const formattedTiming = Object.entries(
                formData.locationLabPinCode.sampleCollectionTiming
              ).map(([day, times]) => ({
                day,
                startTime: times.from,
                endTime: times.to,
              }));
              const formattedTimingString = encodeURIComponent(
                JSON.stringify(formattedTiming)
              );

              try {
                // Notify the user if upload is successful
                AxiosInstance.post(
                  `lab-partner/service-pinCode-import?serviceAreaAddressId=${serviceAreaAddressId}&sampleCollectionTiming=${formattedTimingString}`,
                  documentformData
                )
                  .then((response) => {
                    toast.success("Information submitted successfully");
                    setFileNames(null);
                    setsubmitting(false);
                    resetFormData();
                    setOpen(false);
                    GetAddressInfo();
                  })
                  .catch((error) => {
                    console.log(error);
                    setsubmitting(false);
                  });
              } catch (error) {
                console.error("File upload failed:", error);
                toast.error("File upload failed. Please try again.");
                setsubmitting(false);
              }
            } else {
              setsubmitting(false);
              resetFormData();
              setOpen(false);
              GetAddressInfo();
            }
          })
          .catch((error) => {
            console.log(error);
            setsubmitting(false);
          });
      }
    }
  };

  const GetAddressInfo = async () => {
    setLoading(true);
    await AxiosInstance.get(`${getAddressInfo}`)
      .then((response) => {
        // const lab = response.map((item, index) => item)
        if (response != null) {
          const createDefaultTimings = () => ({
            Monday: { from: "", to: "" },
            Tuesday: { from: "", to: "" },
            Wednesday: { from: "", to: "" },
            Thursday: { from: "", to: "" },
            Friday: { from: "", to: "" },
            Saturday: { from: "", to: "" },
            Sunday: { from: "", to: "" },
          });
          const createDefaultAdminDetails = () => [
            {
              id: null,
              adminName: "",
              adminPhoneNumber: "",
              adminEmail: "",
              accessNeeded: false,
            },
          ];
          const updatedData = response.map((item) => {
            return {
              id: item.id || null,
              labPinCode: item.labPinCode || "",
              stateAdminName: item.stateAdminName || "",
              stateAdminEmail: item.stateAdminEmail || "",
              numberOfCityLocation: item.numberOfCityLocation || "",
              state: item.state || "",
              labAddress: item.labAddress || "",
              labPhoneNumber: item.labPhoneNumber || "",
              coordinatorDetails: item.coordinatorDetails
                ? item.coordinatorDetails
                : createDefaultAdminDetails(),
              timings: item.labTiming
                ? transformLabTimingToTimings(item.labTiming)
                : createDefaultTimings(),
              locationLabPinCode: {
                serviceAreaPinCode:
                  item.locationLabPinCode?.serviceAreaPinCode || [],
                sampleCollectionTiming: item.locationLabPinCode
                  ?.sampleCollectionTiming
                  ? transformLabTimingToTimings(
                      item.locationLabPinCode.sampleCollectionTiming
                    )
                  : createDefaultTimings(),
              },
            };
          });

          setAddressRow(updatedData);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err: ", err);
      });
  };
  useEffect(() => {
    GetAddressInfo();
  }, []);
  const handleEditClick = (index, row) => {
    // setSelectedIndex(index);
    console.log("index", index, row);

    setFormData(row);
    addAddressRow();
  };

  const filteredRows = selectedState
    ? addressrow.filter((row) => row.state === selectedState)
    : addressrow;
  console.log("address ", formData, addressrow);
  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="danger" outline onClick={addAddressRow}>
              Click here to add more Locations
            </Button>
            <div>
              <Label style={{ marginRight: "10px" }}>
                <b>Filter by State:</b>
              </Label>
              <Select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                displayEmpty
                style={{ width: "200px" }}
              >
                <MenuItem value="">Select State</MenuItem>
                {stateList.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          {filteredRows.length > 0 ? (
            filteredRows?.map((row, addressIndex) => (
              <>
                <Form
                  style={{
                    border: "1px solid lightgrey",
                    padding: "15px",
                    marginTop: "20px",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    fontSize: "18px",
                  }}
                >
                  <>
                    <Row
                      className="align-items-center mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Col
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          onClick={() => handleEditClick(addressIndex, row)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`stateAdminName${addressIndex}`}>
                            <b>State Admin/Coordinator Name</b>
                          </Label>
                          <Input
                            for={`stateAdminName${addressIndex}`}
                            name="stateAdminName"
                            placeholder="Enter State Admin Name"
                            value={row?.stateAdminName}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labPhoneNumber${addressIndex}`}>
                            <b>State Admin/Coordinator Phone Number</b>
                          </Label>
                          <Input
                            for={`labPhoneNumber${addressIndex}`}
                            name="labPhoneNumber"
                            placeholder="Enter Location Phone Number"
                            value={row?.labPhoneNumber}
                            type="number"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`stateAdminEmail${addressIndex}`}>
                            <b>State Admin/Coordinator Email</b>
                          </Label>
                          <Input
                            for={`stateAdminEmail${addressIndex}`}
                            name="stateAdminEmail"
                            placeholder="Enter State Admin Email"
                            value={row?.stateAdminEmail}
                            type="email"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labPinCode${addressIndex}`}>
                            <b>Pin Code</b>
                          </Label>
                          <Input
                            for={`labPinCode${addressIndex}`}
                            name="labPinCode"
                            placeholder="Enter Location PinCode"
                            value={row?.labPinCode}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`numberOfCityLocation${addressIndex}`}>
                            <b>No. Of City Locations</b>
                          </Label>
                          <Input
                            for={`numberOfCityLocation${addressIndex}`}
                            name="numberOfCityLocation"
                            placeholder="Enter No. Of City Location"
                            value={row?.numberOfCityLocation}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`state${addressIndex}`}>
                            <b>State</b>
                          </Label>
                          <Autocomplete
                            options={stateList} // The list of states
                            value={row?.state || ""} // Current state value for the specific address row
                            disabled
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="State"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  style: {
                                    backgroundColor: "#f5f5f5", // Light grey background
                                    color: "#333", // Darker text color for visibility
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label for={`labAddress${addressIndex}`}>
                            <b>Location Address</b>
                          </Label>
                          <Input
                            for={`labAddress${addressIndex}`}
                            name="labAddress"
                            placeholder="Enter Location Address"
                            value={row.labAddress}
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr style={{ border: "1px solid #000000" }}></hr>

                    {row.coordinatorDetails &&
                      row.coordinatorDetails.length > 0 &&
                      !(
                        row.coordinatorDetails.length === 1 &&
                        row.coordinatorDetails[0].id === null &&
                        row.coordinatorDetails[0].adminName === "" &&
                        row.coordinatorDetails[0].adminPhoneNumber === "" &&
                        row.coordinatorDetails[0].adminEmail === "" &&
                        row.coordinatorDetails[0].accessNeeded === false
                      ) && (
                        <>
                          {row.coordinatorDetails?.map(
                            (adminRow, adminIndex) => (
                              <Row key={adminIndex} className="mt-4">
                                <Col md={4}>
                                  <FormGroup>
                                    <Label for={`adminName${adminIndex}`}>
                                      <b>Administrator/Coordinator Name</b>
                                    </Label>
                                    <Input
                                      id={`adminName${adminIndex}`}
                                      name="adminName"
                                      placeholder="Enter Name"
                                      type="text"
                                      value={adminRow.adminName}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Label
                                      for={`adminPhoneNumber${adminIndex}`}
                                    >
                                      <b>Phone Number</b>
                                    </Label>
                                    <Input
                                      id={`adminPhoneNumber${adminIndex}`}
                                      name="adminPhoneNumber"
                                      placeholder="Enter Phone Number"
                                      type="number"
                                      value={adminRow.adminPhoneNumber}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={2}>
                                  <FormGroup>
                                    <Label for={`adminEmail${adminIndex}`}>
                                      <b>Email Id</b>
                                    </Label>
                                    <Input
                                      id={`adminEmail${adminIndex}`}
                                      name="adminEmail"
                                      placeholder="Enter Email Id"
                                      type="email"
                                      value={adminRow.adminEmail}
                                      disabled
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={4}>
                                  <FormGroup>
                                    <Label
                                      for={`adminAccessNeeded${adminIndex}`}
                                    >
                                      <b>
                                        Does this person needs access to this
                                        account?
                                      </b>
                                    </Label>
                                    <br />
                                    <Input
                                      name={`adminAccessNeeded${adminIndex}`}
                                      type="radio"
                                      checked={!adminRow.accessNeeded}
                                      disabled
                                    />
                                    &nbsp;
                                    <Label check className="ml-1">
                                      No
                                    </Label>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Input
                                      name={`adminAccessNeeded${adminIndex}`}
                                      type="radio"
                                      checked={adminRow.accessNeeded}
                                      disabled
                                    />
                                    &nbsp;
                                    <Label check className="ml-1">
                                      Yes
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                            )
                          )}
                          <hr style={{ border: "1px solid #000000" }}></hr>
                        </>
                      )}

                    {row.timings && (
                      <Row className="mt-5">
                        <Col md={12}>
                          <Label>
                            <b>Working Timings</b>
                          </Label>
                        </Col>

                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(row.timings).map((day) => (
                              <tr
                                key={day}
                                style={{
                                  backgroundColor:
                                    Object.keys(row.timings).indexOf(day) %
                                      2 ===
                                    0
                                      ? "#f9f9f9"
                                      : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={row.timings[day].from}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={row.timings[day].to}
                                    disabled
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    )}

                    <hr style={{ border: "1px solid #000000" }}></hr>
                    <Row>
                      <Col md={12} className="mt-3">
                        <FormGroup>
                          <Label for={`LocPincode${addressIndex}`}>
                            <b>
                              List all PIN Codes where this location will
                              provide home collection service
                            </b>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    {row?.locationLabPinCode &&
                      (row.locationLabPinCode.serviceAreaPinCode.length > 0 ? (
                        <>
                          <div>
                            <button
                              onClick={() => handleTabChange(null, 2, row)}
                              style={{
                                background: "none",
                                border: "none",
                                padding: 0,
                                textDecoration: "underline",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              View All Pincodes
                            </button>
                          </div>
                        </>
                      ) : (
                        <Typography
                          variant="h6"
                          color="textSecondary"
                          sx={{ mb: 2 }}
                        >
                          No Pincode Available
                        </Typography>
                      ))}

                    {row.locationLabPinCode && (
                      <Row className="mt-5">
                        <Col md={12}>
                          <Label>
                            <b>Sample Collection Timings.</b>
                          </Label>
                        </Col>

                        <Table
                          bordered
                          responsive
                          style={{ border: "1px solid #ddd" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background:
                                  "linear-gradient(90deg,royalblue,mediumvioletred)",
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              <th>Day</th>
                              <th>From</th>
                              <th>To</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(
                              row.locationLabPinCode.sampleCollectionTiming
                            ).map((day) => (
                              <tr
                                key={day}
                                style={{
                                  backgroundColor:
                                    Object.keys(
                                      row.locationLabPinCode
                                        .sampleCollectionTiming
                                    ).indexOf(day) %
                                      2 ===
                                    0
                                      ? "#f9f9f9"
                                      : "#ffffff",
                                  textAlign: "center",
                                }}
                              >
                                <td>
                                  <b style={{ color: "#333" }}>{day}</b>
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={
                                      row.locationLabPinCode
                                        .sampleCollectionTiming[day].from
                                    }
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Input
                                    type="time"
                                    value={
                                      row.locationLabPinCode
                                        .sampleCollectionTiming[day].to
                                    }
                                    disabled
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Row>
                    )}
                  </>
                </Form>
              </>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40vh",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 2 }}>
                No Branches Available.
              </Typography>
              <SentimentDissatisfiedIcon fontSize="large" color="disabled" />
            </Box>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
          >
            <Box
              sx={{
                width: "90%", // Medium width (adjust as needed)
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                maxHeight: "80vh", // Limit height
                overflowY: "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography id="modal-title" variant="h6">
                  Add New State
                </Typography>
                <IconButton onClick={handleClose} size="small">
                  <CloseIcon />
                </IconButton>
              </Box>

              <Form
                style={{
                  border: "1px solid lightgrey",
                  padding: "15px",
                  marginTop: "20px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  fontSize: "18px",
                }}
              >
                <>
                  <Row className="mt-4">
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`stateAdminName`}>
                          <b>State Admin/Coordinator Name</b>
                        </Label>
                        <Input
                          for={`stateAdminName`}
                          name="stateAdminName"
                          placeholder="Enter State Admin Name"
                          value={formData?.stateAdminName}
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            borderColor: fieldErrors?.stateAdminName
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`labPhoneNumber`}>
                          <b>State Admin/Coordinator Phone Number</b>
                        </Label>
                        <Input
                          for={`labPhoneNumber`}
                          name="labPhoneNumber"
                          placeholder="Enter Location Phone Number"
                          value={formData?.labPhoneNumber}
                          type="text"
                          max={10}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Allow only digits and restrict to 10 characters
                            if (/^\d{0,10}$/.test(value)) {
                              handleChange(e);
                            }
                          }}
                          style={{
                            borderColor: fieldErrors?.labPhoneNumber
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`stateAdminEmail`}>
                          <b>State Admin/Coordinator Email</b>
                        </Label>
                        <Input
                          for={`stateAdminEmail`}
                          name="stateAdminEmail"
                          placeholder="Enter State Admin Email"
                          value={formData?.stateAdminEmail}
                          type="email"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            borderColor: fieldErrors?.stateAdminEmail
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`labPinCode`}>
                          <b>Pin Code</b>
                        </Label>
                        <Input
                          for={`labPinCode`}
                          name="labPinCode"
                          placeholder="Enter Location PinCode"
                          value={formData?.labPinCode}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type="number"
                          style={{
                            borderColor: fieldErrors?.labPinCode
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`numberOfCityLocation`}>
                          <b>No. Of City Locations</b>
                        </Label>
                        <Input
                          for={`numberOfCityLocation`}
                          name="numberOfCityLocation"
                          placeholder="Enter No. Of City Location"
                          value={formData?.numberOfCityLocation}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          type="number"
                          style={{
                            borderColor: fieldErrors?.numberOfCityLocation
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`state`}>
                          <b>State</b>
                        </Label>
                        <Autocomplete
                          options={stateList} // The list of states
                          value={formData.state || ""} // Current state value for the specific address row
                          onChange={(event, newValue) => {
                            // Create a synthetic event to use handleAddressChange
                            const syntheticEvent = {
                              target: {
                                name: "state", // The field name in addressrow
                                value: newValue || "", // Set the new state value or empty string
                              },
                            };
                            handleChange(syntheticEvent);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State"
                              variant="outlined"
                              fullWidth
                              error={!!fieldErrors?.state}
                              helperText={fieldErrors?.state || ""}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`labAddress`}>
                          <b>Location Address</b>
                        </Label>
                        <Input
                          for={`labAddress`}
                          name="labAddress"
                          placeholder="Enter Location Address"
                          value={formData.labAddress}
                          type="text"
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          style={{
                            borderColor: fieldErrors?.labAddress
                              ? "red"
                              : "lightgrey",
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr style={{ border: "1px solid #000000" }}></hr>
                  <p style={{ color: "red" }}>
                    Want to add more Administrator/Coordinator then click on
                    "Add Administrator/Coordinator"
                  </p>

                  {formData.coordinatorDetails?.map((adminRow, adminIndex) => (
                    <Row key={adminIndex} className="mt-4">
                      <Col md={3}>
                        <FormGroup>
                          <Label for={`adminName${adminIndex}`}>
                            <b>Administrator/Coordinator Name</b>
                          </Label>
                          <Input
                            id={`adminName${adminIndex}`}
                            name="adminName"
                            placeholder="Enter Name"
                            type="text"
                            value={adminRow.adminName}
                            onChange={(e) =>
                              handleAdminChange(
                                adminIndex,
                                "adminName",
                                e.target.value
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for={`adminPhoneNumber${adminIndex}`}>
                            <b>Phone Number</b>
                          </Label>
                          <Input
                            id={`adminPhoneNumber${adminIndex}`}
                            name="adminPhoneNumber"
                            placeholder="Enter Phone Number"
                            type="text"
                            maxLength={10}
                            value={adminRow.adminPhoneNumber}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits and restrict to 10 characters
                              if (/^\d{0,10}$/.test(value)) {
                                handleAdminChange(
                                  adminIndex,
                                  "adminPhoneNumber",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                          <Label for={`adminEmail${adminIndex}`}>
                            <b>Email Id</b>
                          </Label>
                          <Input
                            id={`adminEmail${adminIndex}`}
                            name="adminEmail"
                            placeholder="Enter Email Id"
                            type="email"
                            value={adminRow.adminEmail}
                            onChange={(e) =>
                              handleAdminChange(
                                adminIndex,
                                "adminEmail",
                                e.target.value
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Label for={`adminAccessNeeded${adminIndex}`}>
                            <b>
                              Does this person needs access to this account?
                            </b>
                          </Label>
                          <br />
                          <Input
                            name={`adminAccessNeeded${adminIndex}`}
                            type="radio"
                            checked={!adminRow.accessNeeded}
                            onChange={() =>
                              handleAdminChange(
                                adminIndex,
                                "accessNeeded",
                                false
                              )
                            }
                          />
                          &nbsp;
                          <Label check className="ml-1">
                            No
                          </Label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <Input
                            name={`adminAccessNeeded${adminIndex}`}
                            type="radio"
                            checked={adminRow.accessNeeded}
                            onChange={() =>
                              handleAdminChange(
                                adminIndex,
                                "accessNeeded",
                                true
                              )
                            }
                          />
                          &nbsp;
                          <Label check className="ml-1">
                            Yes
                          </Label>
                        </FormGroup>
                      </Col>
                      {formData.coordinatorDetails.length - 1 ===
                        adminIndex && (
                        <Col md={2}>
                          <FormGroup>
                            <Button
                              color="danger"
                              outline
                              style={{ marginTop: "33px", fontSize: "12px" }}
                              onClick={() => addAdminRow()}
                            >
                              Add Administrator/Coordinator
                            </Button>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  ))}
                  <hr style={{ border: "1px solid #000000" }}></hr>

                  {formData.timings && (
                    <Row className="mt-5">
                      <Col md={12}>
                        <Label>
                          <b>Working Timings</b>
                        </Label>
                      </Col>

                      <Table
                        bordered
                        responsive
                        style={{ border: "1px solid #ddd" }}
                      >
                        <thead>
                          <tr
                            style={{
                              background:
                                "linear-gradient(90deg,royalblue,mediumvioletred)",
                              color: "white",
                              textAlign: "center",
                            }}
                          >
                            <th>Day</th>
                            <th>From</th>
                            <th>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(formData.timings).map((day) => (
                            <tr
                              key={day}
                              style={{
                                backgroundColor:
                                  Object.keys(formData.timings).indexOf(day) %
                                    2 ===
                                  0
                                    ? "#f9f9f9"
                                    : "#ffffff",
                                textAlign: "center",
                              }}
                            >
                              <td>
                                <b style={{ color: "#333" }}>{day}</b>
                              </td>
                              <td>
                                <Input
                                  type="time"
                                  value={formData.timings[day].from}
                                  onChange={(e) =>
                                    handleTimingChange(
                                      day,
                                      "from",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    borderColor: fieldErrors[day]?.from
                                      ? "red"
                                      : "#ddd",
                                    textAlign: "center",
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  type="time"
                                  value={formData.timings[day].to}
                                  onChange={(e) =>
                                    handleTimingChange(
                                      day,
                                      "to",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    borderColor: fieldErrors[day]?.to
                                      ? "red"
                                      : "#ddd",
                                    textAlign: "center",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {fieldErrors?.timings && (
                        <p className="error">
                          Provide at least one working timing.
                        </p>
                      )}
                    </Row>
                  )}

                  <hr style={{ border: "1px solid #000000" }}></hr>
                  <Row>
                    <Col md={7} className="mt-3">
                      <FormGroup>
                        <Label for={`LocPincode`}>
                          <b>
                            List all PIN Codes where this location will provide
                            home collection service
                          </b>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* {formData.locationLabPinCode?.serviceAreaPinCode?.map(
                    (prow, index) => (
                      <>
                        <Row key={index} className="mt-2">
                          <Col md="4">
                            <FormGroup>
                              <Input
                                // id={`locPincode${index}`}
                                id={`locPincode-${index}`}
                                name="locPincode"
                                placeholder="Enter Pincode"
                                value={prow || ""}
                                type="number"
                                onChange={(e) => {
                                  handlePincodeChange(index, e);
                                  // handleLocPinCode(e);
                                }}
                                style={{
                                  borderColor: fieldErrors.locPincode
                                    ? "red"
                                    : "lightgrey",
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )
                  )} */}
                  {visibleInputs.map((_, index) => (
                    <Row key={index} className="mt-2">
                      <Col md="4">
                        <FormGroup>
                          <Input
                            id={`locPincode-${index}`}
                            name="locPincode"
                            placeholder="Enter Pincode"
                            value={
                              formData.locationLabPinCode.serviceAreaPinCode[
                                formData.locationLabPinCode.serviceAreaPinCode
                                  .length -
                                  visibleInputs.length +
                                  index
                              ] || ""
                            }
                            type="number"
                            onChange={(e) =>
                              handlePincodeChange(
                                formData.locationLabPinCode.serviceAreaPinCode
                                  .length -
                                  visibleInputs.length +
                                  index,
                                e
                              )
                            }
                            style={{ borderColor: "lightgrey" }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ))}

                  <Col md={12} className="mt-2">
                    <FormGroup
                      style={{
                        gap: "20px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="danger"
                        outline
                        style={{ fontSize: "15px" }}
                        onClick={() => addPincode()}
                      >
                        Add More Pincode
                      </Button>
                      <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                        OR
                      </span>

                      <Button
                        color="primary"
                        outline
                        style={{ fontSize: "15px" }}
                        onClick={() =>
                          document.getElementById(`attachFile`).click()
                        }
                      >
                        {uploadingIndex ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "Attach Pincode File*"
                        )}
                      </Button>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        id={`attachFile`}
                        onChange={(e) => handleFileChange(e, formData.id)}
                      />

                      {fileNames && (
                        <div
                          style={{
                            fontSize: "12px",
                            color: "grey",
                            marginTop: "2px",
                          }}
                        >
                          {fileNames.name}
                        </div>
                      )}
                    </FormGroup>

                    <div className="mt-2">
                      <b>*Note:</b> Please Attach Excel File Only (Only One
                      Column Pin Code)
                    </div>

                    {fileErrors && (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginTop: "5px",
                        }}
                      >
                        {fileErrors}
                      </div>
                    )}
                  </Col>
                  {formData.locationLabPinCode && (
                    <Row className="mt-5">
                      <Col md={12}>
                        <Label>
                          <b>Sample Collection Timings.</b>
                        </Label>
                      </Col>

                      <Table
                        bordered
                        responsive
                        style={{ border: "1px solid #ddd" }}
                      >
                        <thead>
                          <tr
                            style={{
                              background:
                                "linear-gradient(90deg,royalblue,mediumvioletred)",
                              color: "white",
                              textAlign: "center",
                            }}
                          >
                            <th>Day</th>
                            <th>From</th>
                            <th>To</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(
                            formData.locationLabPinCode.sampleCollectionTiming
                          ).map((day) => (
                            <tr
                              key={day}
                              style={{
                                backgroundColor:
                                  Object.keys(
                                    formData.locationLabPinCode
                                      .sampleCollectionTiming
                                  ).indexOf(day) %
                                    2 ===
                                  0
                                    ? "#f9f9f9"
                                    : "#ffffff",
                                textAlign: "center",
                              }}
                            >
                              <td>
                                <b style={{ color: "#333" }}>{day}</b>
                              </td>
                              <td>
                                <Input
                                  type="time"
                                  value={
                                    formData.locationLabPinCode
                                      .sampleCollectionTiming[day].from
                                  }
                                  onChange={(e) =>
                                    handleSampleCollectionTimingChange(
                                      day,
                                      "from",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    borderColor: fieldErrors[day]?.from
                                      ? "red"
                                      : "#ddd",
                                    textAlign: "center",
                                  }}
                                />
                              </td>
                              <td>
                                <Input
                                  type="time"
                                  value={
                                    formData.locationLabPinCode
                                      .sampleCollectionTiming[day].to
                                  }
                                  onChange={(e) =>
                                    handleSampleCollectionTimingChange(
                                      day,
                                      "to",
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    borderColor: fieldErrors[day]?.to
                                      ? "red"
                                      : "#ddd",
                                    textAlign: "center",
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      {fieldErrors?.sampleTimings && (
                        <p className="error">
                          Provide at least one Sample Collection timing.
                        </p>
                      )}
                    </Row>
                  )}
                </>
              </Form>

              {/* Save Button */}
              <center>
                <Button
                  onClick={handleSubmit}
                  style={{
                    marginTop: "4%",
                    width: "50%",
                    backgroundColor: "rgb(83, 83, 233)",
                  }}
                >
                  {submitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : formData.id ? (
                    "Update"
                  ) : (
                    "Save"
                  )}
                </Button>
              </center>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};
export default LabAddress;
