import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Button,
  Table,
} from "reactstrap";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import Swal from "sweetalert2";

const TrainingApproval = ({
  selectedUser,
  approval,
  onCloseOpenModel,
  getAllUnApprovedPartners,
}) => {
  // State for storing selected diseases, gender, and age groups
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [selectedGender, setSelectedGender] = useState({}); // Store gender per disease
  const [selectedAgeGroups, setSelectedAgeGroups] = useState({}); // Store age groups per disease

  // List of diseases, gender options, and age groups
  const diseases = ["Jumba", "CrossFit", "Yoga"];
  const genderOptions = ["Male", "Female", "Both"];
  const ageGroups = ["0-3", "3-13", "13-21", "Greater than 21"];

  // Handler for checking/unchecking diseases
  const handleDiseaseChange = (event) => {
    const { value, checked } = event.target;
    setSelectedDiseases((prev) =>
      checked ? [...prev, value] : prev.filter((disease) => disease !== value)
    );
  };

  // Handler for selecting gender for a specific training
  const handleGenderChange = (event, disease) => {
    setSelectedGender((prev) => ({
      ...prev,
      [disease]: event.target.value,
    }));
  };

  // Handler for checking/unchecking age groups for a specific training
  const handleAgeGroupChange = (event, disease) => {
    const { value, checked } = event.target;
    setSelectedAgeGroups((prev) => ({
      ...prev,
      [disease]: checked
        ? [...(prev[disease] || []), value]
        : prev[disease].filter((ageGroup) => ageGroup !== value),
    }));
  };

  // Function to get structured selected data
  const getSelectedData = () => {
    const result = {};
    selectedDiseases.forEach((disease) => {
      result[disease] = {
        gender: selectedGender[disease] || "",
        ageGroup: (selectedAgeGroups[disease] || []).join(", "),
      };
    });
    console.log("Selected Data:", result);
    return result;
  };

  const handleSubmit = async (status) => {
    const approveDetail =
      Object.keys(getSelectedData()).length === 0
        ? null
        : JSON.stringify(getSelectedData());
    const userId = selectedUser.userId;
    const formData = {
      approvedDetail: approveDetail,
      userId: userId,
      approveFlag: status,
      userTypeId: approval,
    };

    console.log("formData" , formData)

    try {
      await AxiosInstance.post(`admin/profile-approve`, formData)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: status ? "Approved!" : "Declined!",
            text: status
              ? "Profile Approved Successfully!"
              : "Profile Declined Successfully!",
          });
          onCloseOpenModel();
          getAllUnApprovedPartners();
        })
        .catch((err) => {
          console.log("Upload error:", err);
        });
    } catch (error) {
      console.error("Error uploading invoice:", error);
    }
  };

  return (
    <Form
      style={{
        border: "1px solid lightgrey",
        padding: "15px",
        marginTop: "25px",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
      }}
    >
      <h5>Internal Training Approval</h5>

      <Table striped bordered style={{ marginTop: "20px" }}>
        <thead>
          <tr>
            <th>Trainings</th>
            <th>Gender</th>
            <th>Age Group</th>
          </tr>
        </thead>
        <tbody>
          {diseases.map((disease) => (
            <tr key={disease}>
              <td>
                <input
                  type="checkbox"
                  value={disease}
                  checked={selectedDiseases.includes(disease)}
                  onChange={handleDiseaseChange}
                />
                {disease}
              </td>
              <td>
                {genderOptions.map((gender) => (
                  <FormGroup check inline key={gender}>
                    <Input
                      type="radio"
                      name={`gender-${disease}`}
                      value={gender}
                      checked={selectedGender[disease] === gender}
                      onChange={(e) => handleGenderChange(e, disease)}
                    />
                    <Label>{gender}</Label>
                  </FormGroup>
                ))}
              </td>
              <td>
                {ageGroups.map((ageGroup) => (
                  <FormGroup check inline key={ageGroup}>
                    <Input
                      type="checkbox"
                      value={ageGroup}
                      checked={selectedAgeGroups[disease]?.includes(ageGroup)}
                      onChange={(e) => handleAgeGroupChange(e, disease)}
                    />
                    <Label>{ageGroup}</Label>
                  </FormGroup>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Footer with Approve/Reject Buttons */}
      <ModalFooter>
        <Button color="success" onClick={() => handleSubmit(true)}>
          Approve
        </Button>
        <Button color="danger" onClick={() => handleSubmit(false)}>
          Reject
        </Button>
      </ModalFooter>
    </Form>
  );
};

export default TrainingApproval;
