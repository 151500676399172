import React, { useState } from "react";
import { Button, TextField, Box, Typography } from "@mui/material";

const DietitianNotes = () => {
  const [notes, setNotes] = useState([""]); // Initialize with one empty text field

  // Handle adding a new text field
  const handleAddNote = () => {
    setNotes([...notes, ""]);
  };

  // Handle changing text in the text fields
  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = value;
    setNotes(updatedNotes);
  };

  // Handle save button click
  const handleSave = () => {
    console.log("Dietitian Notes:", notes);
  };

  return (
    <Box
      sx={{
        padding: 4,
        margin: "0 auto",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h6"color="text.secondary" gutterBottom>
        Today's Date: {new Date().toLocaleDateString()}
      </Typography>

      {notes.map((note, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
          <TextField
            fullWidth
            label={`Note ${index + 1}`}
            variant="outlined"
            value={note}
            onChange={(e) => handleNoteChange(index, e.target.value)}
          />
        </Box>
      ))}

      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNote}
          sx={{ flexGrow: 1 }}
        >
          Add Note
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleSave}
          sx={{ flexGrow: 1 }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default DietitianNotes;
