import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "@mui/material";
import { Card, Col, Row } from "reactstrap";
import "./style.css";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { toast } from "react-toastify";

export const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);
function Event({ event, title }) {
  return (
    <Button
      variant="contained"
      size="small"
      color="error"
      sx={{
        height: "16px", // equivalent to h-4
        paddingX: "4px", // equivalent to px-1
        fontSize: "10px", // equivalent to text-xs
        borderRadius: "4px", // equivalent to rounded-sm
        position: "absolute",
        bottom: "4px", // equivalent to bottom-1
        zIndex: 10, // equivalent to z-10
      }}
      // Uncomment and add functionality if needed
      // onClick={() => {
      //   deleteAvailability({
      //     ...event,
      //     slots: event.slots.map((time) => ({
      //       slotTime: time,
      //       slotDate: time,
      //     })),
      //   }).then(() => {
      //     window.location.reload();
      //   });
      // }}
    >
      Delete
    </Button>
  );
}

const DietitianManageSchedule = () => {
  const [events, setEvents] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const refreshViewOnce = () => {
    setRefresh(!refresh);
  };
  const handleView = (view) => {
    console.log(view);
  };

  const handleSelectSlot = (slot) => {
    const newEvents = [];
    const start = moment(slot.start);
    const end = moment(slot.end);

    // Iterate through each day in the selected range
    while (start.isSameOrBefore(end, "day")) {
      const eventStart = moment(start)
        .set({
          hour: moment(slot.start).hour(),
          minute: moment(slot.start).minute(),
        })
        .toDate();
      const eventEnd = moment(start)
        .set({
          hour: moment(slot.end).hour(),
          minute: moment(slot.end).minute(),
        })
        .toDate();

      // Log the selected slot with the respective date
      console.log(
        `Slot on ${moment(start).format(
          "YYYY-MM-DD"
        )}: ${eventStart} - ${eventEnd}`
      );

      newEvents.push({
        start: eventStart,
        end: eventEnd,
        title: "Available",
      });

      start.add(1, "day"); // Move to the next day
    }

    setEvents([...events, ...newEvents]);
  };

  console.log("events", events);

  // const handleSelectSlot = (slot) => {
  //   const newEvents = [];
  //   const start = moment(slot.start);
  //   const end = moment(slot.end);

  //   // Iterate through each day in the selected range
  //   while (start.isSameOrBefore(end, "day")) {
  //     let currentStartTime = moment(start)
  //       .set({
  //         hour: moment(slot.start).hour(),
  //         minute: moment(slot.start).minute(),
  //       })
  //       .toDate();

  //     const endTimeOfDay = moment(start)
  //       .set({
  //         hour: moment(slot.end).hour(),
  //         minute: moment(slot.end).minute(),
  //       })
  //       .toDate();

  //     // Create 30-minute interval events
  //     while (currentStartTime < endTimeOfDay) {
  //       const eventStart = moment(currentStartTime).toDate();
  //       const eventEnd = moment(currentStartTime).add(30, "minutes").toDate();

  //       newEvents.push({
  //         start: eventStart,
  //         end: eventEnd,
  //         title: "Available",
  //       });

  //       // Move to the next 30-minute slot
  //       currentStartTime = eventEnd;
  //     }

  //     start.add(1, "day"); // Move to the next day
  //   }

  //   setEvents([...events, ...newEvents]);
  // };

  const saveSlots = () => {
    // const slots = events.map((event) => {
    //   const scheduleDate = moment(event.start).isValid()
    //     ? moment(event.start).format("YYYY-MM-DD")
    //     : "Invalid date";

    //   const startTime = moment(event.start).isValid()
    //     ? moment(event.start).format("HH:mm")
    //     : "Invalid date";

    //   const endTime = moment(event.end).isValid()
    //     ? moment(event.end).format("HH:mm")
    //     : "Invalid date";

    //   return {
    //     scheduleDate: scheduleDate, // Extract the date only
    //     startTime: startTime, // Extract the start time
    //     endTime: endTime, // Extract the end time
    //     status: "Available", // Set status to "Available" or any other string
    //   };
    // });
    const slots = events.flatMap((event) => {
      const start = moment(event.start);
      const end = moment(event.end);
      const intervalSlots = [];

      // Split the event into 30-minute intervals
      while (start.isBefore(end)) {
        const intervalStart = start.clone();
        const intervalEnd = start.clone().add(30, "minutes");

        intervalSlots.push({
          scheduleDate: intervalStart.format("YYYY-MM-DD"),
          startTime: intervalStart.format("HH:mm"),
          endTime: intervalEnd.format("HH:mm"),
          status: "Available",
        });

        start.add(30, "minutes"); // Move to the next 30-minute slot
      }

      return intervalSlots;
    });
    console.log("slots", slots);
    AxiosInstance.post("dietitian/create-schedule", slots)
      .then((response) => {
        toast.success("Slot Saved Successfully");
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  // useEffect(() => {
  //   AxiosInstance.get(`dietitian/get-dietitian-session/1081`)
  //     .then((response) => {
  //       if (response != null) {
  //         const transformedEvents = response.map((session) => {
  //           return {
  //             start: new Date(
  //               `${session.scheduleDate}T${session.startTime}:00`
  //             ),
  //             end: new Date(`${session.scheduleDate}T${session.endTime}:00`),
  //             // title: `Session`,
  //             status: session.status || "Available",
  //           };
  //         });
  //         setEvents(transformedEvents);
  //       } else {
  //         setEvents([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err: ", err);
  //     });
  // }, []);

  useEffect(() => {
    AxiosInstance.get(`dietitian/get-dietitian-session/1081`)
      .then((response) => {
        if (response != null) {
          // Step 1: Sort the sessions by date and time
          const sortedSessions = response.sort((a, b) => {
            const dateTimeA = new Date(`${a.scheduleDate}T${a.startTime}:00`);
            const dateTimeB = new Date(`${b.scheduleDate}T${b.startTime}:00`);
            return dateTimeA - dateTimeB;
          });

          // Step 2: Merge consecutive sessions
          const mergedSessions = [];
          let currentSession = {
            start: new Date(
              `${sortedSessions[0].scheduleDate}T${sortedSessions[0].startTime}:00`
            ),
            end: new Date(
              `${sortedSessions[0].scheduleDate}T${sortedSessions[0].endTime}:00`
            ),
            scheduleDate: sortedSessions[0].scheduleDate,
          };

          for (let i = 1; i < sortedSessions.length; i++) {
            const session = sortedSessions[i];
            const sessionStart = new Date(
              `${session.scheduleDate}T${session.startTime}:00`
            );
            const sessionEnd = new Date(
              `${session.scheduleDate}T${session.endTime}:00`
            );

            if (
              session.scheduleDate === currentSession.scheduleDate &&
              currentSession.end.getTime() === sessionStart.getTime()
            ) {
              // If the session is consecutive, extend the current session's end time
              currentSession.end = sessionEnd;
            } else {
              // If the session is not consecutive, push the current session and start a new one
              mergedSessions.push({ ...currentSession });
              currentSession = {
                start: sessionStart,
                end: sessionEnd,
                scheduleDate: session.scheduleDate,
              };
            }
          }

          // Push the last session
          mergedSessions.push({ ...currentSession });

          // Step 3: Set the merged events to the state
          setEvents(mergedSessions);
        } else {
          setEvents([]);
        }
      })
      .catch((err) => {
        console.error("Error fetching sessions:", err);
      });
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col sm={12}>
          <Card>
            <DragAndDropCalendar
              selectable
              onSelectSlot={handleSelectSlot}
              events={events}
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              views={["week"]}
              view="week"
              onView={handleView}
              step={30}
              defaultDate={new Date()}
              min={new Date(new Date().setHours(0, 0, 0))} // Set start time to 10 AM
              max={new Date(new Date().setHours(22, 0, 0))}
              onNavigate={(date) => console.log("Navigated to:", date)}
              components={{
                event: Event,
              }}
            />
          </Card>
        </Col>
      </Row>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={saveSlots}
          sx={{
            background: "#5353e9",
            color: "#fff",
            minWidth: "180px",

            "&:hover": { background: "#c60176" },
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default DietitianManageSchedule;
