import React, { useEffect, useState } from "react";
import trainer from "./images/trainer1.avif"
import trainer1 from "./images/triner.avif"
import trainer2 from "./images/trinrer.avif";
import { Card } from "reactstrap";

const TrainerHomePage = () => {
  return (
    <>
      <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Welcome Trainer Brand Partner</h2>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
            minHeight:"35rem"
          }}
        >
          <img src={trainer2} alt="Lab" style={{  width: "30rem", padding: "10px" }} />
        </Card>
        <Card
          style={{
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid black",
          }}
        >
          <img src={trainer1} alt="Lab" style={{ width: "30rem", padding: "10px" }} />
        </Card>
      </div>
    </>
  );
};
export default TrainerHomePage;
