import React, { useState } from "react";
import {
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { MdCheckCircle } from "react-icons/md";
import WellnessSurveyImage from "./WellnessSurveyImage.png";

const WellnessSurvey = () => {
  const [formData, setFormData] = useState({
    stressManagementProgram: "",
    wellnessHealthProgram: "",
    centralizedDigitalVault: "",
    personalizedHealthRiskAssessment: "",
    effectiveWellnessProgramReducingDiseases: "",
    wellnessProgramReducesAbsenteeism: "",
    wellnessProgramPreventsLifestyleDiseases: "",
    labServicesImportanceEmployeeWellness: "",
    onPremises: "",
    digital: "",
    counsellingServices: "",
    onDemandWorkshops: "",
    mentalHealthBenefits: "",
    employeeStressorsIdentification: "",
    stressManagementReduction: "",
    centralizedMedicalRecordsStorage: "",
    personalizedWellnessHealthRiskAssessment: "",
    quantifiedBenefitsDashboardMetrics: "",
    measurableAbsenteeismReduction: "",
    corporateWellnessProgramEnhancement: "",
    wellnessServiceDetails: "",
    name: "",
    email: "",
    contactNumber: "",
    company: "",
    location: "",
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    let newErrors = {};
    // ["name", "email", "contactNumber", "company", "location"].forEach(
    //   (field) => {
    //     if (!formData[field].trim()) {
    //       newErrors[field] = "This field is required";
    //     }
    //   }
    // );
    Object.keys(formData).forEach((field) => {
      if (!formData[field].trim() && field !== "wellnessServiceDetails") {
        newErrors[field] = "This field is required";
      }
    });
    if (
      formData.corporateWellnessProgramEnhancement === "Yes" &&
      !formData.wellnessServiceDetails.trim()
    ) {
      newErrors.wellnessServiceDetails = "Please provide details";
    }
    if (
      formData.email &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Invalid email format";
    }
    if (formData.contactNumber && !/^\d{10}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = "Phone number must be of 10 digits";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_SERVER_URL}wellness-health/submit-survey`;
    try {
      const response = await axios.post(apiUrl, formData);
      setSubmitted(true);
    } catch (error) {
      console.error("Error submitting survey:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{
        backgroundColor: "white",
        width: "100%",
        minHeight: "100vh",
        padding: 0,
      }}
    >
      {submitted ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 2,
          }}
        >
          <div>
            <div>
              <MdCheckCircle size={50} color="green" />
            </div>
            <Typography variant="h6" sx={{ color: "green" }}>
              Your Response has been submitted Successfully.
            </Typography>
          </div>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              background: "linear-gradient(to right , cornflowerblue, white)",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                padding: { xs: "8px 8px", md: "8px 32px" },
                flex: 1.4,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#333",
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", md: "2.125rem" },
                }}
              >
                A Healthier Workplace Starts with You
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Did you know that <strong>92% of workers</strong> consider
                emotional well-being a top priority when choosing a job?
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Employee wellness isn’t just a perk; it’s essential. We want to
                understand how companies like yours approach health and wellness
                at work.
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                <b>Take this quick survey</b> and share your insights to help
                build better workplace wellness programs.
              </Typography>
            </Box>
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <img
                src={WellnessSurveyImage}
                alt="Wellness"
                style={{
                  width: "100%",
                  // maxWidth: "350px",
                  height: "auto",
                }}
              />
            </Box>
          </Box>

          <Box sx={{ backgroundColor: "#e2e8f0", mt: 2, padding: 4 }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: 3 }}
            >
              Please respond to the following wellness and health survey
              questions:
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {[
                  {
                    name: "stressManagementProgram",
                    question:
                      "1. We have a stress management program that allows us to identify, manage and reduce stress in workplace to prevent burnout and mental issues.",
                  },
                  {
                    name: "wellnessHealthProgram",
                    question:
                      "2. We have a wellness and health program that fully meets our employees needs.",
                  },
                ].map((q) => (
                  <Grid item xs={12} sm={6} key={q.name}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                        {q.question}
                      </FormLabel>
                      <RadioGroup
                        row
                        name={q.name}
                        value={formData[q.name]}
                        onChange={handleChange}
                        error={!!errors[q.name]}
                      >
                        {[
                          "Strongly agree",
                          "Agree",
                          "Neutral",
                          "Disagree",
                          "Strongly disagree",
                          "Do not have one",
                        ].map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                      {errors[q.name] && (
                        <Typography color="error" variant="body2">
                          {errors[q.name]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>

              <Grid container spacing={2}>
                {[
                  {
                    name: "centralizedDigitalVault",
                    question:
                      "3. It will be nice to have a centralized digital vault or wallet wherein all our employees’ medical records and data can be stored with 100% privacy and security so employees can access their records as and when they need it.",
                  },
                  {
                    name: "personalizedHealthRiskAssessment",
                    question:
                      "4. We have a wellness and health program that accurately assess the health risk of each employee and offers personalized solutions to each employee based on their specific needs.",
                  },
                  {
                    name: "effectiveWellnessProgramReducingDiseases",
                    question:
                      "5. The wellness and health program at our workplace is effective in reducing lifestyle diseases like obesity, diabetes, hypertension, CVD, cancer as well as mental disorder like anxiety, etc.",
                  },
                  {
                    name: "wellnessProgramReducesAbsenteeism",
                    question:
                      "6. The wellness program has measurably reduced absenteeism and improved productivity in our organization.",
                  },
                  {
                    name: "wellnessProgramPreventsLifestyleDiseases",
                    question:
                      "7. The wellness program in our organization prevents lifestyle disease in our employees as well as their families while providing appropriate cure to those who are already suffering from these diseases (Obesity, hypertension, diabetes, CVD, cancer, PCOD, Thyroid, etc).",
                  },
                ].map((q) => (
                  <Grid item xs={12} sm={6} key={q.name}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                        {q.question}
                      </FormLabel>
                      <RadioGroup
                        row
                        name={q.name}
                        value={formData[q.name]}
                        onChange={handleChange}
                        error={!!errors[q.name]}
                      >
                        {[
                          "Strongly agree",
                          "Agree",
                          "Neutral",
                          "Disagree",
                          "Strongly disagree",
                        ].map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                      {errors[q.name] && (
                        <Typography color="error" variant="body2">
                          {errors[q.name]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>

              <FormLabel
                sx={{ fontWeight: "bold", color: "black", mt: 3, mb: 2 }}
              >
                8. The Wellness program in our organization has following
                attributes.
              </FormLabel>

              <Grid container spacing={2}>
                {[
                  {
                    name: "onPremises",
                    question: "On-premises",
                  },
                  {
                    name: "digital",
                    question: "Digital",
                  },
                  {
                    name: "counsellingServices",
                    question:
                      "Counselling services (dietician, physiotherapist, psychiatrist, etc)",
                  },
                  {
                    name: "onDemandWorkshops",
                    question: "On demand workshops",
                  },
                  {
                    name: "labServicesImportanceEmployeeWellness",
                    question: "Lab Services",
                  },
                  {
                    name: "mentalHealthBenefits",
                    question: "Mental health benefits",
                  },
                  {
                    name: "employeeStressorsIdentification",
                    question:
                      "Identification of stressors in employees (stressors meaning what may be causing stress)",
                  },
                  {
                    name: "stressManagementReduction",
                    question: "Stress Management and reduction ",
                  },
                  {
                    name: "centralizedMedicalRecordsStorage",
                    question: "Centralized Medical records storage",
                  },
                  {
                    name: "personalizedWellnessHealthRiskAssessment",
                    question:
                      "Personalized wellness & health services based on risk assessment of each employee",
                  },
                  {
                    name: "quantifiedBenefitsDashboardMetrics",
                    question:
                      "Quantified benefits to employees as well as Corporate (Dashboard of metrics)",
                  },
                  {
                    name: "measurableAbsenteeismReduction",
                    question:
                      "Measurable reduction in absenteeism and increase in productivity as ROI",
                  },
                ].map((attr, index) => (
                  <Grid item xs={12} sm={6} key={attr.name}>
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                        {attr.question}
                      </FormLabel>
                      <RadioGroup
                        row
                        name={attr.name}
                        value={formData[attr.name]}
                        onChange={handleChange}
                        error={!!errors[attr.name]}
                      >
                        {["Yes", "No"].map((option) => (
                          <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={option}
                          />
                        ))}
                      </RadioGroup>
                      {errors[attr.name] && (
                        <Typography color="error" variant="body2">
                          {errors[attr.name]}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>

              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    9. We are looking to further improve (or start) our
                    corporate wellness and health program for employees and are
                    open to talking to wellness providers.
                  </FormLabel>
                  <RadioGroup
                    row
                    name="corporateWellnessProgramEnhancement"
                    value={formData.corporateWellnessProgramEnhancement}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                  {errors.corporateWellnessProgramEnhancement && (
                    <Typography color="error" variant="body2">
                      {errors.corporateWellnessProgramEnhancement}
                    </Typography>
                  )}
                </FormControl>
              </Box>

              {formData.corporateWellnessProgramEnhancement === "Yes" && (
                <Box mt={2}>
                  <FormLabel sx={{ fontWeight: "bold", color: "black" }}>
                    10. Please elaborate briefly what kind of wellness services
                    you are looking for:
                  </FormLabel>
                  <TextField
                    name="wellnessServiceDetails"
                    fullWidth
                    margin="normal"
                    onChange={handleChange}
                    multiline
                    rows={3}
                    inputProps={{ maxLength: 1000 }}
                    error={!!errors.wellnessServiceDetails}
                    helperText={errors.wellnessServiceDetails}
                  />
                </Box>
              )}

              <Box mt={2}>
                <Grid container spacing={2}>
                  {[
                    "name",
                    "contactNumber",
                    "email",
                    "company",
                    "location",
                  ].map((field) => (
                    <Grid item xs={12} sm={3} key={field}>
                      <TextField
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        name={field}
                        fullWidth
                        margin="normal"
                        onChange={handleChange}
                        error={!!errors[field]}
                        helperText={errors[field]}
                        {...(field === "contactNumber"
                          ? {
                              type: "tel",
                              inputProps: {
                                maxLength: 10,
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                                onInput: (e) =>
                                  (e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  )),
                              },
                            }
                          : field === "email"
                          ? { type: "email" }
                          : {})}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: 4,
                  marginBottom: 2,
                }}
              >
                LUCKY DRAW
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "black", marginBottom: 3 }}
              >
                First 100 responses will be put in a lucky draw.
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{ marginTop: 2 }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Box>
        </>
      )}
    </Container>
  );
};

export default WellnessSurvey;
