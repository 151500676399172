import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { GetChannelMessageResponseFilterSensitiveLog, } from "../models/models_0";
import { de_GetChannelMessageCommand, se_GetChannelMessageCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class GetChannelMessageCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "GetChannelMessage", {})
    .n("ChimeSDKMessagingClient", "GetChannelMessageCommand")
    .f(void 0, GetChannelMessageResponseFilterSensitiveLog)
    .ser(se_GetChannelMessageCommand)
    .de(de_GetChannelMessageCommand)
    .build() {
}
