import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Card, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import "./Admin.css";
import { toast } from "react-toastify";
import { MedicalLabSignUpModel } from "../SignUp/MedicalLabSignUpModel";
import { DoctorsSignUpModel } from "../SignUp/DoctorsSignUpModel";
import { CoperateSignUpModel } from "../SignUp/CoperateSignUpModel";
import { ResearchSignUpModel } from "../SignUp/ResearchSignUpModel";
import { DieticianSignUpModel } from "../SignUp/DieticianSignUpModel";
import { GymSignUpModel } from "../SignUp/GymSignUpModel";
import { TrainerSignUpModel } from "../SignUp/TrainerSignUpModel";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import { useNavigate } from "react-router";
import Spinner from "../../shared/Spinner";
import { PhysiotherapistSignUpModel } from "../SignUp/PhysiotherapistSignUpModel";
import axios from "axios";
const CreatePartners = () => {
  const [selectedRole, setSelectedRole] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [modelContent, setModelContent] = useState(null);
  const [partnersData, setPartnersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const roleTitles = {
    TESTLAB: "Medical Lab Partners Information",
    DOCTOR: "Doctors Information",
    DIETITIAN: "Dieticians Information",
    PHYSICIAN: "Physiotherapist Information",
    CORPORATE: "Corporate Partners Information",
    RESEARCH: "Research Partners Information",
    GYM: "Gym Partners Information",
    TRAINER: "Trainer Partners Information",
  };

  const handleRoleChange = (event) => {
    const userType = event.target.value;
    setSelectedRole(userType);

    // Set partners data based on the selected role
    if (userType != "") {
      fetchPartners(userType);
    }
  };

  const fetchPartners = async (userType) => {
    setLoading(true);
    console.log("selected tle", userType);
    await AxiosInstance.get(`api/admin/get-all-partners-detail/${userType}`)
      .then((response) => {
        if (response != null) {
          setPartnersData(response);
        } else {
          setPartnersData([]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err: ", err);
        setLoading(false);
      });
  };

  // Open the modal based on the selected role
  const handleCreatePartnersClick = () => {
    const selectedRoleObj = roles.find(
      (role) => role.partnerType === selectedRole
    );
    const selectedRoleId = selectedRoleObj ? selectedRoleObj.id : null;
    console.log("selectedId", selectedRoleId);
    switch (selectedRole) {
      case "TESTLAB":
        setModelContent(
          <MedicalLabSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "DOCTOR":
        setModelContent(
          <DoctorsSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "DIETITIAN":
        setModelContent(
          <DieticianSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "PHYSICIAN":
        setModelContent(
          <PhysiotherapistSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "CORPORATE":
        setModelContent(
          <CoperateSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "RESEARCH":
        setModelContent(
          <ResearchSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "GYM":
        setModelContent(
          <GymSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      case "TRAINER":
        setModelContent(
          <TrainerSignUpModel
            open={true}
            onClose={handleCloseModal}
            partnerTypeId={selectedRoleId}
            onSuccess={() => fetchPartners(selectedRole)}
          />
        );
        break;
      default:
        setModelContent(null);
    }
    setOpenModel(true); // Open the modal once the role is selected
  };
  const handleCloseModal = () => {
    setOpenModel(false);
    setModelContent(null);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}api/get-partner-type`)
      .then((response) => {
        // Transform role names
        const transformedRoles = response.data.data.map((role) => {
          let transformedRole = role.partnerType
            .toLowerCase()
            .replace(/_/g, " ");
          transformedRole = transformedRole
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          // Special cases for specific roles
          if (role.partnerType === "PHYSICIAN") {
            transformedRole = "Physiotherapist";
          } else if (role.partnerType === "TESTLAB") {
            transformedRole = "Medical Lab";
          }

          return {
            id: role.id,
            partnerType: role.partnerType,
            displayName: transformedRole,
          };
        });
        setRoles(transformedRoles);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  }, []);

  return (
    <>
      <Form
        style={{
          border: "1px solid lightgrey",
          padding: "15px",
          marginTop: "25px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          fontSize: "18px",
        }}
      >
        <h5>Select Partners: </h5>
        <hr style={{ border: "1px solid #000000", width: "12%" }} />
        <Card className="LabOrderDetailCard">
          <Col md={6}>
            <FormGroup>
              <Label for="roleSelect">
                <b>Partners:</b>
              </Label>
              <select
                className="custom-select"
                id="roleSelect"
                aria-label="Select Partner Role"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                onChange={handleRoleChange}
                value={selectedRole}
              >
                <option value="">Select Role</option>
                {roles?.map((role) => (
                  <option key={role.id} value={role.partnerType}>
                    {role.displayName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
        </Card>
        <br />
        {loading ? (
          <Spinner></Spinner>
        ) : partnersData.length > 0 ? (
          selectedRole != "" && (
            <Card className="LabOrderDetailCard">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h5>{roleTitles[selectedRole]}</h5>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1%" }}
                  onClick={handleCreatePartnersClick}
                >
                  Create {selectedRole}
                </Button>
              </div>
              <TableContainer>
                <Table
                  style={{
                    border: "1px solid lightgrey",
                    marginTop: "20px",
                    whiteSpace: "nowrap",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableCell">First Name</TableCell>
                      <TableCell className="tableCell">Last Name</TableCell>
                      <TableCell className="tableCell">Phone Number</TableCell>
                      <TableCell className="tableCell">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {partnersData.map((partner, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ border: "1px solid black" }}>
                          {partner.firstName}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {partner.lastName}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {partner.phoneNumber}
                        </TableCell>
                        <TableCell style={{ border: "1px solid black" }}>
                          {partner.email}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )
        ) : (
          selectedRole != "" && (
            <Card className="LabOrderDetailCard">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h5>{roleTitles[selectedRole]}</h5>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1%" }}
                  onClick={handleCreatePartnersClick}
                >
                  Create {selectedRole}
                </Button>
              </div>
              <Box mt={3} textAlign="center">
                <Typography variant="h6" color="error" fontWeight="bold">
                  No {selectedRole} Partner is Created Yet.
                </Typography>
              </Box>
            </Card>
          )
        )}
      </Form>
      {openModel && modelContent}
    </>
  );
};
export default CreatePartners;
