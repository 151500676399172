import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
  Grid,
} from "@mui/material";
import { AxiosInstance } from "../../configration/AxiosConfiguration";
import {
  getLabOrderDetailApi,
  getLabOrderDetailByIdApi,
} from "../../constants/apiList";
import AppLogo from "../../../static/img/AppLogoWithName.png";
import NoOrderImage from "./Images/no-order.jpeg";
import EditIcon from "@mui/icons-material/Edit";

const CorporateOrders = () => {
  const [rowData, setRowData] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const toggleModal = () => setModal(!modal);
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [updatedOrder, setUpdatedOrder] = useState(null);

  const getLabOrderDetail = async () => {
    await AxiosInstance.get(`${getLabOrderDetailApi}`)
      .then((response) => {
        if (response != null) {
          setRowData(response);
        } else {
          setRowData([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };
  const viewOrderDetail = (id) => {
    setOrderId(id);
    setModal(!modal);
  };
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "Issues") {
      Swal.fire({
        html: `
        <div>
          <p>Mention what issue you are facing to execute this home collection service.</p>
          <input type="text" id="swal-input1" class="swal2-input">
          <p><b>Note: </b>Please call our Account Manager for resolving if needed.</p>
        </div>
      `,
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Submit",
        cancelButtonText: "Cancel",
        showLoaderOnConfirm: true,
      });
    }
  };
  useEffect(() => {
    // getLabOrderDetail();
    // const today = new Date().toISOString().substr(0, 10);
    // setSelectedDate(today);
    AxiosInstance.get("corporate-order/getOrdersDetail")
      .then((response) => {
        if (response != null) {
          setOrders(response);
        } else {
          setOrders([]);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12; // Convert 0 hour to 12
    return `${formattedHour}:${minutes} ${period}`;
  };
  const filteredOrders = rowData.filter((order) => order.date === selectedDate);
  const handleRowClick = (order, index) => {
    setSelectedOrder(order);
    setClickedRowIndex(index);
  };
  const handleEdit = (order) => {
    setSelectedOrder(order);
    setUpdatedOrder({ ...order }); // Create a copy of the order to edit
    toggleModal();
  };

  // Handle input change in modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevOrder) => ({
      ...prevOrder,
      [name]: value,
    }));
  };

  // Handle Update action
  const handleUpdate = () => {
    if (updatedOrder) {
      console.log("updated", updatedOrder);
      // Call the API to update the order
    }
  };
  console.log("orders", orders, selectedOrder);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ margin: 0 }}>My Orders</h3>
        <div
          style={{
            border: "1px solid lightgrey",
            padding: "15px",
            display: "flex",
            alignItems: "center",
            width: "25%",
          }}
        >
          <b>Date :</b>
          <span style={{ marginLeft: "10px" }}>
            <input
              type="date"
              className="form-control"
              id="dateInput"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </span>
        </div>
      </div>
      <p style={{ marginTop: "1%" }}>
        <b>This screen will show you your orders from Uncurl: Health</b>
      </p>
      <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
        <Table style={{ whiteSpace: "nowrap" }}>
          <TableRow>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>LSH Order ID </b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: " rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Date</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Time</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Client Type</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Client Name</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Partner Type</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Partner Name</b>
              </p>
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <p style={{ fontSize: "18px" }}>
                <b>Order Status</b>
              </p>
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "rgb(83, 83, 233)",
                color: "white",
                border: "1px solid lightgrey",
                fontSize: "18px",
                padding: "8px",
              }}
            >
              <b>Action</b>
            </TableCell>
          </TableRow>
          <TableBody>
            {orders &&
              orders.map((orders, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(orders, index)}
                  // style={{
                  //   cursor: "pointer",
                  //   backgroundColor:
                  //     clickedRowIndex === index ? "red" : "whitesmoke",
                  //   transition: "background-color 0.3s ease",
                  // }}
                  // onMouseEnter={(e) => {
                  //   if (clickedRowIndex !== index)
                  //     e.currentTarget.style.backgroundColor = "red";
                  // }}
                  // onMouseLeave={(e) => {
                  //   if (clickedRowIndex !== index)
                  //     e.currentTarget.style.backgroundColor = "whitesmoke";
                  // }}
                >
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.purchaseOrderId}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.date}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.time ? formatTime(orders.time) : ""}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.clientType}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.clientName}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "20px",
                    }}
                  >
                    {orders.partnerType}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.partnerName}
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                      border: "1px solid lightgrey",
                      fontSize: "18px",
                      padding: "8px",
                    }}
                  >
                    {orders.orderStatus}
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "8px",
                    }}
                  >
                    <IconButton onClick={() => handleEdit(orders)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* {modal && (
        <div className="modal" style={{ display: "block" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5>Edit Order</h5>
              <Button onClick={toggleModal}>Close</Button>
            </div>
            <div className="modal-body">
              <TextField
                label="Purchase Order ID"
                name="purchaseOrderId"
                value={updatedOrder?.purchaseOrderId || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Date"
                name="date"
                value={updatedOrder?.date || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Time"
                name="time"
                value={updatedOrder?.time || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Client Name"
                name="clientName"
                value={updatedOrder?.clientName || ""}
                onChange={handleInputChange}
                fullWidth
              />
              <TextField
                label="Order Status"
                name="orderStatus"
                value={updatedOrder?.orderStatus || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            <div className="modal-footer">
              <Button onClick={handleUpdate} color="primary">
                Update
              </Button>
            </div>
          </div>
        </div>
      )} */}
      <Modal
        open={modal}
        onClose={toggleModal}
        aria-labelledby="edit-order-modal"
        aria-describedby="edit-order-modal-description"
      >
        {/* <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#f0f8ff", // Light blue background
            color: "#2c3e50", // Darker text
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              color: "#2980b9", // Blue title
              marginBottom: "15px",
            }}
            id="edit-order-modal"
          >
            Edit Order Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Purchase Order ID"
                name="purchaseOrderId"
                value={updatedOrder?.purchaseOrderId || ""}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Date"
                name="date"
                value={updatedOrder?.date || ""}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Time"
                name="time"
                value={updatedOrder?.time || ""}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client Name"
                name="clientName"
                value={updatedOrder?.clientName || ""}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Order Status"
                name="orderStatus"
                value={updatedOrder?.orderStatus || ""}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                sx={{
                  width: "150px",
                  marginTop: "15px",
                  backgroundColor: "#27ae60", // Green button
                  "&:hover": {
                    backgroundColor: "#2ecc71", // Lighter green on hover
                  },
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box> */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            bgcolor: "background.paper",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            backgroundColor: "#f5fafd",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: "#007acc", marginBottom: 3 }}
          >
            Edit Order Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Client Name"
                name="clientName"
                value={updatedOrder?.clientName || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client Address"
                name="clientAddress"
                value={updatedOrder?.clientAddress || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client Phone"
                name="clientPhone"
                value={updatedOrder?.clientPhone || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Client Type"
                name="clientType"
                value={updatedOrder?.clientType || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Date"
                name="date"
                value={updatedOrder?.date || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Lab Address"
                name="labAddress"
                value={updatedOrder?.labAddress || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Lab Phone"
                name="labPhone"
                value={updatedOrder?.labPhone || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Order Status"
                name="orderStatus"
                value={updatedOrder?.orderStatus || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Partner Name"
                name="partnerName"
                value={updatedOrder?.partnerName || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Partner Type"
                name="partnerType"
                value={updatedOrder?.partnerType || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Purchase Order ID"
                name="purchaseOrderId"
                value={updatedOrder?.purchaseOrderId || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Quantity"
                name="quantity"
                value={updatedOrder?.quantity || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Test Collection Date"
                name="testCollectionDate"
                value={updatedOrder?.testCollectionDate || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Test Collection Time"
                name="testCollectionTime"
                value={updatedOrder?.testCollectionTime || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Test Names"
                name="testNames"
                value={updatedOrder?.testNames || ""}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" sx={{ marginTop: 3 }}>
            <Button
              variant="contained"
              onClick={handleUpdate}
              sx={{
                backgroundColor: "#007acc",
                color: "#fff",
                "&:hover": { backgroundColor: "#005fa3" },
              }}
            >
              Update
            </Button>
          </Grid>
        </Box>
      </Modal>
      {/* <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader style={{ backgroundColor: "black", color: "white", border: "1px solid lightgrey" }} toggle={toggle}>
            <img src={AppLogo} alt="Lab" />
          </ModalHeader>
          <ModalBody>
            <OrderDetailModel orderId={orderId} />
          </ModalBody>
        </Modal>
      </div>
      {filteredOrders.length === 0 ? (
        <>
          <div style={{ marginTop: "7%", marginLeft: "35%" }}>
            <span>
              <h4 style={{marginBottom:"2%", marginLeft:"17px"}}>No Orders Available!</h4>
              <img src={NoOrderImage} alt="Lab" style={{width:"25%"}}/>
            </span>
          </div>
        </>
      ) : (
        filteredOrders.map((order) => (
        <>
          <TableContainer style={{ marginTop: "2%", border: "1px solid black" }}>
            <Table key={order.orderId}>
              <TableRow>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Date :</b> {order.date}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: "rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Time :</b> 10:00 AM
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>LSH Order ID :</b> {order.orderId}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: "rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <p style={{ fontSize: "18px" }}>
                    <b>Patient UHID :</b> {order.patientId}
                  </p>
                </TableCell>
                <TableCell style={{ backgroundColor: " rgb(83, 83, 233)", color: "white", border: "1px solid lightgrey", fontSize: "18px" }}>
                  <b>LRID :</b> {order.testDetail.some((test) => test.lrid) ? order.testDetail.map((test) => (test.lrid ? test.lrid : "Not Available")).join(", ") : "Not Available"}
            </TableCell>
          </TableRow>
          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Name</TableCell>
                  <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Cell</TableCell>
                  <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                Address
              </TableCell>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Test(s)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}>{order.patientDetail.patientName}</TableCell>
              <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}>{order.patientDetail.patientContactNumber}</TableCell>
                  <TableCell colSpan={2} style={{ border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                    {order.patientDetail.address.addressLine1 +
                      ", " +
                      order.patientDetail.address.city +
                      " " +
                      order.patientDetail.address.zipcode +
                      "( " +
                      order.patientDetail.address.state +
                      ", " +
                      order.patientDetail.address.country +
                      ")"}
                  </TableCell>
                  <TableCell style={{ border: "1px solid lightgrey", fontSize: "20px" }}> {order.testDetail.map((test) => test.testName).join(", ")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", textAlign: "center", fontSize: "20px", border: "1px solid lightgrey" }}>
                Order Copy
              </TableCell>
              <TableCell style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", border: "1px solid lightgrey", fontSize: "20px" }}>Order Status</TableCell>
            <TableCell colSpan={2} style={{ backgroundColor: "whitesmoke", fontWeight: "bolder", textAlign: "center", border: "1px solid lightgrey", fontSize: "20px" }}>
                Upload Report
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} style={{ border: "1px solid lightgrey", textAlign: "center", fontSize: "20px" }}>
                <a href="#" onClick={() => viewOrderDetail(order.orderId)}>
                      View Order Copy
                    </a>
              </TableCell>
              <TableCell style={{ border: "1px solid lightgrey" }}>
                <select className="form-control form-control-sm" onChange={handleSelectChange}>
                      <option selected disabled>
                        Order Generated
                      </option>
                  <option>Accepted</option>
                  <option>Sample Collected</option>
                  <option>Completed</option>
                  <option>Issues</option>
                </select>
              </TableCell>
              <TableCell colSpan={2} style={{ border: "1px solid lightgrey" }}>
                <center>
                  <input type="file" />
                </center>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
     </>
        ))
      )} */}
    </>
  );
};
export default CorporateOrders;
