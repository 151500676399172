import { getEndpointPlugin } from "@smithy/middleware-endpoint";
import { getSerdePlugin } from "@smithy/middleware-serde";
import { Command as $Command } from "@smithy/smithy-client";
import { commonParams } from "../endpoint/EndpointParameters";
import { ListChannelsRequestFilterSensitiveLog, ListChannelsResponseFilterSensitiveLog, } from "../models/models_0";
import { de_ListChannelsCommand, se_ListChannelsCommand } from "../protocols/Aws_restJson1";
export { $Command };
export class ListChannelsCommand extends $Command
    .classBuilder()
    .ep(commonParams)
    .m(function (Command, cs, config, o) {
    return [
        getSerdePlugin(config, this.serialize, this.deserialize),
        getEndpointPlugin(config, Command.getEndpointParameterInstructions()),
    ];
})
    .s("ChimeMessagingService", "ListChannels", {})
    .n("ChimeSDKMessagingClient", "ListChannelsCommand")
    .f(ListChannelsRequestFilterSensitiveLog, ListChannelsResponseFilterSensitiveLog)
    .ser(se_ListChannelsCommand)
    .de(de_ListChannelsCommand)
    .build() {
}
